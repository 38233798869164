import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, INews } from 'rebus-models';

@Component({
  selector: 'app-item-news-suggestion',
  templateUrl: './item-news-suggestion.component.html',
  styleUrls: ['./item-news-suggestion.component.scss'],
})
export class ItemNewsSuggestionComponent {

  @Input() public design: IDesign;
  @Input() public eventId: string;
  // TODO: @tote este modelo Inews no tiene campo match como lo solicitas en la plantilla
  @Input() public suggestionItem: INews | any;

  @Output() public evOnViewMoreNews = new EventEmitter<string>();

  public viewMore() {
    this.evOnViewMoreNews.emit(this.suggestionItem.id);
  }

}
