import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SERVICES } from '../shared/service/index';
import { COMPONENTS } from './components';
import { PAGES } from './pages';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InteractivtySharedModule } from '../interactivity/interactivity-shared.module';
import { TransactionsDetailComponent } from './components/profile-components/transactions-detail/transactions-detail.component';
import { HeaderSharedModule } from '../header/header-shared.module';
import {
  GuestListInvitationModalComponent
} from './components/guest-components/guest-list-invitation-modal/guest-list-invitation-modal.component';
import { CardModule } from 'ngx-card/ngx-card';
import { PaymentsDetailComponent } from './components/profile-components/payments-detail/payments-detail.component';
import { UserRoutingModule } from './user.router';
import { SelectPaymentMethodComponent } from './components/profile-components/select-payment-method/select-payment-method.component';
import { TermsPublicityPage } from './pages/terms/terms-publicity/terms-publicity.page';
import { TermsServicePage } from './pages/terms/terms-service/terms-service.page';
import { TransactionResumeComponent } from './components/profile-components/transaction-resume/transaction-resume.component';

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...PAGES,
  ],
  imports: [
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    InteractivtySharedModule,
    HeaderSharedModule,
    CardModule,
    UserRoutingModule
  ],
  providers: [
    SERVICES,
  ],
  entryComponents: [
    TransactionsDetailComponent,
    TransactionResumeComponent,
    GuestListInvitationModalComponent,
    PaymentsDetailComponent,
    SelectPaymentMethodComponent,
    TermsPublicityPage,
    TermsServicePage
  ]
})
export class UserSharedModule { }
