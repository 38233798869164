import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { IEvent } from 'rebus-models';

@Component({
  selector: 'app-event-widget',
  templateUrl: './event-widget.component.html',
  styleUrls: ['./event-widget.component.scss'],
})
export class EventWidgetComponent {

  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public event: IEvent;
  @Input() public views: number;

  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.event);
  }

}
