import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-agenda-item-widget',
  templateUrl: './agenda-widget.component.html',
  styleUrls: ['./agenda-widget.component.scss'],
})
export class AgendaWidgetComponent implements OnInit {

  @Input() public agenda: any;

  constructor(
    public modalCtrl: ModalController,
    private router: Router
  ) { }

  ngOnInit() {
  }

  public onGoEvent(item) {
    if (item.urlRedirect) {
      this.router.navigateByUrl(item.urlRedirect, { replaceUrl: true })
    }
    this.modalCtrl.dismiss()
  }

}
