import { environment } from '@app/env';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, of } from 'rxjs';
import { IEvent, IUser } from 'rebus-models';
import { toArray } from 'lodash';
import { isValidEventId } from '@helper/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as firebase from 'firebase/app';

@Injectable()
export class EventService {

  constructor(
    private afdb: AngularFireDatabase,
    private http: HttpClient,
    private authService: AuthService
  ) { }

  public getEventById(eventId): Observable<IEvent> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<IEvent>(`/Clients/${environment.clientId}/EventConfig/${eventId}`)
        .valueChanges();
    }
    return of(null);
  }

  public getEventList(): Observable<IEvent[]> {
    return this.afdb.list<IEvent>(`/Clients/${environment.clientId}/EventConfig`,
      ref => ref.orderByChild('state').equalTo(true))
      .valueChanges();
  }

  public getEventListSnap(): Promise<IEvent[]> {
    return this.afdb.list<IEvent>(`/Clients/${environment.clientId}/EventConfig`)
      .query.once('value').then(snap => snap.val()).then(collection => toArray(collection));
  }

  public getSubEvents(eventId: string): Observable<IEvent[]> {
    return this.afdb.list<IEvent>(`/Clients/${environment.clientId}/EventConfig`,
      ref => ref.orderByChild('eventId').equalTo(eventId))
      .valueChanges();
  }

  public getAllowedPrivateUsers(eventId: string): Observable<any[]> {
    return this.afdb.list<any>(`/Clients/${environment.clientId}/EventPrivateUser/${eventId}`)
      .valueChanges();
  }

  public async getAllowedPrivateUsersCodeByCode(eventId: string, code: string) {
    return this.afdb.list<any>(`/Clients/${environment.clientId}/EventPrivateUser/${eventId}`,
      ref => ref.orderByChild('code').equalTo(code)
    ).query.once('value').then(snap => snap.val())
  }

  public async getAllowedPrivateUsersCodeByUser(eventId: string, userId: string) {
    return this.afdb.list<any>(`/Clients/${environment.clientId}/EventPrivateUser/${eventId}`,
      ref => ref.orderByChild('userId').equalTo(userId)
    ).query.once('value').then(snap => snap.val())
  }

  public trackUTM(user, utm, eventId) {
    return this.afdb.object(`Clients/${environment.clientId}/___utm_click/${utm}/${user._id}`).query.once('value')
      .then(res => res.val())
      .then(res => {
        let send = {
          ...user.basicInformation,
          userId: user._id,
          eventId,
          click: 1,
          createdAt: firebase.database.ServerValue.TIMESTAMP,
          updatedAt: firebase.database.ServerValue.TIMESTAMP,
        }
        if (res) {
          send = {
            click: res.click += 1,
            updatedAt: firebase.database.ServerValue.TIMESTAMP,
          }
        }
        return this.afdb.object(`Clients/${environment.clientId}/___utm_click/${utm}/${user._id}`).update(send)
      })
  }

  public validateCodeEntry(eventId: string, key: string, update: any) {
    return this.afdb.object(`Clients/${environment.clientId}/EventPrivateUser/${eventId}/${key}`).update(update)
  }

  public async setRegisterEventAccess(user: IUser, eventId: string) {
    const token = await this.authService.getTokenUser().pipe(take(1)).toPromise();
    const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
    return this.http.post(`${environment.backend}/users/events/${user._id}/${eventId}`,
      {}, { headers }).toPromise();
  }

  public async getAllowedCodesPluginIntegration(eventId: string, code: string, userId: string, ancillaryId: string = null) {
    const clientId = environment.clientId;
    const token = await this.authService.getTokenUser().pipe(take(1)).toPromise();
    const headers = new HttpHeaders({ Authorization: token, 'Content-Type': 'application/json' });
    const body = {
      clientId,
      eventId,
      code,
      userId,
    }
    if (ancillaryId) {
      body['ancillaryId'] = ancillaryId
    }

    return this.http
      .post(
        `${environment.backend}/users/code/plugin_validate`,
        body,
        { headers }
      )
      .toPromise();
  }

}
