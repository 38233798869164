import { Component, OnInit, Input } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDesign } from 'rebus-models';
import { getDesign } from '@state/design';

@Component({
  selector: 'app-auth-required',
  templateUrl: './auth-required.component.html',
  styleUrls: ['./auth-required.component.scss']
})
export class AuthRequiredComponent implements OnInit {

  @Input() public redirectTo: string;
  @Input() public pageName: string;
  public design$: Observable<IDesign>;

  constructor(
    private store: Store<{}>,
    private navCtrl: NavController,
    private modalCtrl: ModalController
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
  }

  public onClose() {
    this.modalCtrl.dismiss().then(ok => {

    }, err => this.navCtrl.navigateRoot(''));
  }

  public goToLogin() {
    this.modalCtrl.dismiss();
    this.navCtrl.navigateForward(['/user/login'], { queryParams: { redirect: this.redirectTo } });
  }
}
