import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { IDesign, ISurveyQuestion, ISurveyAnswer } from 'rebus-models';
import { toArray } from 'lodash';

@Component({
  selector: 'app-survey-open',
  templateUrl: './survey-open.component.html',
  styleUrls: ['./survey-open.component.scss']
})
export class SurveyOpenComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public idxSurvey: number;
  @Input() public totalSurveys: number;
  @Input() public question: ISurveyQuestion;
  @Input() public userHasRecord: boolean;
  @Input() public userAnswerSurvey: any;

  @Output() public sendAnswer: EventEmitter<any> = new EventEmitter();

  public answerUser: any;

  public ngOnInit() {
    const surveyAnswerUser = toArray(this.userAnswerSurvey)[0];
    if (surveyAnswerUser) {
      const answerUser = toArray(surveyAnswerUser.answers).filter(answer => answer.questionId === this.question.id);
      this.answerUser = answerUser[0].answerUser;
    }
  }


  public changeAnswerSelected(answer) {
    this.sendAnswer.emit({
      question: this.question,
      questionId: this.question.id,
      answerUser: answer.detail.value,
      createdAt: new Date().getTime(),
    });
  }
}
