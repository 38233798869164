import { ActionReducerMap } from '@ngrx/store';

import * as fromConfig from './config/config.reducer';
import * as fromAuth from './auth/auth.reducer';
import * as fromMenu from './menu/menu.reducer';
import * as fromDesign from './design/design.reducer';
import * as fromUser from './user/user.reducer';
import * as fromLoading from './loading/loading.reducer';

export interface AppState {
  config: fromConfig.State;
  auth: fromAuth.State;
  menu: fromMenu.State;
  user: fromUser.State;
  design: fromDesign.State;
  loading: fromLoading.State;
}

export const appReducer: ActionReducerMap<AppState> = {
  config: fromConfig.reducer,
  auth: fromAuth.reducer,
  menu: fromMenu.reducer,
  user: fromUser.reducer,
  design: fromDesign.reducer,
  loading: fromLoading.reducer,
};
