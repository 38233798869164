import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getClient } from '../state/config/config.reducer';
import { map, tap } from 'rxjs/operators';
import { has } from 'lodash';

@Pipe({
  name: 'menu_config',
  pure: true
})
@Injectable()
export class MenuConfigPipe implements PipeTransform {

  transform(value: any): Observable<any> {
    return this.store.select(getClient).pipe(
      map(items => has(items, 'modules_config') ? items.modules_config : null),
      map(items => items[value.component] ? { ...items[value.component], component: value.component } : null),
    );
  }

  constructor(
    private store: Store<{}>,
  ) { }
}
