import { Component, OnInit, Input } from '@angular/core';
import { IDesign } from 'rebus-models';
import { NavController } from '@ionic/angular';
import { environment } from '@app/env';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getClient, getEvent } from '@state/config';
import { ActivatedRoute } from '@angular/router';
import { get } from 'lodash';

@Component({
  selector: 'app-empty-module',
  templateUrl: './empty-module.component.html',
  styleUrls: ['./empty-module.component.scss'],
})
export class EmptyModuleComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public firstLabel: string;
  @Input() public secondLabel: string;
  @Input() public image: string;
  @Input() public typeAction: string;
  @Input() public labelBtnAction = 'Volver al inicio';
  @Input() public module: string;
  @Input() public eventId = '--';

  public client$: Observable<any>;

  constructor(
    public navCtrl: NavController,
    private store: Store<{}>,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit() {
    const eventId = get(this.route.snapshot, 'params.eventId', '--')

    if (eventId === '--') {
      this.client$ = this.store.select(getClient);
    } else {
      this.client$ = this.store.select(getEvent);
    }
  }

  public onGoItems(module: string) {
    switch (module) {
      case 'products':
        this.navCtrl.navigateForward([`/product/${this.eventId}`]);
        break;
      case 'service':
        this.navCtrl.navigateForward([`/service/${this.eventId}`]);
        break;
      case 'tickets':
        this.navCtrl.navigateForward([`/ticket/${this.eventId}`]);
        break;
    }
  }

  public onGoModule() {
    if (!this.typeAction) {
      this.navCtrl.navigateForward(window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo);
    } else {
      switch (this.typeAction) {
        case 'send_pqr':
          this.navCtrl.navigateForward(['/pqr/new/--']);
          break;

        case 'go_coupons':
          this.navCtrl.navigateForward([`coupon/${this.eventId}`]);
          break;

        case 'go_news':
          this.navCtrl.navigateForward([`/news/${this.eventId}`]);
          break;
        default:
          break;
      }
    }
  }

}
