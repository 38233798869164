import { Component, OnInit, Input, Output, EventEmitter, ViewChild, SimpleChanges, OnChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IDesign, INetworkingChatRoom, INetworkingUsers } from 'rebus-models';
import { Store } from '@ngrx/store';
import { getUserBasicInfo } from '@state/user';
import { of } from 'rxjs';
import { SlaaskHidden } from '@decorator/slaask-hidden';

@Component({
  selector: 'app-chat-person',
  templateUrl: './chat-person.component.html',
  styleUrls: ['./chat-person.component.scss'],
})

@SlaaskHidden()

export class ChatPersonComponent implements OnInit, OnChanges {

  @ViewChild('slides') public slides: any;
  @Output() public evOnClose = new EventEmitter<any>();
  @Output() public evOnSendNotificationFromChat = new EventEmitter<any>();
  @Output() public evOnSendMessage = new EventEmitter<any>();
  @Output() public evSlideChangeChat = new EventEmitter<any>();
  @Input() public design: IDesign;
  @Input() public users: any;
  @Input() public userP: string;
  @Input() public chatUser: INetworkingChatRoom;
  public showLoader = false;
  public isScroll = false;
  public showModalDetailInfoUser = false;
  public dataToShow: string;
  public typeInfoDetail: string;
  public userActiveinSlide: any;
  public message = '';

  constructor(
    private modalCtrl: ModalController,
    private store: Store<any>
  ) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.users) {
      this.users = changes.users.currentValue;
    }
    if (changes.chatUser) {
      this.chatUser = changes.chatUser.currentValue;
    }
  }

  public onClose() {
    this.modalCtrl.dismiss();
    this.evOnClose.emit();
  }

  public onSendNotification(user) {
    this.evOnSendNotificationFromChat.emit(user);
  }

  public onSendMessage(userId, message) {
    this.evOnSendMessage.emit({ userId, message });
    this.message = '';
  }

  public handleSendMessage(keyCode, userId, message) {
    if (keyCode === 13) {
      this.evOnSendMessage.emit({ userId, message });
      this.message = '';
    }
  }

  public slideChange(users) {
    this.showLoader = true;
    setTimeout(() => {
      this.showLoader = false;
    }, 1000);
    this.slides.getActiveIndex().then(index => {
      if (users[index]) {
        this.userActiveinSlide = users[index];
      }
      this.evSlideChangeChat.emit(this.userActiveinSlide);
    });
  }

  public prevSlide() {
    this.slides.slidePrev();
  }

  public nextSlide() {
    this.slides.slideNext();
  }

  /**
   *
   * @param sender id del usuario que envio el mensaje
   * @param uid id de usuario autenticado
   * @param sdata data del usuario que envio el mensaje
   */
  public getUserByMessage(sender: string, uid: string, sdata: any) {
    return sender === uid ?
      this.store.select(getUserBasicInfo) :
      of(sdata);
  }


  public getPrincipalUser() {
    return this.store.select(getUserBasicInfo);
  }

  public scrollChat() {
    const containerChat = document.getElementById('chatContainer');
    containerChat.scrollTop = containerChat.scrollHeight;
  }

  public calculateScrollContainerChat() {
    const containerChat = document.getElementById('chatContainer');
    if (containerChat) {
      containerChat.scrollTop = containerChat.scrollHeight;
    }
    return true;
  }

  public onShowModalDetailInfoUser(data, type) {
    this.showModalDetailInfoUser = true;
    this.typeInfoDetail = type;
    this.dataToShow = data;
  }

  public onHiddenModalDetailInfoUser() {
    this.showModalDetailInfoUser = false;
  }

}
