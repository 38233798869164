import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderBy',
})
export class OrderByPipe implements PipeTransform {

  public transform(value: any[], by: string, isMultiLanguage: boolean = false, reverse: boolean = false) {
    return value.sort((a: any, b: any) => {
      if (isMultiLanguage) {
        return reverse ? b[by].en - a[by].en : a[by].en - b[by].en;
      } else {
        return reverse ? b[by] - a[by] : a[by] - b[by];
      }
    });
  }
}
