import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IDesign } from 'rebus-models';
import { getClientDesign } from '@state/config';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-load-change-route',
  templateUrl: './load-change-route.component.html',
  styleUrls: ['./load-change-route.component.scss'],
})
export class LoadChangeRouteComponent implements OnInit {

  public design$: Observable<IDesign>;

  constructor(
    private store: Store<{}>,
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getClientDesign);
  }
}
