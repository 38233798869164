import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-survey-finish',
  templateUrl: './survey-finish.component.html',
  styleUrls: ['./survey-finish.component.scss']
})
export class SurveyFinishComponent {

  @Input() public design: IDesign;
  @Input() public totalAnswerUsers: number;

  @Output() public evOnClose = new EventEmitter<any>();


}
