import { Component, Input } from '@angular/core';
import { Icon } from 'rebus-models';

@Component({
  selector: 'app-print-icon',
  templateUrl: './print-icon.component.html',
  styleUrls: ['./print-icon.component.scss']
})
export class PrintIconComponent {

  @Input() public icon: Icon;
  @Input() public color: string;
  @Input() public class: string;
  @Input() public size: 'small' | 'large' | 'default' = 'default';

}
