import { Component, OnInit, ViewChild } from '@angular/core';
import { BarcodeFormat } from '@zxing/library';
import { BehaviorSubject, Observable } from 'rxjs';
import { ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { IDesign } from 'rebus-models';
import { getDesign } from '@state/design';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
// import { ScanQrCouponResultPage } from '../scan-qr-coupon-result/scan-qr-coupon-result.page';

@Component({
  selector: 'app-scan-qr-coupon-reader',
  templateUrl: './scan-qr-coupon-reader.component.html',
  styleUrls: ['./scan-qr-coupon-reader.component.scss'],
})
export class ScanQrCouponReaderComponent implements OnInit {

  timeScan = 0;
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;

  @ViewChild('inputQr') inputQr;

  formatsEnabled: BarcodeFormat[] = [
    BarcodeFormat.CODE_128,
    BarcodeFormat.DATA_MATRIX,
    BarcodeFormat.EAN_13,
    BarcodeFormat.QR_CODE,
  ];

  hasDevices: boolean;
  hasPermission: boolean;
  qrResultString: string;
  torchEnabled = false;
  torchAvailable$ = new BehaviorSubject<boolean>(false);
  tryHarder = false;

  public design$: Observable<IDesign>;

  public menu_select = window.localStorage.getItem(`LAST-QR-OPEN`)

  constructor(
    public modalController: ModalController,
    private store: Store<{}>,
    private navCtrl: NavController,
    private notiService: NotificationsService
  ) { }

  ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.clearResult();
  }

  ngAfterViewChecked() {
    if (this.inputQr) {
      this.inputQr.setFocus()
    }
  }

  clearResult(): void {
    this.qrResultString = null;
    this.timeScan = 0;
  }

  onCamerasFound(devices: MediaDeviceInfo[]): void {
    this.availableDevices = devices;
    this.hasDevices = Boolean(devices && devices.length);
  }

  async onCodeResult(value: string) {
    if (value) {
      this.modalController.dismiss();
      this.openModalResultCoupon()
      this.notiService.showRebusToast('Cupon validado correctamente ', 'Success');
    }
  }

  async openModalResultCoupon() {
    // const modal = await this.modalController.create({
    //   component: ScanQrCouponResultPage,
    //   animated: true,
    //   showBackdrop: true,
    //   backdropDismiss: true,
    //   cssClass: 'custom-modal-scan-qr'
    // });
    // return await modal.present()
  }

  public onChangeQty(value) {
    if (value) {
      this.navCtrl.navigateRoot([`/scan-coupon/result/${value}`]);
      this.modalController.dismiss();
    }
  }

  onDeviceSelectChange(selected: string) {
    const device = this.availableDevices.find(x => x.deviceId === selected);
    this.currentDevice = device || null;
  }

  onHasPermission(has: boolean) {
    this.hasPermission = has;
  }
  onTorchCompatible(isCompatible: boolean): void {
    this.torchAvailable$.next(isCompatible || false);
  }

  toggleTorch(): void {
    this.torchEnabled = !this.torchEnabled;
  }

  toggleTryHarder(): void {
    this.tryHarder = !this.tryHarder;
  }

  public segmentChanged(ev: any) {
    window.localStorage.setItem(`LAST-QR-OPEN`, ev.detail.value);
    this.menu_select = ev.detail.value;
  }

}
