export const GLOBAL = {
  customParams: [
    { 'name': 'Document', 'id': 'dni', 'route': 'customInformation', 'model': 'user' },
    { 'name': 'Email', 'id': 'email', 'route': 'basicInformation', 'model': 'user' },
    { 'name': 'Name', 'id': 'name', 'route': 'basicInformation', 'model': 'user' },
    { 'name': 'Phone', 'id': 'phone', 'route': 'basicInformation', 'model': 'user' }
  ],
  version: '5',
  develop: '0',
  client: '0',
  // homepage: '/companies/--', // Esta variable configura la pagina de inicio con la que arrancará la app
  homepage: '/embed/-M9KCzlm3M-25UKOPCdN/--', // Esta variable configura la pagina de inicio con la que arrancará la app
  authRequired: true, // Esta variable configura si se requiere autenticacion obligatoria para navegar en la app
  toggleMenu: false, // Si el menu esta cerrado o abierto
  trackEvents: false, // Tracking en __track_events firebase
  modulesRequiredAuth: [], // Aca se configuran los modulos que requieren autenticacion para acceder
  initModeValidTicket: 'qr_input' // Aca se configuran el tab inicial en el verificador de QR
};
