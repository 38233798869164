import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ICoupon, IDesign } from 'rebus-models';

@Component({
  selector: 'app-coupon-widget',
  templateUrl: './coupon-widget.component.html',
  styleUrls: ['./coupon-widget.component.scss'],
})
export class CouponWidgetComponent {

  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public coupon: ICoupon;
  @Input() public views: number;
  @Input() public eventId: string;
  @Input() public userId: string;
  @Output() public evOnAdquiereCoupon = new EventEmitter<any>();
  @Output() public evOnDeleteCoupons = new EventEmitter<any>();
  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onAcquireCoupon(coupon) {
    this.evOnAdquiereCoupon.emit(coupon);
  }

  public onDeleteCoupon(coupon) {
    this.evOnDeleteCoupons.emit(coupon);
  }

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.coupon.id);
  }

}
