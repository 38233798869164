import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuController, ModalController, NavController, PopoverController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getAuthUID } from '@state/auth';
import { getClient } from '@state/config';
import { getUser, getUserBasicInfo } from '@state/user';
import { get, has } from 'lodash';
import { IBasicInformationUser, IUser } from 'rebus-models';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { CartService } from 'src/app/modules/cart/services/cart.service';
import { ClientService } from 'src/app/modules/shared/service/client.service';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public design: any;
  @Input() public pageName: string;
  @Input() public moduleFrom = '--'; // Módulo desde donde se hace la búsqueda
  @Input() public routeFrom: string; // Ruta desde donde se hace la búsqueda
  @Input() public returnRoute: string; // Ruta para botón 'volver'
  @Input() public isMenu: boolean;
  @Input() public isBackButton: boolean;
  @Input() public searchInput = '';
  @Input() public loadingActive = false;
  @Input() public loadingColor;
  @Input() public loadingType = 'indeterminate';
  @Input() public loadingValue = 0;

  @ViewChild('searchbar') public searchEl;

  public user: IUser;
  public blOpenSearchBar = false;
  public blOpenNotifications = false;
  public blOpenProfileInfo = false;
  public userInfo$: Observable<IBasicInformationUser>;
  public cartItems$: Observable<number>;
  public moduleStateShoppingCart$: Observable<boolean>;
  private unsubscribe$ = new Subject<void>();
  private e: Event;
  public footerActive;
  public userId: string;
  public eventId: string;
  public client$: Observable<any>;

  constructor(
    public navCtrl: NavController,
    private store: Store<{}>,
    private changeDetector: ChangeDetectorRef,
    private cartService: CartService,
    private popoverCtrl: PopoverController,
    private notifications: NotificationsService,
    private route: ActivatedRoute,
    private clienteService: ClientService,
    private menuCtrl: MenuController,
    private modalCtrl: ModalController,
    private translate: TranslateService,
  ) { }

  public async ngOnInit() {
    this.client$ = this.store.select(getClient)
    this.loadingColor = this.design ? this.design.firstColor : 'tertiary'
    this.eventId = get(this.route.snapshot, 'params.eventId') || '--';
    this.userId = await this.store.select(getAuthUID).pipe(take(1)).toPromise();
    this.moduleStateShoppingCart$ = this.clienteService.getModuleState(
      'shopping_cart',
      '--'
    );
    this.watchShoppingCart();
    this.userInfo$ = this.store.select(getUserBasicInfo);
    const user = await this.store.select(getUser).pipe(take(1)).toPromise();
    const uid = await this.store.select(getAuthUID).pipe(take(1)).toPromise();
    const userNotiPermission = await this.notifications.checkSubscription();
    // if (userNotiPermission) {
    //   const { endpoint } = userNotiPermission;
    //   // const token = last(endpoint.split('/'));
    // }
    // if (uid && !(userNotiPermission) && user) {
    //   const actionModalNotification = window.localStorage.getItem('userCloseSuscribeNotification' + uid);
    //   if (!actionModalNotification) {
    //     const popover = await this.popoverCtrl.create({
    //       component: PermissionNotificationsTooltipComponent,
    //       componentProps: { design: this.design, userId: uid, user: user },
    //       event: this.e,
    //       translucent: true,
    //       cssClass: 'rebus-match-tooltip'
    //     });
    //     return await popover.present();
    //   }
    // }

    // TODO: Esto por alguna puta razon hizo que perdieramos case 3 horas de trabajo porque edgar no nos deja avanzar
    // if (!this.isMenu) {
    //   this.menuCtrl.enable(false)
    // }
  }

  public watchShoppingCart() {
    this.cartItems$ = this.store.select(getAuthUID)
      .pipe(
        mergeMap(uid =>
          this.cartService.getShoppingCart(uid).pipe(
            map(cart => ({ numAmount: 0, load: false, ...cart })),
            tap(({ load }) => this.loadingActive = load),
            map(cart => cart.numAmount),
          ),
        ),
      );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.loadingColor) {
      this.loadingColor = changes.loadingColor.currentValue;
    }
    if (changes.loadingType) {
      this.loadingType = changes.loadingType.currentValue;
    }
    if (changes.loadingValue) {
      this.loadingValue = changes.loadingValue.currentValue;
    }
  }

  footerCallback(msg) {
    let target = "_self";
    if (has(msg, "is_external")) {
      if (msg.is_external) {
        target = "_blank"
      }
    }

    if (msg.callback !== '') {
      window.open(msg.callback, target);
    }
  }

  public onOpenSearchBar() {
    this.changeDetector.detectChanges();
    this.onClosePopups();
    this.blOpenSearchBar = !this.blOpenSearchBar;
    if (this.blOpenSearchBar) {
      setTimeout(() => {
        this.searchEl.setFocus();
      }, 1);
    }
  }

  public onOpenRecentSearch(search: string) {
    this.searchInput = search;
    this.goToResults();
  }

  public onDoSearch(event) {
    if (event.key === 'Enter' && this.searchInput.replace(/\s/g, '')) {
      this.goToResults();
    }
  }

  public goToResults() {
    this.navCtrl.navigateForward(['/search',
      this.routeFrom, this.moduleFrom, this.eventId, this.searchInput.trim()]).then(val => {
        this.blOpenSearchBar = false;
        this.searchInput = '';
      });
  }

  public onOpenNotifications() {
    this.onClosePopups();
    this.blOpenNotifications = !this.blOpenNotifications;
  }

  public onOpenProfileInfo() {
    this.onClosePopups();
    this.blOpenProfileInfo = !this.blOpenProfileInfo;
  }

  public onClosePopups() {
    this.blOpenNotifications = false;
    this.blOpenSearchBar = false;
    this.blOpenProfileInfo = false;
  }


  public onGoProfile() {
    this.navCtrl.navigateForward(['/user/profile/--']);
  }

  public onGoCart() {
    this.navCtrl.navigateForward(['/cart', this.eventId]);
  }

  public onLogin() {
    const queryParams = { redirect: this.routeFrom };
    this.navCtrl.navigateForward(['/user/login'], { queryParams });
  }

  public showFooterNotification(footer) {
    this.footerActive = footer;
  }

  // public async openModalSelectLenguage() {
  //   const modal = await this.modalCtrl.create({
  //     component: ModalLenguageSelectComponent,
  //     animated: true,
  //     backdropDismiss: true,
  //     componentProps: { eventId: this.eventId, design: this.design },
  //     cssClass: 'modal-lenguage-select',
  //     showBackdrop: false
  //   });
  //   await modal.present();
  // }

  public getLenguageSelected() {
    switch (window.localStorage.getItem('rebus-language')) {
      case 'en':
        return 'English'

      case 'es':
        return 'Español'

      default:
        return 'Español'
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
