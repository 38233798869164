import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, IStaff } from 'rebus-models';

@Component({
  selector: 'app-highlight-staff',
  templateUrl: './highlight-staff.component.html',
  styleUrls: ['./highlight-staff.component.scss']
})
export class HighlightStaffComponent {

  @Input() public design: IDesign;
  @Input() public data: IStaff;
  @Input() public match: number;

  @Output() public evOnClickViewMoreStaff = new EventEmitter<string>();

  public viewMore() {
    this.evOnClickViewMoreStaff.emit(this.data.id);
  }

}
