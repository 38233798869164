import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-top-messages-bar',
  templateUrl: './top-messages-bar.component.html',
  styleUrls: ['./top-messages-bar.component.scss']
})
export class TopMessagesBarComponent {
  @Input() design: any;
}
