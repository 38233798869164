import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { IDesign, ISurveyQuestion } from 'rebus-models';
import { toArray } from 'lodash';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';

@Component({
  selector: 'app-survey-multiple',
  templateUrl: './survey-multiple.component.html',
  styleUrls: ['./survey-multiple.component.scss']
})
export class SurveyMultipleComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public idxSurvey: number;
  @Input() public totalSurveys: number;
  @Input() public answers: any;
  @Input() public question: any;
  @Input() public userHasRecord: boolean;
  @Input() public userAnswerSurvey: any;

  @Output() public sendAnswer: EventEmitter<any> = new EventEmitter();
  @Output() public emitShowBtnContinue: EventEmitter<any> = new EventEmitter();

  public answerUser: any;

  constructor(
    private notiService: NotificationsService
  ) {
  }

  public ngOnInit() {
    const surveyAnswerUser = toArray(this.userAnswerSurvey)[0];
    if (surveyAnswerUser) {
      const answerUserArray = toArray(surveyAnswerUser.answers).filter(answer => answer.questionId === this.question.id);
      this.answerUser = answerUserArray[0].answerUser;

      this.answers = toArray(this.answers).map(item => {
        if (this.answerUser.includes(item.id)) {
          item.isChecked = true;
        }
        return item;
      });
    }
  }

  public changeAnswerSelected(answers, question) {
    const answersSelected = toArray(answers).filter(a => a.isChecked).map(item => item.id);
    const numberAnswerSelected = answersSelected.length;
    if (question.mutiple_min_answer || question.mutiple_max_answer) {
      if (numberAnswerSelected >= question.mutiple_min_answer && numberAnswerSelected <= question.mutiple_max_answer) {
        this.emitSelectOptions(answersSelected)
      } else {
        this.notiService.showRebusToast(`Debes de seleccionar minimo ${question.mutiple_min_answer} o maximo ${question.mutiple_max_answer} respuestas`, 'Error', false)
      }
    } else {
      this.emitSelectOptions(answersSelected)
    }
  }

  emitSelectOptions(answersSelected) {
    this.emitShowBtnContinue.emit(true)
    this.sendAnswer.emit(
      {
        question: this.question,
        questionId: this.question.id,
        answerUser: answersSelected,
        createdAt: new Date().getTime(),
      });
  }

}
