import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from '@app/env';
import { IEmbed, IEmbedConfig } from 'rebus-models';
import { isValidEventId } from '@helper/utils';

@Injectable()
export class EmbedService {

  constructor(
    private afdb: AngularFireDatabase,
  ) { }

  public getEmbedListWithMenu(eventId: string = null): Observable<IEmbed[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<IEmbed>(`/Clients/${environment.clientId}/Event/${eventId}/Embed`,
        ref => ref.orderByChild('withMenu').equalTo(true)).valueChanges();
    } else {
      return this.afdb.list<IEmbed>(`/Clients/${environment.clientId}/Interactivity/Embed`,
        ref => ref.orderByChild('withMenu').equalTo(true)).valueChanges();
    }
  }

  public getEmbedById(eventId: string, embedId: string): Observable<IEmbed> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<IEmbed>(`/Clients/${environment.clientId}/Event/${eventId}/Embed/${embedId}`)
        .valueChanges();
    } else {
      return this.afdb.object<IEmbed>(`/Clients/${environment.clientId}/Interactivity/Embed/${embedId}`)
        .valueChanges();
    }
  }

  public getConfigWidgetsEmbed(eventId: string): Observable<IEmbedConfig> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<IEmbedConfig>(`/Clients/${environment.clientId}/Event/${eventId}/EmbedConfig`)
        .valueChanges();
    } else {
      return this.afdb.object<IEmbedConfig>(`/Clients/${environment.clientId}/Interactivity/EmbedConfig`)
        .valueChanges();
    }
  }
}
