import { PrintIconComponent } from './print-icon/print-icon.component';
import { LoadPageComponent } from './load-page/load-page.component';
import { PublicityBannerComponent } from './publicity-banner/publicity-banner.component';
import { MarketCartComponent } from './market-cart/market-cart.component';
import { SuggestionsComponent } from './suggestions/suggestions.component';
import { VersionComponent } from './version/version.component';
import { LoadComponentComponent } from './load-component/load-component.component';
import { RebusIconComponent } from './rebus-icon/rebus-icon.component';
import { BtnMyDayComponent } from './btn-my-day/btn-my-day.component';
import { BtnPlanMyDayComponent } from './btn-plan-my-day/btn-plan-my-day.component';
import { BtnUploadFileComponent } from './btn-upload-file/btn-upload-file.component';
import { IconBtnMyDayComponent } from './icon-btn-my-day/icon-btn-my-day.component';
import { SlidesItemsSuggestionsComponent } from './slides-items-suggestions/slides-items-suggestions.component';
import { FilterItemGeneralComponent } from './filter-item-general/filter-item-general.component';
import { TopMessagesBarComponent } from './top-messages-bar/top-messages-bar.component';
import { AdSidebarComponent } from './ad-sidebar/ad-sidebar.component';
import { EventBannerComponent } from './event-banner/event-banner.component';
import { HighlightCouponComponent } from './highlight/highlight-coupon/highlight-coupon.component';
import { HighlightEventComponent } from './highlight/highlight-event/highlight-event.component';
import { HighlightProductComponent } from './highlight/highlight-product/highlight-product.component';
import { HighlightStaffComponent } from './highlight/highlight-staff/highlight-staff.component';
import { HighlightStandComponent } from './highlight/highlight-stand/highlight-stand.component';
import { HighlightTicketComponent } from './highlight/highlight-ticket/highlight-ticket.component';
import { LoadChangeRouteComponent } from './load-change-route/load-change-route.component';
import { AddItemShoppingCartWidgetComponent } from './add-item-shopping-cart-widget/add-item-shopping-cart-widget.component';
import { UploadFileComponent } from './upload-file/upload-file.component';
import { ValidateFormComponent } from './validate-form/validate-form.component';
import { DropdownInputComponent } from './dropdown-input/dropdown-input.component';
import { SubEventItemComponent } from './sub-event-item/sub-event-item.component';
import { UserImageComponent } from './user-image/user-image.component';
import { TplPopupFilterItemComponent } from './tpl-popup-filter-item/tpl-popup-filter-item.component';
import { WidgetRightComponent } from './widget-right/widget-right.component';
import { ShareItemComponent } from './share-item/share-item.component';
import { LoadSkeletonComponent } from './load-skeleton/load-skeleton.component';
import { ProductWidgetComponent } from './widget-right/product-widget/product-widget.component';
import { TicketWidgetComponent } from './widget-right/ticket-widget/ticket-widget.component';
import { EventWidgetComponent } from './widget-right/event-widget/event-widget.component';
import { CouponWidgetComponent } from './widget-right/coupon-widget/coupon-widget.component';
import { NewsWidgetComponent } from './widget-right/news-widget/news-widget.component';
import { ServiceWidgetComponent } from './widget-right/service-widget/service-widget.component';
import { StandWidgetComponent } from './widget-right/stand-widget/stand-widget.component';
import { StaffWidgetComponent } from './widget-right/staff-widget/staff-widget.component';
import { AuthRequiredComponent } from './auth-required/auth-required.component';
import { HighlightNewComponent } from './highlight/highlight-new/highlight-new.component';
import { HighlightServiceComponent } from './highlight/highlight-service/highlight-service.component';
import { ItemAgendaSuggestionComponent } from './items-suggestion-footer/item-agenda-suggestion/item-agenda-suggestion.component';
import { ItemProductSuggestionComponent } from './items-suggestion-footer/item-product-suggestion/item-product-suggestion.component';
import { ItemCouponSuggestionComponent } from './items-suggestion-footer/item-coupon-suggestion/item-coupon-suggestion.component';
import { ItemNewsSuggestionComponent } from './items-suggestion-footer/item-news-suggestion/item-news-suggestion.component';
import { ItemServiceSuggestionComponent } from './items-suggestion-footer/item-service-suggestion/item-service-suggestion.component';
import { ItemStaffSuggestionComponent } from './items-suggestion-footer/item-staff-suggestion/item-staff-suggestion.component';
import { ItemStandSuggestionComponent } from './items-suggestion-footer/item-stand-suggestion/item-stand-suggestion.component';
import { ItemTicketSuggestionComponent } from './items-suggestion-footer/item-ticket-suggestion/item-ticket-suggestion.component';
import { NetworkingWidgetComponent } from './widget-right/networking-widget/networking-widget.component';
import { EmptyModuleComponent } from './empty-module/empty-module.component';
import { ModalShareItemComponent } from './modal-share-item/modal-share-item.component';
import { RebusMatchComponent } from './rebus-match/rebus-match.component';
import { RebusMatchTooltipComponent } from './rebus-match-tooltip/rebus-match-tooltip.component';
import { SelectDatePickerComponent } from './select-date-picker/select-date-picker.component';
// tslint:disable-next-line: max-line-length
import { PermissionNotificationsTooltipComponent } from './permission-notifications-tooltip/permission-notifications-tooltip.component';
import { MessageNotificationsComponent } from '../message-notifications/message-notifications.component';
import { ModalAlertNotificationComponent } from '../modal-alert-notification/modal-alert-notification.component';
import { ModalInfoBrowserComponent } from './modal-info-browser/modal-info-browser.component';
import { ItemSponsorSuggestionComponent } from './items-suggestion-footer/item-sponsor-suggestion/item-sponsor-suggestion.component';
import { SponsorWidgetComponent } from './widget-right/sponsor-widget/sponsor-widget.component';
import { ViewFullImageComponent } from './view-full-image/view-full-image.component';

export const COMPONENTS = [
  PrintIconComponent,
  LoadPageComponent,
  LoadComponentComponent,
  PublicityBannerComponent,
  MarketCartComponent,
  SuggestionsComponent,
  VersionComponent,
  RebusIconComponent,
  BtnMyDayComponent,
  BtnPlanMyDayComponent,
  BtnUploadFileComponent,
  IconBtnMyDayComponent,
  SlidesItemsSuggestionsComponent,
  FilterItemGeneralComponent,
  ItemProductSuggestionComponent,
  ItemAgendaSuggestionComponent,
  ItemCouponSuggestionComponent,
  ItemSponsorSuggestionComponent,
  ItemNewsSuggestionComponent,
  ItemServiceSuggestionComponent,
  ItemStaffSuggestionComponent,
  ItemStandSuggestionComponent,
  ItemTicketSuggestionComponent,
  TopMessagesBarComponent,
  AdSidebarComponent,
  EventBannerComponent,
  HighlightCouponComponent,
  HighlightEventComponent,
  HighlightProductComponent,
  HighlightStaffComponent,
  HighlightStandComponent,
  HighlightTicketComponent,
  HighlightNewComponent,
  HighlightServiceComponent,
  LoadChangeRouteComponent,
  AddItemShoppingCartWidgetComponent,
  UploadFileComponent,
  ValidateFormComponent,
  DropdownInputComponent,
  SubEventItemComponent,
  UserImageComponent,
  SubEventItemComponent,
  TplPopupFilterItemComponent,
  WidgetRightComponent,
  ShareItemComponent,
  LoadSkeletonComponent,
  ProductWidgetComponent,
  NetworkingWidgetComponent,
  TicketWidgetComponent,
  EventWidgetComponent,
  CouponWidgetComponent,
  NewsWidgetComponent,
  SponsorWidgetComponent,
  ServiceWidgetComponent,
  StandWidgetComponent,
  StaffWidgetComponent,
  AuthRequiredComponent,
  EmptyModuleComponent,
  ModalShareItemComponent,
  RebusMatchComponent,
  RebusMatchTooltipComponent,
  SelectDatePickerComponent,
  PermissionNotificationsTooltipComponent,
  MessageNotificationsComponent,
  ModalAlertNotificationComponent,
  ModalInfoBrowserComponent,
  ViewFullImageComponent
];

