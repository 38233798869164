import { Component, OnInit, Input } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-btn-my-day',
  templateUrl: './btn-my-day.component.html',
  styleUrls: ['./btn-my-day.component.scss']
})
export class BtnMyDayComponent implements OnInit {

  @Input() public design: IDesign;

  constructor() { }

  public ngOnInit() {
  }

}
