import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IProduct } from 'rebus-models';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-product-widget',
  templateUrl: './product-widget.component.html',
  styleUrls: ['./product-widget.component.scss'],
})
export class ProductWidgetComponent {

  @Output() public addToCart = new EventEmitter();
  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public product: IProduct;
  @Input() public views: number;

  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.product.id);
  }

}
