import { Component, OnInit, Input } from "@angular/core";
import { IDesign } from "rebus-models";
import { Observable } from "rxjs";
import { getDesign } from "@state/design";
import { Store } from "@ngrx/store";
import { AuthService } from "../../../service/auth.service";
import { consoleDebug, consoleError } from "../../../helper/console.helper";
import { NotificationsService } from "../../../service/notifications.service";
import { ModalController } from "@ionic/angular";
import { environment } from "@app/env";
import { Router } from "@angular/router";
import { LogoutAction } from "src/app/modules/shared/state/auth/auth.actions";
import { take } from "rxjs/operators";

@Component({
  selector: "app-modal-validate-user",
  templateUrl: "./modal-validate-user.component.html",
  styleUrls: ["./modal-validate-user.component.scss"],
})
export class ModalValidateUserComponent implements OnInit {
  public design$: Observable<IDesign>;

  @Input() public user;
  @Input() public codeLinK;
  @Input() public typeValidation;

  loaderValidateCodeLinK = true;
  getNewCode = false
  timeToNewCode = 180;
  statusValidateLink = "Estamos validando tu cuenta";

  public value_1 = "";
  public value_2 = "";
  public value_3 = "";
  public value_4 = "";
  public value_5 = "";
  public value_6 = "";

  public code: string;
  public numberMask: string;

  constructor(
    private store: Store<{}>,
    private authService: AuthService,
    private notificationService: NotificationsService,
    private modalCtrl: ModalController,
    private router: Router
  ) {}

  ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.numberMask =
      "*********" + this.user.basicInformation.phone.substring(9);
    this.enabledResend()
  }

  enabledResend() {
    const interval = setInterval(() => {
      this.timeToNewCode--;
      if (this.timeToNewCode <= 0) {
        this.getNewCode = true;
        clearInterval(interval);
      }
    }, 1000);
  }

  typeInput(event) {
    // const inputValue = event.target.value;
    // if (inputValue.length === 6) {
    //   this.code = inputValue;
    //   this.validateCode();
    // }
  }

  async validateCode() {
    const code = this.code;
    const phone = this.user.basicInformation.phone;
    if (code) {
      await this.authService
        .validateOtpUserCode(code, phone)
        .pipe(take(1))
        .toPromise()
        .then(async (res) => {
          if (res) {
            window.localStorage.setItem(
              `${environment.clientId}-OTP-CODE-VALIDATE-${this.user._id}`,
              code.toString()
            );
            this.loaderValidateCodeLinK = false;
            this.statusValidateLink = "Cuenta validada correctamente";
            this.notificationService.showRebusToast(
              "Cuenta validada correctamente",
              "Success"
            );
            this.modalCtrl.dismiss();
            this.forceCloseModal();
            consoleDebug("Code Valid", null, res);
            return this.router.navigateByUrl(environment.redirectTo, {
              replaceUrl: true,
            });
          } else {
            this.value_1 = null;
            this.value_2 = null;
            this.value_3 = null;
            this.value_4 = null;
            this.value_5 = null;
            this.value_6 = null;
            return this.notificationService.showRebusToast(
              "El código ingresado es inválido, por favor inténtalo nuevamente",
              "Error",
              false
            );
          }
        })
        .catch((err) => {
          this.statusValidateLink = "El código es inválido";
          this.loaderValidateCodeLinK = false;
          this.notificationService.showRebusToast("El código ingresado no es válido", "Error");
          consoleError("Error occured", err);
        });
    } else {
      this.notificationService.showRebusToast(
        "Debes agregar un código válido para continuar",
        "Error"
      );
    }
  }

  async sendCodeEmail() {
    if (!this.getNewCode) return false;

    await this.authService
      .sendOtpCodeValidation(this.user.basicInformation.phone)
      .pipe(take(1))
      .toPromise()
      .then((res) => {
        this.getNewCode = false;
        this.timeToNewCode = 180;
        this.enabledResend();
        this.notificationService.showRebusToast(
          "El código ha sido enviado correctamente",
          "Success"
        );
      })
      .catch((err) => {
        this.notificationService.showRebusToast(
          "Error enviando el código, por favor inténtalo nuevamente más tarde",
          "Error"
        );
        consoleError("Error occured email sended", err);
      });
  }

  forceCloseModal() {
    document
      .querySelectorAll(".modal-validate-user-block")
      .forEach(function (a) {
        a.remove();
      });
  }

  public onLogout() {
    this.store.dispatch(new LogoutAction());
    return window.location.reload();
  }
}
