import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { IDesign } from 'rebus-models';
import { filter, find } from 'lodash';

@Component({
  selector: 'app-networking-availability',
  templateUrl: './networking-availability.component.html',
  styleUrls: ['./networking-availability.component.scss']
})
export class NetworkingAvailabilityComponent implements OnInit {

  @Input() public design: IDesign;
  @Output() public evNetworkingInfo = new EventEmitter<any>();
  public days: any[] = [{ 'day': 'Lu', 'active': false }, { 'day': 'Ma', 'active': false },
  { 'day': 'Mi', 'active': false }, { 'day': 'Ju', 'active': false }, { 'day': 'Vi', 'active': false },
  { 'day': 'Sa', 'active': false }, { 'day': 'Do', 'active': false }];
  public selectedDays: any[] = [];

  constructor(
  ) { }

  public ngOnInit() {
  }

  public onClickDay(day) {
    const clickedDay = find(this.days, ((d) => day.day === d.day));
    clickedDay.active = !clickedDay.active;

  }

  public onSave() {
    // this.evNetworkingInfo.emit();
    this.selectedDays = filter(this.days, ((d) => d.active));
  }

}
