import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from '@app/env';
import { isValidEventId } from '@helper/utils';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap, take } from 'rxjs/operators';
import * as moment from 'moment';
import * as firebase from 'firebase/app';
import { consoleError } from '../helper/console.helper';
import { select, Store } from '@ngrx/store';
import { getUser } from '@state/user';

@Injectable()
export class LivestreamService {

  constructor(
    private afdb: AngularFireDatabase,
    private http: HttpClient,
    private authService: AuthService,
    private store: Store<{}>
  ) { }

  public getLivestreamListWithMenu(eventId: string = null): Observable<any[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Event/${eventId}/Livestream`,
        ref => ref.orderByChild('withMenu').equalTo(true)).valueChanges();
    } else {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Interactivity/Livestream`,
        ref => ref.orderByChild('withMenu').equalTo(true)).valueChanges();
    }
  }

  public getLivestreamChannelListByUser(userId: string, livestreamId: string, eventId: string = null): Observable<any[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Event/${eventId}/LivestreamChannelUsersList/${userId}`,
        ref => ref.orderByChild('livestreamId').equalTo(livestreamId)
      ).valueChanges();
    } else {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Interactivity/LivestreamChannelUsersList/${userId}`,
        ref => ref.orderByChild('livestreamId').equalTo(livestreamId)
      ).valueChanges();
    }
  }

  public getLivestreamChatList(livestreamId: string, limit = 10, eventId: string = null): Observable<any[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Event/${eventId}/LivestreamChatList/${livestreamId}`, ref =>
        ref.orderByChild('created').limitToLast(limit)
      ).valueChanges();
    } else {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Interactivity/LivestreamChatList/${livestreamId}`, ref =>
        ref.orderByChild('created').limitToLast(limit)
      ).valueChanges();
    }
  }

  public async createMessage(livestreamId: string, value: any, eventId: string = null): Promise<any> {

    value.id = this.afdb.createPushId();
    value.created = new Date().getTime()

    let base = `/Clients/${environment.clientId}/Interactivity/LivestreamChatList`
    if (isValidEventId(eventId)) {
      base = `/Clients/${environment.clientId}/Event/${eventId}/LivestreamChatList`
    }

    return this.afdb
      .object(`${base}/${livestreamId}/${value.id}`)
      .set(value)
  }

  public async deleteChannel(livestream: any, eventId: string = null): Promise<any> {
    const promises = []
    let base = `/Clients/${environment.clientId}/Interactivity`
    if (isValidEventId(eventId)) {
      base = `/Clients/${environment.clientId}/Event/${eventId}`
    }

    livestream.users.map(user => {
      promises.push(
        this.afdb
          .object(`${base}/LivestreamChannelUsersList/${user.user_id}/${livestream.id}`).remove()
      )
    })
    return this.afdb
      .object(`${base}/LivestreamChannelList/${livestream.id}`)
      .remove()
      .then(() => Promise.all(promises))
  }

  public async getUrlChannelReslash(userId, userName, userEmail, url, meetingId) {
    const data: any = await from(this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        return this.http.post(`${environment.backend}/streaming/meeting-reslash-url`, {
          clientId: environment.clientId,
          userName,
          userEmail,
          url
        }, { headers })
      }))).pipe(take(1)).toPromise()

    let base = `/Clients/${environment.clientId}/Interactivity`
    this.afdb
      .object(`${base}/LivestreamChannelUsersList/${userId}/${meetingId}/value/embedUrl`)
      .set(data.embedUrl)

    return data.embedUrl
  }

  public async createChannel(value: any, userId: string, livestreamId: string, eventId: string = null): Promise<any> {
    const action = value.id ? 'update' : 'create'
    let updateLive = null
    value.created = new Date().getTime()
    if (value.id === '') {
      value.id = this.afdb.createPushId();
    }
    updateLive = value

    if (action === 'create') {
      try {
        let body = {}
        let meetingEp = 'meeting'
        if (environment.streaming_type === 'whereby') {
          body = {
            "startDate": moment().utc().format(),
            "endDate": moment().utc().add('days', 10).format(),
            "clientId": environment.clientId,
            "roomNamePrefix": value.name,
            "roomMode": 'group',
          }
        } else if (environment.streaming_type === 'reslash') {
          const user = await this.store.pipe(select(getUser), take(1)).toPromise()
          meetingEp = 'meeting-reslash'
          body = {
            "startDate": moment().utc().format(),
            "endDate": moment().utc().add('days', 10).format(),
            "clientId": environment.clientId,
            "roomName": value.name,
            "userName": user.basicInformation.name,
            "userEmail": user.basicInformation.email,
          }
        } else {
          return false
        }
        const room: any = await from(this.authService.getTokenUser().pipe(
          switchMap((token: string) => {
            const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
            return this.http.post(`${environment.backend}/streaming/${meetingEp}`, body, { headers })
          }))).pipe(take(1)).toPromise()

        if (environment.streaming_type === 'reslash') {
          value.url = room.url
          value.userEmail = room.userEmail
          value.embedUrl = room.embedUrl
        } else {
          value.url = `${room.roomUrl}?embed&iframeSource=${environment.whereby_domain}&screenshare=on&background=on`
          value.meetingId = room.meetingId
          value.startDate = room.startDate
          value.endDate = room.endDate
        }
        value.livestreamId = livestreamId
      } catch (e) {
        throw Error('Error creando la sala privada, por favor contacte al administrador')
      }
    }

    let base = `/Clients/${environment.clientId}/Interactivity`
    if (isValidEventId(eventId)) {
      base = `/Clients/${environment.clientId}/Event/${eventId}`
    }

    let promise_delete: any[] = []
    let nuevos: any[] = []
    if (action === 'update') {
      await this.afdb
        .object(`${base}/LivestreamChannelList/${value.id}`).query.ref.once('value').then(res => {
          const actual = res.val()
          const nuevo = value.users
          const eliminar = actual.users.map(user_actual =>
            nuevo.filter(i => i.user_id === user_actual.user_id).length ? null : user_actual
          )
          nuevos = nuevo.map(n =>
            actual.users.filter(i => i.user_id === n.user_id).length ? null : n
          ).filter(i => i)
          promise_delete = eliminar.filter(i => i).map(item =>
            this.afdb
              .object(`${base}/LivestreamChannelUsersList/${item.user_id}/${value.id}`)
              .remove()
          )
        })

      updateLive = await this.afdb
        .object(`${base}/LivestreamChannelList/${value.id}`)
        .query.ref.once('value').then(res => res.val())

      updateLive.users = value.users
      updateLive.name = value.name
    }

    // Se crean las promesas de los usuarios a crear
    const promises = []
    value.users.map(user => {
      const toUpdate = updateLive
      if (environment.streaming_type === 'reslash' && user.user_id !== userId) {
        toUpdate.embedUrl = ""
      }
      promises.push(
        this.afdb
          .object(`${base}/LivestreamChannelUsersList/${user.user_id}/${value.id}`)
          .update({ id: value.id, value: toUpdate, livestreamId, role: user.user_id === userId ? 'create' : 'invite' })
      )
    })

    return this.afdb
      .object(`${base}/LivestreamChannelList/${value.id}`)
      .update(updateLive)
      .then(() => Promise.all(promises))
      .then(() => this.afdb
        .object(`/Clients/${environment.clientId}/__chats_meeting/${value.id}`)
        .update({ id: value.id, created_by: userId, created: value.created })
      )
      .then(() => {
        if (action === 'update') {
          return Promise.all(promise_delete)
        }
        return
      })
      .then(() => this.afdb.object(`/Clients/${environment.clientId}/__chat_meeting_stats/total_users`)
        .query.ref.transaction(val => val + (action === 'update' ? nuevos.length : value.users.length)))
      .then(() => action === 'create' ? this.afdb.object(`/Clients/${environment.clientId}/__chat_meeting_stats/total_chats`)
        .query.ref.transaction(val => val + 1) : true)
      .then(() => action === 'create' ? this.afdb.object(`/Clients/${environment.clientId}/__chat_meeting_stats/livestream/${livestreamId}/total_chats`)
        .query.ref.transaction(val => val + 1) : true)
      .then(() => action === 'create' ? this.afdb.object(`/Clients/${environment.clientId}/__chat_meeting_stats/users/${userId}/total_chats`)
        .query.ref.transaction(val => val + 1) : true)
  }

  public getUsers(search = '', limit = 100, skip = 0) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        return this.http.post<any>(`${environment.backend}/users/v1/search/${environment.clientId}`, {
          search,
          skip,
          limit
        }, { headers });
      })
    );
  }

  public getChatsGlobalUsers(userId: string) {
    return this.afdb
      .object(`/Clients/${environment.clientId}/__chat_meeting_stats/users/${userId}/total_chats`)
  }

  public getChatsUsersGlobal() {
    return this.afdb
      .object(`/Clients/${environment.clientId}/__chat_meeting_stats/total_users`)
  }

  public getLivestreamById(eventId: string, livestreamId: string): Observable<any> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<any>(`/Clients/${environment.clientId}/Event/${eventId}/Livestream/${livestreamId}`)
        .valueChanges();
    } else {
      return this.afdb.object<any>(`/Clients/${environment.clientId}/Interactivity/Livestream/${livestreamId}`)
        .valueChanges();
    }
  }

  public getViewRecordLiveStreamUser(livestreamId: string, userId: string) {
    return this.afdb.list<any>(
      `/Clients/${environment.clientId}/__views_livestream/${livestreamId}`,
      ref => ref.orderByChild('userId').equalTo(userId)
    ).query.once('value').then(snap => snap.val());
  }

  public async createViewRecordLiveStream(livestreamId: string, userId: string, eventId: string) {
    const reference = `/Clients/${environment.clientId}/__views_livestream/`;
    const recordView = {
      eventId,
      createAt: firebase.database.ServerValue.TIMESTAMP,
      id: userId
    }
    const result = this.afdb.object(`${reference}/${livestreamId}/${userId}`).update(recordView).then(() => recordView)
      .catch((error) => {
        consoleError('Whooops, something happen', error);
      });
    return result;
  }

  public getViewRecordsLiveStream(livestreamId: string) {
    const reference = `/Clients/${environment.clientId}/__views_livestream/${livestreamId}`;
    return this.afdb.list<any>(reference)
      .valueChanges();
  }
}
