import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginPage } from './pages/login/login.page';
import { InterestPage } from './pages/interest/interest.page';
import { ResetPage } from './pages/reset/reset.page';
import { MenuGuard } from '../shared/guards/menu.guard';
import { AuthPagesGuard } from '../shared/guards/auth-pages.guard';
import { ProfilePage } from './pages/profile/profile.page';
import { LicensePage } from './pages/license/license.page';
import { GuestPage } from './pages/guest/guest.page';
import { ProfileInfoComponent } from './components/profile-components/profile-info/profile-info.component';
import { ProfilePaymentsComponent } from './components/profile-components/profile-payments/profile-payments.component';
import { ProfileTransactionsComponent } from './components/profile-components/profile-transactions/profile-transactions.component';

const routes: Routes = [
  { path: 'login', component: LoginPage, pathMatch: 'full', canActivate: [ AuthPagesGuard ] },
  { path: 'interest/:redirectTo', component: InterestPage, pathMatch: 'full', canActivate: [ ] },
  { path: 'reset/:token', component: ResetPage, pathMatch: 'full', canActivate: [ AuthPagesGuard ] },
  { path: 'license/:userId', component: LicensePage, pathMatch: 'full', canActivate: [AuthPagesGuard] },
  {
    path: 'profile', component: ProfilePage, canActivate: [ MenuGuard ], children: [
      { path: 'info', component: ProfileInfoComponent },
      { path: 'payments', component: ProfilePaymentsComponent },
      { path: 'transactions', component: ProfileTransactionsComponent },
      { path: '**', redirectTo: 'info' },
    ]
  },
  { path: 'guest/:userId', component: GuestPage, pathMatch: 'full', canActivate: [MenuGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UserRoutingModule { }
