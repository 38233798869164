import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { ITicket, ITicketShoppingCart } from 'rebus-models';
import { Store } from '@ngrx/store';
import { TicketService } from 'src/app/modules/ticket/services/ticket.service';

@Component({
  selector: 'app-highlight-ticket',
  templateUrl: './highlight-ticket.component.html',
  styleUrls: ['./highlight-ticket.component.scss']
})
export class HighlightTicketComponent {

  @Input() public design: IDesign;
  @Input() public data: ITicket;
  @Input() public match: number;
  @Input() public eventId = '--';

  @Output() public evOnClickViewMoreTicket = new EventEmitter<string>();

  constructor(
    private ticketService: TicketService,
    private store: Store<any>,
  ) { }

  public viewMore() {
    this.evOnClickViewMoreTicket.emit(this.data.id);
  }

  public async getCoupon(data: ITicket) {
    const ticket: ITicketShoppingCart = {
      ...data,
      plans: data.plans[0],
    };
    await this.ticketService.addTicketToShoppingCart(ticket, 1);
  }

}
