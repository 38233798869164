import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ITimelineLike } from 'rebus-models';
import { TimeLineService } from '../../timeline/services/timeline.service';
import { map } from 'rxjs/operators';

@Pipe({ name: 'validateLike' })
export class ValidateLikePipe implements PipeTransform {
  constructor(
    private timeLineService: TimeLineService
  ) { }
  public transform(timeLineId: string, userId: string, eventId: string): Observable<ITimelineLike[]> {
    return this.timeLineService.getLikePost(timeLineId, eventId).pipe(
      map(list => {
        return list.filter(item => item.userId === userId)
      })
    )
  }
}
