import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { toArray } from 'lodash';
import { ServicesService } from '../../service/services/services.service';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'priceEntityService'
})
export class PriceEntityServiceDiscountPipe implements PipeTransform {

  constructor(
    private servicesService: ServicesService,
  ) { }

  public transform(value: { [key: string]: { id: string } }, eventId: string): Observable<number[]> {
    const entityId = toArray(value);
    return this.servicesService.getServiceById(eventId, entityId[0].id).pipe(
      map(service => {
        if (service) {
          const modality = service.modality ? toArray(service.modality) : null;
          if (modality) {
            return modality.map(item => {
              return item.payPricePerBlock;
            });
          }
        }
      }),
    );
  }

}
