import { Component, Input, OnInit, OnDestroy, EventEmitter, Output } from '@angular/core';
import { ModalController, NavController, Platform, PopoverController } from '@ionic/angular';
import { SurveyPage } from '../../pages/survey/survey.page';
import { QuestionsCtrlPage } from '../../pages/questions-pages/questions-ctrl/questions-ctrl.page';
import { IDesign, IUser, TRecommendationEntity, ISurveyAnswer, ISurvey } from 'rebus-models';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUser } from '@state/user';
import { Observable, Subscription } from 'rxjs';
import { get } from 'lodash';
import { AIService } from 'src/app/modules/shared/service/ai.service';
import { tap, take, map, } from 'rxjs/operators';
import { isValidEventId } from '@helper/utils';
import { SurveyService } from '../../services/survey.service';
import { ModalActionSurveyComponent } from '../survey-components/modal-action-survey/modal-action-survey.component';
import { UtilitiesService } from '@service/utilities';
import { getClientModules, getEventModules } from '@state/config';
import { environment } from '@app/env';
import { LivestreamChatComponent } from 'src/app/modules/livestream/components/livestream-chat/livestream-chat-component';
import { LogoutAction } from 'src/app/modules/shared/state/auth/auth.actions';
import { AgendaWidgetPage } from '../../pages/agenda-widget/agenda-widget.page';
import { OpenMicComponent } from '../open-mic/open-mic.component';
import { NetworkingService } from 'src/app/modules/networking/services/networking.service';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { ModalPendingMessagesNetworkingComponent } from 'src/app/modules/networking/pages/modal-pending-messages-networking/modal-pending-messages-networking.component';

@Component({
  selector: 'app-interactivity-bar',
  templateUrl: './interactivity-bar.component.html',
  styleUrls: ['./interactivity-bar.component.scss']
})
export class InteractivityBarComponent implements OnInit, OnDestroy {

  @Input() public design: IDesign;
  @Input() public customButton: string;
  @Input() public livestreamInfo: any;
  @Output() public evEmitOpenLiveStream = new EventEmitter()

  public blShowSideDialog: Boolean = false;
  public sideDialogModule: String = '';
  public actualUrl: string;
  public activeSurveySusb: Subscription;
  public modules$: Observable<any>;
  public user$: Observable<any>;
  public recommendationProducts$: Observable<any>;
  public totalRecomendations: number;
  public eventId: string;
  public user: IUser;
  public userSurvey$: Observable<ISurveyAnswer[]>;
  public chatNotificationsUser$: Observable<any[]>;
  public survey$: Observable<ISurvey>;
  public displayInteractivity = true;

  constructor(
    private modalCtrl: ModalController,
    private router: Router,
    private store: Store<{}>,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private aiService: AIService,
    private surveyService: SurveyService,
    private ut: UtilitiesService,
    private platform: Platform,
    private networkingService: NetworkingService,
    private notiService: NotificationsService
  ) { }

  public async ngOnInit() {
    this.eventId = get(this.route.snapshot, 'params.eventId');
    this.actualUrl = this.router.url;
    if (isValidEventId(this.eventId)) {
      this.modules$ = this.store.select(getEventModules).pipe(
        map(({ networking, catalog, questions, opinometer, home, diary, open_mic }) => ({ networking, catalog, questions, opinometer, home, diary, open_mic })),
        tap(modules => {
          this.displayInteractivity = false
          for (let m in modules) {
            if (modules[m]) {
              this.displayInteractivity = true
            }
          }
        })
      );
    } else {
      this.modules$ = this.store.select(getClientModules).pipe(
        map(({ networking, catalog, questions, opinometer, home, diary, open_mic }) => ({ networking, catalog, questions, opinometer, home, diary, open_mic })),
        tap(modules => {
          this.displayInteractivity = false
          for (let m in modules) {
            if (modules[m] === true) {
              this.displayInteractivity = true
            }
          }
        })
      );
    }
    this.user$ = this.store.select(getUser).pipe(
      tap((user) => {
        if (user) {
          this.user = user;
          this.chatNotificationsUser$ = this.networkingService.getChatNotificationsByUser(user._id)
          this.recommendationProducts$ = this.getRecommendations('Products', user);
          this.validateActionUrl();
        }
        return user;
      })
    );

    this.getUserSurvey();
  }

  public getUserSurvey() {
    this.activeSurveySusb = this.surveyService.getActiveSurvey(this.eventId).pipe(
      tap(async survey => {
        if (this.user && survey) {
          this.userSurvey$ = this.surveyService.getSurveyByUser(this.user._id, this.eventId, survey);
          const list = await this.surveyService.getSurveyByUser(this.user._id, this.eventId, survey).pipe(take(1)).toPromise();
          if (list.length === 0) {
            this.survey$ = this.surveyService.getSurvey(survey);
            const surveyData = await this.surveyService.getSurvey(survey).pipe(take(1)).toPromise();
            this.handleModalNotificationSurvey(surveyData, survey);
          }
        }
      })
    ).subscribe();
  }

  public async handleModalNotificationSurvey(dataSurvey, survey) {
    const actionSurvey = window.localStorage.getItem('actionSurvey' + survey);
    if (dataSurvey) {
      if (dataSurvey.general.isAlert && !actionSurvey) {
        const modal = await this.modalCtrl.create({
          component: ModalActionSurveyComponent,
          componentProps: { user: this.user, design: this.design },
          cssClass: 'custom-modal-survey-noti',
          animated: true,
          backdropDismiss: true,
        });
        await modal.present();
        modal.onDidDismiss().then((data: any) => {
          if (data.data === 'close') {
            window.localStorage.setItem(`actionSurvey` + survey, 'true');
          }
          if (data.data === 'go') {
            if (this.validateDevice('mobile') || this.validateDevice('mobileweb')) {
              this.onOpenModal('survey');
            }
            if (this.validateDevice('desktop')) {
              this.onOpenSideDialog('survey');
            }
          }
        });
      }
    }
  }

  public getRecommendations(entity: TRecommendationEntity, user): Observable<any> {
    const eid = isValidEventId(this.eventId) ? this.eventId : null;
    return this.aiService.getRecommendationsForUser(get(user, '_id', null), entity, null, eid);
  }

  public onOpenSideDialog(module: String) {
    this.blShowSideDialog = true;
    this.sideDialogModule = module;
  }

  public onGoNetworking() {
    this.navCtrl.navigateForward(['/networking/', this.eventId || '--']);
  }

  public async onOpenModalPendingMessage(notificationsNumber) {
    if (notificationsNumber > 0) {
      const modal = await this.modalCtrl.create({
        component: ModalPendingMessagesNetworkingComponent,
        componentProps: { userId: this.user._id, design: this.design, eventId: this.eventId },
        cssClass: 'modal-pending-message',
        animated: true,
        backdropDismiss: true,
        showBackdrop: false,
      });
      await modal.present();
    } else {
      this.onGoNetworking()
    }
  }

  public onGoHome() {
    this.navCtrl.navigateForward(window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo);
  }

  public onCloseSideDialog(event) {
    this.blShowSideDialog = false;
    this.sideDialogModule = '';
  }

  public async onOpenModal(module: String) {
    let component;
    let componentProps: any = { eventId: this.eventId };
    switch (module) {
      case 'survey':
        component = SurveyPage;
        break;
      case 'questions':
        component = QuestionsCtrlPage;
        break;
      case 'streaming':
        component = LivestreamChatComponent;
        componentProps = { eventId: this.eventId, info: this.livestreamInfo, userId: this.user._id, design: this.design }
        break;
      case 'agenda':
        component = AgendaWidgetPage;
        componentProps = { eventId: this.eventId, design: this.design }
        break;
      case 'openMic':
        component = OpenMicComponent;
        componentProps = { eventId: this.eventId, design: this.design }
        break;
      default:
        component = null;
        break;
    }
    const modal = await this.modalCtrl.create({
      component: component,
      componentProps,
      animated: true,
      backdropDismiss: true,
    });
    return await modal.present();
  }

  public onGoProducts() {
    this.navCtrl.navigateForward(['/product/', this.eventId || '--']);
  }

  public onGoAprobacion(profile = null) {
    if (profile === 'view') {
      return this.navCtrl.navigateForward('/embed/-MAhcjwrL5ezJeobmarc/--');
    }
    this.navCtrl.navigateForward('/embed/-MAhXdeJqR3hb2WleQMr/--');
  }

  public validateDevice(device) {
    return this.ut.getDevice(device);
  }

  public ngOnDestroy() {
    if (this.activeSurveySusb) {
      this.activeSurveySusb.unsubscribe();
    }
  }

  private validateActionUrl() {
    const widthWindow = this.platform.width();
    this.route.queryParamMap.subscribe((result: any) => {
      if (get(result.params, 'action', false)) {
        if (result.params.action === 'open_question') {
          if (widthWindow >= 768) {
            return this.onOpenSideDialog('questions');
          }
          return this.onOpenModal('questions');
        }
      }
      if (get(result.params, 'action', false)) {
        if (result.params.action === 'open_survey') {
          if (widthWindow >= 768) {
            return this.onOpenSideDialog('survey');
          }
          return this.onOpenModal('survey');
        }
      }
    });
  }

}
