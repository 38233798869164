import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { environment } from '@app/env';
import { isValidEventId } from '@helper/utils';
import { IItem, IEmployee, IService, IServiceGroup, IServiceConfig, IServiceReserve } from 'rebus-models';
import { Store } from '@ngrx/store';
import { getAuthUID } from '@state/auth';
import { take } from 'rxjs/operators';
import { NotificationsService } from '../../shared/service/notifications.service';
import { CartService } from '../../cart/services/cart.service';
import { consoleError } from '../../shared/helper/console.helper';
import { TranslateObjectPipe } from '../../shared/pipes/translate-obj.pipe';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
const moment = extendMoment(Moment);

@Injectable({ providedIn: 'root' })
export class ServicesService {

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<any>,
    private notiService: NotificationsService,
    private cartService: CartService,
    private translate_obj: TranslateObjectPipe,
  ) { }


  public getServiceById(eventId: string, serviceId: string): Observable<IService> {
    let reference = `/Clients/${environment.clientId}/Interactivity/Services/${serviceId}`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Services/${serviceId}`;
    }
    return this.afdb.object<IService>(reference).valueChanges();
  }

  public getServiceGroups(eventId: string): Observable<IServiceGroup[]> {
    let reference = `/Clients/${environment.clientId}/Interactivity/ServiceGroups`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/ServiceGroups`;
    }
    return this.afdb.list<IServiceGroup>(reference).valueChanges();
  }

  public getServiceByServiceGroup(eventId: string, groupId: string): Observable<IService[]> {
    let reference = `/Clients/${environment.clientId}/Interactivity/Services`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Services`;
    }
    return this.afdb.list<IService>(reference,
      ref => ref.orderByChild('groupId').equalTo(groupId)).valueChanges();
  }

  public getConfigWidgetsService(eventId: string): Observable<IServiceConfig> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<IServiceConfig>(`/Clients/${environment.clientId}/Event/${eventId}/ServiceConfig`)
        .valueChanges();
    } else {
      return this.afdb.object<IServiceConfig>(`/Clients/${environment.clientId}/Interactivity/ServiceConfig`)
        .valueChanges();
    }
  }

  public getEmployeeById(eventId: string, employeeId: string): Observable<IEmployee> {
    let reference = `/Clients/${environment.clientId}/Interactivity/Employee/${employeeId}`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Employee/${employeeId}`;
    }
    return this.afdb.object<IEmployee>(reference).valueChanges();

  }

  // Obtiene una semana
  public get week() {
    const list = Array(7).fill(0);
    return list.map((_, i) => moment().add(i + 1, 'days'));
  }

  /**
   * getDateFromDayName
   * @param dayName 'Monday' | 'Sunday' | ... etc
   * @returns Devuelve un string de la fecha más cercana al dayName
   * en formato DD MM YYYY
   */
  public getDateFromDayName(dayName: string) {
    const day = this.week.find(m => m.locale('en').format('dddd') === dayName);
    return day.locale('es').format('DD MMMM YYYY');
  }

  public async addServiceToShoppingCart(service: IService, reserve: IServiceReserve) {
    const uid = await this.store.select(getAuthUID).pipe(take(1)).toPromise();
    const item = this.createServiceShoppingCartItem(service, reserve);
    const { quota } = service.modality[reserve.modality];
    if (quota <= 0) {
      this.notiService.showRebusToast(
        'Este servicio se encuentra agotado ',
        'Warning'
      );
      return;
    }
    try {
      const cart = await this.cartService.addShoppingCartService(uid, item);
      if (cart) {
        this.notiService.showRebusToast(`${this.translate_obj.transform(item.data.service.name)} agregado al carrito de compras `,
          'Success', true, null, null, 'product', uid, item.eventId, `cart/${item.eventId}`, `${item.data.service.photo}`);
      }
    } catch (error) {
      consoleError('err[0] carrito servicios', error),
        this.notiService.showRebusToast('Error agregando al carrito ', 'Error', false, null, null, 'product');
    }
  }



  private createServiceShoppingCartItem(service: IService, serviceReserve: IServiceReserve): IItem {
    const { payPricePerBlock, tax } = service.modality[serviceReserve.modality];
    return {
      addAt: Date.now(),
      basePrice: payPricePerBlock,
      data: { service, reserve: serviceReserve },
      discountApplied: null,
      eventId: service.eventId,
      id: service.id,
      price: payPricePerBlock * serviceReserve.dates.length,
      priceDiscount: payPricePerBlock * serviceReserve.dates.length,
      priceReference: payPricePerBlock,
      saleAmount: serviceReserve.dates.length,
      shop: null,
      tax,
      type: 'service',
    };
  }


}
