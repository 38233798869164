import { Action } from '@ngrx/store';

export const LOAD_DESIGN                      = '[ DESIGN ] Carga la configuracion del diseño específico';
export const LOAD_DESIGN_SUCCESS              = '[ DESIGN ] Carga la configuracion del diseño específico correctamente';
export const LOAD_DESIGN_ERROR                = '[ DESIGN ] Carga la configuracion del diseño específico con error';

export class LoadDesignAction implements Action {
  public readonly type = LOAD_DESIGN;
  constructor(public payload: string) { }
}

export class LoadDesignSuccessAction implements Action {
  public readonly type = LOAD_DESIGN_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadDesignErrorAction implements Action {
  public readonly type = LOAD_DESIGN_ERROR;
  constructor(public payload: any) { }
}

export type MenuActions =
  LoadDesignAction |
  LoadDesignSuccessAction |
  LoadDesignErrorAction;
