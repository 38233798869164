import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { ITicketPlan } from 'rebus-models';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss']
})
export class DropdownInputComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public placeholder: string;
  @Input() public items: any[];
  @Input() public disabled: Boolean = false;
  @Input() public moreEconomicPlan: ITicketPlan;
  @Input() public selectFilterDate: string;

  @Output() public evOnSelectItem = new EventEmitter<any>();

  public selectedItem: string;
  public blShowItems: Boolean = false;

  constructor() { }

  public ngOnInit() {

    // Validación para input en listado de pagos
    if (this.selectFilterDate) {
      this.selectedItem = this.selectFilterDate;
    }

    // Validación para plan seleccionado en tickets
    if (this.moreEconomicPlan) {
      this.selectedItem = this.moreEconomicPlan.location;
    }
  }

  public onSelectItem(item: any) {
    if (!this.disabled) {
      this.selectedItem = item.value || item;
      this.evOnSelectItem.emit(this.selectedItem);
      this.onToggleShowItems();
    }
  }

  public onToggleShowItems() {
    if (!this.disabled) {
      this.blShowItems = !this.blShowItems;
    }
  }

}
