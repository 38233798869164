import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { ITicket } from 'rebus-models';

@Component({
  selector: 'app-item-ticket-suggestion',
  templateUrl: './item-ticket-suggestion.component.html',
  styleUrls: ['./item-ticket-suggestion.component.scss'],
})
export class ItemTicketSuggestionComponent {

  @Input() public design: IDesign;
  @Input() public eventId: string;
  // TODO: @tote este modelo Iticket no tiene campo match como lo solicitas en la plantilla
  @Input() public suggestionItem: ITicket | any;

  @Output() public evOnViewMoreTicket = new EventEmitter<string>();

  public vieMore() {
    this.evOnViewMoreTicket.emit(this.suggestionItem.id);
  }

}
