import { Component, Input } from '@angular/core';
import { IDesign, IService } from 'rebus-models';

@Component({
  selector: 'app-highlight-service',
  templateUrl: './highlight-service.component.html',
  styleUrls: ['./highlight-service.component.scss'],
})
export class HighlightServiceComponent {

  @Input() public design: IDesign;
  @Input() public data: IService;
  @Input() public match: number;

}
