import { PersonNetworkingComponent } from './person-networking/person-networking.component';
import { ChatPersonComponent } from './chat-person/chat-person.component';
import { ChatMessageComponent } from './chat-message/chat-message.component';
import { NoRequestComponent } from './no-request/no-request.component';

export const COMPONENTS = [
  PersonNetworkingComponent,
  ChatPersonComponent,
  ChatMessageComponent,
  NoRequestComponent
];
