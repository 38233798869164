import { Action } from '@ngrx/store';
import { IClient, IEvent } from 'rebus-models';

export const LOAD_CLIENT = '[ CONFIG ] Carga la configuracion del cliente específico';
export const LOAD_CLIENT_SUCCESS = '[ CONFIG ] Carga la configuracion del cliente específico correctamente';
export const LOAD_CLIENT_ERROR = '[ CONFIG ] Carga la configuracion del cliente específico con error';
export const LOAD_EVENT = '[ CONFIG ] Carga la configuracion del evento en el que se encuentra el usuario';

export class LoadEventAction implements Action {
  public readonly type = LOAD_EVENT;
  constructor(public payload: IEvent) { }
}
export class LoadClientAction implements Action {
  public readonly type = LOAD_CLIENT;
}

export class LoadClientSuccessAction implements Action {
  public readonly type = LOAD_CLIENT_SUCCESS;
  constructor(public payload: IClient) { }
}

export class LoadClientErrorAction implements Action {
  public readonly type = LOAD_CLIENT_ERROR;
  // tslint:disable-next-line:no-any
  constructor(public payload: any) { }
}

export type ConfigActions =
  LoadEventAction |
  LoadClientAction |
  LoadClientSuccessAction |
  LoadClientErrorAction;
