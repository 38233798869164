import { Component, OnInit, Input } from '@angular/core';
import { IDesign } from 'rebus-models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'transaction-resume',
  templateUrl: './transaction-resume.component.html',
  styleUrls: ['./transaction-resume.component.scss'],
})
export class TransactionResumeComponent implements OnInit {


  @Input() public transactionInfo: any;
  @Input() public design: IDesign;
  @Input() public userId: string;
  @Input() public tracking: boolean; // Esto se usa para marcar un pixel o algun tracking analytics

  constructor(
    public modalCtrl: ModalController,
  ) { }

  public ngOnInit() {
    this.validateTracking();
  }

  public parseNameItem(nameItem: string) {
    switch (nameItem) {
      case 'service':
        return 'Servicio';
      case 'product':
        return 'Producto';
      case 'Ticket':
        return 'Ticket';
      default:
        break;
    }
  }

  public validPSP(pspType: string) {
    if (pspType === 'FAKE') {
      return 'Pago interno'
    }
    return pspType
  }

  private validateTracking() {
    if (this.tracking) {
      // TODO: Aca se hace tracking de algun punto analitico
    }
  }
}
