// This is a EntryComponent is used inside for others
// component from this same section like a
// optional template of list items.
import { Component, OnInit } from '@angular/core';
import { PopoverController, NavParams } from '@ionic/angular';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-tpl-popup-filter-item',
  templateUrl: './tpl-popup-filter-item.component.html',
  styleUrls: ['./tpl-popup-filter-item.component.scss'],
})
export class TplPopupFilterItemComponent implements OnInit {

  public categories = [];
  public design: IDesign;

  constructor(
    private popoverController: PopoverController,
    private navParams: NavParams
  ) { }

  public ngOnInit() {
    this.categories = this.navParams.get('items');
  }

  public async closePopover() {
    await this.popoverController.dismiss(this.categories);
  }

}