import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController } from '@ionic/angular';
import { UtilitiesService } from '@service/utilities';
import { IDesign, IModuleConfig, IQuestion, IQuestionConfig } from 'rebus-models';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { consoleError } from 'src/app/modules/shared/helper/console.helper';
import { ClientService } from 'src/app/modules/shared/service/client.service';
import { QuestionService } from '../../../services/question.service';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.page.html',
  styleUrls: ['./questions.page.scss'],
})
export class QuestionsPage implements OnInit, OnDestroy {
  @Input() public design: IDesign;
  @Input() public userId: string;
  @Input() public eventIdModal: string;

  @Output() public evOnClose = new EventEmitter<any>();
  @Output() public evOnOpenDetail = new EventEmitter<string>();
  public moduleConfig$: Observable<IModuleConfig>;
  public questionConfig$: Observable<IQuestionConfig>;
  public menu_select: 'recent' | 'archived' = 'recent';
  public recentQuestions$$ = new BehaviorSubject<IQuestion[]>(new Array<IQuestion>());
  public recentQuestions$: Observable<IQuestion[]>;
  public archivedQuestions$: Observable<IQuestion[]>;
  public questions = [];
  public eventId: string;
  public questionText: string = '';
  private questionToSend: IQuestion;
  private prevTitle: string = '';
  private unsubscribe$ = new Subject<void>();

  public limit = 10;
  public loader = true;

  constructor(
    public navCtrl: NavController,
    public modalCtrl: ModalController,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private ut: UtilitiesService,
    private titleService: Title
  ) {}

  public ngOnInit() {
    this.moduleConfig$ = this.clientService.getConfigModule('questions');
    this.prevTitle = this.titleService.getTitle();
    this.ut.calculateTitleName(this.moduleConfig$, 'interactivity');
    this.eventId = this.route.snapshot.paramMap.get('eventId') || this.eventIdModal;
    this.questionConfig$ = this.questionService.getQuestionConfig(this.eventId);
    this.recentQuestions$ = this.questionService.getQuestion(this.eventId, this.limit).pipe(
      map((questions) => {
        this.loader = false;
        return questions
          .filter((el) => el.inactive === '')
          .slice()
          .reverse();
      })
    );

    this.archivedQuestions$ = this.questionService.getQuestion(this.eventId, this.limit).pipe(
      map((questions) => {
        return questions
          .filter((el) => el.inactive !== '')
          .slice()
          .reverse();
      })
    );
  }

  public viewMore() {
    this.loader = true;
    const limitTo = (this.limit += 10);
    this.recentQuestions$ = this.questionService.getQuestion(this.eventId, limitTo).pipe(
      map((questions) => {
        this.loader = false;
        return questions
          .filter((el) => el.inactive === '')
          .slice()
          .reverse();
      })
    );
  }

  public trackElement(index: number, element: IQuestion) {
    return element ? element.id : null;
  }

  public segmentChanged(ev) {
    this.menu_select = ev.detail.value;
  }

  public onClose() {
    this.evOnClose.emit();
    this.titleService.setTitle(this.prevTitle);
  }

  public onOpenDetail(ev: string) {
    this.evOnOpenDetail.emit(ev);
  }

  public onSendQuestion() {
    this.questionToSend = {
      id: '',
      questionText: this.questionText,
      userId: this.userId,
      inactive: '',
      createdAt: new Date().getTime(),
      stats: {
        counterComment: '0',
        counterLike: '0',
      },
    };
    if (this.questionText.trim().length > 1) {
      this.questionService.createQuestion(this.questionToSend, this.eventId).then(
        (val) => {
          this.questionText = '';
          this.ut.sendGtagData(`Nueva pregunta`);
        },
        (err) => consoleError('error al preguntar', err)
      );
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
