export function SlaaskHidden(): ClassDecorator {
  return function (constructor: any) {

    const ngOnInit = constructor.prototype.ngOnInit;
    constructor.prototype.ngOnInit = function (...args) {
      const slaask = document.getElementById('slaask-button');
      if (slaask) {
        slaask.style.display = 'none';
      }
      if (ngOnInit) { ngOnInit.apply(this, args); }
    };

    const ngOnDestroy = constructor.prototype.ngOnDestroy;
    constructor.prototype.ngOnDestroy = function (...args) {
      const slaask = document.getElementById('slaask-button');
      if (slaask) {
        slaask.style.display = 'block';
      }
      if (ngOnDestroy) { ngOnDestroy.apply(this, args); }
    };
  };
}
