import * as fromConfig from './config.actions';
import { has } from 'lodash';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IClient, IEvent } from 'rebus-models';

export interface State {
  client: IClient;
  event: IEvent;
  eventId: string;
  error: any;
}

export const initialState: State = {
  client: null,
  event: null,
  eventId: null,
  error: null,
};

export function reducer(state = initialState, action: fromConfig.ConfigActions): State {

  switch (action.type) {
    case fromConfig.LOAD_EVENT: {
      return {
        ...state,
        eventId: action.payload ? action.payload.id : null,
        event: action.payload ? action.payload : null,
      };
    }

    case fromConfig.LOAD_CLIENT: {
      return {
        ...state,
        error: null,
      };
    }

    case fromConfig.LOAD_CLIENT_SUCCESS: {
      return {
        ...state,
        client: action.payload,
        error: null,
      };
    }

    case fromConfig.LOAD_CLIENT_ERROR: {
      return {
        ...state,
        client: null,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

function calculateClientModules(state: State) {
  if (!has(state, 'client')) {
    return null;
  }
  if (!has(state.client, 'modules')) {
    return null;
  }
  if (!state.client.modules) {
    return null;
  }
  return { ...state.client.modules };
}

function calculateEventModules(state: State) {
  if (!has(state, 'event')) {
    return null;
  }
  if (!has(state.event, 'modules')) {
    return null;
  }
  if (!state.event.modules) {
    return null;
  }
  return { ...state.event.modules };
}

function calculateClientDesign(state: State) {
  if (!has(state, 'client')) {
    return null;
  }
  if (!state.client) {
    return null;
  }
  return { ...state.client.design };
}

function calculateClientCategories(state: State) {
  if (!has(state, 'client')) {
    return null;
  }
  if (!state.client) {
    return null;
  }
  return { ...state.client.categories };
}

function calculateClientAuthConfig(state: State) {
  if (!has(state, 'client')) {
    return null;
  }
  if (!state.client) {
    return null;
  }
  if (!state.client.AuthConfig) {
    return null;
  }
  return { ...state.client.AuthConfig };
}

export const getConfig = createFeatureSelector<State>('config');
export const getClient = createSelector(getConfig, (state: State) => state.client);
export const getClientCategories = createSelector(getConfig, (state: State) => calculateClientCategories(state));
export const getClientGateways = createSelector(getConfig, (state: State) => {
  const { payMethods } = { payMethods: [], ...state.client };
  return payMethods;
});
export const getClientDesign = createSelector(getConfig, (state: State) => calculateClientDesign(state));
export const getClientModules = createSelector(getConfig, (state: State) => calculateClientModules(state));
export const getEventModules = createSelector(getConfig, (state: State) => calculateEventModules(state));
export const getClientAuthConfig = createSelector(getConfig, (state: State) => calculateClientAuthConfig(state));
export const getEvent = createSelector(getConfig, (state: State) => state.event);
export const getError = createSelector(getConfig, (state: State) => state.error);
