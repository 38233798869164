import { FormControl } from '@angular/forms';

export class HexaValidator {

  public static isHexa(control: FormControl): any {

    if (control.value) {
      const expre = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/);
      if (!expre.exec(control.value)) {
        return { 'invalid': true };
      }
    }

    return null;
  }
}
