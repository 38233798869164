import { Pipe, PipeTransform } from '@angular/core';
import { toArray } from 'lodash';

@Pipe({ name: 'toArray' })
export class ToArrayPipe implements PipeTransform {
  // tslint:disable-next-line:no-any
  public transform(value: any): any[] {
    return toArray(value);
  }
}
