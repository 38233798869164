import { Injectable } from '@angular/core';
import { async } from '@angular/core/testing';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@app/env';
import { ModalController } from '@ionic/angular';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UtilitiesService } from '@service/utilities';
import { getClient } from '@state/config';
import { getUser, getUserState } from '@state/user';
import { SocialUser } from 'angularx-social-login';
import * as firebase from 'firebase/app';
import { get } from 'lodash';
import { from, Observable, pipe } from 'rxjs';
import { catchError, debounceTime, flatMap, map, mergeMap, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { InterestPage } from 'src/app/modules/user/pages/interest/interest.page';
import { GLOBAL } from 'src/environments/global';
import { ModalValidateUserComponent } from '../../entry-components/pages/modal-validate-user/modal-validate-user.component';
import { consoleDebug, consoleError, consoleStore, consoleTracking } from '../../helper/console.helper';
import { getCookie, getFirestoreDate } from '../../helper/utils.helper';
import { AnalyticsService } from '../../service/analytics.service';
import { AuthService } from '../../service/auth.service';
import { ClientService } from '../../service/client.service';
import { NotificationsService } from '../../service/notifications.service';
import { LoadClientAction } from '../config/config.actions';
import { LoadingStartAction, LoadingStopAction } from '../loading/loading.actions';
import { GetCurrentUserAction, UpdateCurrentUserAction } from '../user/user.actions';
import * as authActions from './auth.actions';


//declare const WidgetRebus: any;

const code = '[ AUTH ]';
declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Injectable()
export class AuthEffects {

  @Effect()
  public GetCurrentUser$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.GET_CURRENT_AUTH),
    pipe(
      tap(() => consoleStore(`${code} Inicia la carga de auth`)),
      switchMap(() => this.authService.getLocalUser().
        pipe(
          tap((user: any) => {
            if (user.isAnonymous) {
              consoleStore(`${code} Usuario ANÓNIMO local encontrado, previamente generado`, user);
            } else {
              consoleStore(`${code} Usuario REGISTRADO local encontrado, previamente autenticado`, user);
            }
          }),
          mergeMap((user) => {
            if (user.isAnonymous) {
              if (GLOBAL.authRequired) {
                return [new authActions.SetAuthRequiredAction(), new LoadClientAction()]
              }
              return [new LoadClientAction()]
            }
            return [new authActions.GetCurrentAuthSuccessAction(user), new LoadClientAction()]
          }),
          catchError((err) => {
            if (GLOBAL.authRequired) {
              consoleError(`${code} Usuario local NO encontrado se redirecciona al login`, err);
              return [
                new authActions.SetAuthRequiredAction(),
                new LoadClientAction()
              ];
            }
            consoleError(`${code} Usuario local NO encontrado se crea un usuario anónimo`, err);
            return [new authActions.SetAuthAnonymousAction()];
          })
        ))
    )
  );

  @Effect()
  public SetAuthAnonymous$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.SET_AUTH_ANONYMOUS),
    pipe(
      tap(() => consoleStore(`${code} Se inicia la creación autenticación anónima`)),
      switchMap(() => this.authService.signInAnonymously()),
      tap((FBuser: any) => consoleStore(`${code} Autenticación anónima realizada`, FBuser)),
      mergeMap(FBuser => [
        new authActions.GetCurrentAuthSuccessAction(FBuser.user),
        new LoadClientAction(),
      ]),
      catchError(err => {
        consoleError(`${code} Autenticacion anónima NO realizada`, err);
        return [
          new authActions.SetAuthErrorAction(err),
        ];
      })
    )
  );

  @Effect({ dispatch: false })
  public GetCurrentAuthSuccess$ = this.actions$.pipe(
    ofType(authActions.GET_CURRENT_AUTH_SUCCESS),
    map((action: { payload }) => action.payload),
    withLatestFrom(this.store.select(getUserState)),
    switchMap(([user, userState]) => this.store.select(getClient).pipe(
      map((client) => {
        if (client && !user.isAnonymous) {
          consoleStore(`${code} Tracking inicial de autenticación ${user.isAnonymous ? 'anónima' : 'registrada'}`, user, userState);
          this.analytics.saveFirstTrackOnFirestore(
            user.uid,
            getFirestoreDate(),
            { platform: userState.platform, position: userState.position },
          );
          // Como se demora en cargar title se da un tiempo para su ejecucion
          setTimeout(() => {
            const basicInformation = get(userState.user, 'basicInformation', {});
            const dataToTrack = {
              clientId: environment.clientId,
              clientName: client.name,
              userId: get(userState.user, '_id', false),
              userName: get(basicInformation, 'name', null),
              userEmail: get(basicInformation, 'email', null),
              page: this.router.url,
              title: this.titleService.getTitle() || client.name,
              origin: window.origin,
              url: document.location.href,
              processId: window.localStorage.getItem(`${environment.clientId}-PROCESS-TRACKING`),
              cookieId: getCookie(`${environment.clientId}-COOKIE-TRACKING`),
              payload: { url: this.router.url },
              date: firebase.database.ServerValue.TIMESTAMP,
            };
            consoleTracking("First load tracking", dataToTrack)
            // tracking google tag manager
            const layer = window.dataLayer.push({
              event: 'navegacion',
              informacion: {
                ...dataToTrack,
                date: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
                timestamp: 'd ' + Date.now(),
              },
            });
            // Tracking firebase
            const fb = firebase.database().ref(`/Clients/${environment.clientId}/___tracking_urls/`).push(dataToTrack);
          }, 1000)
        }

        return userState;
      }),
      map(async (userState) => {
        const configValidateUser = await this.clientService.getConfigValidationUser();
        const configInterest = await this.clientService.getConfigInterest();
        const interestDefaultClient = await this.clientService.getInterestDefaultClient();
        if (!userState.user) {
          return new authActions.LogoutSuccessAction()
          // throw new Error('No se encontró el usuario');
        }
        if (configValidateUser.required_validation && !window.localStorage.getItem(`${environment.clientId}-OTP-CODE-VALIDATE-${get(userState, 'user._id', null)}`)) {
          return this.calculateTypeValidationUser(configValidateUser.typeValidationUser, userState, configInterest, interestDefaultClient, userState.user)
        } else {
          return this.router.navigateByUrl(this.calculateRedirectTo(userState), { replaceUrl: true })
        }
      }),
      catchError((err) => {
        consoleError(`${code} Autenticacion anónima NO realizada`, err);
        return [
          new authActions.AuthFailedAction(err),
          new LoadingStopAction(),
        ];
      })
    ))
  );

  public calculateTypeValidationUser(typeValidationUser, userState, configInterest, interestDefaultClient, user) {
    switch (typeValidationUser) {
      case 'link':
        const code = this.route.snapshot.queryParams.code;
        consoleDebug("Codigo validación usuario", "one", code)
        return this.openModalValidateUser(userState.user, configInterest, interestDefaultClient, code, 'link');
      case 'otp':
        return this.openModalValidateUser(userState.user, configInterest, interestDefaultClient, null, 'otp');
    }
  }

  public async openModalValidateUser(user, configInterest, interestDefaultClient, codeLinK, typeValidation) {
    const modal = await this.modalController.create({
      component: ModalValidateUserComponent,
      componentProps: { user, codeLinK, typeValidation },
      animated: true,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'modal-validate-user-block'
    });
    await modal.present();
    modal.onDidDismiss().then((data: any) => {
      this.openModalInterestUser(user, configInterest, interestDefaultClient)
    });
  }

  @Effect()
  public Register$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.REGISTER),
    map((action: { payload }) => action.payload),
    tap(() => this.store.dispatch(new LoadingStartAction({ type: 'load', message: this.translate.instant('Registering user') }))),
    debounceTime(250),
    switchMap(({ email, name, phone, passwordHash, terms, policy, interest, dni, category, company, position, loginCustom, surname }) =>
      this.authService.register(email, passwordHash, phone, terms, policy, name, interest, dni, category, company, position, loginCustom, surname)
        .pipe(
          switchMap((res) => {
            const user = get(res, "info", null);
            return this.authService
              .sendOtpCodeValidation(user.basicInformation.phone)
              .pipe(map(() => new authActions.RegisterSuccessAction(res)));          
          }),
          catchError((err) => {
            consoleError(`${code} Registro NO realizado (1)`, err);
            return [
              new authActions.AuthFailedAction(err),
              new LoadingStopAction(),
            ];
          })
        )
    ),
  );

  @Effect()
  public RegisterSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.REGISTER_SUCCESS),
    map((action: { payload }) => action.payload),
    switchMap(({ customToken }) => from(this.authService.signInCustom(customToken)).pipe(
      mergeMap((FBuser: any) => [
        new GetCurrentUserAction(null),
        new authActions.LoginSuccessAction(FBuser)
      ]),
      catchError(err => {
        consoleError(`${code} Registro NO realizado (2)`, err);
        return [
          new authActions.AuthFailedAction(err),
          new LoadingStopAction(),
        ];
      })
    )),
    catchError(err => {
      this.ut.getMessageError(err, 'An error occurred while registering, please contact the administrator.')
        .then(message =>
          this.notiService.showRebusToast(message, 'Error', false, null, null, 'cart')
        );
      consoleError(`${code} Registro NO realizado (3)`, err);
      return [
        new authActions.AuthFailedAction(err),
        new LoadingStopAction(),
      ];
    })
  );

  @Effect()
  public LoginSocial$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.LOGIN_SOCIAL),
    map((action: { payload }) => action.payload),
    tap(() => this.store.dispatch(new LoadingStartAction({ type: 'load', message: this.translate.instant('Logging in') }))),
    debounceTime(250),
    switchMap(({ provider }) => from(this.authService.signInSocial(provider)).pipe(
      flatMap((socialUser: SocialUser) => this.authService.socialLogin(socialUser).pipe(
        flatMap((resultLogin: any) => this.authService.signInCustom(resultLogin.customToken)),
        // tslint:disable-next-line:no-any
        mergeMap((FBuser: any) => [
          new GetCurrentUserAction(null),
          new authActions.LoginSuccessAction(FBuser),
          new authActions.LoginSocialSuccessAction(FBuser)
        ]),
        catchError(err => {
          consoleError(`${code} Autenticación por red social NO realizada (1)`, err);
          return [new authActions.AuthFailedAction(err)];
        })
      )),
      catchError(err => {
        consoleError(`${code} Autenticacion por red social NO realizada (2)`, err);
        return [new authActions.AuthFailedAction(err)];
      })
    )),
    catchError(err => {
      consoleError(`${code} Autenticacion por red social NO realizada (3)`, err);
      return [new authActions.AuthFailedAction(err)];
    })
  );

  @Effect()
  public LoginCustom$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.LOGIN),
    map((action: { payload }) => action.payload),
    tap(() =>
      this.store.dispatch(
        new LoadingStartAction({
          type: "load",
          message: this.translate.instant("Logging in"),
        })
      )
    ),
    debounceTime(250),
    switchMap(({ email, password }) =>
      from(this.authService.signInEmail(email, password)).pipe(
        flatMap((resultLogin: any) =>
          this.authService.signInCustom(resultLogin.customToken).then(() => {
            const user = get(resultLogin, "info", null);
            return this.authService
              .sendOtpCodeValidation(user.basicInformation.phone)
              .pipe(take(1))
              .toPromise();
          })
        ),
        mergeMap((FBuser: any) => [
          new GetCurrentUserAction(null),
          new authActions.LoginSuccessAction(FBuser),
        ]),
        catchError((err) => {
          consoleError(`${code} Autenticación por email NO realizada (1)`, err);
          return [new authActions.AuthFailedAction(err)];
        })
      )
    ),
    catchError((err) => {
      consoleError(`${code} Autenticación por email NO realizada`, err);
      return [new authActions.AuthFailedAction(err)];
    })
  );

  @Effect()
  public RestorePassword$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.RESTORE_PASSWORD),
    map((action: { payload }) => action.payload),
    tap(() => this.store.dispatch(new LoadingStartAction({ type: 'load', message: this.translate.instant('Updating user') }))),
    debounceTime(250),
    switchMap(({ token, passwordHash }) => from(this.authService.restoreEmail(token, passwordHash)).pipe(
      flatMap((resultLogin: any) => this.authService.signInCustom(resultLogin.customToken)),
      // TODO: esto se pone con un reload ya que el menu esta generando un problema de carga
      tap(() => setTimeout(() => { window.location.reload() }, 300)),
      mergeMap((FBuser: any) => [
        new GetCurrentUserAction(null),
        new authActions.LoginSuccessAction(FBuser)
      ]), tap(() => { this.router.navigateByUrl(environment.redirectTo) }),
      catchError(err => {
        consoleError(`${code} Restore password NO realizada (1)`, err);
        return [new authActions.AuthFailedAction(err)];
      })
    )),
    catchError(err => {
      consoleError(`${code} Restore password NO realizada`, err);
      return [new authActions.AuthFailedAction(err)];
    })
  );

  @Effect()
  public AuthFailed$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.AUTH_FAILED),
    map((action: { payload }) => action.payload),
    map((error) => {
      this.ut.getMessageError(error)
        .then(message => {
          if (message === 'El usuario ya existe') {
            this.notiService.showRebusToast('User already exists', 'Error', false);
          } else {
            this.notiService.showRebusToast('Check email', 'Error', false);
          }
        });
      return true;
    }),
    // Se retarda el cierre del loading ya que muchas veces no a abierto y se queda prendido
    debounceTime(200),
    switchMap(() => [new LoadingStopAction()])
  );


  @Effect()
  public Logout$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.LOGOUT),
    tap(() => consoleStore(`${code} Iniciando cierre de sesión`)),
    switchMap(() => this.store.select(getUser).pipe(
      take(1),
      flatMap((user) => this.store.select(getClient).pipe(
        take(1),
        flatMap((clientConfig) => from(this.authService.signOut()).pipe(
          take(1),
          switchMap(() => {
            /* if (WidgetRebus.default.isPrepared()) {
              console.log("Se cierra sesion en el widget y en meetups")
              WidgetRebus.default.setLogout()
            } else {
              console.log("No se pudo hacer el cierre de sesion de widget")
            } */
            // this.notiService.showRebusToast("Has cerrado sesión correctamente, recuerda iniciar sesión para poder usar todos los módulos!", 'Success', false);
            this.authService.authLogoutProcess(user._id)
            // if (get(clientConfig, 'isActiveIntegrationIncontacto', false) && get(get(user, 'customInformation', null), 'custom1', null)) {
            //   console.log('Usuario registrado se marca unCheckin en incontacto', get(user.customInformation, 'custom1', 'null'))
            //   this.authService.createConnectionIncontacto(get(user.customInformation, 'custom1', 'null'), get(clientConfig, 'uncheckingUrl', false))
            // }
            return [new authActions.LogoutSuccessAction()]
          }),
          catchError(err => [new authActions.LogoutFailedAction(err)])
        )),
      ))
    ))
  );

  @Effect()
  public SetAuthRequiredAction$: Observable<Action> = this.actions$.pipe(
    ofType(authActions.SET_AUTH_REQUIRED),
    tap(() => consoleStore(`${code} Es necesario iniciar sesión para acceder a la plataforma`)),
    debounceTime(200),
    switchMap(() => {
      return this.store.select(getUserState)
    }),
    switchMap((userState) => {
      if (userState) {
        if (GLOBAL.authRequired && !this.isWhiteListUrl) {
          setTimeout(() => {
            // const redirect = window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo;
            // this.router.navigateByUrl(`user/login?redirect=${redirect}`, { replaceUrl: true })
            this.router.navigateByUrl(`user/login?redirect=${this.calculateRedirectTo(userState)}`, { replaceUrl: true })
          }, 1000)
        }
      }
      return [new LoadingStopAction()];
    }),
  );

  @Effect({ dispatch: false })
  public GetLoginSuccessAction$ = this.actions$
    .pipe(
      ofType(authActions.LOGIN_SUCCESS),
      switchMap(() => this.store.select(getUser).pipe(
        tap(async (user) => {
          const userProfile = user;
          const configInterest = await this.clientService.getConfigInterest();
          const interestDefaultClient = await this.clientService.getInterestDefaultClient();
          // const clientConfig = await this.clientService.getClientConfig();
          // if (user && get(clientConfig, 'isActiveIntegrationIncontacto', false)) {
          //   this.authService.createConnectionIncontacto(get(user.customInformation, 'custom1', 'null'), get(clientConfig, 'checkinUrl', false))
          // }
          if (user) {
            this.openModalInterestUser(userProfile, configInterest, interestDefaultClient)
          }
        }),
      ))
    );

  public async openModalInterestUser(user, configInterest, interestDefaultClient) {
    if (user) {
      if (user.interest.length === 0) {
        if (configInterest) {
          const interestToSave = [];
          Object.keys(interestDefaultClient).map(item => {
            interestToSave.push(item);
          })
          const userUpdate = { ...user, interest: interestToSave }
          this.store.dispatch(new UpdateCurrentUserAction({ user: userUpdate }));
        } else {
          const modal = await this.modalController.create({
            component: InterestPage,
            componentProps: { openModalInterest: true, user },
            animated: true,
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'custom-modal-add-intereset-user'
          });
          return await modal.present();
        }
      }
    }
  }

  constructor(
    public modalController: ModalController,
    private actions$: Actions,
    private authService: AuthService,
    private store: Store<{}>,
    private analytics: AnalyticsService,
    private router: Router,
    private ut: UtilitiesService,
    private notiService: NotificationsService,
    private translate: TranslateService,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private titleService: Title
  ) { }

  private get isWhiteListUrl() {
    return this.router.url.includes('user/login') ||
      this.router.url.includes('user/register') ||
      this.router.url.includes('user/reset')
  }

  private async integrationIncontacto(user) {
    const clientConfig = await this.clientService.getClientConfig();
    if (user && get(clientConfig, 'isIntegrationAsambleas', false)) {
      return await this.authService.createConnectionIncontactoAsambleas(user)
    }
  }

  private calculateRedirectTo(userState) {
    if (!userState.user) {
      return userState.redirectTo;
    }
    if (!userState.redirectTo) {
      return window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo;
    }

    if (
      userState.redirectTo.includes('user/login') ||
      userState.redirectTo.includes('user/register') ||
      userState.redirectTo.includes('user/reset')
    ) {
      return window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo;
    }

    return userState.redirectTo;
  }

  private currentUser = null


  // private sendCodeEmail(user) {
  //   //TODO: REVISAR POR QUE A VECES NO ENVÍA DESDE ACA
  //   this.authService.sendOtpCodeValidation(user.basicInformation.phone).subscribe(
  //     res => {
  //       consoleDebug('RES Email sended', null, res);
  //     },
  //     err => {
  //       consoleError('Error occured email sended', err);
  //     }
  //   );
  // }



}
