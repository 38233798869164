import { Component } from '@angular/core';

@Component({
  selector: 'app-load-page',
  templateUrl: './load-page.component.html',
  styleUrls: ['./load-page.component.scss']
})
export class LoadPageComponent {
  // tslint:disable-next-line:max-line-length
  public img = 'https://firebasestorage.googleapis.com/v0/b/rebus-v4.appspot.com/o/Client%2Fheader%2F60_Copia%20de%20logotipo-25.png?alt=media&token=78879983-0eb9-41a7-94b1-3172f45474f2';
}
