import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, OnChanges } from '@angular/core';
import { IDesign, IModuleConfig, IEmbed } from 'rebus-models';
import { NavController } from '@ionic/angular';
import { ClientService } from 'src/app/modules/shared/service/client.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-embed-item-search-result',
  templateUrl: './embed-item-search-result.component.html',
  styleUrls: ['./embed-item-search-result.component.scss']
})
export class EmbedItemSearchResultComponent implements OnInit, OnChanges {

  @Input() public design: IDesign;
  @Input() public embed: IEmbed;
  @Input() public eventId: string;
  @Output() public evOnClickItem = new EventEmitter<void>();
  public moduleConfig$: Observable<IModuleConfig>;

  constructor(
    private navCtrl: NavController,
    private clienteService: ClientService,

  ) { }

  public ngOnInit() {
    this.moduleConfig$ = this.clienteService.getConfigModule('embed'); // para sacar la imagen del banner

  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.embed) {
      this.embed = changes.embed.currentValue;
    }
  }

  public onClickEmbed() {
    this.navCtrl.navigateForward([`embed/${this.embed.id}/${this.eventId}`]);
    this.evOnClickItem.emit();

  }
}
