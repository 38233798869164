import * as fromMenu from './menu.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IModuleConfig } from 'rebus-models';

export interface State {
  menu: IModuleConfig[];
  eventId: string;
  error: any;
}

export const initialState: State = {
  menu: null,
  eventId: null,
  error: null,
};

export function reducer(state = initialState, action: fromMenu.MenuActions): State {

  switch (action.type) {
    case fromMenu.LOAD_MENU: {
      return {
        ...state,
        eventId: action.payload || null,
        error: null,
      };
    }

    case fromMenu.LOAD_MENU_SUCCESS: {
      return {
        ...state,
        menu: action.payload,
        error: null,
      };
    }

    case fromMenu.LOAD_MENU_ERROR: {
      return {
        ...state,
        menu: null,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getMenuState = createFeatureSelector<State>('menu');
export const getMenu = createSelector(getMenuState, (state: State) => state.menu);
export const getMenuEventId = createSelector(getMenuState, (state: State) => ({ menu: state.menu, eventId: state.eventId }));
export const getEventId = createSelector(getMenuState, (state: State) => (state.eventId));
export const getError = createSelector(getMenuState, (state: State) => state.error);
