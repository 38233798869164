import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { TimeLineService } from '../../timeline/services/timeline.service';
import { map } from 'rxjs/operators';

@Pipe({ name: 'getTotalCommentsPost' })
export class GetTotalCommentsPost implements PipeTransform {
  constructor(
    private timeLineService: TimeLineService
  ) { }
  public transform(timeLineId: string, eventId: string): Observable<number> {
    return this.timeLineService.getCommentsByTimeLine(timeLineId, eventId).pipe(
      map(list => list.length)
    )
  }
}
