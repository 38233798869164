import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { IShoppingCart, IItem, IShoppingCartConfig, ITransactionItem, IFormCustomConfig } from 'rebus-models';
import { environment } from '@app/env';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NotificationsService } from '../../shared/service/notifications.service';
import { Observable } from 'rxjs';
import { AuthService } from '../../shared/service/auth.service';
import { take } from 'rxjs/operators';
import { isValidEventId } from '@helper/utils';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class CartService {

  public readonly path = `/Clients/${environment.clientId}/ShoppingCart`;

  constructor(
    private fb: AngularFireDatabase,
    private http: HttpClient,
    private notificationService: NotificationsService,
    private authService: AuthService,
    private modalCtrl: ModalController,
    private store: Store<{}>,
  ) { }

  public async createOrUpdateShoppingCart(uid: string, cart: IShoppingCart) {
    const result = await this.fb.object(`${this.path}/${uid}`).update(cart)
      .then(() => this.setCartLoadFlag(uid))
      .then(() => cart);
    return result;
  }

  public setCartLoadFlag(uid: string) {
    return this.fb.object(`${this.path}/${uid}/load`).set(true);
  }

  public getShoppingCart(uid: string) {
    return this.fb.object<IShoppingCart>(`${this.path}/${uid}`).valueChanges();
  }

  public async getShoppingCartSnap(uid: string): Promise<IShoppingCart> {
    const snap = await this.fb.object<IShoppingCart>(`${this.path}/${uid}`).query.once('value');
    return snap.val();
  }

  public getShoppingCartItems(uid: string) {
    return this.fb.list<IItem>(`${this.path}/${uid}/items`).valueChanges();
  }

  public async getShoppingCartItemsSnap(uid: string) {
    const snap = await this.fb.list(`${this.path}/${uid}/items`).query.once('value');
    return snap.val();
  }

  public async getItemShoppingCart(uid: string, itemId: string) {
    const snap = await this.fb.list(`${this.path}/${uid}/items/${itemId}`).query.once('value');
    return snap.val();
  }

  public getFormStoreConfig(storeId: string, eventId: string) {
    let reference = `/Clients/${environment.clientId}/Interactivity/Store`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Store`;
    }
    return this.fb.list<IFormCustomConfig>(`${reference}/${storeId}/FormStoreConfig/`).query.once('value').then(snap => snap.val());
  }

  public removeShoppingCart(uid: string) {
    return this.fb.object(`${this.path}/${uid}`).remove();
  }

  public async addShoppingCartService(uid: string, item: IItem) {
    let cart = await this.verifyShoppingCart(uid);
    let { items, state } = { items: {}, state: null, ...cart };
    if (state === 'payment_process') {
      this.notificationService.showRebusToast(
        'Tienes un carrito de compra en proceso de pago',
        'Warning'
      );
      return null;
    }
    const lastUpdateAt = Date.now();
    items = { ...items, [item.id]: item };
    cart = { ...cart, items, lastUpdateAt };
    return this.createOrUpdateShoppingCart(uid, cart);
  }

  // Aca se deberia hacer lo del formulario a medida
  public async addShoppingCartItem(uid: string, item: IItem): Promise<IShoppingCart> {
    let cart = await this.verifyShoppingCart(uid);
    let { items, state } = { items: {}, state: null, ...cart };
    if (state === 'payment_process') {
      this.notificationService.showRebusToast(
        'Tienes un carrito de compra en proceso de pago',
        'Warning'
      );
      return null;
    }
    if (item.id in items) {
      let currentItem = items[item.id];
      currentItem = { ...currentItem, saleAmount: currentItem.saleAmount + item.saleAmount };
      if (!this.validateItemAmount(currentItem)) {
        return null;
      }
      items = { ...items, [item.id]: currentItem };
    } else {
      if (!this.validateItemAmount(item)) {
        return null;
      }
      items = { ...items, [item.id]: item };
    }

    const lastUpdateAt = Date.now();
    cart = { ...cart, items, lastUpdateAt };
    return this.createOrUpdateShoppingCart(uid, cart);
  }

  public async updateShoppingCartItem(uid: string, item: IItem): Promise<IShoppingCart> {
    if (!this.validateItemAmount(item)) {
      return null;
    }
    let cart = await this.verifyShoppingCart(uid);
    const lastUpdateAt = Date.now();
    cart = { ...cart, lastUpdateAt, items: { ...cart.items, [item.id]: item } };
    return this.createOrUpdateShoppingCart(uid, cart);
  }

  public removeShoppingCartItem(uid: string, id: string) {
    return this.fb.object(`${this.path}/${uid}/items/${id}`).remove()
      .then(() => this.setCartLoadFlag(uid));
  }

  public async fakePay(user: string) {
    const token = await this.authService.getTokenUser()
      .pipe(take(1)).toPromise();
    const client = environment.clientId;
    const headers = new HttpHeaders({ 'Authorization': token });
    return this.http.post<{ code: string, data: ITransactionItem }>(`${environment.pay_rebus}/v2/fake/`, { client, user }, { headers })
      .toPromise();
  }

  public async paymentezPay(user: string, token: string) {
    const authToken = await this.authService.getTokenUser().pipe(take(1)).toPromise();
    const client = environment.clientId;
    const headers = new HttpHeaders({ Authorization: authToken });
    return this.http.post<any>(`${environment.pay_rebus}/v2/paymentez/transaction`, { client, user, token }, { headers }).toPromise();
  }

  public async payUPay(user: string) {
    const authToken = await this.authService.getTokenUser().pipe(take(1)).toPromise();
    const client = environment.clientId;
    const headers = new HttpHeaders({ Authorization: authToken });
    return this.http.post<any>(`${environment.pay_rebus}/v2/pay_u/`, { client, user }, { headers }).toPromise();
  }

  public async payEpaycoSplit(user: string) {
    const authToken = await this.authService.getTokenUser().pipe(take(1)).toPromise();
    const client = environment.clientId;
    const headers = new HttpHeaders({ Authorization: authToken });
    return this.http.post<any>(`${environment.pay_rebus}/v2/epayco/split`, { client, user }, { headers }).toPromise();
  }

  public async payEpayco(user: string) {
    const authToken = await this.authService.getTokenUser().pipe(take(1)).toPromise();
    const client = environment.clientId;
    const headers = new HttpHeaders({ Authorization: authToken });
    return this.http.post<any>(`${environment.pay_rebus}/v2/epayco/`, { client, user }, { headers }).toPromise();
  }

  /**
   *
   * @param uid id del usuario
   * @param cid id del cupón
   * @param eid id del evento al que pertenece el cupón
   */
  public setCouponToShoppingCart(uid: string, couponData: { id: string, eventId: string, code: string }) {
    return this.fb.object(`${this.path}/${uid}/coupon`).set(couponData).then(() => this.setCartLoadFlag(uid));
  }

  public getConfigWidgetsCart(): Observable<IShoppingCartConfig> {
    const reference = `/Clients/${environment.clientId}/Interactivity/ShoppingCartConfig`;
    return this.fb.object<IShoppingCartConfig>(reference).valueChanges();
  }

  private async verifyShoppingCart(uid: string) {
    let cart = await this.getShoppingCartSnap(uid);
    if (!cart) {
      const ts = Date.now();
      const dummyCart: IShoppingCart = {
        coupon: null,
        createdAt: ts,
        items: {},
        lastUpdateAt: ts,
        load: false,
        numAmount: 0,
        state: 'pending',
        total: 0,
        totalBase: 0,
        totalDiscount: 0,
        totalTax: 0,
      };
      cart = await this.createOrUpdateShoppingCart(uid, dummyCart);
    }
    return cart;
  }

  private validateItemAmount(item: IItem) {
    if (item.type === 'product') {
      if (item.saleAmount > item.data.pay.maxQuantity) {
        this.notificationService.showRebusToast(
          `No puedes tener más de ${item.data.pay.maxQuantity} productos en el carrito de compras`, 'Warning'
        );
        return false;
      }
      if (item.saleAmount < item.data.pay.minQuantity) {
        this.notificationService.showRebusToast(
          `Debes tener como mínimo ${item.data.pay.minQuantity} productos en el carrito de compras`, 'Warning'
        );
        return false;
      }
    }
    if (item.type === 'ticket') {
      if (item.saleAmount > item.data.plans.maxQuantity) {
        this.notificationService.showRebusToast(
          `No puedes tener más de ${item.data.plans.maxQuantity} tickets en el carrito de compras`, 'Warning'
        );
        return false;
      }
      if (item.saleAmount > item.data.plans.maxQuantity) {
        this.notificationService.showRebusToast(
          `Debes tener como mínimo ${item.data.plans.maxQuantity} tickets en el carrito de compras`, 'Warning'
        );
        return false;
      }
    }
    return true;
  }

}
