import { Component, OnInit, Input } from '@angular/core';
import { IUser, IDesign } from 'rebus-models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-action-survey',
  templateUrl: './modal-action-survey.component.html',
  styleUrls: ['./modal-action-survey.component.scss'],
})
export class ModalActionSurveyComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public user: IUser;


  constructor(
    public modalCtrl: ModalController
  ) { }

  public ngOnInit() { }

  public actionSurvey(action: string) {
    this.modalCtrl.dismiss(action);
  }

}
