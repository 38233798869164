import { Component, Input, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { IWidgetRight, IDesign, TRecommendationEntity, TWidgetType, IEvent } from 'rebus-models';
import { Observable, from, of } from 'rxjs';
import { AIService } from '../../service/ai.service';
import { Store } from '@ngrx/store';
import { switchMap, map, take } from 'rxjs/operators';
import { SuggestionTypes, SaleTypes, SuggestionForUserNetowrking } from '../../helper/widget.enum';
import { isValidEventId } from '@helper/utils';
import { UtilitiesService } from '@service/utilities';
import { CouponService } from '../../../coupon/services/coupon.service';
import { ProductService } from 'src/app/modules/product/services/product.service';
import { CartService } from 'src/app/modules/cart/services/cart.service';
import { getAuthUID } from '@state/auth';
import { UserService } from 'src/app/modules/user/service/user.service';
import { consoleError } from '../../helper/console.helper';
import { NetworkingService } from 'src/app/modules/networking/services/networking.service';
import { NotificationsService } from '../../service/notifications.service';
import { NavController, ModalController } from '@ionic/angular';
import { EventPage } from 'src/app/modules/event/pages/event/event.page';
import { EventService } from '../../service/event.service';
import { ClientService } from '../../service/client.service';
import { ActivatedRoute } from '@angular/router';
import { isArray } from 'lodash';

@Component({
  selector: 'app-widget-right',
  templateUrl: './widget-right.component.html',
  styleUrls: ['./widget-right.component.scss']
})
export class WidgetRightComponent implements OnInit, OnChanges {

  @Input() public config: IWidgetRight;
  @Input() public design: IDesign;
  @Input() public eventId: string;
  public userId: string;

  public data$: Observable<any>;

  constructor(
    public couponService: CouponService,
    public productService: ProductService,
    public cartService: CartService,
    public userService: UserService,
    public networkingService: NetworkingService,
    public notificationService: NotificationsService,
    public navCtrl: NavController,
    public eventService: EventService,
    public modalController: ModalController,
    private aiService: AIService,
    private store: Store<any>,
    private router: NavController,
    private clienteService: ClientService,
    private route: ActivatedRoute,
    private utilities: UtilitiesService
  ) { }

  public async ngOnInit() {
    this.userId = await this.store.select(getAuthUID).pipe(take(1)).toPromise();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.config) {
      this.config = changes.config.currentValue;
      this.data$ = this.getWidgetData(this.config.type);
    }
    if (changes.design) {
      this.design = changes.design.currentValue;
    }
    if (changes.eventId) {
      this.eventId = changes.eventId.currentValue;
    }
  }

  public getWidgetData(type: TWidgetType) {
    if (type in SuggestionTypes) {
      const recommendationEntity = SuggestionTypes[type];
      return this.getRecommendations(recommendationEntity, 5);
    } else if (type in SaleTypes) {
      return from(this.aiService.fecthEntityData(
        this.eventId,
        SaleTypes[type],
        this.config.dataWidget.toString(),
      ));
    } else if (type in SuggestionForUserNetowrking) {
      return this.getRecomendatiosForUserNetworking();
    } else {
      return of(this.config.dataWidget);
    }
  }

  public getRecommendations(entity: TRecommendationEntity, limit = 5) {
    const eid = isValidEventId(this.eventId) ? this.eventId : null;
    return this.store.select(getAuthUID).pipe(
      switchMap(uid =>
        this.aiService.getRecommendationsForUser(uid, entity, limit, eid)
          .pipe(
            switchMap(res => {
              if (!isArray(res)) {
                res = [];
              }
              return from(Promise.all(
                res.map(r =>
                  this.aiService.fecthEntityData(eid, entity, r.id)
                    .then(data => ({ data, match: r.match, views: r.view }))
                )
              ))
            }),
            map(res => res.filter(e => !!e.data))
          )
      ),
    );
  }

  public getRecomendatiosForUserNetworking() {
    return this.store.select(getAuthUID).pipe(
      switchMap(uid =>
        this.aiService.getUserRecomendationsForUserNetworking(uid)
          .pipe(
            switchMap(res =>
              from(Promise.all(
                res.map(async user => {
                  try {
                    const recomendations = await this.userService.getUserById(user.id)
                      .then(response => ({ data: response, match: user.match, view: user.view }));
                    return recomendations;
                  } catch (error) {
                    consoleError('Error obteniendo recomendaciones de usuarios: ', error);
                    return [];
                  }
                }
                )
              ))
            ),
          )
      ),
    );
  }

  public onAdquiereCoupon(coupon) {
    this.couponService.acquireCouponUser(
      this.eventId || null,
      this.userId,
      coupon
    );
  }

  public onDeleteCoupons(coupon) {
    this.couponService.deleteCouponUser(this.eventId, this.userId, coupon);
  }

  public async onItemAction(item: any, type: string) {
    switch (type) {
      case 'product':
        this.productService.addProductToShoppingCart(item, item.pay.minQuantity);
      default:
        break;
    }
  }

  public onAddUser(userIdUserSelected: string) {
    this.networkingService.sendRequestUser(userIdUserSelected, this.userId, this.eventId).subscribe(
      res => {
        this.notificationService.showRebusToast('Se envio solicitud correctamente', 'Success', false);
        this.data$ = this.getWidgetData(this.config.type);
      },
      err => {
        consoleError('Error occured request sended', err);
        this.notificationService.showRebusToast('Ocurrio un error enviando solicitud', 'Error', false);
      }
    );
  }

  public onViewDetail(idItem: string, typeItem: string) {
    switch (typeItem) {
      case 'ticket':
        this.navCtrl.navigateForward(['/ticket/detail', idItem, this.eventId || '--']);
        break;
      case 'coupon':
        this.navCtrl.navigateForward(['/coupon/detail', idItem, this.eventId || '--']);
        break;
      case 'news':
        this.navCtrl.navigateForward(['/news/detail', idItem, this.eventId || '--']);
        break;
      case 'product':
        this.navCtrl.navigateForward(['/product/detail', idItem, this.eventId || '--']);
        break;
      case 'service':
        this.navCtrl.navigateForward(['/service/detail', idItem, this.eventId || '--']);
        break;
      case 'staff':
        this.navCtrl.navigateForward(['/staff/detail', idItem, this.eventId || '--']);
        break;
      case 'stand':
        this.navCtrl.navigateForward(['/stand/detail', idItem, this.eventId || '--']);
        break;
      case 'sponsor':
        this.navCtrl.navigateForward(['/sponsor/detail', idItem, this.eventId || '--']);
        break;
      default:
        break;
    }
  }

  public onViewConfigEvent(event: IEvent) {
    // const eventClassInstance = new EventPage(
    //   this.eventService,
    //   this.store,
    //   this.router,
    //   this.clienteService,
    //   this.modalController,
    //   this.route,
    //   this.utilities
    // );
    // eventClassInstance.onClickEvent(event);
  }
}
