import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable, of, from } from 'rxjs';
import { environment } from '@app/env';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AuthService } from '../../shared/service/auth.service';
import { isValidEventId } from '@helper/utils';
import { map, switchMap } from 'rxjs/operators';
import { head } from 'lodash';
import { INetworkingResponse } from 'rebus-models';
import * as firebase from 'firebase/app';

@Injectable({ providedIn: 'root' })

export class NetworkingService {

  constructor(
    private afdb: AngularFireDatabase,
    private http: HttpClient,
    public authService: AuthService
  ) { }

  public sendRequestUser(userIdSelected, userId, eventId) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        return this.http.post(`${environment.backend}/networking`,
          {
            'clientId': environment.clientId,
            'eventId': eventId,
            'userIdSolicitante': userId,
            'userIdRequerido': userIdSelected,
            'requestState': 'enviado'

          }, { headers });
      })
    );
  }

  public updateRequestState(userIdSelected, action, userId, eventId) {
    console.log("update", userIdSelected, action, userId, eventId);
    
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        return this.http.post(`${environment.backend}/networking`,
          {
            'clientId': environment.clientId,
            'eventId': eventId,
            'userIdSolicitante': userId,
            'userIdRequerido': userIdSelected,
            'requestState': action

          }, { headers });
      })
    );
  }

  // tslint:disable-next-line:max-line-length
  public calculateGetUserNetworking(segmentSelected, userId: string, search?: string, limit?: number, segmenetUsers?: string): Observable<INetworkingResponse> {
    switch (segmentSelected) {
      case 'all_contacts':
        return this.getUsersNetworking(null, userId, search, limit, segmenetUsers);

      case 'my_contacts':
        return this.getUsersNetworking('aprobado', userId, search, limit, segmenetUsers);

      case 'send_request':
        return this.getUsersNetworking('enviado', userId, search, limit, segmenetUsers);

      case 'request_received':
        return this.getUsersNetworking('requerido', userId, search, limit, segmenetUsers);
      default:
        return of(null);
    }
  }

  public getUsersNetworking(state, userId, search, limit, segmenetUsers) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        return this.http.post<INetworkingResponse>(`${environment.backend}/networking/search_user?limit=${limit}&skip=${limit - 10}`,
          {
            'userId': userId,
            'clientId': environment.clientId,
            'query': {
              'name': search,
              'company': search,
              'position': search,
              'eventId': segmenetUsers
            },
            'includes': [
              'id',
              'basicInformation',
              'customInformation',
              'clientId'
            ],
            'state': state,
          }, { headers });
      })
    );
  }

  public getConfigWidgetsNetworking(eventId: string): Observable<any> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<any>(`/Clients/${environment.clientId}/Event/${eventId}/NetworkingConfig`)
        .valueChanges();
    } else {
      return this.afdb.object<any>(`/Clients/${environment.clientId}/Interactivity/NetworkingConfig`)
        .valueChanges();
    }
  }

  public getChatRoomsNetworking(): Observable<any> {
    return this.afdb.list(`/Clients/${environment.clientId}/ChatRooms`).valueChanges();
  }

  public getChatRoomByForeignKey(foreingKey) {
    return this.afdb.list(`/Clients/${environment.clientId}/ChatRooms`,
      ref => ref.orderByChild('foreign_key').equalTo(foreingKey))
      .query.once('value').then(snap => snap.val());
  }

  public createChatRoomUsers(chat, id: string = '') {
    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;
    chat.id = id;
    chat.userInitChat = chat.user1;
    chat.created_at = created_at;

    return this.afdb.database
      .ref(`Clients/${environment.clientId}/ChatRooms/${id}`)
      .update(chat).then(() => chat).catch(error => error);
  }

  public createMessageChat(message, chatId: string, id: string = '') {
    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;
    message.id = id;
    message.created_at = created_at;

    return this.afdb.database
      .ref(`Clients/${environment.clientId}/ChatRooms/${chatId}/messages/${id}`)
      .update(message);
  }

  public getMessagesChatRoom(foreingKey) {
    return this.afdb.list<any>(`/Clients/${environment.clientId}/ChatRooms`,
      ref => ref.orderByChild('foreign_key').equalTo(foreingKey))
      .valueChanges()
      .pipe(map(rooms => head(rooms)));
  }

  public createNotificationMessage(userIdSelected, userId, message, infoUser, id: string = '') {
    id = this.afdb.createPushId()
    const data = {
      id,
      infoUser,
      userIdSenderMessage: userId,
      createAt: firebase.database.ServerValue.TIMESTAMP,
      message
    }
    return this.afdb.database
      .ref(`Clients/${environment.clientId}/ChatNotificationsUser/${userIdSelected}/${id}`)
      .update(data);
  }

  public getChatNotificationsByUser(userId: string) {
    return this.afdb.list(`/Clients/${environment.clientId}/ChatNotificationsUser/${userId}`).valueChanges();
  }

  public deleteAllNotificationsByUser(userId: string): Promise<void> {
    return this.afdb.object(`Clients/${environment.clientId}/ChatNotificationsUser/${userId}`).remove();
  }

  public deleteNotificationsByUser(userId: string, messagesNotificationUser, userIdSelected) {
    const promises = []
    messagesNotificationUser.map((message: any) => {
      if (message.userIdSenderMessage === userIdSelected) {
        promises.push(
          this.afdb
            .object(`Clients/${environment.clientId}/ChatNotificationsUser/${userId}/${message.id}`).remove()
        )
      }
    })
    return Promise.all(promises)
  }

}

