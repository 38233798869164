import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'clearMenu' })

@Injectable()
export class ClearMenuPipe implements PipeTransform {
  /**
   * Aca se limitan los componentes que no se deben mostrar en el menu principal, sea porque el modulo no esta preparado o porque no debe aparecer alli
   * @param value
   */
  public transform(value: any[]): any[] {
    if (value) {
      const noMenus = [
        'notification',
        'questions',
        'favorites',
        'my_reserves',
        'employee',
        'streaming',
        'legal_terms_service',
        'likes',
        'opinometer',
        'pay_methods',
        'plan_my_day',
        'raffle',
        'referred',
        'share_pages',
        'shopping_cart',
        'sponsor_banner',
      ];
      const dataTransform = value.reduce((total, next) => {
        if (noMenus.includes(next.component)) {
          return [...total];
        }
        return [...total, next];
      }, []);
      return dataTransform;
    }
    return value;
  }
}
