import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { Store } from '@ngrx/store';
import { getAuthUID } from '@state/auth';
import { mergeMap, catchError, take } from 'rxjs/operators';
import { AIService } from '../../service/ai.service';
import { IDesign, ISuggestion } from 'rebus-models';
import { SuggestionTypes, FooterModes } from '../../helper/widget.enum';
import { CouponService } from 'src/app/modules/coupon/services/coupon.service';
import { IEvent } from 'rebus-models';
import { EventPage } from 'src/app/modules/event/pages/event/event.page';
import { EventService } from '../../service/event.service';
import { ModalController, NavController } from '@ionic/angular';
import { ClientService } from '../../service/client.service';
import { ProductService } from 'src/app/modules/product/services/product.service';
import { consoleError } from '../../helper/console.helper';
import { ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '@service/utilities';
import { NotificationsService } from '../../service/notifications.service';
import { isArray } from 'lodash';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss']
})
export class SuggestionsComponent implements OnInit, OnDestroy {

  @Input() public eventId = '--';
  @Input() public design: IDesign;
  @Input() public config: ISuggestion;
  public userId: string;

  public mode = '';
  public suggestItems$: Observable<any>;
  public recomendationItems$: Observable<any>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public notificationService: NotificationsService,
    public eventService: EventService,
    public modalController: ModalController,
    public productService: ProductService,
    private router: NavController,
    private clienteService: ClientService,
    private aiService: AIService,
    private store: Store<any>,
    private couponService: CouponService,
    private route: ActivatedRoute,
    private utilities: UtilitiesService,
  ) { }

  public async ngOnInit() {
    this.mode = FooterModes[this.config.type];
    this.userId = await this.store.select(getAuthUID).pipe(take(1)).toPromise();
    this.getSuggestItems();
  }

  public getSuggestItems() {
    this.suggestItems$ = this.store.select(getAuthUID)
      .pipe(
        mergeMap(uid =>
          this.aiService.getRecommendationsForUser(uid, SuggestionTypes[this.config.type], 15, this.eventId)
            .pipe(
              mergeMap(res => {
                if (!isArray(res)) {
                  res = []
                }
                return from(Promise.all(
                  res.map(r =>
                    this.aiService.fecthEntityData(this.eventId, SuggestionTypes[this.config.type], r.id)
                      .then(val => ({ ...r, ...val }))
                  )
                ))
              }
              ),
              catchError(error => {
                consoleError('Error obteniendo recomendaciones por usuario en footer: ', error);
                return [];
              }),
            )
        ),
        catchError(error => {
          consoleError('Error obteniendo recomendaciones por usuario: ', error);
          return [];
        })
      );
  }

  public moveLeft() {
    document.getElementById('scroll').scrollTo({ left: (document.getElementById('scroll').scrollLeft - 400), behavior: 'smooth' })
  }

  public moveRight() {
    document.getElementById('scroll').scrollTo({ left: (document.getElementById('scroll').scrollLeft + 400), behavior: 'smooth' })
  }

  public onViewMoreEvent(event: IEvent) {
    // const eventClassInstance = new EventPage(
    //   this.eventService,
    //   this.store,
    //   this.router,
    //   this.clienteService,
    //   this.modalController,
    //   this.route,
    //   this.utilities
    // );
    // eventClassInstance.onClickEvent(event);
  }

  public onViewMoreService(serviceId: string) {
    this.router.navigateForward(['/service/detail', serviceId, this.eventId || '--']);
  }

  public onViewMoreSponsor(sponsorId: string) {
    this.router.navigateForward(['/sponsor/detail', sponsorId, this.eventId || '--']);
  }

  public onSelectOptionProduct(optionSelected) {
    const itemSelected = optionSelected.suggestionItem;
    switch (optionSelected.option) {
      case 'addMyDay':
        // TODO: flujo de añadir a mi day
        break;

      case 'buy':
        this.AddItemtShoppingCart(itemSelected, 'product');
        break;
    }
  }

  public onSelectOptionCoupon(optionSelected) {
    const itemSelected = optionSelected.coupon;
    switch (optionSelected.option) {
      case 'addMyDay':
        // TODO: flujo de añadir a mi day
        break;

      case 'acquiere':
        this.couponService.acquireCouponUser(
          this.eventId,
          this.userId,
          itemSelected
        );
        break;
    }
  }

  public async AddItemtShoppingCart(item: any, type: string) {
    switch (type) {
      case 'product':
        this.productService.addProductToShoppingCart(item, item.pay.minQuantity);
        break;
    }
  }

  public onSelectOptionStaff(optionSelected) {
    const itemSelected = optionSelected.staff;
    switch (optionSelected.option) {
      case 'viewMore':
        this.router.navigateForward(['/staff/detail', itemSelected.id, this.eventId || '--']);
        break;

      case 'addMyDay':
        // TODO: flujo de añadir a mi day
        break;
    }
  }

  public onViewMoreCoupon(couponId: string) {
    this.router.navigateForward(['/coupon/detail', couponId, this.eventId || '--']);
  }

  public onViewMoreProduct(productId: string) {
    this.router.navigateForward(['/product/detail', productId, this.eventId || '--']);
  }

  public onViewMoreNews(newsId: string) {
    this.router.navigateForward(['/news/detail', newsId, this.eventId || '--']);
  }

  public onViewMoreTicket(ticketId: string) {
    this.router.navigateForward(['/ticket/detail', ticketId, this.eventId || '--']);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
