import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import * as moment from 'moment';
import { Subscription, Observable } from 'rxjs';
import { sortBy, get, toArray, groupBy } from 'lodash';
import { AgendaService } from '../../services/agenda.service';
import { Router } from '@angular/router';
import { IDesign, IModuleConfig } from 'rebus-models';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { ClientService } from 'src/app/modules/shared/service/client.service';
import { tap } from 'rxjs/operators';
import { UtilitiesService } from '@service/utilities';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda-widget.page.html',
  styleUrls: ['./agenda-widget.page.scss'],
})
export class AgendaWidgetPage implements OnInit, OnDestroy {
  @Input() public eventId: string;
  @Input() public design: any;
  @Output() public evOnClose = new EventEmitter<any>();
  @ViewChild('slides') public slides: any;

  public moduleConfig$: Observable<any>;
  public agendaGroup: any;
  public currentAgenda: string = null;
  public agendaSubscription: Subscription;
  public loader = true;
  public agendas = null;
  public agendasNext: any;
  private prevTitle: string = '';
  public design$: Observable<IDesign>;

  constructor(
    private store: Store<{}>,
    public modalCtrl: ModalController,
    private agendaService: AgendaService,
    private router: Router,
    private clienteService: ClientService,
    private ut: UtilitiesService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.prevTitle = this.titleService.getTitle();
    this.moduleConfig$ = this.clienteService.getConfigModuleAgendaWidget('diary').pipe(
      tap((config) => {
        this.agendaSubscription = this.agendaService
          .getAgendaByClient(config.defaultAgendaEvent)
          .subscribe((agendas) => {
            if (agendas) {
              this.agendas = agendas;
              agendas.sort((a, b) => (a.dateStart > b.dateEnd ? 1 : -1));
              const dateActually = moment().utc(true).format('YYYY-MM-DD HH:mm:ss');
              const reorderAgendas = agendas.reduce((total, next) => {
                if (!total[next.dateStart]) {
                  total[next.dateStart] = [];
                }
                if (!this.currentAgenda) {
                  this.currentAgenda = next.dateStart;
                }
                if (next.dateStart === dateActually) {
                  this.currentAgenda = next.dateStart;
                }
                total[next.dateStart.toString()].push(next);
                total[next.dateStart.toString()] = sortBy(total[next.dateStart.toString()], ['dateStart', 'dateEnd']);
                return total;
              }, {});
              const tmp = toArray(reorderAgendas).map((agenda: any) =>
                agenda.sort((a, b) => (a.hourStart > b.hourStart ? 1 : -1))
              );

              this.agendasNext = tmp.map((agenda: any) => agenda.filter((item) => item.toContinue))[0];

              this.agendaGroup = tmp;
            }
            if (agendas.length === 0) {
              this.loader = false;
            }
          });
      })
    );
    this.ut.calculateTitleName(this.moduleConfig$, 'interactivity');
    this.design$ = this.store.select(getDesign);
  }

  public onGoEvent(item) {
    if (item.urlRedirect) {
      this.router.navigateByUrl(item.urlRedirect, { replaceUrl: true });
    }
    this.modalCtrl.dismiss();
  }

  public nextSlide() {
    this.slides.slideNext();
  }

  public prevSlide() {
    this.slides.slidePrev();
  }

  public onClose() {
    this.modalCtrl.dismiss();
    this.titleService.setTitle(this.prevTitle);
    this.evOnClose.emit();
  }

  public selectDayAgenda(date: string) {
    this.currentAgenda = date;
  }

  public ngOnDestroy() {
    if (this.agendaSubscription) {
      this.agendaSubscription.unsubscribe();
    }
  }
}
