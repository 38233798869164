import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { UtilitiesService } from '@service/utilities';
import { getUser } from '@state/user';
import { get, toArray } from 'lodash';
import { IUser } from 'rebus-models';
import { Observable, of } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { ModalCompanyInfoComponent } from '../entry-components/pages/modal-company-info/modal-company-info.component';
import { consoleDebug, consoleError } from '../helper/console.helper';
import { CompanyService } from '../service/company.service';
import { EventService } from '../service/event.service';
import { NotificationsService } from '../service/notifications.service';
import { LoadDesignAction } from '../state/design/design.actions';
import { LoadMenuAction } from '../state/menu/menu.actions';
import { SetRedirectUserUrlAction } from '../state/user/user.actions';


@Injectable()
export class MenuGuard implements CanActivate {

  public constructor(
    protected store: Store<any>,
    protected eventService: EventService,
    private navCtrl: NavController,
    private router: Router,
    private notificationsService: NotificationsService,
    private alertCtrl: AlertController,
    private notiService: NotificationsService,
    private ut: UtilitiesService,
    private modalCtrl: ModalController,
    private companyService: CompanyService,
  ) { }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!state.url.includes('user/login')) {
      this.store.dispatch(new SetRedirectUserUrlAction(state.url));
    }

    return this.validateEventAccess(route, state);
  }

  // users/events/:id/:event POST
  private validateEventAccess(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const eventId = get(route.params, 'eventId', '--');
    return this.eventService.getEventById(eventId).pipe(
      take(1),
      switchMap(event =>
        this.store.select(getUser).pipe(
          take(1),
          switchMap(user => {
            try {
              if (!user) {
                this.navCtrl.navigateForward(
                  ['/user/login'],
                  { queryParams: { redirect: `event?auto_enter=${eventId}` } }
                );
                this.store.dispatch(new LoadDesignAction(route.params.eventId));
                return of(false);
              } else {
                // if (state.url !== "/companies/--") {
                //   if (this.validateCompaniesUser(eventId, user)) {
                //     return of(true);
                //   }
                //   return of(false);
                // }
                // if (event.privateMode === 'database') {
                //   return this.eventService.getAllowedPrivateUsers(eventId).pipe(
                //     take(1),
                //     map(allowedEmails => {
                //       return !!allowedEmails.find((data) => {
                //         return (data.email === user.basicInformation.email);
                //       });
                //     }),
                //     map(isAllowed => {
                //       if (!isAllowed) {
                //         this.router.navigate(
                //           ['/shared/private']);
                //         return false;
                //       } if (event.privateTicketId) {
                //         this.router.navigate(
                //           ['/shared/private'],
                //           { queryParams: { ticketId: event.privateTicketId } }
                //         );
                //         return false;
                //       }
                //       return this.authTopicUser(user, eventId);
                //     })
                //   )
                // }

                // if (event.privateMode === 'code') {
                //   this.openAlertValidateCode(eventId)
                // }
                return of(true);
              }
            } catch (e) {
              return of(false);
            }
          })
        )),
      tap(data => {
        if (data) {
          this.store.dispatch(new LoadMenuAction(route.params.eventId));
          this.store.dispatch(new LoadDesignAction(route.params.eventId));
        }
      })
    );
  }

  async validateCompaniesUser(eventId, user) {
    if (user) {
      const companiesUser = await this.companyService.
        getCompaniesSnap(eventId, user._id)
        .then(res => toArray(res))
        .then(companies => companies.filter((i: any) => i.state === 'complete'))

      const noDelete = companiesUser.filter(i => !get(i, 'delete', false))
      if (!noDelete.length) {
        const modal = await this.modalCtrl.create({
          component: ModalCompanyInfoComponent,
          animated: true,
          backdropDismiss: false,
          cssClass: 'custom-modal'
        });
        await modal.present()
      }
      return true
    }
  }
  /* async openAlertValidateCode(eventId: string, userId: string, event) {
    const allowedCodes = await this.eventService.getAllowedPrivateUsersCodeByUser(eventId, userId)
    const userValidToEntry = toArray(allowedCodes).map(item => {
      if (item) {
        item.userId === userId;
      }
    })
    if (!userValidToEntry.length) {
      const modal = await this.modalCtrl.create({
        component: ModalValidateCodeEventComponent,
        componentProps: { eventId, userId, event, },
        animated: true,
        cssClass: 'custom-modal-validate-event',
        backdropDismiss: false
      });
      await modal.present();
    }
    return false
  } */



  private authTopicUser(user: IUser, eventId: string) {
    if (user) {
      const isTopicRegister = user.topics.includes(eventId);
      consoleDebug("topics user", null, user.topics);
      if (isTopicRegister) {
        return this.validateEvent(user, eventId);
      } else {
        const result = this.notificationsService
          .subscribeTopicEvent(user._id, eventId).subscribe(
            () => {
              consoleDebug('Usuario suscrito al topic del evento', null, eventId);
            },
            err => {
              consoleError('Error suscribiendo al usuario al topic', err);
            }
          );
        consoleDebug("result subscribe event", null, result)
      }
      return this.validateEvent(user, eventId);
    }
    return this.validateEvent(user, eventId);
  }

  private validateEvent(user: any, eventId: string) {
    if (user) {
      const tmpEvents = user.events.map(item => item.id);
      const isEventRegister = tmpEvents.includes(eventId);
      consoleDebug("Registro de usuario a evento previamente realizado", null, isEventRegister)
      if (!isEventRegister) {
        this.eventService.setRegisterEventAccess(user, eventId)
          .then(res => consoleDebug("Registro de usuario a evento", null, res))
      }
    }
    return true;
  }
}
