import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

import { PAGES } from './pages';
import { COMPONENTS } from './components';
import { SERVICES } from './services';
import { SharedModule } from '../shared/shared.module';
import { EventItemSearchResultComponent } from '../event/components/event-item-search-result/event-item-search-result.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { FormsModule } from '@angular/forms';
import { AgendaItemSearchResultComponent } from '../agenda/components/agenda-item-search-result/agenda-item-search-result.component';
import { NewsItemSearchResultComponent } from '../news/components/news-item-search-result/news-item-search-result.component';
import { ProductItemSearchResultComponent } from '../product/components/product-item-search-result/product-item-search-result.component';
import { StaffItemSearchResultComponent } from '../staff/components/staff-item-search-result/staff-item-search-result.component';
import { GalleryItemSearchResultComponent } from '../gallery/components/gallery-item-search-result/gallery-item-search-result.component';
import { EmbedItemSearchResultComponent } from '../embed/components/embed-item-search-result/embed-item-search-result.component';
import { ENTRYCOMPONENTS } from '../shared/entry-components/index';
import { NotificationOptionsComponent } from './components/notification-options/notification-options.component';
import { CouponItemSearchResultComponent } from '../coupon/components/coupon-item-search-result/coupon-item-search-result.component';
import { TicketItemSearchResultComponent } from '../ticket/components/ticket-item-search-result/ticket-item-search-result.component';
import { ServiceItemSearchResultComponent } from '../service/components/service-item-search-result/service-item-search-result.component';
import { StandItemSearchResultComponent } from '../stand/components/stand-item-search-result/stand-item-search-result.component';
import { SponsorItemSearchResultComponent } from '../sponsor/components/sponsor-item-search-result/sponsor-item-search-result.component';

@NgModule({
  declarations: [
    ...PAGES,
    ...COMPONENTS,
    // Components for search api
    EventItemSearchResultComponent,
    AgendaItemSearchResultComponent,
    NewsItemSearchResultComponent,
    ProductItemSearchResultComponent,
    StaffItemSearchResultComponent,
    GalleryItemSearchResultComponent,
    EmbedItemSearchResultComponent,
    CouponItemSearchResultComponent,
    TicketItemSearchResultComponent,
    ServiceItemSearchResultComponent,
    StandItemSearchResultComponent,
    SponsorItemSearchResultComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    SharedModule,
    FormsModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    }),
  ],
  exports: [
    ...PAGES,
    ...COMPONENTS,
  ],
  providers: [
    ...SERVICES,
  ],
  entryComponents: [
    NotificationOptionsComponent
  ]
})
export class HeaderSharedModule { }
