import { Component, Input } from '@angular/core';
import { UtilitiesService } from '@service/utilities';

@Component({
  selector: 'app-icon-share-item',
  templateUrl: './share-item.component.html',
  styleUrls: ['./share-item.component.scss'],
})
export class ShareItemComponent {

  @Input() public nameItem: string | { en: string, es: string };
  @Input() public labelShareItem: string;

  constructor(
    public ut: UtilitiesService
  ) { }

  public shareTicket() {
    this.ut.shareItemSocialMedia(this.nameItem, this.labelShareItem);
  }
}
