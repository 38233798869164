import { Component } from '@angular/core';

@Component({
  selector: 'item-stand-suggestion',
  templateUrl: './item-stand-suggestion.component.html',
  styleUrls: ['./item-stand-suggestion.component.scss'],
})
export class ItemStandSuggestionComponent {

}
