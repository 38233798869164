import { Component, OnInit, HostBinding } from '@angular/core';
import { ToastrService, ToastPackage, Toast } from 'ngx-toastr';
import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';
import { Icon, IDesign } from 'rebus-models';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { getColor } from 'src/app/modules/shared/helper/color.helper';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-message-toast',
  templateUrl: './message-toast.component.html',
  styleUrls: ['./message-toast.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('inactive', style({
        opacity: 0,
      })),
      transition('inactive => active', animate('300ms ease-out', keyframes([
        style({
          transform: 'none',
          opacity: 1,
        }),
      ]))),
      transition('active => removed', animate('300ms ease-out', keyframes([
        style({
          opacity: 1,
        }),
      ]))),
    ]),
  ],

  preserveWhitespaces: false,
})
export class MessageToastComponent extends Toast implements OnInit {

  public type: string;
  public icon: Icon;
  public image_url: string;
  public design: IDesign;
  @HostBinding('style.background-color')
  public bg_color: string;
  @HostBinding('class')
  public bg_class: string;


  // constructor is only necessary when not using AoT
  constructor(
    protected toastrService: ToastrService,
    public toastPackage: ToastPackage,
    public store: Store<{}>
  ) {
    super(toastrService, toastPackage);
  }

  public ngOnInit(): void {

    this.type = this.toastPackage.toastType.split('|')[0];
    this.icon = JSON.parse(this.toastPackage.toastType.split('|')[1]);
    this.image_url = this.toastPackage.toastType.split('|')[2] === 'null' ? null : this.toastPackage.toastType.split('|')[2];
    this.setBgColor(this.type);
  }

  // Se dispara cuando se hace Tap en la notificación
  public action(event: Event) {
    event.stopPropagation();
    this.toastPackage.triggerTap();
    return false;
  }

  // Hace set de las clases dependiendo del tipo del tipo de la notificación
  private setBgColor(type): void {

    switch (type) {
      case 'Success':
        this.bg_class = 'success';

        break;
      case 'Info':
        this.bg_class = 'info';
        break;

      case 'Error':
        this.bg_class = 'error';
        break;

      case 'Warning':
        this.bg_class = 'warning';
        break;

      case 'Custom':
        this.store.select(getDesign).pipe(take(1)).subscribe(val => {
          this.design = val;
          this.bg_color = getColor(this.design.firstColor);
        });
        break;
    }
  }


}
