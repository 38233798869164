import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { IDesign, IEvent } from 'rebus-models';
import { EventService } from '../../../shared/service/event.service';
import { SubeventPage } from '../../../shared/entry-components/pages/subevent/subevent.page';
import { NavController, ModalController } from '@ionic/angular';
import { AIService } from 'src/app/modules/shared/service/ai.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-event-item-search-result',
  templateUrl: './event-item-search-result.component.html',
  styleUrls: ['./event-item-search-result.component.scss']
})
export class EventItemSearchResultComponent implements OnInit, OnChanges {

  @Input() public design: IDesign;
  @Input() public event: IEvent;
  @Input() public userId: string;
  @Output() public evOnClickItem = new EventEmitter<void>();
  public match$: Observable<any>;

  constructor(
    public modalController: ModalController,
    private router: NavController,
    private eventService: EventService,
    private aiService: AIService
  ) { }

  public ngOnInit() {
    this.match$ = this.aiService.getRecommendationsForUser(this.userId, 'Events', null, null, this.event.id)
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      this.event = changes.event.currentValue;
    }
  }

  public async getSubEvents(event: IEvent) {
    const evServ = await this.eventService.getEventListSnap();
    return evServ.sort((a, b) => a.order - b.order)
      .filter(item => item.isSubevent)
      .filter(ev => ev.eventId === event.id);
  }
  public async onClickEvent() {
    const subEvs = await this.getSubEvents(this.event);
    subEvs.length > 0 ? this.openSubEvents(subEvs, this.event) : this.goEnterEvent(this.event);
  }

  public goEnterEvent(event: IEvent) {
    this.evOnClickItem.emit();
    let cmp = '';

    switch (event.defaultModule) {
      case 'diary':
        cmp = 'agenda';
        break;
      case 'staff':
        cmp = 'staff';
        break;
      case 'news':
        cmp = 'news';
        break;
      case 'catalog':
        cmp = 'product';
        break;
      case 'map':
        cmp = 'map';
        break;
      case 'commercial_samples':
        cmp = 'stand';
        break;
      case 'urlRedirection':
        cmp = 'UrlRedirectionEventPage';
        break;
    }

    if (cmp === 'UrlRedirectionEventPage' && event.withRedirectExternalUrl) {
      window.open(event.urlRedirection, '_blank');
    }
    if (cmp === 'UrlRedirectionEventPage' && !event.withRedirectExternalUrl) {
      window.location.href = event.urlRedirection;
    }
    if (cmp !== 'UrlRedirectionEventPage') {
      this.router.navigateForward([cmp, event.id]);
    }
  }


  public async openSubEvents(subEvents: IEvent[], event: IEvent) {
    const modal = await this.modalController.create({
      component: SubeventPage,
      componentProps: { events: subEvents, principalEvent: event },
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'modal-sub-event'
    });

    return await modal.present();
  }

  public onAddMyDay() {

  }

  public onReserve() {

  }
}
