import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NavController, MenuController } from '@ionic/angular';

import { environment } from '@app/env';
import { CustomValidators } from '../../../shared/validators/customValidators';
import { RestoreAction } from '../../../shared/state/auth/auth.actions';
import { UtilitiesService } from '@service/utilities';
import { getUserState } from '@state/user';
import { IDesign } from 'rebus-models';
import { consoleError } from '../../../shared/helper/console.helper';
import { takeUntil } from 'rxjs/operators';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';

@Component({
  selector: 'app-reset',
  templateUrl: './reset.page.html',
  styleUrls: ['./reset.page.scss'],
})
export class ResetPage implements OnInit, OnDestroy {

  @Input() public redirectTo: string = null;
  public design$: Observable<IDesign>;
  public form: FormGroup;
  public isAuthenticated = false;
  public submitAttempt = false;
  private token: string;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<{}>,
    private fb: FormBuilder,
    private ut: UtilitiesService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private menuCtrl: MenuController,
    private notiService: NotificationsService
  ) { }

  public ngOnInit() {
    this.menuCtrl.enable(false);
    this.design$ = this.store.select(getDesign);

    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(paramMap => {
      this.token = paramMap['token'];
    });

    // Este observable se encarga de hacer una redireccion del usuario en caso finalizar registro o ya ternerlo anteriormente
    this.store.select(getUserState).pipe(takeUntil(this.unsubscribe$))
      .subscribe(currentUser => {
        if (currentUser.user) {
          if (!this.isAuthenticated) {
            this.isAuthenticated = true;
            this.notiService.showRebusToast('Bienvenido al sistema', 'Success', false)
              .then(() => this.navCtrl.navigateRoot(window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo))
              .then(() => this.menuCtrl.enable(true));
          }
        }
      });

    this.initForm();
  }

  public onSubmitted({ value, valid }: { value: any; valid: boolean }) {
    if (valid) {
      this.form.reset();
      this.store.dispatch(new RestoreAction({ token: this.token, passwordHash: value.___password_conf }));
    } else {
      consoleError('Error en reset page form', value);
      this.notiService.showRebusToast('Debe diligenciar el formulario completamente',
        'Error', false);
    }
  }

  public onCancelRestart() {
    this.navCtrl.navigateBack(this.redirectTo || window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo)
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private initForm() {
    this.form = this.fb.group({
      ___password_new: new FormControl('', [
        Validators.required,
        Validators.min(6),
        Validators.pattern(new RegExp(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{6,20}$/))
      ]),
      ___password_conf: new FormControl('', [Validators.required, Validators.min(5)],
      )
    }, {
      validator: CustomValidators.Match('___password_new', '___password_conf')
    });
  }
}
