import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IDesign } from 'rebus-models';
import { UtilitiesService } from '@service/utilities';
import { ModalController, AlertController } from '@ionic/angular';
import { PaymentsDetailComponent } from '../payments-detail/payments-detail.component';
import { IUser, ICard } from 'rebus-models';
import { UserService } from '../../../service/user.service';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUserPaymentMethods, getUser } from '@state/user';
import { SetPaymentMethodsAction, SetDefaultPaymentMethodAction } from 'src/app/modules/shared/state/user/user.actions';
import { getDesign } from '@state/design';
import { consoleError } from 'src/app/modules/shared/helper/console.helper';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';

@Component({
  selector: 'app-profile-payments',
  templateUrl: './profile-payments.component.html',
  styleUrls: ['./profile-payments.component.scss']
})
export class ProfilePaymentsComponent implements OnInit, OnDestroy {

  public design$: Observable<IDesign>;
  public user$: Observable<IUser>;
  public selected$: Observable<string>;
  public cards: ICard[];
  public blBtnLoading = false;

  private unsubscribe$ = new Subject<void>();
  constructor(
    private modalCtrl: ModalController,
    private userService: UserService,
    private store: Store<{}>,
    private notiService: NotificationsService,
    private alertCtrl: AlertController,
    private ut: UtilitiesService
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.selected$ = this.store.select(getUserPaymentMethods)
      .pipe(map(data => data.default_method));
    this.user$ = this.store.select(getUser)
      .pipe(tap(user => {
        this.userService.getCardsByUser(user._id)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            this.updateCards(data.data.cards);
          });
      }));
  }

  public toggleSelected(cardToken: string) {
    this.store.dispatch(new SetDefaultPaymentMethodAction(cardToken));
  }

  public async addNew(design, user) {
    const modal = await this.modalCtrl.create({
      component: PaymentsDetailComponent,
      componentProps: { design, user },
      animated: true,
      backdropDismiss: false,
      cssClass: 'custom-modal-small'
    });
    await modal.present();
    const { data } = await modal.onDidDismiss();
    if (data) {
      this.updateCards(data.cards);
    }
  }

  public async deleteCard(cardToken: string, user) {
    const alert = await this.alertCtrl.create({
      header: 'Borrar tarjeta',
      subHeader: '¿Seguro que deseas eliminar este método de pago?',
      buttons: [
        { role: 'cancel', text: 'Cancelar' },
        {
          text: 'Eliminar', cssClass: 'danger', handler: () => {
            this.blBtnLoading = true;
            this.userService.deleteCard(cardToken, user._id).pipe(take(1)).toPromise()
              .then((data: any) => {
                this.updateCards(data.cards);
                this.notiService.showRebusToast('Método de pago borrado exitosamente',
                  'Success', false);
                this.blBtnLoading = false;
                this.toggleSelected('');

              }, err => {
                consoleError(err)
                this.ut.getMessageError(err)
                  .then(message => {
                    this.notiService.showRebusToast(message,
                      'Error', false);
                  });
                this.blBtnLoading = false;
              });
          }
        }
      ]
    });
    await alert.present();
  }

  public updateCards(cards) {
    this.cards = cards;
    if (this.cards.length === 1) {
      this.toggleSelected(this.cards[0].token)
    }
    this.store.dispatch(new SetPaymentMethodsAction(this.cards));
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
