import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { UtilitiesService } from '@service/utilities';
import { IAuthConfig, IDesign } from 'rebus-models';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { consoleError } from '../../../shared/helper/console.helper';
import { LoginAction } from '../../../shared/state/auth/auth.actions';


@Component({
  selector: 'app-email-login',
  templateUrl: './email-login.component.html',
  styleUrls: ['./email-login.component.scss']
})
export class EmailLoginComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public authConfig: IAuthConfig;
  @Output() public evOnForgotPassword = new EventEmitter<any>();

  public form: FormGroup;
  public submitAttempt = false;

  constructor(
    private fb: FormBuilder,
    private ut: UtilitiesService,
    private store: Store<{}>,
    private notiService: NotificationsService
  ) { }

  public ngOnInit() {
    this.initForm();
  }

  public onForgotPassword() {
    this.evOnForgotPassword.emit();
  }

  public onSubmitted({ value, valid }: { value: any; valid: boolean }) {
    this.submitAttempt = true;
    if (valid) {
      this.submitAttempt = false;
      this.form.get('___password_hash').setValue('M8Gzw6yDVFEWkvfIL');
      this.store.dispatch(new LoginAction({ email: value.___username_email.toLowerCase(), password: 'M8Gzw6yDVFEWkvfIL' }));
    } else {
      consoleError('Error en el formulario', this.form);
      this.notiService.showRebusToast('Debe diligenciar el formulario correctamente', 'Error', false);
    }
  }

  private initForm() {
    this.form = this.fb.group({
      ___username_email: new FormControl('', [Validators.required]),
      ___password_hash: new FormControl('M8Gzw6yDVFEWkvfIL', [Validators.required]),
    });
  }

}
