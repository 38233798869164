import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { IDesign, ISponsor } from 'rebus-models';
import { NavController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { AIService } from 'src/app/modules/shared/service/ai.service';

@Component({
  selector: 'app-sponsor-item-search-result',
  templateUrl: './sponsor-item-search-result.component.html',
  styleUrls: ['./sponsor-item-search-result.component.scss']
})
export class SponsorItemSearchResultComponent implements OnInit, OnChanges {

  @Input() public design: IDesign;
  @Input() public sponsor: ISponsor;
  @Input() public eventId: string;
  @Input() public userId: string;
  @Output() public evOnClickItem = new EventEmitter<void>();

  constructor(
    private navCtrl: NavController,
    private aiService: AIService
  ) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.sponsor) {
      this.sponsor = changes.sponsor.currentValue;
    }
  }

  public onClickSponsor() {
    this.navCtrl.navigateForward([`sponsor/detail/${this.sponsor.id}/${this.eventId}`]);
    this.evOnClickItem.emit();
  }

}
