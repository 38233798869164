import * as uniqid from 'uuid/v3';
import * as uuidV1 from 'uuid/v1';
import { environment } from '@app/env';
import * as firebase from 'firebase/app';

export function isValidEventId(eventId) {
  return eventId !== '' &&
    eventId !== '--' &&
    eventId !== null &&
    eventId !== 'null' &&
    eventId !== undefined &&
    eventId !== 'undefined' &&
    eventId !== 'false' &&
    eventId !== false;
}

export function getSecureToken() {
  const NAMESPACE = uuidV1();
  return uniqid(environment.clientId, NAMESPACE);
}

export function getFirestoreDate() {
  return firebase.firestore.FieldValue.serverTimestamp();
}

export function setCookie(cname, cvalue, exdays) {
  const d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  const expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function deleteCookie(cname) {
  document.cookie = cname + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

export function validateEmail(email: string) {
  // tslint:disable-next-line:max-line-length
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getCookie(cname) {
  const name = cname + '=';
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return null;
}

export function eliminateDiacritics(text: string) {
  return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
