import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, INews } from 'rebus-models';

@Component({
  selector: 'app-news-widget',
  templateUrl: './news-widget.component.html',
  styleUrls: ['./news-widget.component.scss'],
})
export class NewsWidgetComponent {

  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public new: INews;
  @Input() public views: number;

  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.new.id);
  }

}
