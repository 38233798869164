import { Component, Input } from '@angular/core';

@Component({
    selector: 'rebus-icon',
    templateUrl: './rebus-icon.component.html',
    styleUrls: ['./rebus-icon.component.scss']
})
export class RebusIconComponent {

    @Input() public name: string;
    @Input() public color: string;
    @Input() public class: string = null;
    @Input() public slot: 'start' | 'end' | 'none' = 'none';
    @Input() public size: 'small' | 'large' | 'default' = 'default';
}
