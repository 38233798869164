import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-info-browser',
  templateUrl: './modal-info-browser.component.html',
  styleUrls: ['./modal-info-browser.component.scss'],
})
export class ModalInfoBrowserComponent implements OnInit {

  @Input() public browser: string;

  constructor() { }

  public ngOnInit() { }

}
