import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { getUser, getUserBasicInfo } from '@state/user';
import { toArray } from 'lodash';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { NetworkingService } from 'src/app/modules/networking/services/networking.service';
import { consoleDebug, consoleError } from 'src/app/modules/shared/helper/console.helper';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';

@Component({
  selector: 'app-chat-networking-fast',
  templateUrl: './chat-networking-fast.component.html',
  styleUrls: ['./chat-networking-fast.component.scss'],
})
export class ChatNetworkingFastComponent implements OnInit {

  @Input() public userId;
  @Input() public design;
  @Input() public userIdSelected;
  @Input() public userSelectedBasicInformation;
  @Input() public messagesNotificationUser;

  public infoUser: any;
  public message = '';
  public user$: Observable<any>;

  public chatUser$: Observable<any>;
  public showModalDetailInfoUser = false;
  public dataToShow: string;
  public typeInfoDetail: string;

  constructor(
    public modalCtr: ModalController,
    private networkingService: NetworkingService,
    private store: Store<any>,
    private notiService: NotificationsService
  ) { }

  ngOnInit() {
    this.getMessagesToChat()
    this.deleteNotificactionsMessages()
    this.user$ = this.store.select(getUser).pipe(
      tap(user => {
        if (user) {
          this.infoUser = user.basicInformation
        }
        return user;
      })
    );
  }

  public calculateScrollContainerChat() {
    const containerChat = document.getElementById('chatContainer');
    if (containerChat) {
      containerChat.scrollTop = containerChat.scrollHeight;
    }
    return true;
  }

  public async getMessagesToChat() {
    const users_chat_option1 = this.userId + '_' + this.userIdSelected;
    const users_chat_option2 = this.userIdSelected + '_' + this.userId;
    const chatPerson1 = await this.networkingService.getChatRoomByForeignKey(users_chat_option1);
    const chatPerson2 = await this.networkingService.getChatRoomByForeignKey(users_chat_option2);

    if (!chatPerson1 && !chatPerson2) {
      this.chatUser$ = this.networkingService.getMessagesChatRoom(users_chat_option1);
    } else if (!!chatPerson1) {
      this.chatUser$ = this.networkingService.getMessagesChatRoom(users_chat_option1);
    } else {
      this.chatUser$ = this.networkingService.getMessagesChatRoom(users_chat_option2);
    }
  }

  /**
  *
  * @param sender id del usuario que envio el mensaje
  * @param uid id de usuario autenticado
  * @param sdata data del usuario que envio el mensaje
  */
  public getUserByMessage(sender: string) {
    return sender === this.userId ?
      this.store.select(getUserBasicInfo) :
      of(this.userSelectedBasicInformation);
  }

  public getPrincipalUser() {
    return this.store.select(getUserBasicInfo);
  }

  public handleSendMessage(keyCode, message) {
    if (keyCode === 13) {
      this.sendMessage({ userId: this.userIdSelected, message });
      this.message = '';
    }
  }

  public onSendMessage(message) {
    this.sendMessage({ userId: this.userIdSelected, message });
    this.message = '';
  }

  public async sendMessage(user) {
    if (user.message !== '') {
      const userIdSelected = user.userId;
      const message = user.message;
      const users_chat_option1 = this.userId + '_' + userIdSelected;
      const users_chat_option2 = userIdSelected + '_' + this.userId;
      const chatPerson1 = await this.networkingService.getChatRoomByForeignKey(users_chat_option1);
      const chatPerson2 = await this.networkingService.getChatRoomByForeignKey(users_chat_option2);
      const result = !!chatPerson1 ? chatPerson1 : chatPerson2;

      if (result) {
        const chatUser = toArray(result);
        this.sendMessageChat(message, chatUser[0].id, userIdSelected);
      } else {
        const chat = {
          'foreign_key': users_chat_option1,
          'user1': this.userId,
          'user2': userIdSelected
        };
        this.networkingService.createChatRoomUsers(chat).then(chatUser => {
          this.sendMessageChat(user.message, chatUser.id, userIdSelected);

        }).catch(error => {
          consoleError('error creando chat', error);
        });
      }
    }
  }

  public sendMessageChat(message, chatUserId, userIdSelected) {
    if (message !== '') {
      const messageObj = {
        'sender': this.userId,
        'text': message,
      };
      this.networkingService.createMessageChat(messageObj, chatUserId).then(() => {
        this.networkingService.createNotificationMessage(userIdSelected, this.userId, message, this.infoUser).then((data) => {
          consoleDebug('save notification chat user', data);
        })
      }).catch(() => {
        this.notiService.showRebusToast('Se produjo un error enviando mensaje', 'Error', false, null, null, 'networking');
      });
    }
  }

  deleteNotificactionsMessages() {
    this.networkingService.deleteNotificationsByUser(this.userId, this.messagesNotificationUser, this.userIdSelected).then((val) => {
      consoleDebug('Se borra notificiones de chat a usuario', null, val)
    });
  }

  public onShowModalDetailInfoUser(data, type) {
    this.showModalDetailInfoUser = true;
    this.typeInfoDetail = type;
    this.dataToShow = data;
  }

  public onHiddenModalDetailInfoUser() {
    this.showModalDetailInfoUser = false;
  }

}
