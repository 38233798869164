import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, ITransactionItem, IItem, IService } from 'rebus-models';
import { ModalController, AlertController } from '@ionic/angular';
import * as moment from 'moment';
import { UtilitiesService } from '@service/utilities';
import { UserService } from '../../../service/user.service';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { consoleError } from 'src/app/modules/shared/helper/console.helper';
@Component({
  selector: 'app-transactions-detail',
  templateUrl: './transactions-detail.component.html',
  styleUrls: ['./transactions-detail.component.scss']
})
export class TransactionsDetailComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public data: { item: IItem, data: ITransactionItem };
  @Input() public userId: string;

  public showDropDown = false;

  public options = [
    {
      value: 'detail_reserve',
      label: 'Ver Detalle'
    },
    {
      value: 'cancel_reserve',
      label: 'Cancelar Reserva'
    },
  ];

  constructor(
    public modalCtrl: ModalController,
    private userService: UserService,
    private notiService: NotificationsService,
    private alertCtrl: AlertController
  ) { }

  public ngOnInit() {
  }

  public showDropDownMoreInfo() {
    this.showDropDown = !this.showDropDown;
  }

  public onSelectItem(optionSelected, data: any, paymentId: string, typeItem: string) {

    switch (typeItem) {
      case 'services':
        this.optionsService(paymentId, data, optionSelected);
        break;

      case 'reserve':
        this.optionsReserve(paymentId, data, optionSelected);
        break;
    }
  }

  public optionsService(paymentId: string, data, optionSelected) {
    const dateReserve = data.reserve.dates[0].day;
    const timeStartReserve = moment(data.reserve.dates[0].hourStart, 'HH:mm a');
    const hasCancelServiceRules = data.service.cancel_service.hasCancelServiceRules;
    const daysBeforeToCancel = data.service.cancel_service.daysBeforeToCancel;
    const minutesBeforeToCancel = data.service.cancel_service.minutesBeforeToCancel;
    const penaltyCancelReserve = data.service.cancel_service.penaltyCancelReserve;
    const currentDate = moment();
    const currentTimeInMinutes = currentDate.format('HH:mm a');
    const calculateDayAvalible = currentDate.add(daysBeforeToCancel, 'days').locale('es').format('DD MMMM YYYY');
    const calculateTimeAvalible = timeStartReserve.add(-minutesBeforeToCancel, 'minutes').format('HH:mm a');

    switch (optionSelected) {
      case 'detail_reserve':
        // TODO: hacer modal de detalle de servicio
        break;

      case 'cancel_reserve':

        if (hasCancelServiceRules) {
          if (dateReserve < calculateDayAvalible) {
            if (currentTimeInMinutes >= calculateTimeAvalible) {
              // tslint:disable-next-line:max-line-length
              const message = 'Esta seguro de cancelar esta reserva?, se generara una multa por $' + penaltyCancelReserve + ' ' + 'por no cancelar a tiempo, el tiempo permitido de cancelación es de' + ' ' + daysBeforeToCancel + ' ' + 'dias de anticipación';
              this.openAlertCancelService(paymentId, data.service.id, message);
            } else {
              // tslint:disable-next-line:max-line-length
              const message = 'Esta seguro de cancelar esta reserva?, se generara una multa por $' + penaltyCancelReserve + ' ' + 'por no cancelar a tiempo, el tiempo permitido de cancelación es de' + ' ' + minutesBeforeToCancel + ' ' + 'minutos de anticipación';
              this.openAlertCancelService(paymentId, data.service.id, message);
            }
          } else {
            const message = '¿ Esta seguro de cancelar la reserva ?';
            this.openAlertCancelService(paymentId, data.service.id, message);
          }
        } else {
          const message = '¿ Esta seguro de cancelar la reserva ?';
          this.openAlertCancelService(paymentId, data.service.id, message);
        }
        break;

      default:
        break;
    }
  }

  public optionsReserve(paymentId: string, data, optionSelected) {
    switch (optionSelected) {
      case 'detail_reserve':
        // TODO: hacer modal de detalle de servicio
        break;

      case 'cancel_reserve':
        this.openAlertCancelReserve(paymentId);
        break;

      default:
        break;
    }
  }

  public async openAlertCancelService(paymentId: string, serviceId: string, subHeader) {
    const alert = await this.alertCtrl.create({
      animated: true,
      header: 'Alerta',
      subHeader: subHeader,
      buttons: [
        { text: 'No', role: 'cancel' },
        {
          text: 'Si', handler: () => {
            this.cancelService(paymentId, serviceId);
          }
        }
      ]
    });
    alert.present();
  }

  public async openAlertCancelReserve(paymentId) {
    const alert = await this.alertCtrl.create({
      animated: true,
      header: 'Alerta',
      subHeader: 'Esta seguro de cancelar la reserva ?',
      buttons: [
        { text: 'No', role: 'cancel' },
        {
          text: 'Si', handler: () => {
            this.cancelReserve(paymentId);
          }
        }
      ]
    });
    alert.present();
  }

  private async cancelService(paymentId: string, serviceId: string) {
    return this.userService.transactionCancelReserveService(paymentId, this.userId, serviceId).subscribe(
      res => {
        this.showDropDown = false;
        this.modalCtrl.dismiss();
        this.notiService.showRebusToast('Muy bien! Tu reserva ha sido cancelada correctamente',
          'Success', true, null, null, 'detail-reserve');
      },
      err => {
        this.showDropDown = false;
        this.notiService.showRebusToast('Ocurrio un error procesando la cancelación de tu reserva!',
          'Warning', true, null, null, 'detail-reserve');
        consoleError('Error occured email sended', err);
      }
    );
  }

  private async cancelReserve(paymentId) {
    return this.userService.transactionCancelReserve(paymentId, this.userId).subscribe(
      res => {
        this.showDropDown = false;
        this.modalCtrl.dismiss();
        this.notiService.showRebusToast('Muy bien! Tu reserva ha sido cancelada correctamente',
          'Success', true, null, null, 'detail-reserve');
      },
      err => {
        this.showDropDown = false;
        this.notiService.showRebusToast('Ocurrio un error procesando la cancelación de tu reserva!',
          'Warning', true, null, null, 'detail-reserve');
        consoleError('Error occured email sended', err);
      }
    );
  }
}
