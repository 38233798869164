import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { IClient } from 'rebus-models';
import { Store } from '@ngrx/store';
import { getClient } from '@state/config';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'terms-service',
  templateUrl: './terms-service.page.html',
  styleUrls: ['./terms-service.page.scss'],
})
export class TermsServicePage implements OnInit {

  public client$: Observable<IClient>;

  constructor(
    private store: Store<{}>,
    public modalCtrl: ModalController
  ) { }

  public ngOnInit() {
    this.client$ = this.store.select(getClient);
  }

}
