import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-btn-upload-file',
  templateUrl: './btn-upload-file.component.html',
  styleUrls: ['./btn-upload-file.component.scss']
})
export class BtnUploadFileComponent {

  @Input() public design: IDesign;
  @Input() public text: String = 'Cargar Archivos';
  @Output() public evLoadedFiles = new EventEmitter<any>();

  private selectedFiles = null;

  constructor() { }

  public onFilesSelected(event) {
    this.selectedFiles = event.target.files;
    this.evLoadedFiles.emit(this.selectedFiles);
  }
}
