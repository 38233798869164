import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { appReducer } from './app.reducer';
import { ConfigEffects } from './config/config.effects';
import { AuthEffects } from './auth/auth.effects';
import { SERVICES } from '../service';
import { MenuEffects } from './menu/menu.effects';
import { DesignEffects } from './design/design.effects';
import { UserEffects } from './user/user.effects';
import { UserService } from '../../user/service/user.service';
import { LoadingEffects } from './loading/loading.effects';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(appReducer),
    EffectsModule.forRoot([
      ConfigEffects,
      AuthEffects,
      MenuEffects,
      DesignEffects,
      UserEffects,
      LoadingEffects,
    ])
  ],
  declarations: [],
  providers: [
    ...SERVICES,
    UserService,
  ]
})

export class StateModule {

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: StateModule
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: StateModule) {
    if (parentModule) {
      throw new Error(
        'StateModule is already loaded. Import it in the AppModule only');
    }
  }
}
