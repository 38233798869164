import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IUser, IDesign } from 'rebus-models';
import { NotificationsService } from '../service/notifications.service';
import { Subscription } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { ModalAlertNotificationComponent } from '../modal-alert-notification/modal-alert-notification.component';
import { UtilitiesService } from '@service/utilities';

@Component({
  selector: 'app-message-notifications',
  templateUrl: './message-notifications.component.html',
  styleUrls: ['./message-notifications.component.scss'],
})
export class MessageNotificationsComponent implements OnInit {

  @Input() public eventId: string;
  @Input() public userId: string;
  @Input() public design: IDesign;

  @Output() public evOnShowFooterNotification = new EventEmitter<any>();

  public alertSubscribe: Subscription;
  public toastSubscribe: Subscription;
  public footerSubscribe: Subscription;
  public userViewAlert;
  public userViewToast;

  constructor(
    public modalController: ModalController,
    private notiService: NotificationsService,
    private ut: UtilitiesService
  ) { }

  public ngOnInit() {
    this.getAlertNotifications();
    this.getToastNotifications();
    this.getFooterNotifications();
  }

  public getAlertNotifications() {
    this.alertSubscribe = this.notiService.getNotificationsModule(this.eventId, 'Alert').subscribe(async alert => {
      const alertActive = alert[0];
      if (alertActive) {
        this.userViewAlert = await this.notiService.getUserViewAlertSnap(this.userId, this.eventId, alertActive.id);
        if (alertActive && !this.userViewAlert) {
          const modal = await this.modalController.create({
            component: ModalAlertNotificationComponent,
            componentProps: {
              alert: alertActive,
              userId: this.userId,
              eventId: this.eventId,
              design: this.design
            },
            animated: true,
            showBackdrop: true,
            backdropDismiss: true,
            cssClass: 'custom-modal-noti-alert'
          });

          return await modal.present();
        }
      }
    })
  }

  public getToastNotifications() {
    this.toastSubscribe = this.notiService.getNotificationsModule(this.eventId, 'Toast').subscribe(async toast => {
      const toastActive = toast[0];
      if (toastActive) {
        this.userViewToast = await this.notiService.
          getUserViewToastSnap(this.userId, this.eventId, toastActive.id);
        if (toastActive && !this.userViewToast) {
          const redirect = toastActive.is_external ? '_blank' : '_self';
          const toastPresent = this.ut.presentToastNotification(
            toastActive.label_btn_action || 'Ok',
            toastActive.title,
            redirect,
            toastActive.callback,
            toastActive.text.es,
            toastActive.timeout,
            toastActive.position,
          ).then(async () => {
            const saveView = await this.notiService.saveViewToastPerUser(this.userId, this.eventId, toastActive.id);
          })
        }
      }
    })
  }

  public getFooterNotifications() {
    this.footerSubscribe = this.notiService.getNotificationsModule(this.eventId, 'Footer').subscribe(async footer => {
      const footerActive = footer[0];
      if (footerActive) {
        if (footerActive) {
          this.evOnShowFooterNotification.emit(footerActive);
        }
      } else {
        this.evOnShowFooterNotification.emit(null);
      }
    })
  }

  public ngOnDestroy() {
    if (this.alertSubscribe) {
      this.alertSubscribe.unsubscribe();
    }
    if (this.toastSubscribe) {
      this.toastSubscribe.unsubscribe();
    }
    if (this.footerSubscribe) {
      this.footerSubscribe.unsubscribe();
    }
  }

}
