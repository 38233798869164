import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@app/env';
import { Store } from '@ngrx/store';
import { getAuthState } from '@state/auth';
import { getUser } from '@state/user';
import * as firebase from 'firebase/app';
import { get } from 'lodash';
import { ICard, IUser } from 'rebus-models';
import { Observable } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { consoleError, consoleService } from '../../shared/helper/console.helper';
import { getCookie } from '../../shared/helper/utils.helper';
import { AuthService } from '../../shared/service/auth.service';
import { ClientService } from '../../shared/service/client.service';


@Injectable()
export class UserService {

  constructor(
    private authService: AuthService,
    private http: HttpClient,
    private dbFirestore: AngularFirestore,
    private store: Store<{}>,
    private clientService: ClientService
  ) { }

  public async getUserById(userId: string): Promise<any> {
    const token = await this.authService.getTokenUser()
      .pipe(take(1)).toPromise();
    const headers = new HttpHeaders({ 'Authorization': token });
    const response = await this.http.get(`${environment.backend}/users/${environment.clientId}/${userId}`, { headers }).toPromise();
    return response;
  }

  public getUserByIdObser(userId: string): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(`${environment.backend}/users/${environment.clientId}/${userId}`, { headers });
      })
    );
  }

  public getAnonymousById(id: string): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.get(`${environment.backend}/auth/anonymous/get_id?=${id}`,
      { headers }
    ).pipe(tap(res => consoleService('Get anonymous backend response: ', res)));

  }

  // tslint:disable-next-line:no-any
  public updateUser(payload: any): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.post(`${environment.backend}/users/update`, { ...payload }, { headers });
      })
    );
  }

  // tslint:disable-next-line: no-any
  public updateAnonymous(payload: any): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.post(`${environment.backend}/auth/anonymous/update`, { ...payload }, { headers });
      })
    );
  }

  public getCardsByUser(uid: string): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get(`${environment.pay_rebus}/v2/paymentez/cards/${uid}/${environment.clientId}`,
          { headers });
      })
    );
  }

  public addCard(card: ICard, user: IUser): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.post(`${environment.pay_rebus}/v2/paymentez/add/${environment.clientId}`,
          { card: card, user: { email: user.basicInformation.email, id: user._id } },
          { headers });
      })
    );
  }

  public deleteCard(cardToken: string, userId: string) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.post(`${environment.pay_rebus}/v2/paymentez/deleteCard/${environment.clientId}`,
          { card: { token: cardToken }, user: { id: userId } },
          { headers });
      })
    );
  }

  public setDefaultCard(token: string, uid: string): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((authToken: string) => {
        const headers = new HttpHeaders({ 'Authorization': authToken });
        return this.http.post(`${environment.pay_rebus}/v2/paymentez/`,
          { token, userId: uid, clientId: environment.clientId },
          { headers });
      })
    );
  }

  public setConection(): any {
    let lastUser;
    this.store.select(getAuthState).subscribe(async (user: any) => {
      const storeUser: any = await this.store.select(getUser).pipe(take(1)).toPromise()
      const clientConfig: any = await this.clientService.getClientSnap()

      if (user.authUID || lastUser) {
        const userStatusHistoryDatabaseRef = firebase.database().ref(`/Clients/${environment.clientId}/___connections_history`)
        const userStatusDatabaseRef = firebase.database().ref(`/Clients/${environment.clientId}/___connections/${user.authUID}`)
        const userStatusIncontactoDatabaseRef = firebase.database().ref(`/Clients/${environment.clientId}/___connections_incontacto/${user.authUID}`)

        const isOfflineForDatabase = {
          uid: user.authUID,
          state: false,
          lastDisconnect: firebase.database.ServerValue.TIMESTAMP,
        };
        const isOnlineForDatabase = {
          uid: user.authUID,
          state: true,
          lastConnect: firebase.database.ServerValue.TIMESTAMP,
        };

        // Si cambia el authUID es porque se debe desconectar el usuario anterior y conectar uno nuevo
        if (lastUser && user.authUID !== lastUser.authUID && !get(lastUser.firebaseInfo, 'isAnonymous', false)) {
          const userStatusDatabaseRefHistory = firebase.database().ref(`/Clients/${environment.clientId}/___connections/${user.authUID}`)
          const isOfflineForDatabaseLogout = {
            uid: user.authUID,
            state: false,
            lastDisconnect: firebase.database.ServerValue.TIMESTAMP,
          };

          if (user.authUID) {
            userStatusDatabaseRefHistory.once('value')
              .then(r => {
                if (!r.val()) {
                  return
                }
                return r.val().lastDisconnect && !r.val().state ? userStatusHistoryDatabaseRef.push(r.val()) : null
              })
            userStatusDatabaseRefHistory.update(isOfflineForDatabaseLogout)
          }
        }
        // Se actualiza el nuevo lastUser
        lastUser = Object.assign(user)

        if (get(user, 'authUID', false) && storeUser) {
          firebase.database().ref('.info/connected').on('value', async (snap) => {
            if (snap.val() == false) {
              return;
            }

            if (snap.val() && !get(user.firebaseInfo, 'isAnonymous', false)) {

              if (storeUser && get(clientConfig, 'isActiveIntegrationIncontacto', false)) {
                this.authService.createConnectionIncontacto(get(storeUser.customInformation, 'custom1', 'null'), get(clientConfig, 'checkinUrl', false))
              }

              userStatusIncontactoDatabaseRef.onDisconnect().update(isOfflineForDatabase).then(() => {
                userStatusIncontactoDatabaseRef.update(isOnlineForDatabase)
              });

              userStatusDatabaseRef.onDisconnect().update(isOfflineForDatabase).then(() => {
                userStatusDatabaseRef.once('value')
                  .then(r => r.val().lastDisconnect && !r.val().state ? userStatusHistoryDatabaseRef.push(r.val()) : null)
                userStatusDatabaseRef.update(isOnlineForDatabase)

                // Seguimiento de  la sesion
                window.sessionStorage.setItem(`${environment.clientId}-REBUS-V5-SESSION`, getCookie(`${environment.clientId}-COOKIE-TRACKING`))
                const identify = getCookie(`${environment.clientId}-COOKIE-TRACKING`)
                const userDevicesRef = firebase.database().ref(`/Clients/${environment.clientId}/___connections_devices/${user.authUID}/${identify}`)
                userDevicesRef.update({
                  ...isOnlineForDatabase,
                  // navigator: window.navigator.platform,
                  // userAgent: window.navigator.userAgent,
                })
                // TODO: revisar
                // Aca se marca incontacto cuando se cierra la conexion de firebase
                // this.authService.createConnectionIncontacto(get(user.customInformation, 'custom1', 'null'), get(clientConfig, 'uncheckingUrl', false))
              });
            }
          })
        }
      }
    })
  }

  public uploadImage(file, userId): Promise<any> {
    const storageRef = firebase.storage().ref();
    const uploadTask: firebase.storage.UploadTask = storageRef.child(`user_imgs/${userId}`).put(file.file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        firebase.storage.TaskEvent.STATE_CHANGED,
        (snapshot: firebase.storage.UploadTaskSnapshot) => { },
        (error) => consoleError('error upload image', error),
        () => {
          uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
            // Se guardan los datos en al dbFirestore
            this.saveImage({
              name: userId,
              url: downloadURL
            }).then((val) => {
              resolve(
                {
                  name: userId,
                  url: downloadURL
                }
              );
            }, err => reject(err));

          }, err => reject(err));
        }
      );
    });

  }

  public transactionCancelReserveService(paymentId: string, userId: string, serviceId: string): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((authToken: string) => {
        const headers = new HttpHeaders({ 'Authorization': authToken });
        return this.http.post(`${environment.pay_rebus}/v2/refound/`, {
          client: environment.clientId,
          user: userId,
          payment: paymentId,
          services: [
            serviceId
          ],
        }, { headers });
      })
    );
  }


  public transactionCancelReserve(paymentId: string, userId: string): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((authToken: string) => {
        const headers = new HttpHeaders({ 'Authorization': authToken });
        return this.http.post(`${environment.pay_rebus}/v2/refound/`, {
          client: environment.clientId,
          user: userId,
          payment: paymentId,
          services: [],
        }, { headers });
      })
    );
  }

  private saveImage(image: { name: string, url: string }): Promise<any> {
    return this.dbFirestore.collection(`/user_imgs`).add(image);
  }

  public getUserByDni(dni: string): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.post(`${environment.backend}/users/v1/find_by_dni/${environment.clientId}`,
          { dni: dni },
          { headers });
      })
    );
  }
}

