import { Pipe, PipeTransform } from '@angular/core';
import { isArray, last } from 'lodash';
import { BreakpointObserver } from '@angular/cdk/layout';

@Pipe({ name: 'optimize' })
export class OptimizePipe implements PipeTransform {
  // tslint:disable-next-line:no-any
  constructor(public breakpointObserver: BreakpointObserver,
  ) { }
  public transform(value: any): any[] {
    if (isArray(value)) {
      const low = value[0];
      const path = low.replace('_large.jpg', '')
        .replace('_small.jpg', '')
        .replace('_medium.jpg', '')
        .replace('_small.png', '')
        .replace('_medium.png', '')
        .replace('_large.png', '');
      return path;
      if (this.breakpointObserver.isMatched('(max-width: 599px)')) {
        return value[0]
      } else if (this.breakpointObserver.isMatched('(max-width: 1020px)')) {
        return value[1];
      } else {
        return value[2];
      }
    }
    return value;
  }
}
