import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable, Subject, from } from 'rxjs';
import { IDesign, ICouponConfig } from 'rebus-models';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { SearchService } from '../../services/search.service';
import { takeUntil, tap, map, filter } from 'rxjs/operators';
import { toArray } from 'lodash';
import { getAuthUID } from '@state/auth';
import { CouponService } from 'src/app/modules/coupon/services/coupon.service';


@Component({
  selector: 'app-search-results',
  templateUrl: './search-results.page.html',
  styleUrls: ['./search-results.page.scss'],
})
export class SearchResultsPage implements OnInit, OnDestroy {

  public design$: Observable<IDesign>;
  public searchParams: string;
  public moduleFrom = '';
  public routeFrom: string;
  public eventId: string;
  public searchResults$: Observable<any>;
  public arrModules: string[];
  public userId$: Observable<string>;
  public couponConfigWidgets$: Observable<ICouponConfig>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<{}>,
    public navCtrl: NavController,
    private route: ActivatedRoute,
    private searchService: SearchService,
    private couponService: CouponService
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(paramMap => {
      this.searchParams = paramMap['searchParams'].replace('%20', ' ');
      this.moduleFrom = paramMap['moduleFrom'] === '--' ? '' : paramMap['moduleFrom'];
      this.eventId = paramMap['eventId'];
      this.initTransactionReqs();
      this.routeFrom = paramMap['routeFrom'];
      this.searchResults$ = from(this.searchService.getResults(
        this.searchParams, this.eventId, 5, 'all', this.moduleFrom, false, '')
      ).pipe(
        map(data => data.data),
        map(data => {
          if (data) {
            return data.reduce((total, next) =>
              ({ ...total, [next.module]: next.items }), {});
          }
          return {};
        }),
        tap(data => this.arrModules = Object.keys(data)),
      );

      this.searchService.saveSearch(this.searchParams);
    });
  }

  /**
   * Método que se encarga de inicializar los observables de los cupones y productos
   * Para que puedan ser adquiridos o comprados desde el buscador
   */
  public initTransactionReqs() {
    this.userId$ = this.store.select(getAuthUID).pipe(
      filter(uid => !!uid),
    );

    this.couponConfigWidgets$ = this.couponService.getConfigWidgetsCoupon(this.eventId);
  }

  public onClickItem(item) {
    this.searchService.saveSeen(item, this.moduleFrom);
  }

  public onClose() {
    this.navCtrl.navigateForward(this.routeFrom);
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
