import { Component, OnInit, Input } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-guest-list',
  templateUrl: './guest-list.component.html',
  styleUrls: ['./guest-list.component.scss']
})
export class GuestListComponent implements OnInit {

  @Input() public design: IDesign;
  public blShowModal: Boolean = false;

  constructor() { }

  ngOnInit() {
  }

  public onCloseModal() {
    this.blShowModal = false;
  }

  public onInvite() {
    this.blShowModal = true;
  }

  public onSubmit(event) {
    this.onCloseModal();
  }

}
