import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDesign } from 'rebus-models';
import { getDesign } from '@state/design';
import { NavController, ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-company-info',
  templateUrl: './modal-company-info.component.html',
  styleUrls: ['./modal-company-info.component.scss'],
})
export class ModalCompanyInfoComponent implements OnInit {

  @Input() delete = null

  public design$: Observable<IDesign>;

  constructor(
    public modalController: ModalController,
    private store: Store<{}>,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.design$ = this.store.select(getDesign);
  }

  goCompanyPage() {
    this.navCtrl.navigateRoot(['/companies/--']);
    this.modalController.dismiss();
  }

  sendDelete() {
    this.modalController.dismiss({sendDelete: true});
  }


}
