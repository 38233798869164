import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Page } from './pages/error404/error404.page';
import { Error500Page } from './pages/error500/error500.page';
import { Error401Page } from './pages/error401/error401.page';
import { MenuGuard } from './guards/menu.guard';
import { PrivateEventPage } from './pages/privateEvent/privateEvent.page';

const routes: Routes = [
  { path: '404', component: Error404Page, pathMatch: 'full', canActivate: [MenuGuard] },
  { path: '401', component: Error401Page, pathMatch: 'full', canActivate: [MenuGuard] },
  { path: '500', component: Error500Page, pathMatch: 'full', canActivate: [MenuGuard] },
  { path: 'private', component: PrivateEventPage, pathMatch: 'full', canActivate: [MenuGuard] },
  { path: 'private/:ticketId', component: PrivateEventPage, pathMatch: 'full', canActivate: [MenuGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SharedRoutingModule { }
