import { Pipe, PipeTransform } from '@angular/core';
import { AIService } from '../service/ai.service';
import { TRecommendationEntity } from 'rebus-models';
import { from } from 'rxjs';

/**
 * Se encarga de traer la data de la entidad recomendada por cliente o evento
 * @param [type,userId,eventId, id]
 */
@Pipe({ name: 'getRecomendationsUser' })
export class UserRecomendationsPipe implements PipeTransform {
  constructor(
    private aiService: AIService
  ) { }
  public transform(entity: TRecommendationEntity, eventId: string, id: string) {
    return from(this.aiService.fecthEntityData(eventId, entity, id));
  }
}
