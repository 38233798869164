import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { ModalController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { environment } from '@app/env';
import { IEvent, IDesign } from 'rebus-models';
import * as designSelectors from '../../../state/design/design.reducer';
import { getAuthUID } from '@state/auth';

@Component({
  selector: 'app-subevent',
  templateUrl: './subevent.page.html',
  styleUrls: ['./subevent.page.scss'],
})
export class SubeventPage implements OnInit {

  @Input() public principalEvent: IEvent;
  @Input() public events: IEvent[];

  public limitTo = environment.limitTo;
  public design$: Observable<IDesign>;
  public blDescOpen: Boolean = false;
  public uid: string;

  constructor(
    private store: Store<{}>,
    public modalController: ModalController,
  ) { }

  public ngOnInit() {
    this.store.select(getAuthUID).pipe(take(1)).toPromise()
      .then(uid => this.uid = uid);
    this.design$ = this.store.select(designSelectors.getDesign);
  }

  public onClickEvent() {
    this.modalController.dismiss();
  }

  public onClose() {
    this.modalController.dismiss();
  }

  public onToggleDesc() {
    this.blDescOpen = !this.blDescOpen;
  }

  public moveLeft() {
    document.getElementById('scroll').scrollTo({ left: (document.getElementById('scroll').scrollLeft - 400), behavior: 'smooth' })
  }

  public moveRight() {
    document.getElementById('scroll').scrollTo({ left: (document.getElementById('scroll').scrollLeft + 400), behavior: 'smooth' })
  }

}
