import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, IService } from 'rebus-models';

@Component({
  selector: 'app-service-widget',
  templateUrl: './service-widget.component.html',
  styleUrls: ['./service-widget.component.scss'],
})
export class ServiceWidgetComponent {

  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public service: IService;
  @Input() public views: number;

  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.service.id);
  }
}
