import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IStand, IDesign } from 'rebus-models';

@Component({
  selector: 'app-stand-widget',
  templateUrl: './stand-widget.component.html',
  styleUrls: ['./stand-widget.component.scss'],
})
export class StandWidgetComponent {

  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public stand: IStand;
  @Input() public views: number;

  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.stand.id);
  }
}


