import { Action } from '@ngrx/store';
import { ICard, IUser } from 'rebus-models';

export const GET_CURRENT_USER = '[ USER ] Carga el session actualmente autenticado';
export const GET_CURRENT_USER_SUCCESS = '[ USER ] Carga el session actualmente autenticado correctamente';
export const UPDATE_CURRENT_USER = '[ USER ] Actualiza la información del usuario actual';
export const UPDATE_CURRENT_USER_SUCCESS = '[ USER ] Actualiza la información del usuario actual correctamente';
export const UPDATE_CURRENT_USER_ERROR = '[ USER ] Actualiza la información del usuario actual con error';
export const GET_CURRENT_USER_ERROR = '[ USER ] Carga el session actualmente autenticado con error';
export const SET_REDIRECT_USER_URL = '[ USER ] Carga la url que trae el usuario por defecto';
export const SET_LOGOUT = '[ USER ] Logout del usuario ya autenticado';
export const SET_LOGOUT_SUCCESS = '[ USER ] Logout del usuario ya autenticado correctamente';

export const SET_PAYMENT_METHODS = '[ USER ] Carga de los métodos de pago del usuario actual';
export const SET_PAYMENT_METHODS_ERROR = '[ USER ] Carga de los métodos de pago del usuario actual con error';
export const SET_DEFAULT_PAYMENT_METHOD = '[ USER ] Carga del método de pago por defecto del usuario actual';
export const SET_DEFAULT_PAYMENT_METHOD_ERROR = '[ USER ] Carga del método de pago por defecto del usuario actual con error';

export const TRACKING_INTEREST = '[ USER ] Tracking del cambio de intereses del usuario actual';
export const TRACKING_INTEREST_ERROR = '[ USER ] Tracking del cambio de intereses del usuario actual con error';

export class GetCurrentUserAction implements Action {
  public readonly type = GET_CURRENT_USER;
  constructor(public payload: { platform: any, position: any }) { }
}

export class GetCurrentUserSuccessAction implements Action {
  public readonly type = GET_CURRENT_USER_SUCCESS;
  constructor(public payload: any) { }
}

export class UpdateCurrentUserAction implements Action {
  public readonly type = UPDATE_CURRENT_USER;
  constructor(public payload: { user: IUser, redirectTo?: string }) { }
}

export class UpdateCurrentUserSuccessAction implements Action {
  public readonly type = UPDATE_CURRENT_USER_SUCCESS;
  constructor(public payload: IUser) { }
}

export class UpdateCurrentUserErrorAction implements Action {
  public readonly type = UPDATE_CURRENT_USER_ERROR;
  constructor(public payload: any) { }
}

export class SetLogoutAction implements Action {
  public readonly type = SET_LOGOUT;
  constructor(public payload: any) { }
}

export class SetLogoutSuccessAction implements Action {
  public readonly type = SET_LOGOUT_SUCCESS;
}

export class GetCurrentUserErrorAction implements Action {
  public readonly type = GET_CURRENT_USER_ERROR;
  constructor(public payload: any) { }
}

export class SetRedirectUserUrlAction implements Action {
  public readonly type = SET_REDIRECT_USER_URL;
  constructor(public payload: string) { }
}

export class SetPaymentMethodsAction implements Action {
  public readonly type = SET_PAYMENT_METHODS;
  constructor(public payload: ICard[]) { }
}

export class SetPaymentMethodsErrorAction implements Action {
  public readonly type = SET_PAYMENT_METHODS_ERROR;
  constructor(public payload: any) { }
}

export class SetDefaultPaymentMethodAction implements Action {
  public readonly type = SET_DEFAULT_PAYMENT_METHOD;
  constructor(public payload: string) { }
}

export class SetDefaultPaymentMethodErrorAction implements Action {
  public readonly type = SET_DEFAULT_PAYMENT_METHOD_ERROR;
  constructor(public payload: any) { }
}

export class TrackingInterestAction implements Action {
  public readonly type = TRACKING_INTEREST;
  constructor(public payload: any) { }
}

export class TrackingInterestErrorAction implements Action {
  public readonly type = TRACKING_INTEREST_ERROR;
  constructor(public payload: any) { }
}



export type UserActions =
  GetCurrentUserAction |
  GetCurrentUserSuccessAction |
  GetCurrentUserErrorAction |
  UpdateCurrentUserAction |
  UpdateCurrentUserSuccessAction |
  UpdateCurrentUserErrorAction |
  SetLogoutAction |
  SetLogoutSuccessAction |
  SetRedirectUserUrlAction |
  SetPaymentMethodsAction |
  SetPaymentMethodsErrorAction |
  SetDefaultPaymentMethodAction |
  SetDefaultPaymentMethodErrorAction |
  TrackingInterestAction |
  TrackingInterestErrorAction;
