import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { IEvent } from 'rebus-models';

@Component({
  selector: 'app-item-agenda-suggestion',
  templateUrl: './item-agenda-suggestion.component.html',
  styleUrls: ['./item-agenda-suggestion.component.scss']
})
export class ItemAgendaSuggestionComponent {

  @Input() public design: IDesign;
  // TODO: @tote este modelo Ievent no tiene campo match como lo solicitas en la plantilla
  @Input() public suggestionItem: IEvent | any;
  @Output() public evOnViewMoreEvent = new EventEmitter<IEvent>();

  public viewMoreEvent() {
    this.evOnViewMoreEvent.emit(this.suggestionItem);
  }
}
