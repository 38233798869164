import { ProfilePhotoComponent } from './profile-photo/profile-photo.component';
import { EmailLoginComponent } from './email-login/email-login.component';
import { RegisterComponent } from './register/register.component';
import { SocialLoginComponent } from './social-login/social-login.component';
import { ProfileInfoComponent } from './profile-components/profile-info/profile-info.component';
import { ProfilePaymentsComponent } from './profile-components/profile-payments/profile-payments.component';
import { ProfileTransactionsComponent } from './profile-components/profile-transactions/profile-transactions.component';
import { TransactionsDetailComponent } from './profile-components/transactions-detail/transactions-detail.component';
import { NetworkingAvailabilityComponent } from './profile-components/networking-availability/networking-availability.component';
import { GuestInvitationComponent } from './guest-components/guest-invitation/guest-invitation.component';
import { GuestListComponent } from './guest-components/guest-list/guest-list.component';
import { GuestRecordComponent } from './guest-components/guest-record/guest-record.component';
import { GuestRecordItemComponent } from './guest-components/guest-record-item/guest-record-item.component';
import { GuestListInvitationModalComponent } from './guest-components/guest-list-invitation-modal/guest-list-invitation-modal.component';
import { PaymentsDetailComponent } from './profile-components/payments-detail/payments-detail.component';
import { SelectPaymentMethodComponent } from './profile-components/select-payment-method/select-payment-method.component';
import { TransactionResumeComponent } from './profile-components/transaction-resume/transaction-resume.component';

export const COMPONENTS = [
  ProfilePhotoComponent,
  EmailLoginComponent,
  RegisterComponent,
  SocialLoginComponent,
  NetworkingAvailabilityComponent,
  ProfileInfoComponent,
  ProfilePaymentsComponent,
  PaymentsDetailComponent,
  ProfileTransactionsComponent,
  TransactionsDetailComponent,
  TransactionResumeComponent,
  GuestInvitationComponent,
  GuestListComponent,
  GuestListInvitationModalComponent,
  GuestRecordComponent,
  GuestRecordItemComponent,
  SelectPaymentMethodComponent
];
