import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { IDesign } from 'rebus-models';
import { ITicket } from 'rebus-models';
import { NavController } from '@ionic/angular';
import { AIService } from 'src/app/modules/shared/service/ai.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-ticket-item-search-result',
  templateUrl: './ticket-item-search-result.component.html',
  styleUrls: ['./ticket-item-search-result.component.scss']
})
export class TicketItemSearchResultComponent implements OnInit, OnChanges {

  @Input() public design: IDesign;
  @Input() public ticket: ITicket;
  @Input() public eventId: string;
  @Input() public userId: string;
  @Output() public evOnClickItem = new EventEmitter<void>();
  public match$: Observable<any>;

  constructor(
    private navCtrl: NavController,
    private aiService: AIService
  ) { }

  public ngOnInit() {
    this.match$ = this.aiService.getRecommendationsForUser(this.userId, 'Tickets', null, this.eventId, this.ticket.id)
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.Ticket) {
      this.ticket = changes.Ticket.currentValue;
    }
  }

  public onClickTicket() {
    this.navCtrl.navigateForward([`ticket/detail/${this.ticket.id}/${this.eventId}`]);
    this.evOnClickItem.emit();
  }
}
