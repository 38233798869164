import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';

import { environment } from '@app/env';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import { SharedModule } from './modules/shared/shared.module';
import { StateModule } from './modules/shared/state/state.module';
import { AnalyticsService } from './modules/shared/service/analytics.service';
import { LayoutModule } from '@angular/cdk/layout'; //  Módulo para verificar el tamaño de la pantalla

import { SocialLoginModule, AuthServiceConfig, LinkedInLoginProvider } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { GeneralModalPage } from './modules/shared/pages/general-modal/general-modal.page';
import { LoadChangeRouteComponent } from './modules/shared/components/load-change-route/load-change-route.component';
import { InteractivityModule } from './modules/interactivity/interactivity.module';
import { ToastrModule } from 'ngx-toastr';
import { MessageToastComponent } from './modules/interactivity/components/message-toast/message-toast.component';
import { UserSharedModule } from './modules/user/user-shared.module';
import { ScanQrTicketSharedModule } from './modules/scan-qr-ticket/scan-qr-ticket-shared.module';
import { NgxMaskModule } from 'ngx-mask';
import { ScanQrCouponSharedModule } from './modules/scan-qr-coupon/scan-qr-coupon-shared.module';
import { NetworkingSharedModule } from './modules/networking/networking-shared.module';

export class DevelopErrorHandler implements ErrorHandler {
  public handleError(error) {
    console.error(error);
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config = new AuthServiceConfig([
  // {
  //   id: GoogleLoginProvider.PROVIDER_ID,
  //   provider: new GoogleLoginProvider(environment.socialLogin.google)
  // },
  // {
  //   id: FacebookLoginProvider.PROVIDER_ID,
  //   provider: new FacebookLoginProvider(environment.socialLogin.facebook)
  // },
]);

const config_dev = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.socialLogin.facebook)
  },
  {
    id: LinkedInLoginProvider.PROVIDER_ID,
    provider: new LinkedInLoginProvider(environment.socialLogin.linkedin)
  }
]);

export function provideConfig() {
  return environment.production ? config : config_dev;
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  exports: [
    SharedModule,
  ],
  entryComponents: [
    GeneralModalPage,
    LoadChangeRouteComponent,
    MessageToastComponent,
  ],
  imports: [
    NetworkingSharedModule,
    SharedModule,
    BrowserModule,
    IonicModule.forRoot({
      mode: 'md'
    }),
    NgxMaskModule.forRoot(),
    NgxMaskModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-left',
      closeButton: true,
      toastComponent: MessageToastComponent,
      maxOpened: 5
    }), // ToastrModule added
    BrowserAnimationsModule,
    IonicModule.forRoot(),
    StateModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    LayoutModule,
    SocialLoginModule,
    InteractivityModule,
    // Esto solo se necesita cuando se sube en servidores compartidos
    // RouterModule.forRoot([], { useHash: true }),
    UserSharedModule,
    ScanQrTicketSharedModule,
    ScanQrCouponSharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 25 }) : [],
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: ErrorHandler, useClass: environment.production ? DevelopErrorHandler : DevelopErrorHandler },
    { provide: AuthServiceConfig, useFactory: provideConfig },
    { provide: FirestoreSettingsToken, useValue: {} },
    AnalyticsService,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
