import { LazyloadDirective } from './lazyload.directive';
import { AuthrequiredDirective } from './auth-required.directive';
import { DisabledDirective } from './disabled.directive';
import { NumberInputDirective } from './number-input.directive';

export const DIRECTIVES = [
  LazyloadDirective,
  AuthrequiredDirective,
  DisabledDirective,
  NumberInputDirective,
];
