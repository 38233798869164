import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { IDesign } from 'rebus-models';
import { environment } from '@app/env';

@Component({
  selector: 'app-error401',
  templateUrl: './error401.page.html',
  styleUrls: ['./error401.page.scss'],
})
export class Error401Page implements OnInit {

  public message: string;
  public design$: Observable<IDesign>;

  constructor(
    private router: ActivatedRoute,
    private store: Store<{}>,
    private navCtrl: NavController
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
    if (this.router.snapshot.queryParams.message) {
      this.message = this.router.snapshot.queryParams.message;
    }
  }

  public onGoHome() {
    this.navCtrl.navigateForward(window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo);
  }

}
