import { Component, OnInit, OnChanges, Input, } from '@angular/core';
import { Store } from '@ngrx/store';

import { LoginSocialAction } from '../../../shared/state/auth/auth.actions';
import { TranslateService } from '@ngx-translate/core';
import { getClient, getClientAuthConfig } from '@state/config';
import { Observable } from 'rxjs';
import { IAuthConfig } from 'rebus-models';

@Component({
  selector: 'app-social-login',
  templateUrl: './social-login.component.html',
  styleUrls: ['./social-login.component.scss']
})
export class SocialLoginComponent implements OnChanges {

  @Input() public authConfig: IAuthConfig;

  constructor(
    private store: Store<{}>,
    private translate: TranslateService,
  ) { }

  public ngOnChanges() {

  }
  public login(provider: 'facebook' | 'google') {
    this.store.dispatch(new LoginSocialAction({ provider }));
  }
}
