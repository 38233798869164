import { Component, OnInit, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { UserService } from '../../service/user.service';
import { select, Store } from '@ngrx/store';
import { IUser } from 'rebus-models';
import { UtilitiesService } from '@service/utilities';
import { UpdateCurrentUserAction } from 'src/app/modules/shared/state/user/user.actions';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';

@Component({
  selector: 'app-profile-photo',
  templateUrl: './profile-photo.component.html',
  styleUrls: ['./profile-photo.component.scss']
})
export class ProfilePhotoComponent implements OnInit {

  @Input() public user: IUser;
  @Output() public evReloadUser = new EventEmitter<any>();
  @ViewChild('imageInput') public imageInput;

  public up_url: string;
  public blFilePicked = false;
  public blUpdating = false;
  public selectedFile: any;
  public module;
  constructor(
    private userService: UserService,
    private store: Store<{}>,
    private notiService: NotificationsService
  ) { }

  public ngOnInit() {
  }

  public onClickCamera() {
    this.imageInput.nativeElement.click();
  }

  public addImage() {
    const file: File = this.imageInput.nativeElement.files[0];
    const reader = new FileReader();

    reader.addEventListener('load', (event: any) => {
      this.selectedFile = {
        src: event.target.result, file
      };
      this.up_url = this.selectedFile.src;
      this.blFilePicked = true;
    });
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  public uploadImage() {
    this.blUpdating = true;
    this.userService.uploadImage(this.selectedFile, this.user._id)
      .then((val: any) => {
        this.updateUser({ name: val.name, url: val.url });
      }, err => {
        this.notiService.showRebusToast('Error subiendo la foto',
          'Error', false);
        this.onFinish();
      });
  }


  public updateUser(fileToDb) {
    this.user = {
      ...this.user,
      passwordHash: null,
      basicInformation: {
        ...this.user.basicInformation,
        profilePhoto: fileToDb.url
      }
    };
    this.store.dispatch(new UpdateCurrentUserAction({ user: this.user }));
    this.onFinish();
  }

  public onFinish() {
    this.imageInput.nativeElement.value = '';
    this.blFilePicked = false;
    this.blUpdating = false;
    this.up_url = '';
  }
}
