import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
  OnInit
} from '@angular/core';
import { IDesign, IEvent } from 'rebus-models';
import { NavController, ModalController } from '@ionic/angular';
import { LoadChangeRouteComponent } from '../load-change-route/load-change-route.component';
import { Observable } from 'rxjs';
import { AIService } from '../../service/ai.service';
import { tail, last } from 'lodash';
import { UtilitiesService } from '@service/utilities';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-sub-event-item',
  templateUrl: './sub-event-item.component.html',
  styleUrls: ['./sub-event-item.component.scss']
})
export class SubEventItemComponent implements OnInit, OnChanges {

  @Input() public event: IEvent;
  @Input() public design: IDesign;
  @Input() public uid: string;
  @Output() public evOnClick = new EventEmitter<void>();

  public match$: Observable<any>;

  constructor(
    private router: NavController,
    private modalCtrl: ModalController,
    private aiService: AIService,
    private utilities: UtilitiesService,
    private store: Store<{}>,
  ) { }

  public ngOnInit() {
    this.match$ = this.aiService.getRecommendationsForUser(
      this.uid,
      'Events',
      null,
      null,
      this.event.id
    );
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.event) {
      this.event = changes.event.currentValue;
    }
  }

  public onClickEvent() {
    this.evOnClick.emit();
  }

  public async onGoSubEventRedirect(event: IEvent) {
    const cmp = this.utilities.calculateModuleEnterToEvent(event);

    const modal = await this.modalCtrl.create({
      id: 'loader',
      component: LoadChangeRouteComponent,
      animated: true,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: 'loading-modal'
    });
    modal.present();

    if (cmp === 'UrlRedirectionEventPage' && event.withRedirectExternalUrl) {
      modal.dismiss()
        .then(() => this.utilities.presentToast('En este momento te estamos redireccionando...'))
        .then(() => setTimeout(() => true, 1000))
        .then(() => this.modalCtrl.getTop())
        .then(() => this.modalCtrl.dismiss())
        .then(() => this.evOnClick.emit())
        .then(() => window.open(event.urlRedirection, '_blank'));
    }
    if (cmp === 'UrlRedirectionEventPage' && !event.withRedirectExternalUrl) {
      const urlToRedirect: string = event.urlRedirection;
      const detailModule = tail(urlToRedirect.split('/'))[3];
      const eventId = last(urlToRedirect.split('/'));
      const moduleName = tail(urlToRedirect.split('/'))[2];
      const itemId = tail(urlToRedirect.split('/'))[4];

      if (itemId) {
        return this.router
          .navigateForward([
            `/${moduleName}/${detailModule}`,
            itemId,
            eventId
          ])
          .then(() => modal.dismiss())
          .then(() => this.modalCtrl.getTop())
          .then(() => this.modalCtrl.dismiss());
      } else {
        return this.router
          .navigateForward([`/${moduleName}`, eventId])
          .then(() => modal.dismiss())
          .then(() => this.modalCtrl.getTop())
          .then(() => this.modalCtrl.dismiss());
      }
    }
    if (cmp !== 'UrlRedirectionEventPage') {
      return this.router
        .navigateForward([cmp, event.id])
        .then(() => modal.dismiss())
        .then(() => this.modalCtrl.getTop())
        .then(() => this.modalCtrl.dismiss());
    }

    return modal
      .dismiss()
      .then(() => this.modalCtrl.getTop())
      .then(() => this.modalCtrl.dismiss());
  }
}
