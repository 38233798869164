import { Error404Page } from './error404/error404.page';
import { Error500Page } from './error500/error500.page';
import { Error401Page } from './error401/error401.page';
import { GeneralModalPage } from './general-modal/general-modal.page';
import { PrivateEventPage } from './privateEvent/privateEvent.page';

export const PAGES = [
  Error401Page,
  Error404Page,
  Error500Page,
  GeneralModalPage,
  PrivateEventPage
];
