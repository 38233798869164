import { LoadEventAction } from './../config/config.actions';
import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError, filter, tap } from 'rxjs/operators';

import * as designActions from './design.actions';
import { isValidEventId } from '../../helper/utils.helper';
import { EventService } from '../../service/event.service';
import { consoleStore, consoleError } from '../../helper/console.helper';
import { getClientDesign } from '@state/config';

const code = '[ DESIGN ]';

@Injectable()
export class DesignEffects {

  @Effect()
  public LoadDesign$: Observable<Action> = this.actions$.pipe(
    ofType(designActions.LOAD_DESIGN),
    map((action: { payload }) => action.payload),
    tap(() => consoleStore(`${code} Inciando la carga de design`)),
    switchMap((eventId) => {
      if (!isValidEventId(eventId)) {
        return this.store.select(getClientDesign).pipe(
          switchMap(design => {
            consoleStore(`${code} Carga de design general cargada correctamente`, design);
            return [new designActions.LoadDesignSuccessAction(design)];
          })
        );
      } else {
        return this.eventService.getEventById(eventId).pipe(
          map(config => {
            if (!config) {
              throw ({ code: 404, message: 'No es posible cargar la información de este módulo (1)' });
            }
            return config;
          }),
          // Este tap mete la informacioin del evento en la configuracion general
          tap((config) => this.store.dispatch(new LoadEventAction(config))),
          filter(config => config != null),
          switchMap(config => {
            if (config.withCustomDesign) {
              consoleStore(`${code} Carga de design evento cargada correctamente`, config);
              return of(config.design);
            } else {
              consoleStore(`${code} Carga de design evento No custom cargada correctamente`, config);
              return this.store.select(getClientDesign);
            }
          }),
          switchMap((design) => {
            return [
              new designActions.LoadDesignSuccessAction(design)
            ];
          }),
          catchError(err => {
            consoleError(`${code} Carga de design fallida (2)`, err);
            return [new designActions.LoadDesignErrorAction(err)];
          }),
        );
      }
    }),
    catchError(err => {
      consoleError(`${code} Carga de design fallida (2)`, err);
      // TODO:
      // hacer redireccion del usuario a 404 o 500 ya que no se carga la info de design
      // OJO que no se vaya a hacer doble redireccion con el menu
      return [new designActions.LoadDesignErrorAction(err)];
    }),
  );

  constructor(
    private actions$: Actions,
    private store: Store<{}>,
    private eventService: EventService,
  ) { }
}
