import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IDesign } from 'rebus-models';
import { getDesign } from '@state/design';
import { get } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { ScanTicketService } from '../../services/scan-qr-ticket.service';
import { tap, map } from 'rxjs/operators';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import * as moment from 'moment';
import { consoleError } from 'src/app/modules/shared/helper/console.helper';
import { ScanQrTicketReaderComponent } from '../../components/scan-qr-ticket-reader/scan-qr-ticket-reader.component';
import { ModalController, AlertController } from '@ionic/angular';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GenFormBuilder } from 'src/app/modules/shared/service/form.service';

@Component({
  selector: 'app-scan-qr-ticket-item',
  templateUrl: './scan-qr-ticket-item.page.html',
  styleUrls: ['./scan-qr-ticket-item.page.scss'],
})
export class ScanQrTicketItemPage implements OnInit {

  qty: number;
  public design$: Observable<IDesign>;
  public idTransaction: string;
  public itemId: string;
  public payment$: Observable<any>
  public form: FormGroup;

  constructor(
    private store: Store<{}>,
    private route: ActivatedRoute,
    private scanTicketService: ScanTicketService,
    private notiService: NotificationsService,
    public modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private genForm: GenFormBuilder,
    private alertCtrl: AlertController
  ) { }

  ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.idTransaction = get(this.route.snapshot, 'params.idTransaction');
    this.itemId = get(this.route.snapshot, 'params.itemId');
    this.getPaymentInfo();

  }

  public getPaymentInfo() {
    this.payment$ = this.scanTicketService.getPayment(this.idTransaction).pipe(
      map((item: any) => {
        const dataItem = item.data.summary.items[this.itemId];
        this.qty =
          dataItem.saleAmountAvaliable || dataItem.saleAmountAvaliable == 0
            ? dataItem.saleAmountAvaliable : dataItem.saleAmount;
        this.initForm();
        return item.data
      })
    )
  }

  public initForm() {
    this.form = this.formBuilder.group({});
    this.genForm.genInputNumber('quota', this.form, true, this.qty);
    this.genForm.genInputNumber('exempt', this.form, false);
    this.genForm.genInputString('observe', this.form, false);
    this.genForm.genInputString('id', this.form, false, this.idTransaction);
    this.genForm.genInputString('itemId', this.form, false, this.itemId);
    this.genForm.genInputString('dataTime', this.form, false, moment().format('YYYY-MM-DD HH:mm'));
  }

  async onSubmit({ value, valid }: { value: any; valid: boolean }) {
    if (valid) {
      if (value.quota > 0) {
        const alert = await this.alertCtrl.create({
          animated: true,
          header: 'Alerta',
          message: 'Esta seguro que desea procesar la entrada',
          buttons: [
            { text: 'No', role: 'cancel' },
            {
              text: 'Si', handler: () => {
                this.scanTicketService.validateRegister(value)
                  .subscribe(
                    res => {
                      this.notiService.showRebusToast('Entradas validadas correctamente',
                        'Success', null, null, null);
                      this.getPaymentInfo();
                      this.cleanForm();
                    },
                    err => {
                      consoleError('Error occured request sended', err);
                      this.notiService.showRebusToast(err.error.message.es, 'Error', false, null, null, null);
                    }
                  );
              }
            }
          ]
        });
        alert.present();
      } else {
        this.notiService.showRebusToast('Debes de agregar un numero valido',
          'Error', null, null, null);
      }
    } else {
      this.notiService.showRebusToast('Debes de agregar el numero de entradas a validar',
        'Error', false);
    }
  }

  cleanForm() {
    this.form.get('quota').setValue(0)
    this.form.get('exempt').setValue(0)
    this.form.get('observe').setValue("")
  }

  public async scanOther() {
    const modal = await this.modalCtrl.create({
      component: ScanQrTicketReaderComponent,
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'custom-modal-scan-qr'
    });
    return await modal.present()
  }

}
