import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { COMPONENTS } from './components/index';
import { IonicModule } from '@ionic/angular';
import { SharedRoutingModule } from './shared.router';
import { VersionComponent } from './components/version/version.component';
import { LoadComponentComponent } from './components/load-component/load-component.component';
import { RebusIconComponent } from './components/rebus-icon/rebus-icon.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PIPES } from './pipes/index';
import { SERVICES } from './service/index';
import { GUARDS } from './guards';
import { PAGES } from './pages';
import { DIRECTIVES } from './directives';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ENTRYCOMPONENTS } from './entry-components';
import { RebusMatchTooltipComponent } from './components/rebus-match-tooltip/rebus-match-tooltip.component';
import { DatePickerModule } from 'ionic4-date-picker';
import { SelectDatePickerComponent } from './components/select-date-picker/select-date-picker.component';
// tslint:disable-next-line: max-line-length
import { PermissionNotificationsTooltipComponent } from './components/permission-notifications-tooltip/permission-notifications-tooltip.component';
import { ViewFullImageComponent } from './components/view-full-image/view-full-image.component';
import { NgxMaskModule } from 'ngx-mask';
@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ...PAGES,
    ...ENTRYCOMPONENTS,
    VersionComponent,
    LoadComponentComponent,
    RebusIconComponent,
  ],
  imports: [
    DatePickerModule,
    TranslateModule,
    CommonModule,
    FormsModule,
    IonicModule,
    NgxMaskModule,
    SharedRoutingModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: '#78C000',
      innerStrokeColor: '#C7E596',
      animationDuration: 300,
    })
  ],
  exports: [
    TranslateModule,
    FormsModule,
    NgxMaskModule,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...PIPES,
    ...PAGES,
  ],
  providers: [
    ...SERVICES,
    ...PIPES,
    ...GUARDS
  ],
  entryComponents: [
    ...ENTRYCOMPONENTS,
    RebusMatchTooltipComponent,
    SelectDatePickerComponent,
    PermissionNotificationsTooltipComponent,
    ViewFullImageComponent
  ]
})
export class SharedModule { }
