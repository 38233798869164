import { Component, OnInit, Input } from '@angular/core';

import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-add-item-shopping-cart-widget',
  templateUrl: './add-item-shopping-cart-widget.component.html',
  styleUrls: ['./add-item-shopping-cart-widget.component.scss'],
})
export class AddItemShoppingCartWidgetComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public type: string;

  constructor() { }

  public ngOnInit() { }

}
