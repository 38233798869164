import { ClientService } from './client.service';
import { CdnService } from './cdn.service';
import { AuthService } from './auth.service';
import { EventService } from './event.service';
import { UtilitiesService } from './utilities.service';
import { EmbedService } from './embed.service';
import { NotificationsService } from './notifications.service';
import { GenFormBuilder } from './form.service';
import { CustomService } from './custom.service';
import { LivestreamService } from './livestream.service';
import { CompanyService } from './company.service';

export const SERVICES = [
  ClientService,
  CdnService,
  AuthService,
  EventService,
  EmbedService,
  UtilitiesService,
  NotificationsService,
  GenFormBuilder,
  CustomService,
  LivestreamService,
  CompanyService
];
