import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { HexaValidator } from '../validators/hexa';
import { LocationValidator } from '../validators/location';
import { JsonValidator } from '../validators/json';

@Injectable()
export class GenFormBuilder {

  constructor(
    private formBuilder: FormBuilder
  ) { }

  public genInputLang(control: string, form: FormGroup, langDefault: string = null) {
    form.addControl(
      control,
      this.formBuilder.group({
        en: new FormControl(
          '',
          langDefault === 'en' ? Validators.required : Validators.nullValidator
        ),
        es: new FormControl(
          '',
          langDefault === 'es' ? Validators.required : Validators.nullValidator
        )
      })
    );
  }

  public genGroupToggle(control: string, form: FormGroup, modules: any) {
    const newModules = {};
    modules.forEach(module => {
      newModules[module] = new FormControl(false);
    });

    form.addControl(
      control,
      this.formBuilder.group(newModules)
    );
  }

  public genInputString(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string = null,
    minLength: number = 0
  ) {
    form.addControl(
      control,
      new FormControl(
        // tslint:disable-next-line:max-line-length
        defaultValue ? defaultValue : '', Validators.compose([isRequired ? Validators.required : Validators.nullValidator, Validators.minLength(minLength)])
      )
    );
  }

  public genInputJSON(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: any = {}
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue,
        isRequired ? JsonValidator.isJsonRequired : Validators.nullValidator
      )
    );
  }

  public genInputJSONLength(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: any = {}
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue,
        isRequired ? JsonValidator.isJsonFiveLength : Validators.nullValidator
      )
    );
  }

  public genInputEmail(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string = null
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue ? defaultValue : '',
        Validators.compose([
          Validators.email,
          isRequired ? Validators.required : Validators.nullValidator
        ])
      )
    );
  }

  public genInputLatitude(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string = null
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue,
        Validators.compose([
          LocationValidator.latitude,
          isRequired ? Validators.required : Validators.nullValidator
        ])
      )
    );
  }

  public genInputLongitude(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: string = null
  ) {
    form.addControl(
      control,
      new FormControl(
        defaultValue ? defaultValue : '',
        Validators.compose([
          LocationValidator.longitude,
          isRequired ? Validators.required : Validators.nullValidator
        ])
      )
    );
  }

  public genInputHexa(control: string, form: FormGroup, isRequired: boolean = false) {
    form.addControl(
      control,
      new FormControl(
        '',
        Validators.compose([
          HexaValidator.isHexa,
          isRequired ? Validators.required : null
        ])
      )
    );
  }

  public genToggle(control: string, form: FormGroup, isRequired: boolean = false) {
    form.addControl(
      control,
      new FormControl(
        false,
        isRequired ? Validators.required : Validators.nullValidator
      )
    );
  }

  public genInputNumber(
    control: string,
    form: FormGroup,
    isRequired: boolean = false,
    defaultValue: number = null
  ) {
    // TODO: Poner validador de number
    form.addControl(
      control,
      new FormControl(
        (defaultValue !== null) ? defaultValue : '',
        isRequired ? Validators.required : Validators.nullValidator
      )
    );
  }

  public genInputDate(control: string, form: FormGroup, isRequired: boolean = false) {
    // TODO: Poner validador de fechas
    form.addControl(
      control,
      new FormControl(
        '',
        Validators.compose([
          isRequired ? Validators.required : Validators.nullValidator
        ])
      )
    );
  }
}
// end file
