import { Component, OnInit, Input } from '@angular/core';
import { NotificationsService } from '../../../service/notifications.service';
import { ModalController } from '@ionic/angular';
import { EventService } from '../../../service/event.service';
import { UtilitiesService } from '@service/utilities';
import { Router } from '@angular/router';
import { toArray } from 'lodash';
import { Observable } from 'rxjs';
import { IDesign } from 'rebus-models';
import { getDesign } from '@state/design';
import { Store } from '@ngrx/store';
import { environment } from '@app/env';

@Component({
  selector: 'app-modal-validate-code-event',
  templateUrl: './modal-validate-code-event.component.html',
  styleUrls: ['./modal-validate-code-event.component.scss'],
})
export class ModalValidateCodeEventComponent implements OnInit {

  public code: string;
  public design$: Observable<IDesign>;
  @Input() public eventId: string
  @Input() public userId: string
  @Input() public event: any

  constructor(
    public modalController: ModalController,
    private notificationService: NotificationsService,
    private eventService: EventService,
    private ut: UtilitiesService,
    private router: Router,
    private store: Store<{}>,
  ) { }

  ngOnInit() {
    this.design$ = this.store.select(getDesign);
  }

  async sendCode() {
    const load = await this.ut.presentLoad(null, true);
    if (this.code) {
      if (this.event.typeCodeGeneration === 'database') {
        const allowedCodes = await this.eventService.getAllowedPrivateUsersCodeByCode(this.eventId, this.code.toUpperCase());
        const codeValid = toArray(allowedCodes).find((data) => data.code == this.code.toUpperCase());
        if (codeValid && Object.keys(allowedCodes).length) {
          if (codeValid.state === 'false') {
            this.eventService.validateCodeEntry(this.eventId, Object.keys(allowedCodes)[0], {
              code: codeValid.code,
              state: "true",
              userId: this.userId
            }).then(() => {
              load.dismiss()
              this.notificationService.showRebusToast(
                'Código válido para ingreso',
                'Success'
              );
              this.modalController.dismiss()
            })
          } else {
            if (codeValid.userId === this.userId) {
              load.dismiss()
              this.notificationService.showRebusToast(
                'Código válido para ingreso',
                'Success'
              );
              this.modalController.dismiss()
            } else {
              load.dismiss()
              this.notificationService.showRebusToast(
                'Este código ya fue validado',
                'Error'
              );
            }
          }
        } else {
          load.dismiss()
          this.notificationService.showRebusToast(
            'El código ingresado no es válido para esta sala',
            'Error'
          );
        }
      }
      else if (this.event.typeCodeGeneration === 'plugin') {
        // Se valida primero si el codigo ya esta creado en firebase
        const allowedCodes = await this.eventService.getAllowedPrivateUsersCodeByCode(this.eventId, this.code.toUpperCase());
        const codeValid = toArray(allowedCodes).find((data) => data.code === this.code.toUpperCase() && data.userId === this.userId);
        if (codeValid && Object.keys(allowedCodes).length) {
          return this.eventService.validateCodeEntry(this.eventId, Object.keys(allowedCodes)[0], {
            code: codeValid.code,
            state: "true",
            userId: this.userId,
            updatedAt: new Date().getTime(),
          }).then(() => {
            window.localStorage.setItem(`${environment.clientId}-${this.eventId}-CODE-GENERAL-VALIDATE`, codeValid.code)
            this.notificationService.showRebusToast(
              'Código válido para ingreso',
              'Success'
              );
              this.modalController.dismiss().then(() => load.dismiss())
            })   
          } else {
          // return await this.eventService.getAllowedCodesPluginIntegration(this.eventId, this.code.toUpperCase(), this.userId, this.event?.pluginAncillaryIds).then(async (result: any) => {
          //   // Se guarda finalmente en firebase para que no vuelva a consultarlo a plugin
          //   await this.eventService
          //   .validateCodeEntry(this.eventId, this.code.toUpperCase(), {
          //     createdAt: new Date().getTime(),
          //     code: this.code.toUpperCase(),
          //     state: "true",
          //     userId: this.userId,
          //   })
          //   window.localStorage.setItem(`${environment.clientId}-${this.eventId}-CODE-GENERAL-VALIDATE`, this.code.toUpperCase())
          //   this.notificationService.showRebusToast(
          //     'Código válido para ingreso',
          //     'Success'
          //   );
          //   this.modalController.dismiss().then(() => load.dismiss())
          // })
          //   .catch(err => {
          //     console.error(err)
          //     load.dismiss()
          //     this.notificationService.showRebusToast(
          //       err.error.message,
          //       'Error'
          //     );
          //   })
        }
      }
    } else {
      load.dismiss()
      this.notificationService.showRebusToast(
        'Debes de escribir un código para continuar',
        'Warning'
      );
    }
  }

  async onGoHome() {
    const load = await this.ut.presentLoad(null, true);
    const redirect = this.event.urlRedirect ? this.event.urlRedirect : 'home'
    this.router.navigateByUrl(redirect).then(() => {
      load.dismiss()
      this.modalController.dismiss()
    })
  }

}
