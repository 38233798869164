import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { NavController } from '@ionic/angular';
import { IDesign } from 'rebus-models';
import { environment } from '@app/env';
import { Title } from '@angular/platform-browser';
import { getClient } from '@state/config';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.page.html',
  styleUrls: ['./error404.page.scss'],
})
export class Error404Page implements OnInit {
  public design$: Observable<IDesign>;

  constructor(private store: Store<{}>, private navCtrl: NavController, private titleService: Title) {}

  public async ngOnInit() {
    const client = await this.store.select(getClient).pipe(take(1)).toPromise();
    this.design$ = this.store.select(getDesign);
    this.titleService.setTitle(`${client.name} - Error 404 not found.`);
  }

  public onGoHome() {
    this.navCtrl.navigateForward(window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo);
  }
}
