import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, switchMap, catchError, withLatestFrom, tap } from 'rxjs/operators';
import { has } from 'lodash';
import { ModalController, LoadingController } from '@ionic/angular';
import { Action, Store } from '@ngrx/store';

import * as loadingActions from './loading.actions';
import { GeneralModalPage } from '../../pages/general-modal/general-modal.page';
import { getLoadingState } from './loading.reducer';
import { consoleStore } from '../../helper/console.helper';

const code = '[ LOADING ]';

@Injectable()
export class LoadingEffects {

  @Effect()
  public LoadingStart$: Observable<Action> = this.actions$.pipe(
    ofType(loadingActions.LOADING_START),
    map((action: { payload }) => action.payload),
    tap(() => consoleStore(`${code} Se esta iniciando el loading`)),
    withLatestFrom(this.store.select(getLoadingState)),
    switchMap(async ([payload, current]) => {
      this.loading = await this.modalCtrl.create({
        component: GeneralModalPage,
      });

      if (current.isActive) {
        return new loadingActions.LoadingErrorAction(false);
      }

      switch (payload.type) {
        case 'load':
          this.loading = await this.loadingCtrl.create({});
          if (has(payload, 'message')) {
            this.loading.message = payload.message;
          }
          return await this.loading.present()
            .then(() => new loadingActions.LoadingStartSuccessAction());

        case 'modal':
          this.loading = await this.modalCtrl.create({
            component: GeneralModalPage,
            componentProps: {
              ...payload.params,
              component: payload.component,
            },
            backdropDismiss: false,
            cssClass: 'full-modal',
          });

          return await this.loading.present()
            .then(() => new loadingActions.LoadingStartSuccessAction());

        default:
          return new loadingActions.LoadingErrorAction(false);
      }
    }),
    catchError(err => [new loadingActions.LoadingErrorAction(err)])
  );

  @Effect({ dispatch: false })
  public LoadingStop$ = this.actions$.pipe(
    ofType(loadingActions.LOADING_STOP),
    tap(() => consoleStore(`${code} Se esta deteniendo el loading`)),
    map(async () => {
      if (this.loading) {
        return await this.loading.dismiss();
      }
      return Promise.resolve(true);
    }),
    catchError(err => [new loadingActions.LoadingErrorAction(err)])
  );

  private loading;

  constructor(
    private actions$: Actions,
    private store: Store<{}>,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
  ) { }
}
