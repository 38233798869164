import { Component, OnInit, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

import { isValidEventId } from '@helper/utils';
import { ClientService } from '../../service/client.service';
import { Icon, IDesign } from 'rebus-models';
import { get } from 'lodash';

@Component({
  selector: 'app-icon-btn-my-day',
  templateUrl: './icon-btn-my-day.component.html',
  styleUrls: ['./icon-btn-my-day.component.scss']
})
export class IconBtnMyDayComponent implements OnInit {

  @Input() public icon: Icon;
  @Input() public design: IDesign;
  @Input() public moduleNameFromView: string;

  public configBtn$: Observable<any>;
  public moduleState$: Observable<boolean>;
  public eventId: string;

  constructor(
    private clienteService: ClientService,
    private route: ActivatedRoute,
  ) { }

  public ngOnInit() {
    this.eventId = get(this.route.snapshot, 'params.eventId');
    this.getConfigStateModule(this.eventId);
    this.getConfigBtnMyDay(this.eventId);
  }

  public getConfigStateModule(eventId) {
    this.moduleState$ = this.clienteService.getModuleState('plan_my_day', eventId);
  }

  public getConfigBtnMyDay(eventId) {
    if (isValidEventId(eventId)) {
      this.configBtn$ = this.clienteService.getConfigEventBtnMyDay(eventId)
        .pipe(
          switchMap((eventConfig: any) => {
            if (eventConfig) {
              return of(eventConfig);
            }
            return this.clienteService.getConfigClientBtnMyDay();
          })
        );
    } else {
      this.configBtn$ = this.clienteService.getConfigClientBtnMyDay();
    }
  }

  public isModuleDisable(modules) {
    if (modules) {
      if (modules.indexOf(this.moduleNameFromView) > -1) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
