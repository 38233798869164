import { get } from 'lodash';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getUser } from '../state/user/user.reducer';
import { environment } from '@app/env';
import { consoleDebug, consoleStore, consoleError } from '../helper/console.helper';
import { ModalController } from '@ionic/angular';
import { LoadDesignAction } from '../state/design/design.actions';
@Injectable()
export class AuthPagesGuard implements CanActivate {

  constructor(
    private store: Store<{}>,
    private router: Router,
    private modalCtrl: ModalController
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this.store.select(getUser).pipe(take(1)).toPromise();
    if (!user) {
      if (!this.hasAuthPages(route.url.toString())) {
        const redirect = route.routeConfig.path;
        // La siguiente línea se usa para redireccionar usuarios que no tienen los roles o permisos necesarios
        // await this.router.navigate(['user', '401']);
        await this.router.navigate(
          ['/user/login'],
          { queryParams: { redirect } }
        );
        this.store.dispatch(new LoadDesignAction(route.params.eventId));
        return false;
      }
    } else {
      if (this.hasAuthPages(route.url.toString())) {
        consoleDebug('Redirection: ', 'default', route.params.redirectTo);
        await this.router.navigate(
          [get(route.params, 'redirectTo', (window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo))],
          { replaceUrl: true }
        );
        this.store.dispatch(new LoadDesignAction(route.params.eventId));
        return false;
      }
    }
    this.store.dispatch(new LoadDesignAction(route.params.eventId));
    return true;
  }

  private hasAuthPages(url: string): boolean {
    return url.includes('login') || url.includes('register') || url.includes('reset');
  }
}
