import { Component, OnInit } from '@angular/core';
import { IDesign, ITransactionItem, IItem } from 'rebus-models';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { TransactionService } from 'src/app/modules/shared/service/transaction.service';
import { getAuthUID } from '@state/auth';
import { take, map, tap, takeUntil } from 'rxjs/operators';
import { ModalController } from '@ionic/angular';
import { TransactionsDetailComponent } from '../transactions-detail/transactions-detail.component';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { get } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { TransactionResumeComponent } from '../transaction-resume/transaction-resume.component';

interface ITransactionGroups {
  all: { item: IItem, data: ITransactionItem }[];
  product: { item: IItem, data: ITransactionItem }[];
  service: { item: IItem, data: ITransactionItem }[];
  ticket: { item: IItem, data: ITransactionItem }[];
  reserve: { item: IItem, data: ITransactionItem }[];
}

@Component({
  selector: 'app-profile-transactions',
  templateUrl: './profile-transactions.component.html',
  styleUrls: ['./profile-transactions.component.scss']
})
export class ProfileTransactionsComponent implements OnInit {

  public design$: Observable<IDesign>;
  public menu_select: 'all' | 'services' | 'reserves' | 'tickets' | 'purchases' = 'all';
  public transactions$: Observable<ITransactionItem[]>;
  public infoOpen = false;
  public openDropDown = false;

  public yearFilter: string;
  public monthFilter: string;
  public userId: string;
  public start = 0;
  public end = 10;
  public page = 1;
  public dataPerPage = 10;
  public filterPage = [{ value: '5' }, { value: '10' }, { value: '20' }]
  public totalPages = [{ value: '' }];
  public years = [];
  public months = [
    { value: 'Enero' },
    { value: 'Febrero' },
    { value: 'Marzo' },
    { value: 'Abril' },
    { value: 'Mayo' },
    { value: 'Junio' },
    { value: 'Julio' },
    { value: 'Agosto' },
    { value: 'Septiembre' },
    { value: 'Octubre' },
    { value: 'Noviembre' },
    { value: 'Diciembre' },
  ];

  private unsubscribe$ = new Subject<void>();

  constructor(
    private store: Store<any>,
    private transactionService: TransactionService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private route: ActivatedRoute,
  ) { }

  public async ngOnInit() {
    this.design$ = this.store.select(getDesign).pipe(
      tap(design => this.validateOpenDetail(design))
    );
    const uid = await this.store.select(getAuthUID).pipe(take(1)).toPromise();
    this.userId = uid;
    this.getTransactionsUser(this.userId);
  }

  public getTransactionsUser(uid: string) {
    this.transactions$ = this.transactionService.getUserTransactions(uid)
      .pipe(map(list => {
        this.calculatePages(list)
        this.calculateYears(list)
        return list.sort((a, b) => a.createdAt < b.createdAt ? 1 : -1)
      }
      ));
  }

  public parseStatusTransaction(state: string) {
    switch (state) {
      case 'PENDING':
        return this.translate.instant('Pendiente');
      case 'APPROVED':
      case 'success':
        return this.translate.instant('Completo');
      case 'ERROR':
      case 'CANCEL':
      case 'CANCELED':
      case 'INVALID_TRANSACTION':
        return this.translate.instant('Cancelado');
      case 'DECLINED':
      case 'INVALID_CARD':
      case 'ANTIFRAUD_REJECTED':
      case 'DECLINED_TEST_MODE_NOT_ALLOWED':
        return this.translate.instant('Rechazada');
      default:
        return this.translate.instant('Sin estado');
    }
  }

  public parseBackgroundStateTransaction(state: string) {
    switch (state) {
      case 'PENDING':
        return '#FDF7D5';
      case 'APPROVED':
      case 'success':
        return '#D5FDD6';
      case 'ERROR':
      case 'INVALID_CARD':
      case 'DECLINED':
      case 'DECLINED_TEST_MODE_NOT_ALLOWED':
      case 'ANTIFRAUD_REJECTED':
        return '#FDD5D5';
      case 'CANCEL':
        return '#FDD5D5';
      default:
        return '';
    }
  }

  public parseColorStateTransaction(state: string) {
    switch (state) {
      case 'PENDING':
        return '#898037';
      case 'APPROVED':
        return '#2A8E2E';
      case 'success':
        return '#2A8E2E';
      case 'ERROR':
      case 'ANTIFRAUD_REJECTED':
      case 'DECLINED':
      case 'INVALID_CARD':
      case 'DECLINED_TEST_MODE_NOT_ALLOWED':
        return '#893737';
      case 'CANCEL':
        return '#893737';
      default:
        return '';
    }
  }

  public onInfoOpen(item) {
    return item.openDetail = !item.openDetail;
  }

  public async onClickVoucher(transactionInfo, design, tracking: boolean = false) {
    const modal = await this.modalCtrl.create({
      component: TransactionResumeComponent,
      componentProps: { transactionInfo: transactionInfo, design, userId: this.userId, tracking },
      animated: true,
      backdropDismiss: true,
      cssClass: 'modal-detail-payment'
    });
    await modal.present();
  }

  public async onClickDetail(item, data, design) {
    const dataItem = { item, data };
    const modal = await this.modalCtrl.create({
      component: TransactionsDetailComponent,
      componentProps: { data: dataItem, design, userId: this.userId },
      animated: true,
      backdropDismiss: true,
      cssClass: 'modal-detail-payment'
    });
    await modal.present();
    // TODO: No es necesario recargar las transacciones cada vez que se cierra el modal
    // modal.onDidDismiss().then(() => {
    //   this.getTransactionsUser(this.userId);
    // });
  }

  public onOpenDropDown() {
    this.openDropDown = !this.openDropDown;
  }

  public onSelectMonth(month: string) {
    this.monthFilter = month;
    this.calculateFilterList();
  }

  public onSelectYear(year: string) {
    this.yearFilter = year;
    this.calculateFilterList();
  }

  public calculateFilterList() {
    if (this.yearFilter && this.monthFilter) {
      const monthNumber = this.parseMonthNumber(this.monthFilter);
      const initialDate = this.yearFilter + '-' + monthNumber + '-' + '1';
      const finalDate = this.yearFilter + '-' + monthNumber + '-' + '31';
      this.transactions$ = this.transactionService.getUserTransactions(this.userId, initialDate, finalDate);
    }
  }

  public parseMonthNumber(month: string) {
    switch (month) {
      case 'Enero':
        return '01';
      case 'Febrero':
        return '02';
      case 'Marzo':
        return '03';
      case 'Abril':
        return '04';
      case 'Mayo':
        return '05';
      case 'Junio':
        return '06';
      case 'Julio':
        return '07';
      case 'Agosto':
        return '08';
      case 'Septiembre':
        return '09';
      case 'Octubre':
        return '10';
      case 'Noviembre':
        return '11';
      case 'Diciembre':
        return '12';
    }
  }

  public selectPage(page: string, list) {
    this.page = parseInt(page, 0);
    this.reRenderList(list);
  }

  public selectNumPerPage(num: string, list) {
    this.dataPerPage = parseInt(num, 0);
    this.reRenderList(list);
  }

  public calculatePages(list) {
    this.totalPages = Array.from(Array(Math.ceil(list.length / this.dataPerPage)), (x, index) => {
      return { value: (index + 1).toString() };
    });
  }

  public calculateYears(list) {
    const allYears = list.map(item => moment(item.createdAd).year())
    this.years = allYears.filter((value, index, self) => {
      return self.indexOf(value) === index;
    }).map(item => item.toString());
  }

  public reRenderList(list) {
    this.end = (this.page - 1) * this.dataPerPage + this.dataPerPage;
    this.start = this.end - this.dataPerPage;
    this.calculatePages(list);
  }

  private validateOpenDetail(design) {
    const currentId = get(this.route.snapshot.queryParams, 'view');
    if (currentId) {
      this.transactionService.getUserTransactionById(currentId)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(transaction => {
          if (transaction) {
            this.onClickVoucher(transaction[0], design, true);
          }
        })
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
