import { get } from 'lodash';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { getUser } from '../state/user/user.reducer';
import { isValidEventId } from '@helper/utils';
import { NotificationsService } from '../service/notifications.service';

@Injectable()
export class NetworkingGuard implements CanActivate {

  constructor(
    private store: Store<{}>,
    private router: Router,
    private notiService: NotificationsService
  ) { }

  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const user = await this.store.select(getUser).pipe(take(1)).toPromise();
    const configNetworking = user.allowNetworking;
    let eventId: string;

    if (isValidEventId(get(route.params, 'eventId'))) {
      eventId = get(route.params, 'eventId');
    } else {
      eventId = '--';
    }

    if (!configNetworking) {
      const redirect = '/' + route.routeConfig.path + '/' + eventId;
      this.notiService.showRebusToast('Debes de activar networking para poder usar este modulo', 'Info', false);
      await this.router.navigate(
        ['/user/profile/info'],
        { queryParams: { redirect } }
      );
      return false;
    }
    return true;
  }
}
