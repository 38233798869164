export function getCodesActions() {
  return {
    firstLoad: { id: 0, name: 'FIRST_LOAD' },
    enter: { id: 1, name: 'ENTER' },
    leave: { id: 2, name: 'LEAVE' },
    updateProductCart: { id: 3, name: 'UPDATE_PRODUCT_CART' },
    removeCart: { id: 4, name: 'REMOVE_CART' },
    createNote: { id: 5, name: 'CREATE_NOTE' },
    createQuestion: { id: 6, name: 'CREATE_QUESTION' },
    networkingRequest: { id: 7, name: 'NETWORKING_REQUEST' },
    networkingMessage: { id: 8, name: 'NETWORKING_MESSAGE' },
    addCoupon: { id: 9, name: 'ADD_COUPON' },
    removeCoupon: { id: 10, name: 'REMOVE_COUPON' },
    enterEvent: { id: 11, name: 'ENTER_EVENT' },
    openSubevent: { id: 12, name: 'OPEN_SUBEVENT' },
    saveNote: { id: 13, name: 'SAVE_NOTE' },
    enterNote: { id: 14, name: 'ENTER_NOTE' },
    delNote: { id: 15, name: 'DEL_NOTE' },
    addFavorite: { id: 16, name: 'ADD_FAVORITE' },
    delFavorite: { id: 17, name: 'DEL_FAVORITE' },
    shareNew: { id: 18, name: 'SHARE_NEW' },
    viewNew: { id: 19, name: 'VIEW_NEW' },
    viewCartCatalog: { id: 20, name: 'VIEW_SHOPPING_CART_CATALOG' },
    selectProduct: { id: 21, name: 'SELECT_PRODUCT' },
    slideImageProduct: { id: 22, name: 'SLIDE_IMAGE_PRODUCT' },
    deleteProductCart: { id: 23, name: 'DELETE_PRODUCT_CART' },
    selectPaymentMethods: { id: 24, name: 'SELECT_PAYMENT_METHODS' },
    applyDiscountCode: { id: 25, name: 'APPLY_DISCOUNT_CODE' },
    checkoutAction: { id: 26, name: 'CHECKOUT' },
    viewCouponsPage: { id: 27, name: 'VIEW_COUPONS_PAGE' },
    copyCodeCoupon: { id: 28, name: 'COPY_CODE_COUPON' },
    showQRCoupon: { id: 29, name: 'SHOW_QR_COUPON' },
    showTermnsCoupon: { id: 30, name: 'SHOW_TERMNS_COUPON' },
    addCouponFailed: { id: 31, name: 'ADD_COUPON_FAILED' },
    addProductAdmin: { id: 32, name: 'ADD_PRODUCT_ADMIN' },
    deleteProductAdmin: { id: 33, name: 'DEL_PRODUCT_ADMIN' },
    editProductAdmin: { id: 34, name: 'EDIT_PRODUCT_ADMIN' },
    openProductRank: { id: 35, name: 'OPEN_PRODUCT_RANK' },
    sponsorClick: { id: 36, name: 'SPONSOR_CLICK' },
    updateTicketCart: { id: 37, name: 'UPDATE_TICKET_CART' },
    viewCartTicket: { id: 38, name: 'VIEW_SHOPPING_CART_TICKET' },
    viewCartReserves: { id: 39, name: 'VIEW_SHOPPING_CART_RESERVES' },
    deleteTicketCart: { id: 40, name: 'DELETE_TICKET_CART' },
    login: { id: 41, name: 'LOGIN_USER' },
    register: { id: 42, name: 'REGISTER_USER' },
    currentlyAuthenticated: { id: 43, name: 'CURRENTLY_AUTHENTICATED' },
    registerFailed: { id: 44, name: 'REGISTER_FAILED' },
    saveInterests: { id: 45, name: 'SAVE_INTERESTS' },
    loginFailed: { id: 46, name: 'LOGIN_FAILED' },
    logout: { id: 47, name: 'LOGOUT' },
    search: { id: 48, name: 'SEARCH' },
    networkingChange: { id: 49, name: 'NETWORKING_CHANGE' },
    networkingSendMessage: { id: 50, name: 'NETWORKING_SEND_MESSAGE' },
    networkingSendRequest: { id: 51, name: 'NETWORKING_SEND_REQUEST' },
    networkingDownloadContact: { id: 52, name: 'NETWORKING_DOWNLOAD_CONTACT' },
    networkingCallContact: { id: 53, name: 'NETWORKING_CALL_CONTACT' },
    networkingApproveContact: { id: 54, name: 'NETWORKING_APPROVE_CONTACT' },
    networkingRejectContact: { id: 55, name: 'NETWORKING_REJECT_CONTACT' },
    requestReadMessage: { id: 60, name: 'REQUEST_READ_MESSAGE' },
  };
}
