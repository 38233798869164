import { Component, OnInit, Input, EventEmitter, Output, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { IDesign, ICoupon } from 'rebus-models';
import { NavController } from '@ionic/angular';
import { Subject, Observable } from 'rxjs';
import { UtilitiesService } from '@service/utilities';
import { CouponService } from 'src/app/modules/coupon/services/coupon.service';
import { AIService } from 'src/app/modules/shared/service/ai.service';

@Component({
  selector: 'app-coupon-item-search-result',
  templateUrl: './coupon-item-search-result.component.html',
  styleUrls: ['./coupon-item-search-result.component.scss']
})
export class CouponItemSearchResultComponent implements OnInit, OnDestroy, OnChanges {

  @Input() public design: IDesign;//
  @Input() public coupon: ICoupon;//
  @Input() public eventId: string;//
  @Input() public userId: string;//
  @Input() public hasDeleteCoupon: boolean;
  @Input() public moduleNameFromView: string;//
  @Output() public evOnClickItem = new EventEmitter<void>();
  public match$: Observable<any>;

  private unsubscribe$ = new Subject<void>();

  constructor(
    private router: NavController,
    private couponService: CouponService,
    private ut: UtilitiesService,
    private aiService: AIService
  ) { }

  public ngOnInit() {
    this.match$ = this.aiService.getRecommendationsForUser(this.userId, 'Coupons', null, this.eventId, this.coupon.id)
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.coupon) {
      this.coupon = changes.coupon.currentValue;
    }
  }

  public onClickItem() {
    this.router.navigateForward(['/coupon/detail', this.coupon.id, this.eventId]);
    this.evOnClickItem.emit();
  }

  public acquireCoupon() {
    this.couponService.acquireCouponUser(
      this.eventId,
      this.userId,
      this.coupon
    );
  }

  public deleteCoupon() {
    this.couponService.deleteCouponUser(this.eventId, this.userId, this.coupon);
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
