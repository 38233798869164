import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import '@firebase/messaging';
import * as firebase from 'firebase/app';
import { NavController } from '@ionic/angular';
import { ToastrService } from 'ngx-toastr';
import { ClientService } from './client.service';
import { take, switchMap, map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { INotification, I18n, IUser } from 'rebus-models';
import { consolePush, consoleError } from '../helper/console.helper';
import { AngularFireDatabase } from '@angular/fire/database';
import { isValidEventId } from '@helper/utils';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, of } from 'rxjs';
import { getCookie, deleteCookie } from '@helper/utils';

declare var Notification: any;

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {

  public swReg;
  private swLocation = "/sw.js";

  constructor(
    private navCtrl: NavController,
    private toastrService: ToastrService,
    private clientService: ClientService,
    private translate: TranslateService,
    private afdb: AngularFireDatabase,
    private authService: AuthService,
    private http: HttpClient,
  ) { }

  async checkSubscription() {
    if (!environment.production) {
      return
    }
    // this.swReg = await navigator.serviceWorker.register(this.swLocation);
    // return await this.swReg.pushManager.getSubscription();
  }

  validateLocalClickPushUser(uid: string) {
    const campaingId = getCookie('push-click-event');
    if (campaingId && uid) {
      this.clickPushUser(campaingId, uid);
      deleteCookie('push-click-event');
    }
  }

  clickPushUser(campaingId: string, uid: string): Observable<boolean> {
    if (uid) {
      return from(firebase.database().ref(`Clients/${environment.clientId}/PushStats/${campaingId}`).push({
        userId: uid,
        campaingId,
        clickAt: firebase.database.ServerValue.TIMESTAMP,
      })).pipe(map(() => true));
    }
    return of(true);
  }

  async init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (!environment.production) {
        resolve();
        return;
      }
      navigator.serviceWorker.ready.then((registration) => {
        // Don't crash an error if messaging not supported
        if (!firebase.messaging.isSupported()) {
          resolve();
          return;
        }

        const messaging = firebase.messaging();

        // Register the Service Worker
        messaging.useServiceWorker(registration);

        // Initialize your VAPI key
        messaging.usePublicVapidKey(
          environment.firebase.vapidKey
        );

        // Optional and not covered in the article
        // Listen to messages when your app is in the foreground
        messaging.onMessage((payload) => {
          consolePush('Message payload', payload);
        });
        // Optional and not covered in the article
        // Handle token refresh
        messaging.onTokenRefresh(() => {
          messaging.getToken().then(
            (refreshedToken: string) => {
              consolePush('Refresh token', null, refreshedToken);
            }).catch((err) => {
              consoleError('Refresh token error', err);
            });
        });

        resolve();
      }, (err) => {
        reject(err);
      });
    });
  }

  requestPermission(userId: string, user: IUser): Promise<void> {
    return new Promise<void>(async (resolve) => {
      if (!environment.production) {
        resolve();
        return;
      }
      if (!Notification) {
        resolve();
        return;
      }
      if (!firebase.messaging.isSupported()) {
        resolve();
        return;
      }
      try {
        const messaging = firebase.messaging();
        await messaging.requestPermission();

        const token: string = await messaging.getToken();
        consolePush('User success register FCM Token: ', null, token);

        // Se guarda token en mongo
        await this.saveUserToken(user, token, userId);

        //Se crea topik a nivel cliente
        await this.createTopicClient(user, userId);

      } catch (err) {
        // No notifications granted
        console.error('Error user notifications token:', err);
      }

      resolve();
    });
  }

  public async saveUserToken(user: IUser, token: string, userId: string) {
    const isTokenRegister = user.fcm.includes(token);
    if (!isTokenRegister) {
      const result = await this.addFcm(token, userId);
      consolePush("result save User Token", null, result)
    }
  }

  public async createTopicClient(user: IUser, userId: string) {
    if (user) {
      const isTopicRegister = user.topics.includes(environment.clientId);
      if (!isTopicRegister) {
        const result = await this.subscribeTopic(userId);
        consolePush("result create topic Client", null, result)
      }
    }
  }

  public async addFcm(token: string, userId: string) {
    const tokenU = await this.authService.getTokenUser()
      .pipe(take(1)).toPromise();
    const headers = new HttpHeaders({ 'Authorization': tokenU });
    // return this.http.post(`${environment.notification_rebus}/notifications/user/${userId}/${token}`,
    //   {},
    //   { headers }
    // ).toPromise();
    return Promise.reject()
  }

  public async subscribeTopic(userId: string) {
    const tokenU = await this.authService.getTokenUser()
      .pipe(take(1)).toPromise();
    const headers = new HttpHeaders({ 'Authorization': tokenU });
    // return this.http.post(`${environment.notification_rebus}/topic`,
    //   {
    //     user: userId,
    //     topic: environment.clientId
    //   },
    //   { headers }
    // ).toPromise();
    return Promise.reject()
  }

  public subscribeTopicEvent(userId: string, eventId: string) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        // return this.http.post(`${environment.notification_rebus}/topic`,
        //   {
        //     user: userId,
        //     topic: eventId
        //   },
        //   { headers }
        // );
        return Promise.reject()
      })
    );
  }

  /**
   * showRebusToast
   * Método para mostrar notificaciones toast para visualizar el estado de los procesos de la app y
   * alertar al usuario de cambios ej: notificaciones de chat o invitaciones.
   * @param message Mensaje de la notificación
   * @param type Tipo de la notificación, se encarga del color
   * @param save_to_db Bandera para guardar en db
   * @param icon Nombre del 'rebus-icon' del cuerpo de la notificación (solo visible para el historial)
   * @param title Titulo de la notificación
   * @param module Nombre del módulo de donde viene la notificación
   * @param userId Id del usuario al que pertenece
   * @param eventId Id del evento al que pertenece
   * @param redirect Ruta de redirección dentro de la app al dar click
   * @param photo_url Url de la imagen de la notificación
   */

  public async showRebusToast(message: string | I18n, type: 'Info' | 'Error' | 'Success' | 'Warning' | 'Custom',
    save_to_db?: boolean, icon?: string, title?: string, module?: string, userId?: string, eventId?: string,
    redirect?: string, photo_url?: string) {
    const text = typeof message === 'string' ? await this.translate.instant(message) : message[this.translate.defaultLang];
    const moduleFrom = await this.clientService.getConfigModule(module).pipe(take(1)).toPromise();
    const notification: INotification = {
      module: moduleFrom,
      information: { icon: icon ? { name: icon, type: 'Rebus' } : null, message: text, title },
      type, userId, created_at: new Date().getTime(), eventId, photo_url, redirect, save_to_db
    };

    this.toastrService.show(notification.information.message,
      notification.information.title, {}, notification.type + '|' +
      JSON.stringify(notification.module ? notification.module.icon : null)
      + '|' + (notification.photo_url ? notification.photo_url : null))
      .onTap.pipe(take(1)).subscribe((a) => {
        if (notification.redirect) {
          this.navCtrl.navigateForward([notification.redirect]);
        }
      });
    // TODO: Guardar 'notification' en base de datos si tiene saveToDb

  }

  public getNotificationsModule(eventId: string, moduleName: string) {
    if (isValidEventId(eventId)) {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Event/${eventId}/Messages/${moduleName}`,
        ref => ref.orderByChild('state').equalTo(true)).valueChanges();
    } else {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Interactivity/Messages/${moduleName}`,
        ref => ref.orderByChild('state').equalTo(true)).valueChanges();
    }
  }


  public saveViewAlertPerUser(userId: string, eventId: string, alertId: string, id: string = '') {
    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;
    const view = {
      "createdAt": created_at,
      "idAlert": alertId
    }

    let reference = `/Clients/${environment.clientId}/Interactivity/Messages/UserAlertViews/${userId}`;

    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Messages/UserAlertViews/${userId}`;
    }

    const result = this.afdb.object(`${reference}/${id}`).update(view).then(() => view)
      .catch((error) => {
        consoleError('Whooops, something happen', error);
      });
    return result;
  }

  public saveViewToastPerUser(userId: string, eventId: string, toastId: string, id: string = '') {
    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;
    const view = {
      "createdAt": created_at,
      "toastId": toastId
    }

    let reference = `/Clients/${environment.clientId}/Interactivity/Messages/UserToastViews/${userId}`;

    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Messages/UserToastViews/${userId}`;
    }

    const result = this.afdb.object(`${reference}/${id}`).update(view).then(() => view)
      .catch((error) => {
        consoleError('Whooops, something happen', error);
      });
    return result;
  }

  public getUserViewAlertSnap(userId: string, eventId: string, alertId: string) {
    if (isValidEventId(eventId)) {
      return this.afdb.list<any>(
        `/Clients/${environment.clientId}/Event/${eventId}/Messages/UserAlertViews/${userId}`,
        ref => ref.orderByChild('idAlert').equalTo(alertId)
      ).query.once('value').then(snap => snap.val());
    } else {
      return this.afdb.list<any>(
        `/Clients/${environment.clientId}/Interactivity/Messages/UserAlertViews/${userId}`,
        ref => ref.orderByChild('idAlert').equalTo(alertId)
      ).query.once('value').then(snap => snap.val());
    }
  }

  public getUserViewToastSnap(userId: string, eventId: string, toastId: string) {
    if (isValidEventId(eventId)) {
      return this.afdb.list<any>(
        `/Clients/${environment.clientId}/Event/${eventId}/Messages/UserToastViews/${userId}`,
        ref => ref.orderByChild('toastId').equalTo(toastId)
      ).query.once('value').then(snap => snap.val());
    } else {
      return this.afdb.list<any>(
        `/Clients/${environment.clientId}/Interactivity/Messages/UserToastViews/${userId}`,
        ref => ref.orderByChild('toastId').equalTo(toastId)
      ).query.once('value').then(snap => snap.val());
    }
  }
}
