import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-view-full-image',
  templateUrl: './view-full-image.component.html',
  styleUrls: ['./view-full-image.component.scss'],
})
export class ViewFullImageComponent implements OnInit {

  @Input() public image: string;

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() { }

  public getImage(imageUser) {
    if (imageUser) {
      return `url('${imageUser}')`;
    }
  }

}
