import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { IDesign, IBasicInformationUser } from 'rebus-models';
import { LogoutAction } from 'src/app/modules/shared/state/auth/auth.actions';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getClientGateways } from '@state/config';
import { ClientService } from 'src/app/modules/shared/service/client.service';

@Component({
  selector: 'app-profile-dropdown',
  templateUrl: './profile-dropdown.component.html',
  styleUrls: ['./profile-dropdown.component.scss'],
})
export class ProfileDropdownComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public routeFrom: string;
  @Input() public user: IBasicInformationUser;
  @Output() public evOnClickAny = new EventEmitter<void>();

  public moduleStateShoppingCart$: Observable<boolean>;
  public gateways$: Observable<string[]>;

  constructor(
    private navCtrl: NavController,
    private store: Store<{}>,
    private clienteService: ClientService
  ) { }

  public ngOnInit() {
    this.gateways$ = this.store.select(getClientGateways);
    this.moduleStateShoppingCart$ = this.clienteService.getModuleState(
      'shopping_cart',
      '--'
    );
  }

  public isMethodRequired(gateways: string[]): boolean {
    return gateways.includes('paymentez');
  }

  public onLogout() {
    this.evOnClickAny.emit();
    this.store.dispatch(new LogoutAction());
  }

  public onGoTo(path) {
    this.evOnClickAny.emit();
    this.navCtrl.navigateForward(['/user/profile/' + path]);
  }


}
