import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { NavController } from '@ionic/angular';

import { IStaff, IDesign } from 'rebus-models';
import { AIService } from 'src/app/modules/shared/service/ai.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-staff-item-search-result',
  templateUrl: './staff-item-search-result.component.html',
  styleUrls: ['./staff-item-search-result.component.scss']
})
export class StaffItemSearchResultComponent implements OnInit, OnChanges {

  @Input() public moduleNameFromView: string;
  @Input() public design: IDesign;
  @Input() public staff: IStaff;
  @Input() public eventId: string;
  @Input() public userId: string;
  @Output() public evOnClickItem = new EventEmitter<void>();

  public match$: Observable<any>;
  constructor(
    private navCtrl: NavController,
    private aiService: AIService
  ) { }

  public ngOnInit() {
    this.match$ = this.aiService.getRecommendationsForUser(this.userId, 'Staff', null, this.eventId, this.staff.id)
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.staff) {
      this.staff = changes.staff.currentValue;
    }
  }

  public onGoStaff() {
    this.navCtrl.navigateForward(['/staff/detail', this.staff.id, this.eventId]);
    this.evOnClickItem.emit();
  }
}
