import { Component, OnInit, AfterViewInit } from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { Store } from "@ngrx/store";
import {
  Platform,
  MenuController,
  NavController,
  ModalController,
} from "@ionic/angular";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { IDesign, IModuleConfig, IClient } from "rebus-models";
import { environment } from "@app/env";
import { getMenuEventId } from "./modules/shared/state/menu/menu.reducer";
import { calculateRoute } from "./modules/shared/helper/route.helper";
import {
  getSecureToken,
  getCookie,
  setCookie,
} from "./modules/shared/helper/utils.helper";
import { GetCurrentUserAction } from "./modules/shared/state/user/user.actions";
import { UtilitiesService } from "@service/utilities";
import { getDesign } from "@state/design";
import { getClient } from "@state/config";
import { LoadChangeRouteComponent } from "./modules/shared/components/load-change-route/load-change-route.component";
import { filter, take, map } from "rxjs/operators";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import {
  AnalyticsService,
  _ACTIONS,
} from "./modules/shared/service/analytics.service";
import { getAuthUID } from "@state/auth";
import { NotificationsService } from "./modules/shared/service/notifications.service";
import { TranslateService } from "@ngx-translate/core";
import { GLOBAL } from "../environments/global";
import { ModalInfoBrowserComponent } from "./modules/shared/components/modal-info-browser/modal-info-browser.component";
import { TermsServicePage } from "./modules/user/pages/terms/terms-service/terms-service.page";
import { UserService } from "./modules/user/service/user.service";
import { ScanQrTicketReaderComponent } from "./modules/scan-qr-ticket/components/scan-qr-ticket-reader/scan-qr-ticket-reader.component";
import { getUser, getUserId } from "@state/user";
import { AuthRequiredComponent } from "./modules/shared/components/auth-required/auth-required.component";
import { ScanQrCouponReaderComponent } from "./modules/scan-qr-coupon/components/scan-qr-coupon-reader/scan-qr-coupon-reader.component";
import { ClientService } from "./modules/shared/service/client.service";
import { LogoutAction } from "./modules/shared/state/auth/auth.actions";
import { get } from "lodash";
import * as firebase from "firebase/app";
import { Title } from "@angular/platform-browser";
import { consoleTracking } from "./modules/shared/helper/console.helper";

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent implements OnInit, AfterViewInit {
  public menu$: Observable<{ menu: IModuleConfig[]; eventId: string }>;
  public client$: Observable<any>;
  public design$: Observable<IDesign>;
  public toggleMenu = GLOBAL.toggleMenu;
  public homepage =
    window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) ||
    environment.redirectTo;
  public itemMenuP = { icon: { name: "menu", type: "Ionic" } };
  private isDesktop = false;
  public user: any;
  private sub: Subscription;
  public user$: Observable<any>;
  public userActionsSubscribe: any = null;
  public listenerLogout = null;
  public listenerVoting = null;
  public currentUserId = null;

  // Orden del Día -M9KCzlm3M-25UKOPCdN
  // Asamblea Virtual -MAhXdeJqR3hb2WleQMr
  // Asamblea. -MAhcjwrL5ezJeobmarc

  public viewBlackListEmbed = [
    // '-MAhXdeJqR3hb2WleQMr'
  ];

  public viewListEmbed = [
    // '-M9KCzlm3M-25UKOPCdN',
    // '-MAhcjwrL5ezJeobmarc',
  ];

  public adminListEmbed = [
    // '-MAiYkB7dSLVAcod0rh6',
    // '-MAiYVKCdc1Y7DBSSMKF',
    // '-M9Y_EXaUhbZzZSZSsMp',
    // '-MAiYVKCdc1Y7DBSSMKF',
    // '-MAiYkB7dSLVAcod0rh6'
  ];

  constructor(
    public menuCtrl: MenuController,
    public modalCtrl: ModalController,
    public breakpointObserver: BreakpointObserver,
    private platform: Platform,
    private store: Store<{}>,
    private route: Router,
    private router: NavController,
    private utilities: UtilitiesService,
    private analytics: AnalyticsService,
    private notifications: NotificationsService,
    private translate: TranslateService,
    private userService: UserService,
    private clientService: ClientService,
    private titleService: Title
  ) {
    this.initializeApp();
  }

  public async ngOnInit() {
    // this.userService.setConection();
    this.store.select(getUserId).subscribe((userId) => {
      if (userId) {
        this.currentUserId = userId;
        this.listenerDisconect(userId);
        this.listenerCalculateVoting(userId);
      } else {
        this.listenerDisconect(null);
        this.listenerCalculateVoting(null);
      }
    });

    await this.notifications.init();
    this.user$ = this.store.select(getUser).pipe(
      filter((user) => !!user),
      map((user) => {
        this.userActionsSubscribe = user._id;
        this.userActionsSubscribe = this.clientService
          .getDeviceSession(user._id)
          .subscribe(async (action) => {
            if (action) {
              if (get(action, "setLogout", false)) {
                await this.clientService.setDeviceSession(user._id);
                this.store.dispatch(new LogoutAction());
                await this.clientService.setLogoutUser(user._id);
                if (this.userActionsSubscribe) {
                  this.userActionsSubscribe.unsubscribe();
                  this.userActionsSubscribe = null;
                  this.utilities.presentToast(
                    "Se ha iniciado sesión en otro dispositivo y se ha cerrado sesión en este dispositivo automáticamente. Por favor inicie sesión nuevamente",
                    35000,
                    "bottom",
                    "dark",
                    true,
                    { showCloseButton: true }
                  );
                }
              }
            }
          });
        return user;
      })
    );
  }

  private listenerCalculateVoting(userId) {
    if (!userId && this.currentUserId) {
      return (this.listenerVoting = null);
    }

    if (!userId) {
      return false;
    }

    this.listenerVoting = firebase
      .database()
      .ref(`/VotingCompaniesByUser/${environment.clientId}/${userId}`)
      .on("value", async (snap) => {
        if (snap.val()) {
          const data = snap.val();
          const promises = Object.keys(data).map((val) =>
            firebase
              .database()
              .ref(`VotingCompanies/${environment.clientId}/${val}`)
              .once("value")
              .then((snapshot) => {
                const form = snapshot.val().form;
                const votes = get(snapshot.val(), "total_votos", 0);
                const company = get(form, "infoCompany.nit", "") + ' - ' + get(form, "infoCompany.name", "");
                const document = get(form, "infoCompany.with_apoderado", false)
                  ? get(form, "infoLegalApoderado.type_document", "") + " " + get(form, "infoLegalApoderado.document_number", "")
                  : get(form, "infoLegalRepresentative.type_document", "") + " " + get(form, "infoLegalRepresentative.document_number", "");
                const email = get(form, "infoCompany.with_apoderado", false)
                  ? get(form, "infoLegalApoderado.email", "")
                  : get(form, "infoLegalRepresentative.email", "");
                const name = get(form, "infoCompany.with_apoderado", false)
                  ? get(form, "infoLegalApoderado.first_name", "") + " " + get(form, "infoLegalApoderado.last_name", "")
                  : get(form, "infoLegalRepresentative.first_name", "") + " " + get(form, "infoLegalRepresentative.last_name", "");

                return { votes, company, email, name, document };
              })
          );
          const dataFinal = await Promise.all(promises).then((values) =>
            values.reduce(
              (a, b) => ({
                votes: a.votes + b.votes,
                companies: [...a.companies, b.company],
                email: b.email,
                name: b.name,
                document: b.document,
              }),
              {
                votes: 0,
                companies: [],
                email: "",
                name: "",
                document: "",
              }
            )
          );
          console.log(`>>> [${new Date().getTime()}] Votos actualizados`, userId, dataFinal);
          this.listenerLogout.update({ ...dataFinal });
        }
      });
  }

  private listenerDisconect(userId) {
    if (!userId && this.currentUserId) {
      console.log(
        `>>> [${new Date().getTime()}] Cierre de sesion de usuario`,
        this.currentUserId
      );
      this.listenerDisconect = null;
      return firebase
        .database()
        .ref(`/VotingQuorum/${environment.clientId}/${this.currentUserId}`)
        .update({
          state: "disconnect",
          updated_at: new Date().getTime(),
        });
    }

    if (!userId) {
      return false;
    }

    console.log(`>>> [${new Date().getTime()}] Usuario conectado`, userId);
    this.listenerLogout = firebase
      .database()
      .ref(`/VotingQuorum/${environment.clientId}/${userId}`);
    this.listenerLogout.update({
      id: userId,
      state: "connect",
      updated_at: new Date().getTime(),
    });
    this.listenerLogout.onDisconnect().update({
      state: "disconnect",
      updated_at: new Date().getTime(),
    });
  }

  public async ngAfterViewInit() {
    // Esta funcion se encarga de hacer un tracking a firebase
    this.route.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(async (event: any) => {
        const user = await this.store.select(getUser).pipe(take(1)).toPromise();
        const client = await this.store
          .select(getClient)
          .pipe(take(1))
          .toPromise();
        if (get(user, "_id", false)) {
          // Como se demora en cargar title se da un tiempo para su ejecucion
          setTimeout(() => {
            const basicInformation = get(user, "basicInformation", {});
            const dataToTrack = {
              clientId: environment.clientId,
              clientName: get(client, "name", environment.clientId),
              userId: get(user, "_id", false),
              userName: get(basicInformation, "name", null),
              userEmail: get(basicInformation, "email", null),
              page: event.urlAfterRedirects,
              title: this.titleService.getTitle() || client.name,
              origin: window.origin,
              url: document.URL,
              processId: window.localStorage.getItem(
                `${environment.clientId}-PROCESS-TRACKING`
              ),
              cookieId: getCookie(`${environment.clientId}-COOKIE-TRACKING`),
              payload: {
                url: event.urlAfterRedirects
                  .slice(1, event.urlAfterRedirects.length)
                  .split("/"),
              },
              date: firebase.database.ServerValue.TIMESTAMP,
            };
            consoleTracking("Change route, send tracking", dataToTrack);
            // tracking google tag manager
            /* window.dataLayer.push({
            event: 'navegacion',
            informacion: {
              ...dataToTrack,
              date: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
              timestamp: 'd ' + Date.now(),
            },
          }); */
            // Tracking firebase
            firebase
              .database()
              .ref(`/Clients/${environment.clientId}/___tracking_urls/`)
              .push(dataToTrack);
          }, 800);
        }
      });
  }

  public toggleDesktopMenu() {
    this.toggleMenu = this.isDesktop ? !this.toggleMenu : this.toggleMenu;
  }

  public goItineraryModule() {
    this.router.navigateForward(["/itinerary/--"]);
  }

  public async routeTo(menu, eventId) {
    const moduleAuthList = GLOBAL.modulesRequiredAuth;
    if (moduleAuthList.includes(menu.component)) {
      const user = await this.store.select(getUser).pipe(take(1)).toPromise();

      if (!user) {
        const modal = await this.modalCtrl.create({
          component: AuthRequiredComponent,
          componentProps: { redirectTo: calculateRoute(menu, eventId) },
          animated: true,
          backdropDismiss: true,
          cssClass: "custom-modal",
        });
        return await modal.present();
      }
    }

    const modal = await this.modalCtrl.create({
      component: LoadChangeRouteComponent,
      animated: true,
      showBackdrop: true,
      backdropDismiss: false,
      cssClass: "loading-modal",
    });

    // Si el modulo es embed se debe explorar lo que debe pasar antes de abrir un modal
    if (menu.component === "embed") {
      if (menu.withRedirectMenu) {
        menu.redirectMenuUrl = menu.redirectMenuUrl.trim();
        const re = new RegExp("^(http|https)://", "i");
        if (re.test(menu.redirectMenuUrl)) {
          const target = menu.redirectMenuTargetNew ? "_self" : "_blank ";
          return window.open(menu.redirectMenuUrl, target);
        }

        return modal
          .present()
          .then(() =>
            this.router.navigateRoot(menu.redirectMenuUrl, { replaceUrl: true })
          )
          .then(() => (this.menuCtrl.isOpen() ? this.menuCtrl.close() : true))
          .then(() => modal.dismiss());
      }
    }

    if (menu.component === "terms") {
      const modal2 = await this.modalCtrl.create({
        component: TermsServicePage,
        animated: true,
        showBackdrop: true,
        backdropDismiss: true,
        cssClass: "custom-modal",
      });
      return await modal2.present().then(() => modal.dismiss());
    }

    if (menu.component === "scan-ticket") {
      const modal3 = await this.modalCtrl.create({
        component: ScanQrTicketReaderComponent,
        animated: true,
        showBackdrop: true,
        backdropDismiss: true,
        cssClass: "custom-modal-scan-qr",
      });
      return await modal3.present().then(() => modal.dismiss());
    }

    return modal
      .present()
      .then(() => this.router.navigateRoot(calculateRoute(menu, eventId)))
      .then(() => (this.menuCtrl.isOpen() ? this.menuCtrl.close() : true))
      .then(() => modal.dismiss());
  }

  public async openModalScanCoupon() {
    const modal = await this.modalCtrl.create({
      component: ScanQrCouponReaderComponent,
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: "custom-modal-scan-qr",
    });
    return await modal.present();
  }

  public getRoute(menu, eventId) {
    return calculateRoute(menu, eventId).join("/");
  }

  public validateEmbed(embedId, userProfile = null) {
    if (!embedId) {
      if (userProfile === "view") {
        return false;
      }
      return true;
    }

    if (!userProfile) {
      if (this.adminListEmbed.includes(embedId)) {
        return false;
      }
    }
    if (userProfile === "view") {
      if (this.viewListEmbed.includes(embedId)) {
        return true;
      }
      if (this.viewBlackListEmbed.includes(embedId)) {
        return false;
      }
      if (this.adminListEmbed.includes(embedId)) {
        return false;
      }
    }
    if (embedId === "-MAhcjwrL5ezJeobmarc") {
      return false;
    }
    return true;
  }

  public openWhatspp(number) {
    window.open(`https://api.whatsapp.com/send?phone=${number}`, "_blank");
  }

  public ngOnDestroy() {
    if (this.userActionsSubscribe) {
      this.userActionsSubscribe.unsubscribe();
    }
  }

  private initializeApp() {
    this.platform.ready().then(() => {
      const navEndEvents$ = this.route.events.pipe(
        filter((event) => event instanceof NavigationEnd)
      );
      this.breakpointObserver
        .observe([`(min-width: 768px)`])
        .subscribe((state: BreakpointState) => {
          this.isDesktop = state.matches;
        });

      this.store.select(getAuthUID).subscribe((uid) => {
        if (uid) {
          // se asocian user id al codigo analytics para mayor presicion de control de sesion por usuarios realmente unicos
          if (this.sub) {
            this.sub.unsubscribe();
          }
          this.sub = navEndEvents$.subscribe((event: NavigationEnd) => {
            this.analytics.savePageTrackOnFirestore(
              event.urlAfterRedirects,
              _ACTIONS.enter,
              {
                url: event.urlAfterRedirects
                  .slice(1, event.urlAfterRedirects.length)
                  .split("/"),
              }
            );
          });
        }
      });

      this.design$ = this.store.select(getDesign);
      this.menu$ = this.store.select(getMenuEventId);
      this.client$ = this.store.select(getClient).pipe();
      this.startMetas();
      this.validateCookie();
      this.startSlaask();
      this.startAnalytics();
    });
    this.validatePlatformUser();
  }

  private async validatePlatformUser() {
    const agent = window.navigator.userAgent.toLowerCase();
    let browser;
    switch (true) {
      case agent.indexOf("edge") > -1:
        browser = "edge";
        break;
      case agent.indexOf("opr") > -1 && !!(<any>window).opr:
        browser = "opera";
        break;
      case agent.indexOf("chrome") > -1 && !!(<any>window).chrome:
        browser = "chrome";
        break;
      case agent.indexOf("trident") > -1:
        browser = "ie";
        break;
      case agent.indexOf("firefox") > -1:
        browser = "firefox";
        break;
      case agent.indexOf("safari") > -1:
        browser = "safari";
        break;
      default:
        browser = "other";
    }

    if (browser === "firefox" || browser === "ie") {
      const modal = await this.modalCtrl.create({
        component: ModalInfoBrowserComponent,
        componentProps: { browser: browser },
        animated: true,
        backdropDismiss: false,
        cssClass: "custom-modal",
      });
      await modal.present();
    }
  }

  private startMetas() {
    this.store.select(getClient).subscribe((client) => {
      if (client) {
        // document.title = client.name;
        const language = window.localStorage.getItem("rebus-language");
        if (language) {
          this.translate.setDefaultLang(language);
        } else {
          this.translate.setDefaultLang(this.translate.getBrowserLang());
        }
        // this.translate.setDefaultLang(client.defaultLanguage);
        this.setMetas(client);
      }
    });
  }

  private async validateCookie() {
    if (!getCookie(`${environment.clientId}-COOKIE-TRACKING`)) {
      await setCookie(
        `${environment.clientId}-COOKIE-TRACKING`,
        getSecureToken(),
        300
      );
    }
    return getCookie(`${environment.clientId}-COOKIE-TRACKING`);
  }

  private async startAnalytics() {
    const tokenUid = getSecureToken();
    window.localStorage.setItem(
      `${environment.clientId}-PROCESS-TRACKING`,
      tokenUid
    );

    // const script = document.createElement('script');
    // script.async = true;
    // script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.analyticsId}`;
    // document.head.appendChild(script);

    // TODO: sae debe pedir la location del usuario a medida que va navegando no al inicio de la app
    // const position = await this.getLocation()
    //   .then(pos => pos)
    //   .catch(err => {
    //     console.warn('Error obteniendo la posición del usuario', err);
    //     return null;
    //   });

    const position = null;
    const platform = this.utilities.getPlatformData();

    this.store.dispatch(new GetCurrentUserAction({ platform, position }));
  }

  private startSlaask() {
    this.store.select(getClient).subscribe((client) => {
      if (client) {
        if (client.defaultSupport === "slack") {
          const script = document.createElement("script");
          script.innerHTML = `!function(){
            var x=document.createElement('script');
            x.src='https://cdn.slaask.com/chat.js',x.type='text/javascript',x.async='true',
            x.onload=x.onreadystatechange=function() {
              var x=this.readyState;if(!x||'complete'==x||'loaded'==x)
              try {
                _slaask.init('${environment.slaaskId}')
              } catch(x){ }
            };
            var t=document.getElementsByTagName('script')[0];t.parentNode.insertBefore(x,t)}();`;
          document.head.appendChild(script);
        }
      }
    });
  }

  // tslint:disable-next-line:no-any
  private getLocation(): Promise<any> {
    const options = {
      enableHighAccuracy: true,
      timeout: 3000,
      maximumAge: 0,
    };

    return new Promise((resolve, reject) => {
      if (window.navigator.geolocation) {
        window.navigator.geolocation.getCurrentPosition(
          (pos) =>
            resolve({
              latitude: pos.coords.latitude,
              longitude: pos.coords.longitude,
              speed: pos.coords.speed,
              heading: pos.coords.heading,
              altitudeAccuracy: pos.coords.altitudeAccuracy,
              altitude: pos.coords.altitude,
              accuracy: pos.coords.accuracy,
            }),
          (error) => reject(error),
          options
        );
      }
    });
  }

  private setMetas(client: IClient) {
    // TODO: configurar estas variables del cliente
    const metaA = document.createElement("meta");
    metaA.setAttribute("name", "theme-color");
    metaA.content = "#151515";
    document.getElementsByTagName("head")[0].appendChild(metaA);

    const metaB = document.createElement("meta");
    metaB.setAttribute("name", "msapplication-TileColor");
    metaB.content = "#151515";
    document.getElementsByTagName("head")[0].appendChild(metaB);

    const metaC = document.createElement("meta");
    metaC.setAttribute("name", "apple-mobile-web-app-status-bar-style");
    metaC.content = "black";
    document.getElementsByTagName("head")[0].appendChild(metaC);

    const metaD = document.createElement("meta");
    metaD.setAttribute("name", "apple-mobile-web-app-title");
    metaD.content = client.name;
    document.getElementsByTagName("head")[0].appendChild(metaD);
  }
}
