import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { NavController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { IDesign, ITicket } from 'rebus-models';
import { environment } from '@app/env';

@Component({
  selector: 'app-privateEvent',
  templateUrl: './privateEvent.page.html',
  styleUrls: ['./privateEvent.page.scss'],
})
export class PrivateEventPage implements OnInit {

  public ticketId: string;
  public eventId: string;
  public design$: Observable<IDesign>;

  constructor(
    private router: ActivatedRoute,
    private store: Store<{}>,
    private navCtrl: NavController
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.ticketId = this.router.snapshot.queryParams.ticketId;
  }

  public onGoHome() {
    this.navCtrl.navigateForward(window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo);
  }

  public goTicket() {
    this.navCtrl.navigateForward(['/ticket/detail', this.ticketId, '--'])
  }
}
