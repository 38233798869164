import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of, pipe } from 'rxjs';
import { map, switchMap, catchError, tap, take } from 'rxjs/operators';

import * as configActions from './config.actions';
import { ClientService } from '../../service/client.service';
import { IClient } from 'rebus-models';
import { Router } from '@angular/router';
import { consoleStore, consoleError } from '../../helper/console.helper';
import { getUser } from '@state/user';
import { get } from 'lodash';
import { AuthService } from '../../service/auth.service';
import { environment } from '@app/env';
import * as firebase from 'firebase/app';

const code = '[ CONFIG ]';

@Injectable()
export class ConfigEffects {

  @Effect()
  public LoadClient$: Observable<Action> = this.actions$.pipe(
    ofType(configActions.LOAD_CLIENT),
    pipe(
      tap(() => consoleStore(`${code} Inciando carga configuración de cliente`)),
      switchMap(() => this.clientService.getClient().
        pipe(
          tap(async (client) => {
            const user: any = await this.store.select(getUser).pipe(take(1)).toPromise()
            // if (get(client, 'isActiveIntegrationIncontacto', false) && get(get(user, 'customInformation', null), 'custom1', null)) {
            //   console.log('Usuario registrado se marca checkin en incontacto', get(user.customInformation, 'custom1', 'null'))
            //   await this.authService.createConnectionIncontacto(get(user.customInformation, 'custom1', 'null'), get(client, 'checkinUrl', false))
            //     .then(result => {
            //       console.log("result")
            //       console.log(result)
            //       firebase.database().ref(`/Clients/${environment.clientId}/___connections_incontacto/${user._id}/incontacto`).update(true)
            //       firebase.database().ref(`/Clients/${environment.clientId}/___connections_incontacto/${user._id}/response_incontacto`).update(result)
            //     })
            //     .catch(error => {
            //       firebase.database().ref(`/Clients/${environment.clientId}/___connections_incontacto/${user._id}/incontacto`).update(true)
            //       firebase.database().ref(`/Clients/${environment.clientId}/___connections_incontacto/${user._id}/response_incontacto`).update(error)
            //     })
            // }
          }),
          map((client: IClient) => {
            if (client) {
              consoleStore(`${code} Se carga la configuración del cliente correctamente`, client);
              return new configActions.LoadClientSuccessAction(client);
            }
            // TODO: Esto se tiene que implementar en la pag 401
            this.router.navigate(['shared', '401'], {
              queryParams: {
                message: 'No se ha podido cargar la información del cliente. Notifique al administrador del sistema',
              }
            });
            throw ({ code: 404, message: 'El cliente no ha sido encontrado' });
          }),
          catchError(err => {
            consoleError(`${code} Carga de configuración del cliente fallida`, err);
            return of(new configActions.LoadClientErrorAction(err));
          })
        ))
    )
  );

  constructor(
    private actions$: Actions,
    private clientService: ClientService,
    private router: Router,
    private store: Store<{}>,
    private authService: AuthService
  ) { }
}
