import { Component, Input, OnInit } from '@angular/core';
import { IUser, IQuestionComments, IDesign } from 'rebus-models';
import { QuestionService } from '../../../services/question.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.scss']
})
export class CommentItemComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public comment: IQuestionComments;
  public diffDate: String = 'Justo ahora';
  public user$: Observable<IUser>;

  constructor(
    private questionService: QuestionService,
  ) {
  }

  public ngOnInit() {
    this.getDateDiff();
    this.user$ = this.questionService.getCommentWritter(this.comment).pipe(
      map(user => user.basicInformation)
    );
  }

  public getDateDiff() {
    const cDate = moment(this.comment.createdAt);
    const now = moment();
    const diff = {
      'days': now.diff(cDate, 'days'),
      'hours': now.diff(cDate, 'hours'),
      'minutes': now.diff(cDate, 'minutes')
    };
    if (diff.days > 0) {
      this.diffDate = 'Hace ' + diff.days + ' días';
    } else if (diff.hours > 0) {
      this.diffDate = 'Hace ' + diff.hours + ' horas';
    } else if (diff.minutes > 1) {
      this.diffDate = 'Hace ' + diff.minutes + ' minutos';
    }
  }

}
