import { Component, Input, OnInit } from '@angular/core';
import { IDesign } from 'rebus-models';
import { PopoverController } from '@ionic/angular';
import { RebusMatchTooltipComponent } from '../rebus-match-tooltip/rebus-match-tooltip.component';

@Component({
  selector: 'app-rebus-match',
  templateUrl: './rebus-match.component.html',
  styleUrls: ['./rebus-match.component.scss']
})
export class RebusMatchComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public match: any;

  private e: Event;

  constructor(
    private popoverCtrl: PopoverController,
  ) { }

  public ngOnInit() {
    this.match = parseFloat(this.match);

    if (!JSON.parse(localStorage.getItem('matchSeen')) && this.match * 100 > 15) {
      this.show(this.e);
      localStorage.setItem('matchSeen', JSON.stringify(true));
    }
  }

  public async show(ev) {
    const popover = await this.popoverCtrl.create({
      component: RebusMatchTooltipComponent,
      componentProps: { design: this.design },
      event: ev,
      translucent: true,
      cssClass: 'rebus-match-tooltip'
    });
    return await popover.present();
  }

}
