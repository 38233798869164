import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@app/env';
import { AngularFireDatabase } from '@angular/fire/database';
import { isValidEventId } from '@helper/utils';
import { AuthService } from './auth.service';
import { switchMap, take } from 'rxjs/operators';
import {
  IRecommendationsResponse,
  TRecommendationEntity,
  ITicket,
  IService,
  IStaff,
  IProduct,
  INews,
  IAgenda,
  ICoupon,
  IEvent,
  IGallery,
  IStand,
  ISponsor
} from 'rebus-models';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable({ providedIn: 'root' })
export class AIService {

  constructor(
    private http: HttpClient,
    private fb: AngularFireDatabase,
    private authService: AuthService,
    private store: Store<{}>
  ) { }

  public getRecommendationsForUser(uid: string, entity: any, limit = 5, eid = null, itemId = null) {
    return of([])
    // return this.authService.getTokenUser().pipe(
    //   switchMap((token: string) => {
    //     if (!token) {
    //       return of([]);
    //     }
    //     const headers = new HttpHeaders({ 'Authorization': token });
    //     return this.http.get<IRecommendationsResponse[]>(
    //       // tslint:disable-next-line: max-line-length
    //       `${environment.ai_rebus}/v1/for_user/${environment.clientId}/${uid}/${entity}/${limit}?eventId=${eid !== '--' ? eid : null}&itemId=${itemId}`
    //       , { headers }
    //     );
    //   })
    // );
  }

  public getUserRecomendationsForUserNetworking(userId: string) {
    return of([])
    // return this.authService.getTokenUser().pipe(
    //   switchMap((token: string) => {
    //     if (!token) {
    //       return of([]);
    //     }
    //     const headers = new HttpHeaders({ 'Authorization': token });
    //     return this.http.get<IRecommendationsResponse[]>(
    //       `${environment.ai_rebus}/v1/users/similar`, {
    //       headers,
    //       params: {
    //         userId: userId,
    //         clientId: environment.clientId,
    //         limit: '5'
    //       },
    //     }
    //     );
    //   })
    // );
  }

  /**
   * @param eventId Id del evento
   * @param entity Entidad del objeto que se desea consultar
   * @param id Id del objeto
   */
  public fecthEntityData(eventId: string, entity: any, id: string) {
    switch (entity) {
      case 'Tickets':
        return this.getTicketById(eventId, id);
      case 'Products':
        return this.getProductById(eventId, id);
      case 'News':
        return this.getNewsById(eventId, id);
      case 'Agenda':
        return this.getAgendaById(eventId, id);
      case 'Coupons':
        return this.getCouponById(eventId, id);
      case 'Gallery':
        return this.getGalleryById(eventId, id);
      case 'Events':
        return this.getEventById(id);
      case 'Staff':
        return this.getStaffById(eventId, id);
      case 'Services':
        return this.getServiceById(eventId, id);
      case 'Stand':
        return this.getStandById(eventId, id);
      case 'Sponsor':
        return this.getSponsorById(eventId, id);
      default:
        return Promise.resolve(null);
    }
  }

  private getStaffById(eventId: string, staffId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<IStaff>(`/Clients/${environment.clientId}/Event/${eventId}/Staff/${staffId}`)
        .query.once('value').then(snap => snap.val());
    } else {
      return this.fb.object<IStaff>(`/Clients/${environment.clientId}/Interactivity/Staff/${staffId}`)
        .query.once('value').then(snap => snap.val());
    }
  }

  private getStandById(eventId: string, standId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<IStand>(`/Clients/${environment.clientId}/Event/${eventId}/Stand/${standId}`)
        .query.once('value').then(snap => snap.val());
    } else {
      return this.fb.object<IStand>(`/Clients/${environment.clientId}/Interactivity/Stand/${standId}`)
        .query.once('value').then(snap => snap.val());
    }
  }

  public getSponsorById(eventId: string, sponsorId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<ISponsor>(`/Clients/${environment.clientId}/Event/${eventId}/Sponsor/${sponsorId}`)
        .query.once('value').then(snap => snap.val());
    } else {
      return this.fb.object<ISponsor>(`/Clients/${environment.clientId}/Interactivity/Sponsor/${sponsorId}`)
        .query.once('value').then(snap => snap.val());
    }
  }

  private getServiceById(eventId: string, serviceId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<IService>(`/Clients/${environment.clientId}/Event/${eventId}/Services/${serviceId}`)
        .query.once('value').then(snap => snap.val());
    } else {
      return this.fb.object<IService>(`/Clients/${environment.clientId}/Interactivity/Services/${serviceId}`)
        .query.once('value').then(snap => snap.val());
    }
  }

  private getGalleryById(eventId: string, galleryId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<IGallery>(`/Clients/${environment.clientId}/Event/${eventId}/Gallery/${galleryId}`)
        .query.once('value').then(snap => snap.val());
    } else {
      return this.fb.object<IGallery>(`/Clients/${environment.clientId}/Interactivity/Gallery/${galleryId}`)
        .query.once('value').then(snap => snap.val());
    }
  }

  private getEventById(eventId) {
    return this.fb.object<IEvent>(`/Clients/${environment.clientId}/EventConfig/${eventId}`)
      .query.once('value').then(snap => snap.val());
  }

  private getCouponById(eventId: string, couponId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<ICoupon>(`/Clients/${environment.clientId}/Event/${eventId}/Coupon/${couponId}`)
        .query.once('value').then(snap => snap.val());
    } else {
      return this.fb.object<ICoupon>(`/Clients/${environment.clientId}/Interactivity/Coupon/${couponId}`)
        .query.once('value').then(snap => snap.val());
    }
  }

  private getAgendaById(eventId: string, agendaId: string) {
    return this.fb.object<IAgenda>(`/Clients/${environment.clientId}/Event/${eventId}/Agenda/${agendaId}`)
      .query.once('value').then(snap => snap.val());
  }

  private getNewsById(eventId: string, newsId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<INews>(`/Clients/${environment.clientId}/Event/${eventId}/News/${newsId}`)
        .query.once('value').then(snap => snap.val());
    } else {
      return this.fb.object<INews>(`/Clients/${environment.clientId}/Interactivity/News/${newsId}`)
        .query.once('value').then(snap => snap.val());
    }
  }

  private getTicketById(eventId: string, ticketId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<ITicket>(`/Clients/${environment.clientId}/Event/${eventId}/Ticket/${ticketId}`)
        .query.once('value').then(snap => snap.val());
    } else {
      return this.fb.object<ITicket>(`/Clients/${environment.clientId}/Interactivity/Ticket/${ticketId}`)
        .query.once('value').then(snap => snap.val());
    }
  }

  private getProductById(eventId: string, productId: string) {
    if (isValidEventId(eventId)) {
      return this.fb.object<IProduct>(`/Clients/${environment.clientId}/Event/${eventId}/Product/${productId}`)
        .query.once('value').then(snap => snap.val());

    } else {
      return this.fb.object<IProduct>(`/Clients/${environment.clientId}/Interactivity/Product/${productId}`)
        .query.once('value').then(snap => snap.val());
    }
  }
}
