import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@app/env';
import { getCookie } from '@helper/utils';
import { AlertController, LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { getClient } from '@state/config';
import { getUser } from '@state/user';
import * as firebase from 'firebase';
import { get, has } from 'lodash';
import { IEvent } from 'rebus-models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { ModalShareItemComponent } from '../components/modal-share-item/modal-share-item.component';
import { consoleDebug, consoleError, consoleTracking } from '../helper/console.helper';
import { parseMessages } from '../helper/messages.helper';
import { TranslateObjectPipe } from '../pipes/translate-obj.pipe';

@Injectable()
export class UtilitiesService {
  constructor(
    private toastCtrl: ToastController,
    private loadingCtrl: LoadingController,
    private platform: Platform,
    private translate: TranslateService,
    private translate_obj: TranslateObjectPipe,
    private modalController: ModalController,
    private alertCtrl: AlertController,
    private http: HttpClient,
    private titleService: Title,
    private store: Store<{}>,
    private route: Router
  ) { }

  /**
   * Helper que crea unmensaje toast
   *
   * @param message
   * @param duration
   * @param position
   * @param color
   * @param present
   * @param opts
   */
  public async presentToast(
    message: string,
    duration: number = 4500,
    position: 'bottom' | 'middle' | 'top' = 'bottom',
    color: string = 'dark',
    present: boolean = true,
    opts: {
      translucent?: boolean;
      showCloseButton?: boolean;
      closeButtonText?: string;
      cssClass?: string;
    } = null
  ): Promise<any> {
    const toast = await this.toastCtrl.create({
      message: await this.translate.instant(message),
      duration,
      position,
      color, // El color por defecto es negro
      mode: 'ios', // Todos los toast tendran el efecto de ios
      ...opts,
    });

    if (present) {
      return toast.present();
    }
    return Promise.resolve(toast);
  }

  public async presentToastNotification(
    labelBtnAction: string,
    header: string,
    redirect: string,
    callBack: string,
    message: string,
    duration: number = 4500,
    position: 'bottom' | 'middle' | 'top' = 'bottom',
    color: string = 'dark',
    present: boolean = true
  ): Promise<any> {
    const buttonAction = callBack
      ? {
        text: labelBtnAction,
        handler: () => {
          window.open(callBack, redirect);
        },
      }
      : {};
    const toast = await this.toastCtrl.create({
      header,
      message: await this.translate.instant(message),
      duration,
      position,
      color, // El color por defecto es negro
      mode: 'ios', // Todos los toast tendran el efecto de ios
      buttons: [buttonAction],
    });

    if (present) {
      return toast.present();
    }
    return Promise.resolve(toast);
  }

  get alert() {
    return this.alertCtrl;
  }

  public async presentLoad(message: string = null, present: boolean = true): Promise<HTMLIonLoadingElement> {
    const load = await this.loadingCtrl.create({
      message,
    });
    if (present) {
      load.present();
    }
    return load;
  }

  public getMessageSuccess(result: any): Promise<any> {
    let message: string;

    if (has(result, 'message')) {
      message = result.message;
    } else if (typeof result === 'string') {
      message = result;
    } else {
      message = 'Se ha procesado la solicitud correctamente';
    }

    message = this.translate.instant(message);

    return Promise.resolve(parseMessages(message));
  }

  public getMessageError(e: any, defaultMessage: string = null): Promise<any> {
    let message: string;

    consoleError('parsed error', e);

    if (e instanceof HttpErrorResponse) {
      if (get(e.error, 'message')) {
        consoleError('parsed 1', e);
        message = this.translate_obj.transform(e.error.message).toString();
      } else if (get(e.error, 'response')) {
        consoleError('parsed 111º11', e);
        message = get(e.error.response, 'message', 'El registro no ha sido procesado. Inténtelo nuevamente');
        switch (message) {
          case 'El DNI ya esta registrado':
            message = 'El número de identificación ya se encuetra registrado a otra cuenta';
            break;
        }
      } else {
        consoleError('parsed 2', e);
        message = e.message;
      }
    } else if (e instanceof TypeError) {
      consoleError('parsed 3', e);
      message = e.message;
    } else if (e instanceof Error) {
      consoleError('parsed 4', e);
      message = e.message;
    } else {
      consoleError('parsed 5', e);
      message = e;
    }

    if (!message) {
      message = defaultMessage
        ? 'Se ha presentado un error al procesar su solicitud, póngase en contacto con servicio al cliente.'
        : defaultMessage;
    }

    return Promise.resolve(parseMessages(message));
  }

  public getPlatformData(): any {
    return {
      // FIXME: Esto presenta problemas en las prueba unitarias
      platforms: this.platform.platforms(),
      width: this.platform.width(),
      height: this.platform.height(),
      isPortrait: this.platform.isPortrait(),
      isLandscape: this.platform.isLandscape(),
      // ############
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform,
      appVersion: window.navigator.appVersion,
      appCodeName: window.navigator.appCodeName,
      appName: window.navigator.appName,
    };
  }

  // tslint:disable-next-line:max-line-length
  public getDevice(device) {
    return this.platform.is(device);
  }

  public async shareItemSocialMedia(nameItem, labelShareItem, customUrl: string = null) {
    const load = await this.presentLoad();
    let url = customUrl ? customUrl : window.location.href;

    consoleDebug('Url para procesar', null, url);

    if (customUrl) {
      const urlShort = await this.shortenerUrl(url).catch((error) => {
        consoleError('Error sharing:', error);
        load.dismiss();
        this.presentToast('Error compartiendo la información', 4000);
      });
      if (urlShort) {
        url = 'http://' + urlShort.shortUrl;
      }
    }

    let navigationShare: any;
    navigationShare = window.navigator;
    if (navigationShare && navigationShare.share) {
      navigationShare
        .share({
          title: nameItem,
          url,
        })
        .then(() => load.dismiss())
        .catch(async (error) => {
          consoleError('Error sharing movil:', error);
          // load.dismiss();
          // this.presentToast('Error compartiendo la información', 4000);
          const modal = await this.modalShared(nameItem, labelShareItem, url);
          return await modal.present().then(() => load.dismiss());
        });
    } else {
      const modal = await this.modalShared(nameItem, labelShareItem, url);
      return await modal.present().then(() => load.dismiss());
    }
  }

  private modalShared(nameItem, labelShareItem, url: string) {
    return this.modalController.create({
      component: ModalShareItemComponent,
      componentProps: { nameItem: nameItem, labelShareItem: labelShareItem, customUrl: url },
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'custom-modal-item-gallery',
    });
  }

  // TODO: Esto se debe sacar a un provider nuevo y parametrizar llaves
  public shortenerUrl(url) {
    const headers = new HttpHeaders({ apikey: 'b4abf0a3474d431ea6ac5868598e497d', 'Content-Type': 'application/json' });
    return this.http.post<any>(`https://api.rebrandly.com/v1/links`, { destination: url }, { headers }).toPromise();
  }

  public calculateModuleEnterToEvent(event: IEvent) {
    let cmp = '';

    switch (event.defaultModule) {
      case 'countdown':
        cmp = 'countdown';
        break;
      case 'ticket':
        cmp = 'ticket';
        break;
      case 'stand':
        cmp = 'stand';
        break;
      case 'sponsor':
        cmp = 'sponsor';
        break;
      case 'networking':
        cmp = 'networking';
        break;
      case 'gallery':
        cmp = 'gallery';
        break;
      case 'coupons':
        cmp = 'coupon';
        break;
      case 'carnet':
        cmp = 'carnet';
        break;
      case 'services':
        cmp = 'service';
        break;
      case 'diary':
        cmp = 'agenda';
        break;
      case 'staff':
        cmp = 'staff';
        break;
      case 'news':
        cmp = 'news';
        break;
      case 'catalog':
        cmp = 'product';
        break;
      case 'commercial_samples':
        cmp = 'stand';
        break;
      case 'home':
        cmp = 'home';
        break;
      case 'urlRedirection':
        cmp = 'UrlRedirectionEventPage';
        break;
    }

    return cmp;
  }

  public validateDigistByCurrency(currencyCode) {
    switch (currencyCode) {
      case 'COP':
      case 'CLP':
      case 'CRC':
        return '0.0-0';

      default:
        return '0.2-2';
    }
  }

  public async calculateTitleName(moduleConfig: Observable<any>, moduleType: string, detailConfig?: Observable<any>) {
    let calculatedTitleName: string = '';

    const client = await this.store.select(getClient).pipe(take(1)).toPromise();
    const moduleInformation = await moduleConfig.pipe(take(1)).toPromise();
    const currentLanguage = localStorage.getItem('rebus-language');

    switch (moduleType) {
      case 'module':
        let moduleName = moduleInformation.title ? moduleInformation.title.es : moduleInformation.menuLabel.es;
        calculatedTitleName = `${client.name} - ${moduleName}`;
        break;
      case 'detail':
        const detailInformation = await detailConfig.pipe(take(1)).toPromise();
        const detailName =
          detailInformation.title !== undefined
            ? detailInformation.title.es
            : detailInformation.name !== undefined
              ? detailInformation.name
              : detailInformation.post !== undefined
                ? detailInformation.post
                : detailInformation.post;
        calculatedTitleName = `${client.name} -  ${moduleInformation.title.es}  -  ${detailName}`;
        break;
      case 'interactivity':
        calculatedTitleName = `${client.name} - Interactivity - ${moduleInformation.title.es}`;
        break;
    }
    this.titleService.setTitle(calculatedTitleName);
  }
  public async sendGtagData(titleName: string) {
    const user = await this.store.select(getUser).pipe(take(1)).toPromise();
    const client = await this.store.select(getClient).pipe(take(1)).toPromise();
    const basicInformation = get(user, 'basicInformation', {});
    const dataToTrack = {
      clientId: environment.clientId,
      clientName: client.name,
      userId: get(user, '_id', false),
      userName: get(basicInformation, 'name', null),
      userEmail: get(basicInformation, 'email', null),
      page: this.route.url,
      title: `${client.name} - ${titleName}`,
      origin: window.origin,
      url: document.URL,
      processId: window.localStorage.getItem(`${environment.clientId}-PROCESS-TRACKING`),
      cookieId: getCookie(`${environment.clientId}-COOKIE-TRACKING`),
      payload: { url: this.route.url.toString().slice(1, this.route.url.toString().length).split('/') },
      date: firebase.database.ServerValue.TIMESTAMP,
    };

    consoleTracking('Action submit for tracking, sending data', dataToTrack);
    window.dataLayer.push({
      event: 'navegacion',
      informacion: {
        ...dataToTrack,
        date: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
        timestamp: 'd ' + Date.now(),
      },
    });
  }
}
