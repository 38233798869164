import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, IProduct } from 'rebus-models';

@Component({
  selector: 'app-highlight-product',
  templateUrl: './highlight-product.component.html',
  styleUrls: ['./highlight-product.component.scss']
})
export class HighlightProductComponent {

  @Input() public design: IDesign;
  @Input() public data: IProduct;
  @Input() public match: number;

  @Output() public evOnClickViewMoreProduct = new EventEmitter<string>();
  @Output() public evOnAddProductCart = new EventEmitter<any>();

  public viewMore() {
    this.evOnClickViewMoreProduct.emit(this.data.id);
  }

  public onAddProductCart() {
    this.evOnAddProductCart.emit(this.data);
  }

}
