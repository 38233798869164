import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from '@app/env';
import { Store } from '@ngrx/store';
import { INews, INewsConfig, ITimeline, ITimelineLike, ITimelineComments, ITimelineConfig } from 'rebus-models';
import { isValidEventId } from '@helper/utils';
import * as firebase from 'firebase/app';
import { consoleError } from '../../shared/helper/console.helper';
import { map, take } from 'rxjs/operators';
import { toArray } from 'lodash';

@Injectable()
export class TimeLineService {

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<{}>,
  ) { }

  public getTimeLines(eventId: String): Observable<ITimeline[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<ITimeline>(`/Clients/${environment.clientId}/Event/${eventId}/TimeLine`)
        .valueChanges();
    }
    return this.afdb.list<ITimeline>(`/Clients/${environment.clientId}/Interactivity/TimeLine`)
      .valueChanges();
  }

  public getTimeLineConfig(eventId: String): Observable<ITimelineConfig> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<ITimelineConfig>(`/Clients/${environment.clientId}/Event/${eventId}/TimeLineConfig`)
        .valueChanges();
    }
    return this.afdb.object<ITimelineConfig>(`/Clients/${environment.clientId}/Interactivity/TimeLineConfig`)
      .valueChanges();
  }

  public getTimeLineById(timeLineId: String, eventId: String): Observable<ITimeline> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<ITimeline>(`/Clients/${environment.clientId}/Event/${eventId}/TimeLine/${timeLineId}`)
        .valueChanges();
    }
    return this.afdb.object<ITimeline>(`/Clients/${environment.clientId}/Interactivity/TimeLine/${timeLineId}`)
      .valueChanges();
  }

  public async createPost(
    timeLine,
    userId,
    eventId: string,
    id: string = ''
  ) {
    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;
    timeLine.id = id;
    timeLine.createdAt = created_at;
    timeLine.userId = userId;
    timeLine.eventId = eventId;

    let reference = `/Clients/${environment.clientId}/Interactivity/TimeLine`;

    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/TimeLine`;
    }

    const result = this.afdb.object(`${reference}/${id}`).update(timeLine).then(() => timeLine)
      .catch((error) => {
        consoleError('Whooops, something happen', error);
      });
    return result;
  }

  public applyLike(
    timelineId: string,
    userId: string,
    eventId: string,
    id: string = '',
  ) {
    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;
    const timelineLike: any =
    {
      "id": id,
      "timelineId": timelineId,
      "createdAt": created_at,
      "userId": userId
    }

    let reference = `/Clients/${environment.clientId}/Interactivity/TimelineLike`;

    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/TimelineLike`;
    }

    const result = this.afdb.object(`${reference}/${id}`).update(timelineLike).then(() => timelineLike)
      .catch((error) => {
        consoleError('Whooops, something happen', error);
      });
    return result;
  }

  public createComment(
    timelineId: string,
    userInformation: any,
    eventId: string,
    comment: string,
    id: string = '', ) {
    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;
    const timeLineComment: any =
    {
      "id": id,
      "timelineId": timelineId,
      "createdAt": created_at,
      "userInformation": userInformation,
      "comment": comment
    }

    let reference = `/Clients/${environment.clientId}/Interactivity/TimelineComment`;

    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/TimelineComment`;
    }

    const result = this.afdb.object(`${reference}/${id}`).update(timeLineComment).then(() => timeLineComment)
      .catch((error) => {
        consoleError('Whooops, something happen', error);
      });
    return result;
  }

  public async unlikePost(timelineId: string, userId: string, eventId: string) {
    const items = await this.getLikePost(timelineId, eventId).pipe(
      map(list => {
        return list.filter(item => item.userId === userId)
      })
    ).pipe(take(1)).toPromise()
    if (items) {
      const item = toArray(items)[0];
      this.deleteLikePost(item, eventId);
    }
  }

  public deleteLikePost(item, eventId: string) {
    if (isValidEventId(eventId)) {
      return this.afdb.object(`/Clients/${environment.clientId}/Event/${eventId}/TimelineLike/${item.id}`).remove();
    } else {
      return this.afdb.object(`/Clients/${environment.clientId}/Interactivity/TimelineLike/${item.id}`).remove();
    }
  }

  public getLikePost(timeLineId: string, eventId: string): Observable<ITimelineLike[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<ITimelineLike>(`/Clients/${environment.clientId}/Event/${eventId}/TimelineLike`,
        ref => ref.orderByChild('timelineId').equalTo(timeLineId)
      )
        .valueChanges();
    } else {
      return this.afdb.list<ITimelineLike>(`/Clients/${environment.clientId}/Interactivity/TimelineLike`,
        ref => ref.orderByChild('timelineId').equalTo(timeLineId)
      )
        .valueChanges();
    }
  }

  public getCommentsByTimeLine(timeLineId: string, eventId: string): Observable<ITimelineComments[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<ITimelineComments>(`/Clients/${environment.clientId}/Event/${eventId}/TimelineComment`,
        ref => ref.orderByChild('timelineId').equalTo(timeLineId)
      )
        .valueChanges();
    } else {
      return this.afdb.list<ITimelineComments>(`/Clients/${environment.clientId}/Interactivity/TimelineComment`,
        ref => ref.orderByChild('timelineId').equalTo(timeLineId)
      )
        .valueChanges();
    }
  }
}
