import { Component, Input, OnInit } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-ad-sidebar',
  templateUrl: './ad-sidebar.component.html',
  styleUrls: ['./ad-sidebar.component.scss']
})
export class AdSidebarComponent implements OnInit {

  @Input() public design: IDesign;

  @Input() public data: any;

  public ngOnInit(): void {

  }

}
