import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { SlaaskHidden } from '@decorator/slaask-hidden';

@Component({
  selector: 'app-side-dialog',
  templateUrl: './side-dialog.component.html',
  styleUrls: ['./side-dialog.component.scss']
})

// @SlaaskHidden()

export class SideDialogComponent {

  @Input() public design: IDesign;
  @Input() public module: String = '';
  @Input() public eventId: string;
  @Output() public evOnClose = new EventEmitter<any>();

  public onClose() {
    this.evOnClose.emit();
  }

}
