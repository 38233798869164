import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { getDesign } from '@state/design';
import { Store } from '@ngrx/store';
import { IDesign, IModuleConfig, INetworkingResponse, IUser } from 'rebus-models';
import { NetworkingService } from '../../services/networking.service';
import { ClientService } from 'src/app/modules/shared/service/client.service';
import { getAuthUID } from '@state/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, tap, mergeMap } from 'rxjs/operators';
import { toArray } from 'lodash';
import { get } from 'lodash';
import { ChatPersonComponent } from '../../components/chat-person/chat-person.component';
import { consoleError, consoleDebug } from 'src/app/modules/shared/helper/console.helper';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { getUser } from '@state/user';
import { NavController } from '@ionic/angular';
import { UtilitiesService } from '@service/utilities';

@Component({
  selector: 'networking',
  templateUrl: './networking.page.html',
  styleUrls: ['./networking.page.scss'],
})
export class NetworkingPage implements OnInit, OnDestroy {


  @ViewChild(ChatPersonComponent) public chatPersonComponent: ChatPersonComponent;
  @ViewChild('searchBarUsers') public searchBarUsers: any;

  public userSubscribe: Subscription;
  public moduleConfig$: Observable<IModuleConfig>;
  // TODO: poner modelo de config de networking
  public networkingConfigWidgets$: Observable<any>;
  public design$: Observable<IDesign>;
  public user$: Observable<IUser>;
  public showDialogChat = false;
  public limitNeworking$ = new BehaviorSubject<number>(10);
  public menu_select: 'all_contacts' | 'my_contacts' | 'send_request' | 'request_received' = 'all_contacts';
  public users$: Observable<INetworkingResponse>;
  public password: string;
  public userId: string;
  public eventId: string;
  public redirectTo: string;
  public usersToChat: any;
  public chatSuscription: Subscription;
  public chatUser: Observable<any[]>;
  public configNetworking: any;
  public totalUsersBySegment: number;
  public hasNotResultSearch = false;
  public valueSearch: '';
  public searchGeneral: string;
  public allowNetworking: boolean;
  public infoUser: any;

  public userList = []

  constructor(
    private store: Store<{}>,
    private router: Router,
    public networkingService: NetworkingService,
    public clientService: ClientService,
    public clienteService: ClientService,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private notiService: NotificationsService,
    private ut: UtilitiesService
  ) { }

  public ngOnInit() {
    this.moduleConfig$ = this.clienteService.getConfigModule('networking');
    this.design$ = this.store.select(getDesign);
    this.ut.calculateTitleName(this.moduleConfig$, 'module');
    this.redirectTo = this.router.url;
    this.user$ = this.store.select(getUser).pipe(
      tap(user => {
        if (user) {
          this.allowNetworking = user.allowNetworking;
          this.infoUser = user.basicInformation
        }
        return user;
      })
    );
    this.eventId = get(this.route.snapshot, 'params.eventId');
    this.networkingConfigWidgets$ = this.networkingService.getConfigWidgetsNetworking(this.eventId).pipe(
      tap(config => {
        this.configNetworking = get(config, 'segmentUsers', false);
        this.getUsersSegment('all_contacts', this.userId, this.configNetworking, null);
      })
    );
    this.userSubscribe = this.store.select(getAuthUID)
      .pipe(filter(user => !!user))
      .subscribe(user => {
        this.userId = user;
      });
  }

  public getUsersSegment(segment: string, userId: string, configNetworking, isChangeSegment, el?) {
    this.userList = []
    const segmentUsers = configNetworking === 'segment' ? this.eventId : null;
    this.users$ = this.limitNeworking$.pipe(
      mergeMap(limit => this.networkingService.calculateGetUserNetworking(segment, userId, null, limit, segmentUsers)
        .pipe(tap(response => this.totalUsersBySegment = response.data.length),
          tap(data => {
            this.userList.push(...data.data)
          })
        )
      )
    );
    this.limitNeworking$.next(10);
    if (isChangeSegment) {
      this.searchGeneral = '';
    }
    if (el) {
      el.value = '';
    }
  }

  public changeSlideChat(userIdSelected) {
    this.getMessagesToChat(userIdSelected.id);
  }

  public onShowChatPerson(userActionChat) {
    const userIdSelected = userActionChat.userIdSelected;
    const userSegments = userActionChat.usersSegment;
    this.usersToChat = userSegments.filter(user => user.id === userIdSelected);
    this.chatPersonComponent.showLoader = true;
    setTimeout(() => {
      this.chatPersonComponent.showLoader = false;
    }, 1000);
    this.getMessagesToChat(userIdSelected);
    this.showDialogChat = true;
  }

  public async getMessagesToChat(userIdSelected) {
    const users_chat_option1 = this.userId + '_' + userIdSelected;
    const users_chat_option2 = userIdSelected + '_' + this.userId;
    const chatPerson1 = await this.networkingService.getChatRoomByForeignKey(users_chat_option1);
    const chatPerson2 = await this.networkingService.getChatRoomByForeignKey(users_chat_option2);

    if (!chatPerson1 && !chatPerson2) {
      this.chatUser = this.networkingService.getMessagesChatRoom(users_chat_option1);
    } else if (!!chatPerson1) {
      this.chatUser = this.networkingService.getMessagesChatRoom(users_chat_option1);
    } else {
      this.chatUser = this.networkingService.getMessagesChatRoom(users_chat_option2);
    }
  }

  public async onSendMessageChat(user) {
    if (user.message !== '') {
      const userIdSelected = user.userId;
      const message = user.message;
      const users_chat_option1 = this.userId + '_' + userIdSelected;
      const users_chat_option2 = userIdSelected + '_' + this.userId;
      const chatPerson1 = await this.networkingService.getChatRoomByForeignKey(users_chat_option1);
      const chatPerson2 = await this.networkingService.getChatRoomByForeignKey(users_chat_option2);
      const result = !!chatPerson1 ? chatPerson1 : chatPerson2;

      if (result) {
        const chatUser = toArray(result);
        this.sendMessageChat(message, chatUser[0].id, userIdSelected);
      } else {
        const chat = {
          'foreign_key': users_chat_option1,
          'user1': this.userId,
          'user2': userIdSelected
        };
        this.networkingService.createChatRoomUsers(chat).then(chatUser => {
          this.sendMessageChat(user.message, chatUser.id, userIdSelected);

        }).catch(error => {
          consoleError('error creando chat', error);
        });
      }
    }
  }

  public sendMessageChat(message, chatUserId, userIdSelected) {
    if (message !== '') {
      const messageObj = {
        'sender': this.userId,
        'text': message,
      };
      this.networkingService.createMessageChat(messageObj, chatUserId).then(() => {
        this.networkingService.createNotificationMessage(userIdSelected, this.userId, message, this.infoUser).then((data) => {
          consoleDebug('save notification chat user', data);
        })
      }).catch(() => {
        this.notiService.showRebusToast('Se produjo un error enviando mensaje', 'Error', false, null, null, 'networking');
      });
    }
  }

  public loadData(event, list: any[]) {
    setTimeout(() => {
      const current = this.limitNeworking$.value;
      this.limitNeworking$.next(current + 10);
      event.target.complete();
    }, 500);
  }

  public onCloseChatPerson() {
    this.showDialogChat = false;
    this.usersToChat = [{}];
  }

  public segmentChanged(ev: any) {
    this.menu_select = ev.detail.value;
    this.hasNotResultSearch = false;
    this.clearInputSearchUsers();
  }

  public clearInputSearchUsers(): Promise<HTMLInputElement> {
    if (this.searchBarUsers) {
      return this.searchBarUsers.getInputElement().then(htmlElem => {
        if (htmlElem) {
          htmlElem.value = '';
        }
      });
    }
  }

  public search(ev = null) {
    this.searchGeneral = ev;
    if (ev) {
      const valeSearch = ev.target.value;
      this.users$ = this.networkingService.calculateGetUserNetworking(
        this.menu_select,
        this.userId,
        valeSearch,
        this.limitNeworking$.value
      ).pipe(
        tap(users => {
          this.userList = []
          this.userList.push(...users.data)
          if (users.data.length === 0) {
            this.valueSearch = valeSearch;
            this.hasNotResultSearch = true;
          }
        })
      );
    }
  }

  public onRequestUser(userSelected) {
    const userIdUserSelected = userSelected._id;
    this.networkingService.sendRequestUser(userIdUserSelected, this.userId, this.eventId).subscribe(
      res => {
        this.notiService.showRebusToast('Se envio solicitud correctamente', 'Success', false, null, null, 'networking');
        this.getUsersSegment(this.menu_select, this.userId, this.configNetworking, null);
        if (this.searchGeneral) {
          this.search(this.searchGeneral);
        }
        this.valueSearch = '';
        this.hasNotResultSearch = false;
      },
      err => {
        consoleError('Error occured request sended', err);
        this.notiService.showRebusToast('Error enviando solicitud', 'Error', false, null, null, 'networking');
      }
    );
  }

  public onAcceptNotification(userSelected) {
    const userIdUserSelected = userSelected.user._id;
    const actionSelected = userSelected.action;
    this.networkingService.updateRequestState(userIdUserSelected, actionSelected, this.userId, this.eventId).subscribe(
      res => {
        if (actionSelected === 'aprobado') {
          this.notiService.showRebusToast(`${userSelected.user.basicInformation.name} y tu ahora están en contacto! Envíale un mensaje`,
            'Success', true, null, null, 'networking', this.userId, this.eventId, null, userSelected.user.basicInformation.profilePhoto);
        }
        if (actionSelected === 'cancelada') {
          this.notiService.showRebusToast('Se ha cancelado la solicitud de forma correcta', 'Success', false, null, null, 'networking');
        }
        this.getUsersSegment(this.menu_select, this.userId, this.configNetworking, null);
        if (this.searchGeneral) {
          this.search(this.searchGeneral);
        }
        this.valueSearch = '';
        this.hasNotResultSearch = false;

      },
      err => {
        consoleError('Error occured request sended', err);
        this.notiService.showRebusToast('Error enviando solicitud', 'Error', false, null, null, 'networking');
      }
    );
  }

  public goToProfile() {
    this.navCtrl.navigateForward(['/user/profile/info'], { queryParams: { redirect: this.redirectTo } });
  }

  public ngOnDestroy() {
    if (this.userSubscribe) {
      this.userSubscribe.unsubscribe();
    }
  }
}
