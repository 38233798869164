import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ITicket } from 'rebus-models';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-ticket-widget',
  templateUrl: './ticket-widget.component.html',
  styleUrls: ['./ticket-widget.component.scss'],
})
export class TicketWidgetComponent {

  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public ticket: ITicket;
  @Input() public views: number;

  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.ticket.id);
  }

}
