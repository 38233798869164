import { Component, Output, Input, EventEmitter } from '@angular/core';
import { ISponsor, IDesign } from 'rebus-models';

@Component({
  selector: 'app-item-sponsor-suggestion',
  templateUrl: './item-sponsor-suggestion.component.html',
  styleUrls: ['./item-sponsor-suggestion.component.scss'],
})
export class ItemSponsorSuggestionComponent {


  @Input() public design: IDesign;
  @Input() public eventId: string;
  @Input() public sponsor: ISponsor | any;

  @Output() public evOnViewMoreSponrsor = new EventEmitter<any>();

  public viewMore() {
    this.evOnViewMoreSponrsor.emit(this.sponsor.id);
  }
}
