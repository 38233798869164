import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { cloneDeep, isEmpty } from 'lodash';
import { PopoverController } from '@ionic/angular';
import { TplPopupFilterItemComponent } from '../tpl-popup-filter-item/tpl-popup-filter-item.component';

@Component({
  selector: 'app-filter-item-general',
  templateUrl: './filter-item-general.component.html',
  styleUrls: ['./filter-item-general.component.scss']
})
export class FilterItemGeneralComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public items: any[] = [];
  @Output() public categoryChanges = new EventEmitter();

  public showLabelAll = true;

  constructor(
    public popoverController: PopoverController
  ) { }

  public ngOnInit() {
    this.items = this.items;
  }

  public async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: TplPopupFilterItemComponent,
      event: ev,
      cssClass: 'popover-position',
      componentProps: {
        items: cloneDeep(this.items),
        design: cloneDeep(this.design)
      }
    });
    popover.onDidDismiss().then((objectReturned) => {
      if (objectReturned.data !== undefined) {
        this.items = cloneDeep(objectReturned.data);
        this.categoryChanges.emit(cloneDeep(this.items));
      }
    });
    return await popover.present();
  }

  public canShowAllLabel() {
    if (this.items) {
      return isEmpty(this.items.filter(item => item.isChecked));
    }
  }

  public closeCategory(category) {
    this.items.map(x => {
      if (x.key === category.key) {
        x.isChecked = false;
      }
      return x;
    });
    const list = this.items.filter(i => i.isChecked);
    if (isEmpty(list)) {
      this.showLabelAll = true;
    }
    this.categoryChanges.emit(cloneDeep(this.items));
  }
}
