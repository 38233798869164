import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/env';

@Injectable()
export class CdnService {

  constructor(
    private http: HttpClient,
  ) { }

  // tslint:disable-next-line:no-any
  public getCdnModulesConfig(): Observable<any> {
    const config = {
      headers: { 'Access-Control-Allow-Origin': '*' }
    };
    return this.http.get(environment.cdn.modules_config, config);
  }
}
