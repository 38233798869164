import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IDesign } from 'rebus-models';
import { getDesign } from '@state/design';
import { NavController, ModalController } from '@ionic/angular';
import { get, toArray } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { ScanTicketService } from '../../services/scan-qr-ticket.service';
import { tap } from 'rxjs/operators';
import { ScanQrTicketReaderComponent } from '../../components/scan-qr-ticket-reader/scan-qr-ticket-reader.component';

@Component({
  selector: 'app-scan-qr-ticket-select-item',
  templateUrl: './scan-qr-ticket-select-item.page.html',
  styleUrls: ['./scan-qr-ticket-select-item.page.scss'],
})
export class ScanQrTicketSelectItemPage implements OnInit {

  public design$: Observable<IDesign>;
  public idTransaction: string;
  public payment$: Observable<any>;

  constructor(
    private navCtrl: NavController,
    private store: Store<{}>,
    private route: ActivatedRoute,
    private scanTicketService: ScanTicketService,
    public modalController: ModalController,
  ) { }

  ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.idTransaction = get(this.route.snapshot, 'params.idTransaction');
    this.payment$ = this.scanTicketService.getPayment(this.idTransaction).pipe(
      tap(paymentObj => {
        const items = toArray(paymentObj.data.summary.items)
        if (items.length === 1) {
          this.navCtrl.navigateRoot([`/scan-ticket/result/${this.idTransaction}/${items[0].id}`]);
        }
      })
    )
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  onGoScan(idItem: string) {
    this.navCtrl.navigateRoot([`/scan-ticket/result/${this.idTransaction}/${idItem}`]);
  }

  public async scanOther() {
    const modal = await this.modalController.create({
      component: ScanQrTicketReaderComponent,
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'custom-modal-scan-qr'
    });
    return await modal.present()
  }

}
