import { GLOBAL } from './global';
import { cdn } from './cdn';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  clientId: '-M8Gzw6y-DV-FEWkvfIL',
  //clientId: '-M9QOQnn9uCacpqvaoWX', //TEST
  backend: 'https://rebus-v5-dev.herokuapp.com',
  etl_rebus: 'https://rebus-etl-dev.herokuapp.com',
  pay_rebus: 'https://rebus-v5-pay-dev.herokuapp.com',
  notification_rebus: 'https://notifications-v5-dev.herokuapp.com',
  api_rebus: 'https://rebus-api-v1.herokuapp.com',
  ai_rebus: 'https://rebus-ia-v5.herokuapp.com',
  production: true,
  whereby_domain: 'rebus-co',
  streaming_type: 'reslash', // reslash | whereby
  streaming_limit_total: 250,
  streaming_limit_user: 10,
  logLevelTrack: 'rebus-dev-v5',
  firebase: {
    apiKey: 'AIzaSyCh4g5pP9IOKqgj7GH6WGhucp66oHgrix4',
    authDomain: 'rebus-v5-dev.firebaseapp.com',
    databaseURL: 'https://rebus-v5-dev.firebaseio.com',
    projectId: 'rebus-v5-dev',
    storageBucket: 'rebus-v5-dev.appspot.com',
    messagingSenderId: '665220179440',
    appId: '1:665220179440:web:cdd46c29a5413afd',
    vapidKey: 'BI63mm396_LsymBwmMoov7YPy8L6mUk4Dn4DY7s8kmeMph7tmFB4UFVx2THp_mSMjwgrjrjpZZaD_q0u13dDrYM'
  },
  cdn,
  slaaskId: '5e63f476e0748fef2c5a4d78bbf72d15',
  slackHook: 'https://hooks.slack.com/services/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',
  analyticsId: 'UA-137705728-2',
  limitTo: 20,
  socialLogin: {
    facebook: '334540097213384',
    google: '519996567265-qf4k112nf0i6ehjamb06ebqhhhv4o14s.apps.googleusercontent.com',
    linkedin: '788ph9qzmubn5f',
  },
  redirectTo: GLOBAL.homepage,
  version: `${GLOBAL.version}.${GLOBAL.develop}.${GLOBAL.client}`,
  //Se comenta sitekKeyReCaptch para poner la de test
  // sitekKeyReCaptch: '6LehQ_8UAAAAAKjWNMKIJEkvrJI_Qium6S52PeQm'
  sitekKeyReCaptch: '6LfNDKMZAAAAABImr_b7S--hyRfSmGXKgcnSzwiQ'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
