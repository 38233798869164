import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IBasicInformationUser } from 'rebus-models';

@Component({
  selector: 'app-chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.scss'],
})
export class ChatMessageComponent implements OnInit, OnChanges {

  @Input() public user: IBasicInformationUser;
  @Input() public message: any;
  @Input() public userIdSender: string;

  constructor() { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.user) {
      this.user = changes.user.currentValue;
    }
    if (changes.message) {
      this.message = changes.message.currentValue;
    }
  }

}
