import { Pipe, PipeTransform } from '@angular/core';
import { formatCurrency, getCurrencySymbol } from '@angular/common';
import { Store, select } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { getClient } from '@state/config';
import { UtilitiesService } from '@service/utilities';
import { get } from 'lodash';

@Pipe({
  name: 'client_currency',
})
export class ClientCurrencyPipe implements PipeTransform {

  constructor(
    private store: Store<{}>,
    private ut: UtilitiesService
  ) { }

  transform(value: number, currencyCode: string = null, format: 'narrow' | 'wide' = 'narrow', digitsInfo: string = null, locale: string = 'en'): Observable<string> {
    return this.store.pipe(
      select(getClient),
      map(clientInfo => {
        if (clientInfo) {
          const customCurrency = get(clientInfo, 'customCurrency', false)
          const customCurrencyTRM = get(clientInfo, 'customCurrencyTRM', false)

          if (customCurrency && customCurrencyTRM && !currencyCode) {
            currencyCode = customCurrency
            value = value * customCurrencyTRM
          }

          if (!digitsInfo) {
            digitsInfo = this.ut.validateDigistByCurrency(currencyCode ? currencyCode : clientInfo.currency)
          }

          return formatCurrency(
            value,
            locale,
            getCurrencySymbol(currencyCode ? currencyCode : clientInfo.currency, format),
            currencyCode ? currencyCode : clientInfo.currency,
            digitsInfo
          );
        }
      })
    )
  }
}