import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { NavController } from '@ionic/angular';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-service-item-search-result',
  templateUrl: './service-item-search-result.component.html',
  styleUrls: ['./service-item-search-result.component.scss']
})
export class ServiceItemSearchResultComponent implements OnInit, OnChanges {

  @Input() public design: IDesign;
  @Input() public service: any// IService;
  @Input() public eventId: string;
  @Output() public evOnClickItem = new EventEmitter<void>();

  constructor(
    private navCtrl: NavController
  ) { }

  public ngOnInit() {
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.service) {
      this.service = changes.service.currentValue;
    }
  }

  public onClickservice() {
    this.navCtrl.navigateForward([`service/detail/${this.service.id}/${this.eventId}`]);
    this.evOnClickItem.emit();
  }
}
