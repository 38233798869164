import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IDesign } from 'rebus-models';
import { UtilitiesService } from '@service/utilities';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UserService } from '../../../service/user.service';
import { takeUntil, take } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IUser, ICard } from 'rebus-models';
import creditCardType from 'credit-card-type';
import { consoleError } from 'src/app/modules/shared/helper/console.helper';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { SlaaskHidden } from '@decorator/slaask-hidden';

@Component({
  selector: 'app-payments-detail',
  templateUrl: './payments-detail.component.html',
  styleUrls: ['./payments-detail.component.scss'],
})

@SlaaskHidden()

export class PaymentsDetailComponent implements OnInit, OnDestroy {

  @Input() public design: IDesign;
  @Input() public user: IUser;
  public form: FormGroup;
  public submitAttempt = false;
  public cardToSave: ICard;
  public blBtnLoading = false;

  private unsubscribe$ = new Subject<void>();

  constructor(private modalCtrl: ModalController,
    private fb: FormBuilder,
    private userService: UserService,
    private notiService: NotificationsService,
    private ut: UtilitiesService
  ) { }

  public ngOnInit() {

    this.initForm();
    this.getCardType();
  }


  public onSubmit({ value, valid }: { value: any; valid: boolean }) {
    this.submitAttempt = true;
    if (valid) {
      const fullDate = this.form.get('expiry').value;
      const expiry_year = parseInt(`20${fullDate.split('/')[1].trim()}`, 0);
      const expiry_month = parseInt(fullDate.split('/')[0].trim(), 0);

      this.cardToSave = {
        number: value.number.replace(/ /g, ''), holder_name: value.name,
        expiry_year: expiry_year, expiry_month: expiry_month,
        cvc: value.cvc, type: value.type, token: ''
      };

      this.blBtnLoading = true;

      this.userService.addCard(this.cardToSave, this.user).pipe(take(1)).toPromise()
        .then((data: any) => {
          this.blBtnLoading = false;
          this.notiService.showRebusToast(`Tu método de pago se ha guardado correctamente`,
            'Success', true, null, null, null, this.user._id, null, null, `assets/images/card_${this.cardToSave.type}.png`);

          this.modalCtrl.dismiss({ cards: data.cards });
        }, err => {
          this.blBtnLoading = false;
          this.ut.getMessageError(err, 'Ha ocurrido un problema tratando de almacenar el método de pago, póngase en contacto con servició al cliente')
            .then(message =>
              this.notiService.showRebusToast(message,
                'Error', true, null, null, null, this.user._id, null, null, `assets/images/card_${this.cardToSave.type}.png`)
            );
          consoleError(err);
        });
    }
  }

  public getCardType() {
    this.form.get('number').valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(val => {
      const cards = creditCardType(val);
      let type = '';
      if (cards.length > 0) {

        switch (cards[0].niceType) {
          case 'Visa':
            type = 'vi';
            break;
          case 'Mastercard':
            type = 'mc';
            break;
          case 'American Express':
            type = 'ax';
            break;
          case 'Diners Club':
            type = 'di';
            break;
          default:
            type = null;
            break;
        }
        this.form.get('type').patchValue(type);
      }
    });
  }

  public initForm() {
    this.form = this.fb.group({
      number: new FormControl('', [Validators.required]),
      name: new FormControl('', [Validators.required]),
      expiry: new FormControl('', [Validators.required]),
      cvc: new FormControl('', [Validators.required, Validators.minLength(3)]),
      type: new FormControl('', [Validators.required]),
    });
  }

  public onClose() {
    this.modalCtrl.dismiss();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
