import { Pipe, PipeTransform } from '@angular/core';
import { toArray } from 'lodash';
import { map } from 'rxjs/operators';
import { ClientService } from '../service/client.service';

@Pipe({
  name: 'getStoreShoppingInfo'
})

export class getStoreShoppingInfoPipe implements PipeTransform {

  constructor(
    private clientData: ClientService,
  ) { }

  public transform(storeId: string, eventId: string) {
    return this.clientData.getStore(storeId, eventId);
  }
}
