import { Component, OnInit, Input } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-guest-record-item',
  templateUrl: './guest-record-item.component.html',
  styleUrls: ['./guest-record-item.component.scss']
})
export class GuestRecordItemComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public item: any;
  public blShowDetail: Boolean = false;
  constructor() { }

  ngOnInit() {
  }

  public onToggleDetail() {
    this.blShowDetail = !this.blShowDetail;
  }
}
