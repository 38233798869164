import * as fromDesign from './design.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IDesign } from 'rebus-models';

export interface State {
  design: IDesign;
  eventId: string;
  // tslint:disable-next-line:no-any
  error: any;
}

export const initialState: State = {
  design: null,
  eventId: null,
  error: null,
};

export function reducer(state = initialState, action: fromDesign.MenuActions): State {

  switch (action.type) {
    case fromDesign.LOAD_DESIGN: {
      return {
        ...state,
        eventId: action.payload || null,
        error: null,
      };
    }

    case fromDesign.LOAD_DESIGN_SUCCESS: {
      return {
        ...state,
        design: action.payload,
        error: null,
      };
    }

    case fromDesign.LOAD_DESIGN_ERROR: {
      return {
        ...state,
        design: null,
        error: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}

export const getDesignState = createFeatureSelector<State>('design');
export const getDesign = createSelector(getDesignState, (state: State) => state.design);
export const getError = createSelector(getDesignState, (state: State) => state.error);
