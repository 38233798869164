import { Directive, Input, HostListener } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
  selector: '[appNumberInput]',
})
export class NumberInputDirective {

  @Input() public control: FormControl;

  @HostListener('ionChange') public onValueChange(ev) {
    if (!ev) { return; }
    if (!ev.value) { return; }
    const value = parseInt(ev.value, 10);
    this.control.setValue(value);
  }

}
