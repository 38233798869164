import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { consoleError } from './app/modules/shared/helper/console.helper';
import { environment } from '@app/env';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    registerServiceWorker();
    registerFacebookApp();
  })
  .catch(err => consoleError('Error en el main', err));

function registerServiceWorker() {
  if (environment.production) {
    if ('serviceWorker' in navigator) {
      window.addEventListener('load', function () {
        navigator.serviceWorker.register('/sw.js').then((reg) => {
          if (!navigator.serviceWorker.controller) {
            return;
          }
          if (!navigator.serviceWorker.controller) {
            return;
          }

          // 3-5
          // 1-4
          if (reg.waiting) {
            console.info('SW waiting 1');
            updateReady(reg.waiting);
            return;
          }
          // if (reg.installing) {
          //   console.info('SW installing 2');
          //   updateReady(reg.installing);
          //   return;
          // }
          reg.addEventListener('updatefound', function () {
            console.info('SW updatefound 3');
            updateReady(reg.installing);
          });

          function updateReady(worker) {

            if (worker.state === 'installed') {
              console.info('SW installed 4');
              showSnackbar();
              sendSkipWaiting(worker);
            }
            worker.addEventListener('statechange', () => {
              switch (worker.state) {
                case 'installed':
                  console.info('SW installed on statechange 5');
                  showSnackbar();
                  sendSkipWaiting(worker);
                  break;
              }
            });
          }
        }).catch(function (err) {
          console.error('ServiceWorker reg failed: ', err);
        });
        return;
      });
    }
  }

  function showSnackbar() {
    const div = document.getElementById('snackbar');
    div.className = 'show';
  }

  function sendSkipWaiting(worker) {
    const snack = document.getElementById('snackbar');
    snack.addEventListener('click', () => {
      worker.postMessage({ action: 'skipWaiting' });
      window.location.reload(true);
    });
  }
}

function registerFacebookApp() {
  (function (d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) { return; }
    js = d.createElement(s); js.id = id;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    fjs.parentNode.insertBefore(js, fjs);
  }(document, 'script', 'facebook-jssdk'));

  (<any>window).fbAsyncInit = function () {
    (<any>window).FB.init({
      appId: '1912744835703731',
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v2.12'
    });
  };
}
