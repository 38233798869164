import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { environment } from '@app/env';
import { isValidEventId } from '@helper/utils';
import { Store } from '@ngrx/store';
import { getAuthUID } from '@state/auth';
import { take } from 'rxjs/operators';
import { CartService } from '../../cart/services/cart.service';
import { consoleError } from '../../shared/helper/console.helper';
import { NotificationsService } from '../../shared/service/notifications.service';
import { TranslateObjectPipe } from '../../shared/pipes/translate-obj.pipe';
import { IItem, IProduct, IProductConfig } from 'rebus-models';

@Injectable({ providedIn: 'root' })
export class ProductService {

  private entity = 'Products';

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<{}>,
    private cartService: CartService,
    private notiService: NotificationsService,
    private translate_obj: TranslateObjectPipe
  ) { }

  public getProducts(eventId: string): Observable<IProduct[]> {
    let reference = `/Clients/${environment.clientId}/Interactivity/Product`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Product`;
    }

    return this.afdb.list<IProduct>(reference, ref => ref.orderByChild('state').equalTo(true))
      .valueChanges();
  }

  public getConfigWidgetsProduct(eventId: string): Observable<IProductConfig> {
    let reference = `/Clients/${environment.clientId}/Interactivity/ProductConfig`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/ProductConfig`;
    }

    return this.afdb.object<IProductConfig>(reference).valueChanges();
  }


  public getProductById(eventId: string, productId: string): Observable<IProduct> {
    let reference = `/Clients/${environment.clientId}/Interactivity/Product/${productId}`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Product/${productId}`;
    }

    return this.afdb.object<IProduct>(reference).valueChanges();
  }

  public addProductToShoppingCart(product: IProduct, amount = 1, customForm = null): Promise<any> {
    if (product.pay.quota < product.pay.minQuantity || product.pay.quota <= 0) {
      this.notiService.showRebusToast(
        'Este producto se encuentra agotado',
        'Warning'
      );
      return Promise.resolve();
    }
    return this.store.select(getAuthUID)
      .pipe(take(1)).toPromise().then(uid => {
        const item = this.createProductShoppingCartItem(product, amount, customForm);
        this.cartService.addShoppingCartItem(uid, item).then(val => {
          if (val) {
            this.notiService.showRebusToast(`${this.translate_obj.transform(item.data.name)} agregado al carrito de compras `,
              'Success', true, null, null, 'product', uid, item.eventId, 'cart/--', `${item.data.photo}`);
          }
        }, err => {
          consoleError('err[0] carrito productos', err),
            this.notiService.showRebusToast('Error agregando al carrito ', 'Error', false, null, null, 'product');
        });
      }, err => {
        consoleError('err[1] carrito productos', err),
          this.notiService.showRebusToast('Error agregando al carrito ', 'Error', false, null, null, 'product');

      });
  }

  public createProductShoppingCartItem(product: IProduct, amount: number, customForm): IItem {
    const ts = Date.now();
    return {
      addAt: ts,
      customForm,
      basePrice: product.pay.payPrice,
      data: { ...product },
      eventId: product.eventId || null,
      id: product.id,
      price: product.pay.payPrice,
      priceDiscount: 0,
      priceReference: product.pay.payPriceReference,
      saleAmount: amount,
      shop: product.storeId,
      tax: product.pay.tax || 0,
      type: 'product',
    };
  }

}
