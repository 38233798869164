import { Pipe, PipeTransform } from '@angular/core';
import { toArray } from 'lodash';
import { map } from 'rxjs/operators';
import { ServicesService } from '../../service/services/services.service';

@Pipe({
  name: 'priceEntityServiceDiscount'
})
export class PriceEntityServicePipe implements PipeTransform {

  constructor(
    private servicesService: ServicesService,
  ) { }

  public transform(value: number, eventId: string, entitysId) {
    const entityId = toArray(entitysId);
    return this.servicesService.getServiceById(eventId, entityId[0].id).pipe(
      map(service => {
        if (service) {
          const modality = service.modality ? toArray(service.modality) : null;
          if (modality) {
            return modality.map(item => {
              return item.payPricePerBlock - value;
            });
          }
        }
      })
    );
  }

}
