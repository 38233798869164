import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from '@app/env';
import { isValidEventId } from '@helper/utils';
import * as firebase from 'firebase/app';
import { consoleError } from '../../shared/helper/console.helper';
import { take } from 'rxjs/operators';
import { AuthService } from '../../shared/service/auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { IUser } from 'rebus-models';

@Injectable()
export class OpenMicService {

  constructor(
    private afdb: AngularFireDatabase,
    private authService: AuthService,
    private http: HttpClient,
  ) { }

  public getRequest(eventId: String): Observable<any[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Event/${eventId}/OpenMicRequests`)
        .valueChanges();
    }
    return this.afdb.list<any>(`/Clients/${environment.clientId}/Interactivity/OpenMicRequests`)
      .valueChanges();
  }

  public async getCompaniesSnap(eventId: String, userId: string): Promise<Observable<any[]>> {
    if (isValidEventId(eventId)) {
      const snap = await this.afdb.list<any>(`/Clients/${environment.clientId}/Event/${eventId}/Companies`, ref => ref.orderByChild('userId').equalTo(userId)).query.once('value');
      return snap.val();
    }
    const snap = await this.afdb.list<any>(`/Clients/${environment.clientId}/Interactivity/Companies`, ref =>
      ref.orderByChild('userId').equalTo(userId)).query.once('value');
    return snap.val();
  }

  public getCompanyConfig(eventId: String): Observable<any> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<any>(`/Clients/${environment.clientId}/Event/${eventId}/CompanyConfig`)
        .valueChanges();
    }
    return this.afdb.object<any>(`/Clients/${environment.clientId}/Interactivity/CompanyConfig`)
      .valueChanges();
  }

  public getCompanyById(CompanyId: String, eventId: String): Observable<any> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<any>(`/Clients/${environment.clientId}/Event/${eventId}/Companies/${CompanyId}`)
        .valueChanges();
    }
    return this.afdb.object<any>(`/Clients/${environment.clientId}/Interactivity/Companies/${CompanyId}`)
      .valueChanges();
  }

  public createRequest(
    user: IUser,
    eventId: string,
    id: string = '') {

    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;

    const data = {
      id,
      created_at,
      userInfo: user,
      state: false,
      userId: user._id,
      isLive: false
    }

    let reference = `/Clients/${environment.clientId}/Interactivity/OpenMicRequests`;

    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/OpenMicRequests`;
    }

    const result = this.afdb.object(`${reference}/${id}`).update(data).then(() => data)
      .catch((error) => {
        consoleError('Whooops, something happen', error);
      });
    return result;
  }

  public cancelRequest(request, eventId) {
    let reference = `/Clients/${environment.clientId}/Interactivity/OpenMicRequests`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/OpenMicRequests`;
    }
    return this.afdb.object(`${reference}/${request.id}`).remove();
  }

  public acceptRequest(request, eventId) {
    let reference = `/Clients/${environment.clientId}/Interactivity/OpenMicRequests`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/OpenMicRequests`;
    }
    request.updatedAt = new Date().getTime();
    request.state = true;
    return this.afdb.object(`${reference}/${request.id}`).update(request);
  }

  public selectUserToLiveFalse(request, eventId) {
    let reference = `/Clients/${environment.clientId}/Interactivity/OpenMicRequests`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/OpenMicRequests`;
    }
    return this.afdb.object(`${reference}/${request.id}`).update({ isLive: false });
  }

  public selectUserToLiveTrue(request, eventId) {
    let reference = `/Clients/${environment.clientId}/Interactivity/OpenMicRequests`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/OpenMicRequests`;
    }
    return this.afdb.object(`${reference}/${request.id}`).update({ isLive: true });
  }

}
