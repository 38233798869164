import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { NavController, ModalController, IonSlides } from '@ionic/angular';
import * as designSelectors from '../../../shared/state/design/design.reducer';
import { SurveyService } from '../../services/survey.service';
import { get, toArray } from 'lodash';
import { ActivatedRoute } from '@angular/router';
import { tap, take, map } from 'rxjs/operators';
import { ISurvey, ISurveyUserAnswer, IUser, ISurveyAnswer } from 'rebus-models';
import { consoleError } from 'src/app/modules/shared/helper/console.helper';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { getAuthUID } from '@state/auth';
import { UtilitiesService } from '@service/utilities';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-survey',
  templateUrl: './survey.page.html',
  styleUrls: ['./survey.page.scss'],
})
export class SurveyPage implements OnInit {
  @ViewChild('slides') public slides: IonSlides;
  @Input() public eventId: string;
  @Output() public evOnClose = new EventEmitter<any>();

  public design$: Observable<any>;
  public activeSurvey$: Observable<string>;
  public survey$: Observable<any>;
  public userSurvey$: Observable<ISurveyAnswer[]>;
  public slideOpts: any = { allowTouchMove: false };
  public buttonNext = 'Continuar';
  public buttonPrev = 'Atrás';
  public surveyId: string;
  public userId: string;
  public idxSurvey = 1;
  public blSurveyStarted = false;
  public isFinishSurvey = false;
  public dataAnswer: any[];
  public numQuestions: number;
  public totalAnswerUsers: number;
  public userHasRecord: boolean;
  public userAnswerSurvey: ISurveyUserAnswer;
  public title = { es: 'Nueva encuesta activa', en: 'New active survey' };
  private prevTitle: string = '';

  @Input() public showBtnNextQuestion = true;

  constructor(
    public navCtrl: NavController,
    public surveyService: SurveyService,
    private route: ActivatedRoute,
    private store: Store<{}>,
    public modalCtrl: ModalController,
    private notiService: NotificationsService,
    private ut: UtilitiesService,
    private titleService: Title
  ) {}

  public async ngOnInit() {
    this.prevTitle = this.titleService.getTitle();
    this.setTitleDocument();
    this.design$ = this.store.select(designSelectors.getDesign);
    this.userId = await this.store.select(getAuthUID).pipe(take(1)).toPromise();
    this.activeSurvey$ = this.surveyService.getActiveSurvey(this.eventId).pipe(
      tap(async (survey) => {
        this.surveyId = survey;
        this.survey$ = this.surveyService.getSurvey(survey).pipe(
          map((survey: any) => {
            const questions = toArray(survey.questions).filter((question) => question.isVisible !== 'false');
            return {
              ...survey,
              questions,
            };
          }),
          tap((dataSurvey) => (this.numQuestions = toArray(dataSurvey.questions).length))
        );
        this.userAnswerSurvey = await this.surveyService.getSurveyByUserSnap(this.userId, this.eventId, survey);
        this.calculateUserHasRecord(this.userAnswerSurvey);
      })
    );
  }

  private setTitleDocument() {
    this.ut.calculateTitleName(of({ title: this.title }), 'interactivity');
  }

  public async getNumberAnswer() {
    const answerSurveys = await this.surveyService.getSurveyAnswerSnap(this.surveyId, this.eventId);
    this.totalAnswerUsers = toArray(answerSurveys).length;
  }

  public calculateLabelSurvey(): string {
    if (this.userHasRecord) {
      return 'Ver mis respuestas';
    } else {
      return 'Ir a la encuesta';
    }
  }

  public saveAnswer(answer) {
    this.dataAnswer = { ...this.dataAnswer, [answer.questionId]: { ...answer } };
  }

  public calculateUserHasRecord(userAnswerSurvey) {
    if (userAnswerSurvey) {
      this.userHasRecord = true;
    } else {
      this.userHasRecord = false;
    }
  }

  public onNext(type: string, surveyId: string) {
    switch (type) {
      case 'Finalizar':
        this.handleFinishSurvey(surveyId);
        break;
      case 'Continuar':
        this.handleNextSurvey();
        break;
    }
  }

  public handleFinishSurvey(surveyId: string) {
    if (this.userHasRecord) {
      this.getNumberAnswer();
      this.isFinishSurvey = true;
    } else {
      const arrayAnswer = toArray(this.dataAnswer);
      if (!arrayAnswer[this.idxSurvey - 1] || arrayAnswer[this.idxSurvey - 1].answerUser.length === 0) {
        this.notiService.showRebusToast('Debes de responder la pregunta', 'Warning', false, null, null, 'survey');
      } else {
        this.showBtnNextQuestion = false;
        this.surveyService
          .saveUserSurvey(this.eventId, this.dataAnswer, this.userId, surveyId)
          .then((result) => {
            this.notiService.showRebusToast(
              'Encuesta respondida correctamente',
              'Success',
              false,
              null,
              null,
              'survey'
            );
            this.showBtnNextQuestion = true;
            this.getNumberAnswer();
            this.isFinishSurvey = true;
          })
          .catch((error) => {
            consoleError('error creando encuesta', error);
            this.showBtnNextQuestion = true;
          });
      }
    }
  }

  public handleNextSurvey() {
    if (this.userHasRecord) {
      this.slides.slideNext();
      this.onChangeSurvey();
    } else {
      const arrayAnswer = toArray(this.dataAnswer);
      if (!arrayAnswer[this.idxSurvey - 1] || arrayAnswer[this.idxSurvey - 1].answerUser.length === 0) {
        this.notiService.showRebusToast('Debes de responder la pregunta', 'Warning', false, null, null, 'survey');
      } else {
        this.slides.slideNext();
        this.onChangeSurvey();
      }
    }
  }

  public onPrev() {
    this.slides.slidePrev();
    this.onChangeSurvey();
  }

  public onChangeSurvey() {
    this.slides.getActiveIndex().then((idx) => {
      this.idxSurvey = idx + 1;
      this.buttonNext = this.idxSurvey === this.numQuestions ? 'Finalizar' : 'Continuar';
    });
  }

  public onStart() {
    // go to questions
    this.blSurveyStarted = true;
  }

  public onChangeMultipleQuestion(value) {
    this.showBtnNextQuestion = value;
  }

  public onClose() {
    this.modalCtrl.dismiss();
    this.evOnClose.emit();
    this.titleService.setTitle(this.prevTitle)
  }
}
