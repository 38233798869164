import { Component, Output, EventEmitter, Input } from '@angular/core';
import { IDesign, IUser } from 'rebus-models';

@Component({
  selector: 'app-networking-widget',
  templateUrl: './networking-widget.component.html',
  styleUrls: ['./networking-widget.component.scss'],
})
export class NetworkingWidgetComponent {

  @Output() public evSendRequest = new EventEmitter();
  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public views: number;
  @Input() public user: IUser;

  @Output() public evOnAddUser = new EventEmitter<any>();

  public addUser(userId: string) {
    this.evOnAddUser.emit(userId);
  }

}
