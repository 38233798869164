import { Component, Input, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { groupBy } from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChatNetworkingFastComponent } from 'src/app/modules/networking/pages/chat-networking-fast/chat-networking-fast.component';
import { NetworkingService } from 'src/app/modules/networking/services/networking.service';

@Component({
  selector: 'app-modal-pending-messages-networking',
  templateUrl: './modal-pending-messages-networking.component.html',
  styleUrls: ['./modal-pending-messages-networking.component.scss'],
})
export class ModalPendingMessagesNetworkingComponent implements OnInit {

  public messages$: Observable<any>;

  @Input() public userId: any;
  @Input() public design: any;
  @Input() public eventId: any;

  public chatUser: Observable<any[]>;
  public messagesNotificationUser: any;

  constructor(
    public modalCtr: ModalController,
    private networkingService: NetworkingService,
    private navCtrl: NavController,
  ) { }

  ngOnInit() {
    this.getPendingMessageByUser()
  }

  getPendingMessageByUser() {
    this.messages$ = this.networkingService.getChatNotificationsByUser(this.userId)
      .pipe(
        map((messages: any) => {
          if (messages) {
            this.messagesNotificationUser = messages
            const messagesOrder = groupBy(messages, m => m.userIdSenderMessage);
            return Object.keys(messagesOrder).map(key => {
              return { key, info: messagesOrder[key][0].infoUser, value: messagesOrder[key] };
            });
          }
        })
      );
  }

  public async onOpenChat(userIdSelected, userSelectedBasicInformation) {
    const modal = await this.modalCtr.create({
      component: ChatNetworkingFastComponent,
      componentProps: { userId: this.userId, userIdSelected, userSelectedBasicInformation, design: this.design, messagesNotificationUser: this.messagesNotificationUser },
      cssClass: 'modal-pending-message',
      animated: true,
      backdropDismiss: true,
      showBackdrop: false,
    });
    await modal.present();
  }

  public getImageUser(imageUser) {
    if (imageUser) {
      return `url('${imageUser}')`;
    } else {
      return `url('https://forwardsummit.ca/wp-content/uploads/2019/01/avatar-default.png')`;
    }
  }

  onGoNetworking() {
    this.modalCtr.dismiss()
    this.navCtrl.navigateForward(['/networking/', this.eventId || '--']);
  }

}
