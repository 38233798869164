import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from '@app/env';
import { Store } from '@ngrx/store';
import { IQuestion, IQuestionConfig, IQuestionComments, IQuestionLikes } from 'rebus-models';

import { isValidEventId } from '@helper/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../../shared/service/auth.service';

@Injectable()
export class QuestionService {

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<{}>,
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  public getQuestion(eventId: String, itemPerPage): Observable<IQuestion[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<IQuestion>(`/Clients/${environment.clientId}/Event/${eventId}/Question`,
        ref => ref.orderByChild('createdAt')
          .limitToLast(itemPerPage)
      )
        .valueChanges();
    }
    return this.afdb.list<IQuestion>(`/Clients/${environment.clientId}/Interactivity/Question`,
      ref => ref.orderByChild('createdAt'))
      .valueChanges();
  }

  public getQuestionConfig(eventId: String): Observable<IQuestionConfig> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<IQuestionConfig>(`/Clients/${environment.clientId}/Event/${eventId}/QuestionConfig`)
        .valueChanges();
    }
    return this.afdb.object<IQuestionConfig>(`/Clients/${environment.clientId}/Interactivity/QuestionConfig`)
      .valueChanges();
  }

  public getQuestionById(QuestionId: String, eventId: String): Observable<IQuestion> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<IQuestion>(`/Clients/${environment.clientId}/Event/${eventId}/Question/${QuestionId}`)
        .valueChanges();
    }
    return this.afdb.object<IQuestion>(`/Clients/${environment.clientId}/Interactivity/Question/${QuestionId}`)
      .valueChanges();
  }

  public createQuestion(question: IQuestion, eventId: string): Promise<void> {
    question = { ...question, id: this.afdb.createPushId() };
    eventId ? question = { ...question, eventId: eventId } : null

    if (isValidEventId(eventId)) {
      return this.afdb.object(`Clients/${environment.clientId}/Event/${eventId}/Question/${question.id}`).
        update(question);
    }
    return this.afdb.object(`Clients/${environment.clientId}/Interactivity/Question/${question.id}`).
      update(question);
  }

  public toggleLikeQuestion(questionId: string, like: IQuestionLikes,
    counterLike: string, eventId: string): Promise<void> {
    const likeToSend = { ...like };
    const isLiked = !!likeToSend.id;
    if (!isLiked) {
      likeToSend.id = this.afdb.createPushId();
    }
    const eventRoute = `Clients/${environment.clientId}/Event/${eventId}/QuestionLikes/${likeToSend.id}`;
    const likesEventRoute = `Clients/${environment.clientId}/Event/${eventId}/Question/${questionId}/stats/counterLike`;
    const clientRoute = `Clients/${environment.clientId}/Interactivity/QuestionLikes/${likeToSend.id}`;
    const likesClientRoute = `Clients/${environment.clientId}/Interactivity/Question/${questionId}/stats/counterLike`;

    if (!isLiked) {

      if (isValidEventId(eventId)) {
        return this.handleLike(eventRoute, counterLike, likesEventRoute, likeToSend);
      }
      return this.handleLike(clientRoute, counterLike, likesClientRoute, likeToSend);


    } else {

      if (isValidEventId(eventId)) {
        return this.handleLike(eventRoute, counterLike, likesEventRoute);
      }
      return this.handleLike(clientRoute, counterLike, likesClientRoute);
    }
  }


  public isLiked(questionId: string, userId: string, eventId: String) {
    if (isValidEventId(eventId)) {
      return this.afdb.object(`/Clients/${environment.clientId}/Event/${eventId}/QuestionLikes/`)
        .query.ref.orderByChild('userId_questionId').equalTo(`${userId}_${questionId}`);
    }
    return this.afdb.object(`/Clients/${environment.clientId}/Interactivity/QuestionLikes/`)
      .query.ref.orderByChild('userId_questionId').equalTo(`${userId}_${questionId}`);
  }

  public createComment(questionId: string, comment: IQuestionComments, counterComment: string, eventId: string): Promise<void> {
    comment = { ...comment, id: this.afdb.createPushId() };

    if (isValidEventId(eventId)) {
      return this.afdb.object(`Clients/${environment.clientId}/Event/${eventId}/QuestionComments/${comment.id}`).
        update(comment).then(val => {
          this.afdb.object(`Clients/${environment.clientId}/Event/${eventId}/Question/${questionId}/stats/counterComment`)
            .set(counterComment);
        });
    }
    return this.afdb.object(`Clients/${environment.clientId}/Interactivity/QuestionComments/${comment.id}`).
      update(comment).then(val => {
        this.afdb.object(`Clients/${environment.clientId}/Interactivity/Question/${questionId}/stats/counterComment`)
          .set(counterComment);
      });
  }

  public getComments(questionId: string, eventId: String): Observable<IQuestionComments[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<IQuestionComments>(`/Clients/${environment.clientId}/Event/${eventId}/QuestionComments/`,
        question => question.orderByChild('questionId').equalTo(questionId))
        .valueChanges();
    }
    return this.afdb.list<IQuestionComments>(`/Clients/${environment.clientId}/Interactivity/QuestionComments/`,
      question => question.orderByChild('questionId').equalTo(questionId))
      .valueChanges();
  }

  public getCommentById(commentId: String, eventId: String): Observable<IQuestionComments> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<IQuestionComments>(`/Clients/${environment.clientId}/Event/${eventId}/IQuestionComments/${commentId}`)
        .valueChanges();
    }
    return this.afdb.object<IQuestionComments>(`/Clients/${environment.clientId}/Interactivity/IQuestionComments/${commentId}`)
      .valueChanges();
  }

  public getQuestionWriter(question: IQuestion): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get(`${environment.backend}/users/${environment.clientId}/${question.userId}`,
          { headers });
      })
    );
  }

  public getCommentWritter(comment: IQuestionComments): Observable<any> {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get(`${environment.backend}/users/${environment.clientId}/${comment.userId}`,
          { headers });
      })
    );
  }

  private handleLike(route, counterLike, likesRoute, like = null) {
    if (like) {
      return this.afdb.object(route).
        set(like).then(val => this.afdb.object(likesRoute).set(counterLike));
    }
    return this.afdb.object(route).
      remove().then(val => this.afdb.object(likesRoute).set(counterLike));
  }
}
