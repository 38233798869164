import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { getColor } from '../helper/color.helper';

@Pipe({ name: 'color' })
@Injectable()
export class ColorPipe implements PipeTransform {
  public transform(value: string): string {
    return getColor(value);
  }
}
