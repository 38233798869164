import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, ICoupon } from 'rebus-models';

@Component({
  selector: 'app-highlight-coupon',
  templateUrl: './highlight-coupon.component.html',
  styleUrls: ['./highlight-coupon.component.scss']
})
export class HighlightCouponComponent {

  @Input() public design: IDesign;
  @Input() public data: ICoupon;
  @Input() public match: number;
  @Input() public userId: string;
  @Input() public eventId: string;

  @Output() public evOnClickViewMoreCoupon = new EventEmitter<string>();
  @Output() public evOnClickAcquireCoupon = new EventEmitter<ICoupon>();

  public viewMore() {
    this.evOnClickViewMoreCoupon.emit(this.data.id);
  }

  public acquireCoupon() {
    this.evOnClickAcquireCoupon.emit(this.data);
  }

}
