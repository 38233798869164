import { Action } from '@ngrx/store';

export const LOAD_MENU             = '[ MENU ] Carga la configuracion del menu específico';
export const LOAD_MENU_SUCCESS     = '[ MENU ] Carga la configuracion del menu específico correctamente';
export const LOAD_MENU_ERROR       = '[ MENU ] Carga la configuracion del menu específico con error';


export class LoadMenuAction implements Action {
  public readonly type = LOAD_MENU;
  constructor(public payload: string) { }
}

export class LoadMenuSuccessAction implements Action {
  public readonly type = LOAD_MENU_SUCCESS;
  constructor(public payload: any) { }
}

export class LoadMenuErrorAction implements Action {
  public readonly type = LOAD_MENU_ERROR;
  constructor(public payload: any) { }
}

export type MenuActions =
  LoadMenuAction |
  LoadMenuSuccessAction |
  LoadMenuErrorAction;
