import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '@app/env';
import { getCookie } from '@helper/utils';
import { Store } from '@ngrx/store';
import { AuthService as SocialAuthService, FacebookLoginProvider, GoogleLoginProvider, SocialUser } from 'angularx-social-login';
import * as firebase from 'firebase/app';
import { get, has } from 'lodash';
import { from, Observable } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { consoleService } from '../helper/console.helper';
import { getAuthState } from '../state/auth/auth.reducer';

@Injectable()
export class AuthService {

  private fireAuth: any = null;

  constructor(
    private afauth: AngularFireAuth,
    private http: HttpClient,
    private socialAuthService: SocialAuthService,
    private store: Store<{}>

  ) { this.init(); }

  public getUser() {
    return this.fireAuth;
  }


  public loginAPI() {
    return this.http.post(
      `${environment.api_rebus}/v1/auth/login`, {
      'client': environment.clientId,
      'password': environment.clientId,
    });
  }

  public getLocalUser(): Observable<any> {
    return this.afauth.authState.pipe(
      map(user => {
        if (user) {
          if (has(user, 'uid')) {
            return user;
          }
        }
        throw ({ code: 401, message: 'Usuario local no encontrado' });
      })
    );
  }

  public getTokenUser() {
    return this.afauth.idToken;
  }

  public signInAnonymously(): Promise<any> {
    return this.afauth.auth.signInAnonymously();
  }

  public createAnonymousUser(uid: string) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this.http.post(`${environment.backend}/auth/anonymous/create`, { 'id': uid, 'client': environment.clientId }, { headers })
      .pipe(tap(res => consoleService('Create anonymous backend response', res)));
  }

  public signOut(): Promise<any> {
    return this.afauth.auth.signOut();
  }

  public signInCustom(customToken: string): Promise<any> {
    return this.afauth.auth.signInWithCustomToken(customToken);
  }

  public signInEmail(email: string, password: string): Observable<any> {
    return this.store.select(getAuthState).pipe(
      take(1),
      switchMap(authState =>
        this.http.post(`${environment.backend}/auth/email`, {
          clientId: environment.clientId,
          email,
          passwordHash: password,
          anonymous: authState.authUID,
          customLogin: 'customInformation.dni'
        }).pipe(tap(res => consoleService('Auth email response: ', res)))
      )
    );
  }

  public signInSocial(getProvider: 'facebook' | 'google' | 'linkedin'): Promise<any> {
    if (getProvider === 'linkedin') {
      return Promise.resolve(window.open(`https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${environment.socialLogin.linkedin}&redirect_uri=http://localhost:9012&state=aRandomString`, 'self'));
    }

    let provider: string;
    switch (getProvider) {
      case 'facebook':
        provider = FacebookLoginProvider.PROVIDER_ID;
        break;
      case 'google':
        provider = GoogleLoginProvider.PROVIDER_ID;
        break;
    }

    return this.socialAuthService.signIn(provider)
  }

  public signOutSocial(): Promise<any> {
    return this.socialAuthService.signOut();
  }

  public socialLogin(payload: SocialUser): Observable<any> {
    const clientId = environment.clientId;
    const data = {
      clientId,
      name: payload.name,
      provider: payload.provider,
      providerData: {
        name: payload.name,
        email: payload.email,
        image: payload.photoUrl,
        token: payload.authToken,
        idToken: payload.idToken,
      },
      basicInformation: {
        email: payload.email,
        name: payload.name,
      },
      interest: [], // FIXME: pendiente esta vuelta
    };

    return this.store.select(getAuthState).pipe(
      take(1),
      switchMap(authState =>
        this.http.post(`${environment.backend}/auth/social`, { ...data, anonymous: authState.authUID })
          .pipe(tap(res => consoleService('Auth social response: ', res)))
      )
    );
  }

  public emailLogin(email: string, passwordHash: string): Observable<any> {
    const clientId = environment.clientId;
    const data = { clientId, passwordHash, email };

    return this.store.select(getAuthState).pipe(
      take(1),
      switchMap(authState =>
        this.http.post(`${environment.backend}/auth/email`, { ...data, anonymous: authState.authUID })
          .pipe(tap(res => consoleService('Auth email response: ', res)))
      )
    );
  }

  public resetEmail(email: string): Observable<any> {
    const clientId = environment.clientId;
    const data = { clientId, email };
    return this.http.post(`${environment.backend}/auth/recovery`, data)
      .pipe(tap(res => consoleService('Reset password email response: ', res)));
  }

  public restoreEmail(token: string, passwordHash: string): Observable<any> {
    const data = { token, passwordHash };
    return this.http.post(`${environment.backend}/auth/restart`, data)
      .pipe(tap(res => consoleService('Restore password email response: ', res)));
  }

  public register(email: string, passwordHash: string, phone: string, terms: boolean, policy: boolean, name = null, interest = [], dni = null, category = null, company = null, position = null, loginCustom = "userByDNI", surname = null) {
    const clientId = environment.clientId;
    const data = {
      clientId,
      passwordHash,
      basicInformation: {
        email,
        phone,
        name,
        company,
        position
      },
      customInformation: {
        dni,
        category,
        location,
        surname
      },
      terms,
      policy,
      interest,
      loginCustom
    };

    return this.store.select(getAuthState).pipe(
      take(1),
      switchMap(authState =>
        this.http.post(`${environment.backend}/auth/form`, { ...data, anonymous: authState.authUID })
          .pipe(tap(res => consoleService('Register user response: ', res)))
      )
    );
  }

  public sendEmailCodeValidation(email) {
    const clientId = environment.clientId;
    return this.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        return this.http.post(`${environment.backend}/users/v1/validation/code`,
          {
            email,
            clientId
          }, { headers });
      })
    );
  }

  public sendOtpCodeValidation(phone) {
    return this.http.post(`https://v5.rebustech.io/v5/otp/send`,
      {
        "number": phone
      });
  }


  public validateOtpUserCode(codeValidator, phone) {
    const clientId = environment.clientId;
    return this.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        return this.http.post(`https://v5.rebustech.io/v5/otp/verify`,
          {
            "number": phone,
            "otp": codeValidator
          }, { headers });
      })
    );
  }

  public validateUserCode(codeValidator) {
    const clientId = environment.clientId;
    return this.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
        return this.http.post(`${environment.backend}/users/v1/validate/code`,
          {
            codeValidator,
            clientId
          }, { headers });
      })
    );
  }

  public validateAuthReCaptch(code) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`${environment.backend}/auth/validate/captcha/`,
      {
        code
      }
      , { headers }).toPromise();
  }

  public async createConnectionIncontactoAsambleas(user: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const request = {
      ...user.basicInformation,
      ...user.customInformation,
      userRebusId: user._id,
      eventId: environment.clientId,
    }

    request.dni = String(get(request, 'dni', null))
    return this.http.post("https://alcaldias.rebustech.io/assistant/save/integrationrebus/public", request, { headers }).toPromise()
  }

  public async authLogoutProcess(userId: string) {
    const identify = getCookie(`${environment.clientId}-COOKIE-TRACKING`)
    return await firebase.database().ref(`/Clients/${environment.clientId}/___connections/${userId}`).update({
      state: false,
      lastDisconnect: firebase.database.ServerValue.TIMESTAMP,
    }).then(() => {
      const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
      return this.http.post(`${environment.backend}/auth/logout`,
        { userId, identify }, { headers }).toPromise()
    }).then(() => {
      window.localStorage.removeItem(`${environment.clientId}-OTP-CODE-VALIDATE-${userId}`)
    })
  }





  private init(): void {
    this.afauth.authState.subscribe(user => {
      if (user) {
        this.fireAuth = user;
      }
    });
  }

  public async createConnectionIncontacto(idUser, apiUrl) {
    const assembly = "eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJhZG1pbiIsImV4cCI6MTU4NTk2OTUyNX0.b69ETdbfSF46aEjXZfkvt7uIRYidKHKubuAc8Kb8afg9o2RE5M41wYg9zmSCjoHG1PSTPwfHtmgyr8EP7jgwzg"

    const headers = new HttpHeaders({ 'assembly': assembly, 'Content-Type': 'application/json' });
    return await this.http.post(`${apiUrl}`,
      { idUser }, { headers }).toPromise()
  }

}
