import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { PAGES } from './pages';
import { COMPONENTS } from './components';
import { InteractivtySharedModule } from '../interactivity/interactivity-shared.module';
import { SERVICES } from './services';
import { HeaderSharedModule } from '../header/header-shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScanQrTicketReaderComponent } from './components/scan-qr-ticket-reader/scan-qr-ticket-reader.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

@NgModule({
  declarations: [
    ...PAGES,
    ...COMPONENTS,
  ],
  imports: [
    SharedModule,
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderSharedModule,
    InteractivtySharedModule,
    ZXingScannerModule
  ],
  exports: [
    ...COMPONENTS,
  ],
  providers: [
    ...SERVICES
  ],
  entryComponents: [
    ScanQrTicketReaderComponent
  ],
})
export class ScanQrTicketSharedModule { }
