import { Component, OnInit, Input, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { IDesign } from 'rebus-models';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-person-networking',
  templateUrl: './person-networking.component.html',
  styleUrls: ['./person-networking.component.scss'],
})
export class PersonNetworkingComponent implements OnInit, OnChanges {

  @Input() public design: IDesign;
  @Input() public user: any;
  @Input() public users: any;
  @Input() public userP: string;
  @Output() public evOnSendNotification = new EventEmitter<any>();
  @Output() public evOnAcceptNotification = new EventEmitter<any>();
  @Output() public evOnOpenChat = new EventEmitter<any>();

  constructor(
    private modalCtrl: ModalController
  ) { }

  public ngOnInit() {
  }

  public onSendNotification(user) {
    this.evOnSendNotification.emit(user);
  }

  public onAcceptRequest(user, action) {
    this.evOnAcceptNotification.emit({ user, action });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.user) {
      this.user = changes.user.currentValue;
    }
  }

  public onOpenChat(userIdSelected, usersSegment) {
    this.evOnOpenChat.emit({ userIdSelected, usersSegment });
  }

  public getImageUser(imageUser) {
    if (imageUser) {
      return `url('${imageUser}')`;
    } else {
      return `url('https://forwardsummit.ca/wp-content/uploads/2019/01/avatar-default.png')`;
    }
  }

}
