import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { PAGES } from './pages';
import { COMPONENTS } from './components';
import { SERVICES } from './services';
import { InteractivtySharedModule } from '../interactivity/interactivity-shared.module';
import { HeaderSharedModule } from '../header/header-shared.module';
import { ModalPendingMessagesNetworkingComponent } from './pages/modal-pending-messages-networking/modal-pending-messages-networking.component';
import { ChatNetworkingFastComponent } from './pages/chat-networking-fast/chat-networking-fast.component';

@NgModule({
  declarations: [
    ...PAGES,
    ...COMPONENTS,
  ],
  imports: [
    SharedModule,
    CommonModule,
    IonicModule,
    InteractivtySharedModule,
    HeaderSharedModule
  ],
  exports: [
    ...PAGES,
    ...COMPONENTS,
  ],
  providers: [
    ...SERVICES
  ],
  entryComponents: [
    ModalPendingMessagesNetworkingComponent,
    ChatNetworkingFastComponent
  ]
})
export class NetworkingSharedModule { }
