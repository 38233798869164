import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { isObject } from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'translate_obj',
  pure: false
})

@Injectable()

export class TranslateObjectPipe implements PipeTransform {

  constructor(
    private translateServices: TranslateService,
  ) { }

  public transform(value): any {
    if (!value) {
      return value;
    }
    if (isObject(value)) {
      if (this.translateServices.getDefaultLang()) {
        if (value[this.translateServices.getDefaultLang()]) {
          return value[this.translateServices.getDefaultLang()];
        }
      }
      return value['es'] ? value['es'] : value['en'];
    }
    return value;
  }
}
