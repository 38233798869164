import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { UtilitiesService } from '@service/utilities';
import { getUserBasicInfo } from '@state/user';
import { IBasicInformationUser, IDesign, IQuestion, IQuestionComments } from 'rebus-models';
import { Observable } from 'rxjs';
import { consoleError } from 'src/app/modules/shared/helper/console.helper';
import { QuestionService } from '../../../services/question.service';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.page.html',
  styleUrls: ['./question-detail.page.scss'],
})
export class QuestionDetailPage implements OnInit {
  @Input() public questionId: string;
  @Input() public eventIdModal: string;
  @Input() public design: IDesign;
  @Input() public userId: string;
  @Output() public evOnClose = new EventEmitter<void>();
  @Output() public evOnReturn = new EventEmitter<void>();

  public comments$ = new Observable<any>();
  public design$: Observable<IDesign>;
  public question$: Observable<IQuestion>;
  public commentToSend: IQuestionComments;
  public commentText: string;
  public eventId: string;
  public userInfo$: Observable<IBasicInformationUser>;

  constructor(
    public navCtrl: NavController,
    private store: Store<{}>,
    private route: ActivatedRoute,
    private questionService: QuestionService,
    private ut: UtilitiesService
  ) {}

  public ngOnInit() {
    this.eventId = this.route.snapshot.paramMap.get('eventId') || this.eventIdModal;
    this.comments$ = this.questionService.getComments(this.questionId, this.eventId);
    this.userInfo$ = this.store.select(getUserBasicInfo);
    this.question$ = this.questionService.getQuestionById(this.questionId, this.eventId);
  }

  public onClose() {
    this.evOnClose.emit();
  }

  public onReturn() {
    this.evOnReturn.emit();
  }

  public onComment(event: any, question: IQuestion) {
    if ((event.keyCode === 13 && !event.shiftKey) || event === 'Click') {
      if (event !== 'Click') {
        event.preventDefault();
      }
      this.commentToSend = {
        id: '',
        userId: this.userId,
        questionId: this.questionId,
        message: this.commentText,
        createdAt: new Date().getTime(),
        userId_questionId: `${this.userId}_${this.questionId}`,
      };
      const comments = parseInt(question.stats.counterComment, 0) + 1;
      if (this.commentText.trim().length > 1) {
        this.questionService
          .createComment(this.questionId, this.commentToSend, comments.toString(), question.eventId)
          .then(
            () => {
              this.commentText = '';
              this.ut.sendGtagData(`Respuesta a Pregunta`);
            },
            (err) => {
              return consoleError('error al comentar', [err]);
            }
          );
      }
    }
  }
}
