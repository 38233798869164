import { Component, OnInit, Input } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-guest-record',
  templateUrl: './guest-record.component.html',
  styleUrls: ['./guest-record.component.scss']
})
export class GuestRecordComponent implements OnInit {

  @Input() public design: IDesign;

  constructor() { }

  ngOnInit() {
  }

}
