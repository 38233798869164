import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';

import { IDesign, IStand } from 'rebus-models';
import { Observable } from 'rxjs';
import { AIService } from 'src/app/modules/shared/service/ai.service';

@Component({
  selector: 'app-stand-item-search-result',
  templateUrl: './stand-item-search-result.component.html',
  styleUrls: ['./stand-item-search-result.component.scss']
})
export class StandItemSearchResultComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public isAdmin = false;
  @Input() public moduleNameFromView: string;
  @Input() public eventId: string;
  @Input() public stand: IStand;
  @Input() public userId: string;
  @Output() public evOnClickItem = new EventEmitter<void>();

  public match$: Observable<any>;

  constructor(
    private router: NavController,
    private navCtrl: NavController,
    private aiService: AIService
  ) { }

  public ngOnInit() {
    this.match$ = this.aiService.getRecommendationsForUser(this.userId, 'Stand', null, this.eventId, this.stand.id)
  }

  public viewStand() {
    this.navCtrl.navigateForward(['stand', 'detail', this.stand.id, this.stand.eventId || this.eventId]);
    this.evOnClickItem.emit();
  }


}
