import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe',
  pure: true
})

@Injectable()
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  public transform(style, prop) {
    return this.sanitizer.bypassSecurityTrustStyle(`${prop}:${style}`);
  }
}

