import { Component, OnInit, Input } from '@angular/core';
import { TranslateObjectPipe } from '../../pipes/translate-obj.pipe';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'modal-share-item',
  templateUrl: './modal-share-item.component.html',
  styleUrls: ['./modal-share-item.component.scss'],
})
export class ModalShareItemComponent implements OnInit {

  @Input() public nameItem: string | { en: string, es: string };
  @Input() public labelShareItem: string;
  @Input() public customUrl;

  constructor(
    public translateObjPipe: TranslateObjectPipe,
    public modalCtrl: ModalController
  ) { }

  public async ngOnInit() {
    if (this.nameItem) {
      if (typeof this.nameItem === 'string') {
        this.nameItem = this.nameItem;
      } else {
        this.nameItem = await this.translateObjPipe.transform(this.nameItem);
      }
    }
  }

  public share(type: 'whatsapp' | 'facebook' | 'twitter' | 'linkedin') {
    const url = this.customUrl;

    switch (type) {
      case 'linkedin':
        window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${url}&utm_source=LinkedIn&source=Rebus+Technology`, '_blank');
        break;
      case 'whatsapp':
        window.open(`https://api.whatsapp.com/send?text=${this.nameItem}%2C%20%0A${url}`, '_blank');
        break;
      case 'facebook':
        (<any>window).FB.ui({
          method: 'share',
          href: url
        }, function (response) { });
        break;
      case 'twitter':
        window.open(`http://twitter.com/share?text=${this.nameItem}&url=${url}&via=Rebus`, '_blank');
        break;
    }
  }

}
