export enum SuggestionTypes {
  product_list = 'Products',
  ticket_list = 'Tickets',
  service_list = 'Services',
  event_list = 'Events',
  coupon_list = 'Coupons',
  stand_list = 'Stand',
  staff_list = 'Staff',
  news_list = 'News',
  sponsor_list = 'Sponsor'
}

export enum SaleTypes {
  product_sale = 'Products',
  ticket_sale = 'Tickets',
  service_sale = 'Services'
}

export enum SuggestionForUserNetowrking {
  networking_suggestions = 'Users',
}


export enum FooterModes {
  product_list = 'product',
  ticket_list = 'ticket',
  service_list = 'service',
  event_list = 'event',
  coupon_list = 'coupon',
  stand_list = 'stand',
  staff_list = 'staff',
  news_list = 'news',
  sponsor_list = 'sponsor'
}
