import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-load-skeleton',
  templateUrl: './load-skeleton.component.html',
  styleUrls: ['./load-skeleton.component.scss']
})
export class LoadSkeletonComponent implements OnInit {
  @Input() public structure: string;
  @Input() public items = 4;
  @Input() public action: boolean;
  @Input() public title: boolean;
  @Input() public size = 'small';

  public els;

  public ngOnInit() {
    this.els = new Array(this.items).fill(0);
  }
}
