import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { ProductService } from '../../product/services/product.service';
import { toArray } from 'lodash';
import { IProduct } from 'rebus-models';

@Pipe({
  name: 'priceEntityProduct'
})

export class PriceEntityProductDiscountPipe implements PipeTransform {

  constructor(
    private productService: ProductService,
  ) { }

  public transform(value: { [key: string]: { id: string } }, eventId: string): Observable<IProduct> {
    const entityId = toArray(value);
    return this.productService.getProductById(eventId, entityId[0].id);
  }
}
