import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { IEvent } from 'rebus-models';

@Component({
  selector: 'app-highlight-event',
  templateUrl: './highlight-event.component.html',
  styleUrls: ['./highlight-event.component.scss']
})
export class HighlightEventComponent {

  @Input() public design: IDesign;
  @Input() public data: IEvent;
  @Input() public match: number;

  @Output() public evOnClickViewMoreEvent = new EventEmitter<IEvent>();

  public viewMore() {
    this.evOnClickViewMoreEvent.emit(this.data);
  }

}
