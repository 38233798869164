import { Directive, Input, ElementRef, Renderer2, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import { lazySizes } from 'lazysizes';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[lazyload]',
  exportAs: 'lazyload'
})
export class LazyloadDirective implements OnInit, OnChanges {

  @Input('lazyload') public imgSrc: string;
  @Input() public tmpImage: string;

  public img: string;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  public ngOnInit() {
    this.img = this.imgSrc;
    this.initLazyLoading();
    this.setAttributes();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.imgSrc) {
      this.imgSrc = changes.imgSrc.currentValue;
      this.img = this.imgSrc;
      this.initLazyLoading();
      this.setAttributes();
    }
  }

  private initLazyLoading() {
    if (lazySizes) {
      lazySizes.init();
    }
  }

  private setAttributes() {
    this.renderer.addClass(this.el.nativeElement, 'lazyload');
    if (this.el.nativeElement.localName === 'img') {
      this.setImgSrc();
    } else {
      this.setElementBackgroundImage();
    }
  }

  private setImgSrc() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-src', this.img);
    if (this.tmpImage) {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.tmpImage);
    }
  }

  private setElementBackgroundImage() {
    this.renderer.setAttribute(this.el.nativeElement, 'data-bg', this.img);
    if (this.tmpImage) {
      this.renderer.setStyle(this.el.nativeElement, 'background-image', `url(${this.tmpImage})`);
    }
  }
}