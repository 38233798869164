import { Component, ElementRef, Input, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@app/env';
import { MenuController, ModalController, NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { UtilitiesService } from '@service/utilities';
import { getClientCategories } from '@state/config';
import { getDesign } from '@state/design';
import { getUserState } from '@state/user';
import { groupBy, toArray } from 'lodash';
import { IDesign } from 'rebus-models';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/shared/service/auth.service';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { consoleError } from '../../../shared/helper/console.helper';
import { RegisterAction } from '../../../shared/state/auth/auth.actions';
import { CustomValidators } from '../../../shared/validators/customValidators';
import { TermsServicePage } from '../../pages/terms/terms-service/terms-service.page';


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {

  @ViewChild('recaptcha') recaptchaElement: ElementRef;
  @Input() public authConfig: any;
  @Input() public changeSegment: any;

  public design$: Observable<IDesign>;
  public categoryInterest$: Observable<any>;
  public form: FormGroup;
  public submitAttempt = false;
  public redirectTo: string = null;
  public activatedUserSubscription$: Subscription;
  public activatedRouteSubscription$: Subscription;
  public isAuthenticated = false;
  public interest$: Observable<any>;
  public reCaptch = false;

  constructor(
    private store: Store<{}>,
    private fb: FormBuilder,
    private menuCtrl: MenuController,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private modalController: ModalController,
    private notiService: NotificationsService,
    private authService: AuthService,
    private elRef: ElementRef
  ) { }

  public ngOnInit() {
    this.interest$ = this.store.select(getClientCategories).pipe(
      map(interest => {
        const interestActive = toArray(interest).filter((i: any) => i.state)
        const interestOrder = groupBy(interestActive, (s: any) => s.category);
        const interestCategories = Object.keys(interestOrder).map(key => {
          return { label: key, value: key.toLowerCase() };
        });
        return interestCategories.filter(item => item.value !== 'undefined')
      })
    );

    this.menuCtrl.enable(false);
    this.design$ = this.store.select(getDesign);

    // this.activatedRouteSubscription$ = this.route.params.subscribe(
    //   (paramMap) => {
    //     this.redirectTo = paramMap["redirectTo"];
    //   }
    // );

    // Este observable se encarga de hacer una redireccion del usuario en caso finalizar registro o ya ternerlo anteriormente
    // this.activatedUserSubscription$ = this.store
    //   .select(getUserState)
    //   .subscribe((currentUser) => {
    //     if (currentUser.user) {
    //       if (!this.isAuthenticated) {
    //         this.isAuthenticated = true;
    //         this.navCtrl
    //           .navigateBack(this.redirectTo || environment.redirectTo)
    //           .then(() => this.menuCtrl.enable(true));
    //       }
    //     }
    //   });

    this.initForm();
    this.addRecaptchaScript();
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.addRecaptchaScript();
  }

  public addRecaptchaScript() {
    window['grecaptchaCallback'] = () => {
      this.renderReCaptch();
    }

    (function (d, s, id, obj) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) { obj.renderReCaptch(); return; }
      js = d.createElement(s); js.id = id;
      js.src = "https://www.google.com/recaptcha/api.js?onload=grecaptchaCallback&amp;render=explicit";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'recaptcha-jssdk', this));
  }

  renderReCaptch() {
    if (window['grecaptcha']) {
      if (this.recaptchaElement) {
        window['grecaptcha'].render(this.recaptchaElement.nativeElement, {
          'sitekey': environment.sitekKeyReCaptch,
          'callback': async (response) => {
            this.reCaptch = true;
            await this.authService.validateAuthReCaptch(response)
          }
        });
      } else {
        setTimeout(() => {
          window['grecaptcha'].render(document.getElementById("recaptcha"), {
            'sitekey': environment.sitekKeyReCaptch,
            'callback': async (response) => {
              this.reCaptch = true;
              await this.authService.validateAuthReCaptch(response)
            }
          });
        }, 400)
      }
    }
  }

  public onSubmitted({ value, valid }: { value: any; valid: boolean }) {
    this.submitAttempt = true;
    if (valid && this.reCaptch) {
      const data = this.parseFormData(value);
      this.form.get('___password_hash').setValue("")
      this.form.get('___password_conf').setValue("")
      this.store.dispatch(new RegisterAction(data));
    } else {
      this.notiService.showRebusToast('Debe diligenciar el formulario correctamente', 'Error', false);
      consoleError('Error en el form', this.form);
    }
  }

  public async onViewTermsService() {
    const modal = await this.modalController.create({
      component: TermsServicePage,
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'custom-modal'
    });

    return await modal.present();
  }

  public async onViewTermsPublicity() {
    // const modal = await this.modalController.create({
    //   component: TermsPublicityPage,
    //   animated: true,
    //   showBackdrop: true,
    //   backdropDismiss: true,
    //   cssClass: 'custom-modal'
    // });

    // return await modal.present();
    window.open('https://rebus-plugin-public.s3.amazonaws.com/politicas-colsubsidio.pdf', '_blank');
  }

  public ngOnDestroy() {
    if (this.activatedRouteSubscription$) { this.activatedRouteSubscription$.unsubscribe(); }
    if (this.activatedUserSubscription$) { this.activatedUserSubscription$.unsubscribe(); }
  }

  private initForm() {
    this.form = this.fb.group({
      ___user_email: new FormControl('', [Validators.required, Validators.email]),
      ___user_name: new FormControl('', [Validators.required, Validators.min(5)]),
      ___user_phone: new FormControl('', [Validators.required, Validators.min(5)]),
      ___confirm_phone: new FormControl('', [Validators.required, Validators.min(5)]),
      ___user_dni: new FormControl('', [this.authConfig.hiddenDni ? Validators.nullValidator : Validators.required]),
      ___password_hash: new FormControl('M8Gzw6yDVFEWkvfIL', [
        Validators.required,
        Validators.min(6),
        Validators.pattern(new RegExp(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{6,20}$/))
      ]),
      ___password_conf: new FormControl('M8Gzw6yDVFEWkvfIL', [Validators.required, Validators.min(6)]),
      ___terms_check: new FormControl(this.authConfig.terms, [Validators.requiredTrue]),
      ___policy__check: new FormControl(this.authConfig.terms, [Validators.requiredTrue]),
      ___categoryUser: new FormControl('', [this.authConfig.showCategoryRegister ? Validators.required : Validators.nullValidator]),
      ___company: new FormControl('', [Validators.nullValidator]),
      ___position: new FormControl('', [Validators.nullValidator]),
      ___surname: new FormControl('', [Validators.required])
    }, { validator: CustomValidators.Match('___user_phone', '___confirm_phone') });
  }

  private parseFormData(value: any) {
    return {
      email: value.___user_email,
      passwordHash: value.___password_hash,
      name: value.___user_name,
      phone: '+57' + value.___user_phone,
      dni: value.___user_dni,
      terms: value.___terms_check,
      policy: value.___policy__check,
      category: value.___categoryUser,
      company: value.___company,
      position: value.___position,
      surname: value.___surname
    };
  }
}
