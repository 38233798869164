import { InteractivityBarComponent } from './interactivity-bar/interactivity-bar.component';
import { SurveyMultipleComponent } from './survey-components/survey-multiple/survey-multiple.component';
import { SurveyUniqueComponent } from './survey-components/survey-unique/survey-unique.component';
import { SurveyRankingComponent } from './survey-components/survey-ranking/survey-ranking.component';
import { SurveyOpenComponent } from './survey-components/survey-open/survey-open.component';
import { SurveyFinishComponent } from './survey-components/survey-finish/survey-finish.component';
import { SideDialogComponent } from './side-dialog/side-dialog.component';
import { QuestionItemComponent } from './questions-components/question-item/question-item.component';
import { CommentItemComponent } from './questions-components/comment-item/comment-item.component';
import { MessageToastComponent } from './message-toast/message-toast.component';
import { ModalActionSurveyComponent } from './survey-components/modal-action-survey/modal-action-survey.component';
import { AgendaWidgetComponent } from './agenda-widget/agenda-widget.component';
import { OpenMicComponent } from './open-mic/open-mic.component';

export const COMPONENTS = [
  InteractivityBarComponent,
  SurveyMultipleComponent,
  SurveyUniqueComponent,
  SurveyRankingComponent,
  SurveyOpenComponent,
  SurveyFinishComponent,
  SideDialogComponent,
  QuestionItemComponent,
  CommentItemComponent,
  MessageToastComponent,
  ModalActionSurveyComponent,
  AgendaWidgetComponent,
  OpenMicComponent
];
