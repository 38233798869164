import { environment } from '@app/env';

export function consoleStore(message: string, ...params) {
  if (!environment.production) {
    console.log(`%c [${getTime()}] ↳ ${message}`, `background: ${colorConsole('one')}; color: white; display: block;`, params);
  }
}

export function consoleTracking(message: string, ...params) {
  if (!environment.production) {
    console.log(`%c [${getTime()}] @ ${message}`, `background: ${colorConsole('two')}; color: white; display: block;`, params);
  }
}

export function consoleService(message: string, ...params) {
  if (!environment.production) {
    console.log(`%c [${getTime()}] ✔ ${message}`, `background: ${colorConsole('three')}; color: white; display: block;`, params);
  }
}

export function consoleDebug(message: string, color: 'one' | 'two' | 'three' | 'four' | 'five' | 'default' = null, ...params) {
  if (!color) {
    color = 'default';
  }

  if (!environment.production) {
    console.log(`%c [${getTime()}] ⚒ ${message}`, `background: ${colorConsole(color)}; color: white; display: block;`, params);
  }
}

export function consolePush(message: string, color: 'one' | 'two' | 'three' | 'four' | 'five' | 'default' = null, ...params) {
  if (!color) {
    color = 'five';
  }
  console.log(`%c [${getTime()}] ⚒ ${message}`, `background: ${colorConsole(color)}; color: white; display: block;`, params);
}

export function consoleError(message: string, ...params) {
  console.log(`%c [${getTime()}] ✖ ${message}`, `background: #990000; color: white; display: block;`, params);
}

function getTime() {
  const date = new Date();
  return `${date.getMinutes()}:${date.getSeconds()}:${date.getMilliseconds()}`;
}

function colorConsole(color) {
  const obj = {
    one: '#4a148c',
    two: '#FF4000',
    three: '#808000',
    four: '#234400',
    five: '#ffae19',
    default: '#0B610B',
  };
  return obj[color];
}
