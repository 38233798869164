import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { getDesign } from '@state/design';
import { ModalController } from '@ionic/angular';
import { ScanQrCouponReaderComponent } from '../../components/scan-qr-coupon-reader/scan-qr-coupon-reader.component';

@Component({
  selector: 'app-scan-qr-coupon-result',
  templateUrl: './scan-qr-coupon-result.page.html',
  styleUrls: ['./scan-qr-coupon-result.page.scss'],
})
export class ScanQrCouponResultPage implements OnInit {

  public design$: Observable<any>;

  constructor(
    public modalCtrl: ModalController,
    private store: Store<{}>,
  ) { }

  ngOnInit() {
    this.design$ = this.store.select(getDesign);
  }

  async openScanCoupon() {
    this.modalCtrl.dismiss();
    const modal = await this.modalCtrl.create({
      component: ScanQrCouponReaderComponent,
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'custom-modal-scan-qr'
    });
    return await modal.present()
  }
}
