import { Component, OnInit } from '@angular/core';
import { IDesign, ICategory } from 'rebus-models';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { IUser } from 'rebus-models';
import { map, tap, filter } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getClientCategories } from '@state/config';
import { toArray, get } from 'lodash';
import { NavController, ModalController } from '@ionic/angular';
import { UpdateCurrentUserAction } from 'src/app/modules/shared/state/user/user.actions';
import { getUser, getUserState } from '@state/user';
import { getDesign } from '@state/design';
import { TermsServicePage } from '../../../pages/terms/terms-service/terms-service.page';
import { ActivatedRoute } from '@angular/router';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';

@Component({
  selector: 'app-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss']
})
export class ProfileInfoComponent implements OnInit {

  public design$: Observable<IDesign>;
  public user$: Observable<IUser>;

  public formProfileInfo: FormGroup;
  public formNetworkingInfo: FormGroup;
  public blNetworkingActive: boolean;
  public interest$: Observable<any>;
  public interestUser = [];
  public blBtnLoading$: Observable<boolean>;
  public redirect: string;

  constructor(
    private fb: FormBuilder,
    private store: Store<{}>,
    private navCtrl: NavController,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    private notiService: NotificationsService
  ) { }

  public ngOnInit() {
    this.redirect = get(this.route.snapshot, 'queryParams.redirect');
    this.blBtnLoading$ = this.store.select(getUserState)
      .pipe(map(data => data.loading));
    this.design$ = this.store.select(getDesign);
    this.user$ = this.store.select(getUser).pipe(
      // tslint:disable-next-line:max-line-length
      // Esta linea de codigo filtra usuarios inexistente para evitar errores pero se debe revisar como comportarse con el sistema de usuarios anonimos
      filter(user => !!user),
      tap(user => {
        this.blNetworkingActive = user.allowNetworking;
        this.interestUser = user.interest.length ? user.interest : Object.keys(user.interest);
        this.interest$ = this.store.select(getClientCategories).pipe(
          map(data => {
            return toArray(data).filter((item: any) => this.interestUser.includes(item.id));
          })
        );
        this.initForms(user);
      })
    );
  }

  public onEditInterest() {
    this.navCtrl.navigateForward([`/user/interest`, '/user/profile/--'])
  }

  public onSubmitProfile({ value, valid }: { value: any; valid: boolean }, user) {
    if (valid) {
      user = {
        ...user,
        basicInformation: {
          ...user.basicInformation,
          name: value.___user_name,
          email: value.___user_email,
          phone: value.___user_phone,
        },
        passwordHash: value.___user_pass ? value.___user_pass : null
      };
      this.updateUser(user);
    } else {
      this.notiService.showRebusToast('Debe diligenciar el formulario correctamente',
        'Error', false);
    }
  }

  public async onSubmitNetworking({ value, valid }: { value: any; valid: boolean }, user) {
    if (!this.blNetworkingActive) {
      user = {
        ...user,
        allowNetworking: this.blNetworkingActive
      };
      this.updateUser(user);

    } else if (valid) {
      user = {
        ...user,
        allowNetworking: this.blNetworkingActive,
        basicInformation: {
          ...user.basicInformation,
          company: value.___user_organization,
          position: value.___user_position,
          offer: value.___user_services,
          need: value.___user_interests,
        }
      };
      this.updateUser(user);
    } else {
      this.notiService.showRebusToast('Debe diligenciar el formulario correctamente',
        'Error', false);
    }
  }

  public updateUser(user) {
    this.store.dispatch(new UpdateCurrentUserAction({ user, redirectTo: this.redirect }));
    this.formProfileInfo.get('___user_pass').setValue('');
  }

  public toggle = (user) => {
    const control = this.formNetworkingInfo;
    const check = this.blNetworkingActive;
    user = { ...user, allowNetworking: check };
    check ? control.enable() : control.disable();
  }

  public validateInterestColor(category: ICategory, design: IDesign) {
    let color = design.firstColor;
    this.interestUser.map(key => {
      if (key === category.id) {
        color = 'light';
      }
    });
    return color;
  }

  public validateInterestBackground(category: ICategory, design: IDesign) {
    let color = 'light';
    this.interestUser.map(key => {
      if (key === category.id) {
        color = design.firstColor;
      }
    });
    return color;
  }

  public async onViewTermsService() {
    const modal = await this.modalCtrl.create({
      component: TermsServicePage,
      animated: true,
      showBackdrop: true,
      backdropDismiss: true,
      cssClass: 'custom-modal'
    });

    return await modal.present();
  }

  private initForms(user: IUser) {
    this.formProfileInfo = this.fb.group({
      ___user_name: new FormControl(user.basicInformation.name, [Validators.required]),
      ___user_email: new FormControl(user.basicInformation.email, [Validators.required, Validators.email]),
      ___user_phone: new FormControl(user.basicInformation.phone, [Validators.required]),
      ___user_pass: new FormControl('', [
        Validators.required,
        Validators.min(6),
        Validators.pattern(new RegExp(/^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{6,20}$/))
      ]),
      // blNotificationsActive: new FormControl(user.allowNotifications)
    });

    this.formNetworkingInfo = this.fb.group({
      ___user_organization: new FormControl(user.basicInformation.company, [Validators.required]),
      ___user_position: new FormControl(user.basicInformation.position, [Validators.required]),
      ___user_services: new FormControl(user.basicInformation.offer, [Validators.required]),
      ___user_interests: new FormControl(user.basicInformation.need, [Validators.required]),
    });

    const control = this.formNetworkingInfo;
    const check = this.blNetworkingActive;
    check ? control.enable() : control.disable();
  }
}
