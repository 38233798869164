import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, INews } from 'rebus-models';

@Component({
  selector: 'app-highlight-new',
  templateUrl: './highlight-new.component.html',
  styleUrls: ['./highlight-new.component.scss'],
})
export class HighlightNewComponent {

  @Input() public design: IDesign;
  @Input() public data: INews;
  @Input() public match: number;

  @Output() public evOnClickViewMoreNew = new EventEmitter<string>();

  constructor() { }

  public viewMore() {
    this.evOnClickViewMoreNew.emit(this.data.id);
  }

}
