import { SearchHistoryComponent } from './search-history/search-history.component';
import { HeaderComponent } from './header/header.component';
import { NotificationsHistoryComponent } from './notifications-history/notifications-history.component';
import { NotificationOptionsComponent } from './notification-options/notification-options.component';
import { ProfileDropdownComponent } from './profile-dropdown/profile-dropdown.component';

export const COMPONENTS = [
  SearchHistoryComponent,
  NotificationsHistoryComponent,
  NotificationOptionsComponent,
  ProfileDropdownComponent,
  HeaderComponent
];
