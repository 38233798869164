import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { IDesign } from 'rebus-models';
import { Observable, Subject } from 'rxjs';
import { SuggestionTypes } from '../../helper/widget.enum';
import { AIService } from '../../service/ai.service';
import { take, map, catchError } from 'rxjs/operators';
import { flatMap } from 'lodash';
import { NavController, ModalController } from '@ionic/angular';
import { EventService } from '../../service/event.service';
import { IEvent } from 'rebus-models';
import { EventPage } from 'src/app/modules/event/pages/event/event.page';
import { Store } from '@ngrx/store';
import { ClientService } from '../../service/client.service';
import { CouponService } from 'src/app/modules/coupon/services/coupon.service';
import { consoleError } from '../../helper/console.helper';
import { getUser } from '@state/user';
import { ProductService } from 'src/app/modules/product/services/product.service';
import { ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '@service/utilities';
import { getAuthUID } from '@state/auth';
import { NotificationsService } from '../../service/notifications.service';

@Component({
  selector: 'app-slides-items-suggestions',
  templateUrl: './slides-items-suggestions.component.html',
  styleUrls: ['./slides-items-suggestions.component.scss']
})
export class SlidesItemsSuggestionsComponent implements OnInit, OnDestroy {

  @ViewChild('slides') public slides: any;

  @Input() public design: IDesign;
  @Input() public configWidget: any;
  @Input() public eventId: string;

  public recommendations: any[];
  public data$: Observable<any>;

  public userId: string;


  private unsubscribe$ = new Subject<void>();

  constructor(
    public notificationService: NotificationsService,
    public aiService: AIService,
    public navCtrl: NavController,
    public eventService: EventService,
    public modalController: ModalController,
    private store: Store<{}>,
    private router: NavController,
    private clienteService: ClientService,
    private couponService: CouponService,
    private productService: ProductService,
    private route: ActivatedRoute,
    private utilities: UtilitiesService,
  ) { }

  public async ngOnInit() {
    this.userId = await this.store.select(getAuthUID).pipe(take(1)).toPromise();

    this.recommendations = await this.getUserRecommendations();

  }

  /**
   * Si un usuario no se encuentra autenticado se devuelve un arreglo vacío, no se muestran las sugerencias
   * @todo: Revisar cómo se pueden implementar recomendaciones para usuarios anónimos
   */
  public async getUserRecommendations() {
    const userIsLogged = await this.store.select(getUser).pipe(take(1)).toPromise();
    if (!userIsLogged) { return []; }
    const promises = this.configWidget.map(type => {
      const entityType: any = SuggestionTypes[type];
      const recomendations = this.aiService.getRecommendationsForUser(this.userId, entityType, 5, this.eventId);
      return recomendations.pipe(
        take(1),
        catchError(error => {
          consoleError('Error obteniendo recomendaciones por usuario en slides: ', error);
          return [];
        }),
        map(res => res.map(item => ({ ...item, type: entityType })))
      ).toPromise();
    });
    try {
      const result = await Promise.all(promises).then(list => flatMap(list));
      return result.filter(r => !!r);
    } catch (error) {
      consoleError('Ha ocurrido un error obteniendo sugerencias de usuario: ', error);
      return [];
    }
  }

  public async onViewMoreEvent(event: IEvent) {
    // const eventClassInstance = new EventPage(
    //   this.eventService,
    //   this.store,
    //   this.router,
    //   this.clienteService,
    //   this.modalController,
    //   this.route,
    //   this.utilities
    // );
    // eventClassInstance.onClickEvent(event);
  }

  public onViewMoreCoupon(couponId) {
    this.router.navigateForward(['/coupon/detail', couponId, this.eventId || '--']);
  }

  public onViewMoreTicket(ticketId) {
    this.router.navigateForward(['/ticket/detail', ticketId, this.eventId || '--']);
  }

  public onViewMoreStaff(staffId) {
    this.router.navigateForward(['/staff/detail', staffId, this.eventId || '--']);
  }

  public onViewMoreProduct(productId) {
    this.router.navigateForward(['/product/detail', productId, this.eventId || '--']);
  }

  public onAddProductCart(item: any) {
    this.productService.addProductToShoppingCart(item, item.pay.minQuantity);
  }

  public onViewMoreNew(newId) {
    this.router.navigateForward(['/news/detail', newId, this.eventId || '--']);
  }

  public onAcquireCoupon(coupon) {
    this.couponService.acquireCouponUser(
      this.eventId,
      this.userId,
      coupon
    );
  }

  public prevSlide() {
    this.slides.slidePrev();
  }

  public nextSlide() {
    this.slides.slideNext();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
