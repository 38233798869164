import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, pipe, from, of } from 'rxjs';
import { switchMap, catchError, mergeMap, tap, map, debounceTime, withLatestFrom } from 'rxjs/operators';

import * as userActions from './user.actions';
import { GetCurrentAuthAction } from '../auth/auth.actions';
import { AuthService } from '../../service/auth.service';
import { UserService } from '../../../user/service/user.service';
import { consoleStore, consoleError } from '../../helper/console.helper';
import { LoadingStopAction } from '../loading/loading.actions';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { IUser } from 'rebus-models';
import { getUser } from './user.reducer';
import { NavController } from '@ionic/angular';
import { getAuthUID } from '@state/auth';

/* declare const WidgetRebus: any; */

const code = '[ USER ]';

@Injectable()
export class UserEffects {

  @Effect()
  public GetCurrentUser$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.GET_CURRENT_USER),
    pipe(
      tap(() => consoleStore(`${code} Iniciando la carga de informacion del usuario`)),
      switchMap(() => this.authService.getLocalUser().
        pipe(
          switchMap(firebaseUser => {
            if (firebaseUser) {
              if (!firebaseUser.isAnonymous) {
                return from(this.userService.getUserById(firebaseUser.uid));
              }
            }
            throw ({ code: 401, message: 'No authenticated user exists' });
          }),
          mergeMap((user: IUser) => {
            consoleStore(`${code} Carga de usuario procesada`, user);
            // Aca se realiza la integracion con Plugin
            // Solo con integrarse con la autenticacion de WidgetRebus
            // Se realiza la autenticacion en todos los Widgets expuestos
            // if (user) {              
            //   let i = 0
            //   let interval = setInterval(() => {
            //     const selector = (document.querySelector("#app_plugin_rebus .container-btn-widget-rebus") as any)
            //     if (selector) {
            //       selector.style.display = 'block'
            //       console.log(`Preparando Widget para integracion No ${i++}`)
            //       if (i > 9) {
            //         console.log(`Se ejecutaron maximo ${i} intentos`, user._id)
            //         clearInterval(interval)
            //         interval = null
            //       }
            //       if (WidgetRebus.default.isPrepared()) {
            //         console.log('Autenticando con WidgetRebus ya preparado', user._id)
            //         WidgetRebus.default.setAccount(user._id)
            //         clearInterval(interval)
            //         interval = null
            //       }
            //     }
            //   }, 3000)
            // }
            return [
              new userActions.GetCurrentUserSuccessAction(user),
              new GetCurrentAuthAction(),
              new LoadingStopAction(),
            ];
          }),
          catchError(err => {
            consoleError(`${code} Carga de usuario con error (1)`, err);
            return [
              new userActions.GetCurrentUserErrorAction(err),
              new GetCurrentAuthAction(),
              new LoadingStopAction(),
            ];
          })
        )
      ),
      catchError(err => {
        consoleError(`${code} Carga de usuario con error (2)`, err);
        return [
          new userActions.GetCurrentUserErrorAction(err),
          new GetCurrentAuthAction(),
          new LoadingStopAction(),
        ];
      })
    )
  );

  @Effect()
  public SetLogout$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.SET_LOGOUT),
    debounceTime(450),
    switchMap(() => from(this.authService.signOut()).pipe(
      tap(() => consoleStore(`${code} Cierre de sesión ejecutada`)),
      map(() => {
        this.notiService.showRebusToast('Session successfully closed', 'Success', false);
      }),
      switchMap(() => [
        new userActions.SetLogoutSuccessAction(),
        new userActions.GetCurrentUserAction(null),
      ]),
      catchError(err => {
        consoleError(`${code} Cierre de sesión con error (1)`, err);
        return [
          new userActions.GetCurrentUserErrorAction(err),
          new GetCurrentAuthAction(),
          new LoadingStopAction(),
        ];
      })
    )),
    catchError(err => {
      consoleError(`${code} Cierre de sesión con error (2)`, err);
      return [
        new userActions.GetCurrentUserErrorAction(err),
        new GetCurrentAuthAction(),
        new LoadingStopAction(),
      ];
    })
  );

  @Effect()
  public UpdateUser$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.UPDATE_CURRENT_USER),
    pipe(
      tap(() => consoleStore(`${code} Iniciando la actualización del usuario`)),
      map((action: { payload }) => action.payload),
      switchMap(({ user, redirectTo }) => this.userService.updateUser(user).pipe(
        tap(() => consoleStore(`${code} Usuario actualizado correctamente`)),
        // tap(() => {
        //   this.notiService.showRebusToast('User updated correctly',
        //     'Success', true, null, null, null, user.id);
        // }),
        withLatestFrom(this.store.select(getUser).pipe(map(d => d.interest))),
        switchMap(([user1, interest]) => {
          let arr = [];
          arr = [...arr, new userActions.UpdateCurrentUserSuccessAction(user1)]
          arr = user1.interest === interest ? arr : [...arr, new userActions.TrackingInterestAction('[]')];
          // if (redirectTo) {
          //   this.navCtrl.navigateForward([redirectTo]);
          // }
          return arr;
        }),
        catchError(err => {
          consoleError(`${code} Actualización del usuario con error (1)`, err);
          // this.notiService.showRebusToast('Ha ocurrido un error actualizando el usuario',
          //   'Error', false, null, 'Error', null, user.id);
          return [
            new userActions.UpdateCurrentUserErrorAction(err),
          ];
        })
      )), catchError(err => {
        consoleError(`${code} Actualización del usuario con error (2)`, err);
        this.notiService.showRebusToast('An error occurred updating the user',
          'Error', false, null, 'Error');
        return [
          new userActions.UpdateCurrentUserErrorAction(err),
        ];
      })
    )
  );

  @Effect()
  public setDefaultMethod$: Observable<Action> = this.actions$.pipe(
    ofType(userActions.SET_DEFAULT_PAYMENT_METHOD),
    map((action: { payload: string }) => action.payload),
    withLatestFrom(this.store.select(getAuthUID)),
    switchMap(([token, uid]) => {
      return this.userService.setDefaultCard(token, uid)
        .pipe(
          map(data => new userActions.SetPaymentMethodsAction(data.data.cards)),
          catchError(err => {
            consoleError(`${code} Error obteniendo tarjetas de usuario`, err);
            return of(new userActions.SetDefaultPaymentMethodErrorAction(err));
          })
        );
    })
  )

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private userService: UserService,
    private notiService: NotificationsService,
    private store: Store<{}>,
    private navCtrl: NavController
  ) { }
}
