import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { getColor } from '../helper/color.helper';

@Pipe({ name: 'color_gradient' })
@Injectable()
export class ColorGradientPipe implements PipeTransform {
  public transform(value1: string, value2: string, direction: string): string {

    return `linear-gradient(${direction}, ${!value1.startsWith('#') ?
      getColor(value1) : value1}, ${!value2.startsWith('#') ?
        getColor(value2) : value2})`;
  }
}
