import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, switchMap, catchError, filter, tap } from 'rxjs/operators';
import { forEach, has, get } from 'lodash';

import * as menuActions from './menu.actions';
import { CdnService } from '../../service/cdn.service';
import { getClient } from '../config/config.reducer';
import { isValidEventId } from '../../helper/utils.helper';
import { EventService } from '../../service/event.service';
import { consoleStore, consoleError } from '../../helper/console.helper';
import { EmbedService } from '../../service/embed.service';
import { CustomService } from '../../service/custom.service';
import { LivestreamService } from '../../service/livestream.service';

const code = '[ MENU ]';

@Injectable()
export class MenuEffects {

  @Effect()
  public LoadMenu$: Observable<Action> = this.actions$.pipe(
    ofType(menuActions.LOAD_MENU),
    tap(() => consoleStore(`${code} Inciando carga de menu`)),
    map((action: { payload }) => action.payload),
    switchMap((eventId) => this.embedService.getEmbedListWithMenu(eventId).pipe(
      switchMap((embedList) => this.customService.getCustomListWithMenu(eventId).pipe(
        switchMap((customList) => this.livestreamService.getLivestreamListWithMenu(eventId).pipe(
          switchMap((livestreamList) => this.cdnService.getCdnModulesConfig().pipe(
            switchMap((modulesCdn) => {
              if (!isValidEventId(eventId)) {
                return this.store.select(getClient).pipe(
                  filter(client => client != null),
                  map(client => ({ modules: client.modules, modules_config: client.modules_config })),
                  switchMap(({ modules, modules_config }) => {
                    const menu_modules = [];
                    forEach(modules, (value, key) => {
                      if (key === 'embed') {
                        embedList.map((item) => {
                          menu_modules.push({
                            component: 'embed',
                            icon: item.menuIcon,
                            order: item.menuOrder,
                            state: value,
                            title: item.menuLabel,
                            embedId: item.id,
                            withRedirectMenu: get(item, 'withRedirectMenu', false),
                            redirectMenuUrl: get(item, 'redirectMenuUrl', ''),
                            redirectMenuTargetNew: get(item, 'redirectMenuTargetNew', false),
                          });
                        });
                      } else if (key === 'custom') {
                        customList.map((item) => {
                          menu_modules.push({
                            component: 'custom',
                            icon: item.menuIcon,
                            order: item.menuOrder,
                            state: item.state,
                            title: item.menuLabel,
                            customId: item.id,
                          });
                        });
                      } else if (key === 'livestream') {
                        livestreamList.map((item) => {
                          menu_modules.push({
                            component: 'livestream',
                            icon: item.menuIcon,
                            order: item.menuOrder,
                            state: item.state,
                            title: item.menuLabel,
                            livestreamId: item.id,
                          });
                        });
                      } else {
                        if (has(modules_config, key)) {
                          menu_modules.push({ ...modules_config[key], state: value, component: key });
                        } else {
                          if (has(modulesCdn, key)) {
                            menu_modules.push({ ...modulesCdn[key], state: value, component: key });
                          }
                        }
                      }
                    });

                    const menuResult = menu_modules
                      .filter(item => item.state)
                      .sort((itemA, itemB) => itemA.order - itemB.order);

                    consoleStore(`${code} Carga de menu general correctamente`, menuResult);
                    return [new menuActions.LoadMenuSuccessAction(menuResult)];
                  }),
                  catchError(err => {
                    consoleError(`${code} Carga de menu fallida (10)`, err);
                    return [new menuActions.LoadMenuErrorAction(err)];
                  }),                  
                );
              } else {
                return this.eventService.getEventById(eventId).pipe(
                  map(config => {
                    if (!config) {
                      throw ({ code: 404, message: 'No es posible cargar la información de este módulo (1)' });
                    }
                    return config;
                  }),
                  filter(config => config != null),
                  map(config => ({ modules: config.modules, modules_config: config.modules_config })),
                  switchMap(({ modules, modules_config }) => {
                    const menu_modules = [];
                    forEach(modules, (value, key) => {
                      if (key === 'embed') {
                        embedList.map((item) => {
                          menu_modules.push({
                            component: 'embed',
                            icon: item.menuIcon,
                            order: item.menuOrder,
                            state: value,
                            title: item.menuLabel,
                            embedId: item.id,
                            withRedirectMenu: get(item, 'withRedirectMenu', false),
                            redirectMenuUrl: get(item, 'redirectMenuUrl', ''),
                            redirectMenuTargetNew: get(item, 'redirectMenuTargetNew', false),
                          });
                        });
                      } else if (key === 'livestream') {
                        livestreamList.map((item) => {
                          menu_modules.push({
                            component: 'livestream',
                            icon: item.menuIcon,
                            eventId,
                            order: item.menuOrder,
                            state: item.state,
                            title: item.menuLabel,
                            livestreamId: item.id,
                          });
                        });
                      } else {
                        if (has(modules_config, key)) {
                          menu_modules.push({ ...modules_config[key], state: value, component: key });
                        } else {
                          if (has(modulesCdn, key)) {
                            menu_modules.push({ ...modulesCdn[key], state: value, component: key });
                          }
                        }
                      }
                    });
                    const menuResult = menu_modules
                      .filter(item => item.state)
                      .sort((itemA, itemB) => itemA.order - itemB.order);

                    consoleStore(`${code} Carga de menu de evento correctamente`, menuResult);
                    return [new menuActions.LoadMenuSuccessAction(menuResult)];
                  }),
                  catchError(err => {
                    consoleError(`${code} Carga de menu fallida (1)`, err);
                    return [new menuActions.LoadMenuErrorAction(err)];
                  }),
                );
              }
            }),
            catchError(err => {
              consoleError(`${code} Carga de menu fallida (2)`, err);
              return [new menuActions.LoadMenuErrorAction(err)];
            })
          )),
          catchError(err => {
            consoleError(`${code} Carga de menu fallida (3)`, err);
            return [new menuActions.LoadMenuErrorAction(err)];
          })
        )),
        catchError(err => {
          consoleError(`${code} Carga de menu fallida (3)`, err);
          return [new menuActions.LoadMenuErrorAction(err)];
        })
      )),
      catchError(err => {
        consoleError(`${code} Carga de menu fallida (3)`, err);
        return [new menuActions.LoadMenuErrorAction(err)];
      })
    )),
    catchError(err => {
      consoleError(`${code} Carga de menu fallida (4)`, err);
      return [new menuActions.LoadMenuErrorAction(err)];
    }),
  );

  constructor(
    private actions$: Actions,
    private store: Store<{}>,
    private cdnService: CdnService,
    private eventService: EventService,
    private embedService: EmbedService,
    private customService: CustomService,
    private livestreamService: LivestreamService,
  ) { }
}
