import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';
import { SearchService } from '../../services/search.service';
import { PopoverController } from '@ionic/angular';
import { NotificationOptionsComponent } from '../notification-options/notification-options.component';

@Component({
  selector: 'app-notifications-history',
  templateUrl: './notifications-history.component.html',
  styleUrls: ['./notifications-history.component.scss'],
})
export class NotificationsHistoryComponent implements OnInit {

  @Input() public design: IDesign;
  @Input() public moduleFrom: string;
  @Output() public evClose = new EventEmitter<void>();
  public notifications = [];

  constructor(private searchService: SearchService,
    public popoverCtrl: PopoverController) { }

  public ngOnInit() {
    this.notifications = this.searchService.getRecentSearchs().reverse();
  }

  public openNotification() {

  }

  public async onOptNotification(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: NotificationOptionsComponent,
      event: ev,
      translucent: true
    });
    return await popover.present();
  }

  public onClose() {
    this.evClose.emit();
  }


}
