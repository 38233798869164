import { Injectable } from '@angular/core';
import { environment } from '@app/env';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { takeRight, find } from 'lodash';
import { ITicket, IStaff, IService, IEmbed, IAgenda, IProduct, IGallery, IEvent, INews, IStand } from 'rebus-models';
import { Observable, from } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthService } from '../../shared/service/auth.service';

@Injectable()
export class SearchService {

  private recentSearchs: string[] = [];
  private recentSeen: any[] = [];

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.recentSeen = [

      {
        'module': 'agenda',
        'items': []
      },
      {
        'module': 'embed',
        'items': []
      },
      {
        'module': 'event',
        'items': []
      },
      {
        'module': 'gallery',
        'items': []
      },
      {
        'module': 'news',
        'items': []
      },
      {
        'module': 'product',
        'items': []
      },
      {
        'module': 'services',
        'items': []
      },
      {
        'module': 'staff',
        'items': []
      },
      {
        'module': 'ticket',
        'items': []
      },
      {
        'module': 'coupon',
        'items': []
      },
      {
        'module': 'stand',
        'items': []
      },
      {
        'module': 'sponsor',
        'items': []
      },
    ];
  }

  public async getResults(query: string, eventId: string, limit: number,
    language: string, entity: string, onlyEntity: boolean, userId: string = ''): Promise<any> {

    let body = {
      'clientId': environment.clientId,
      'userId': userId,
      'query': query,
      'limit': limit,
      'language': language,
      'entity': entity,
      'onlyEntity': onlyEntity,
      'eventId': ''
    };

    if (eventId && eventId !== '--') {
      body = { ...body, eventId };
    }

    const token = await this.authService.getTokenUser().pipe(take(1)).toPromise();
    const headers = new HttpHeaders({ 'Authorization': token, 'Content-Type': 'application/json' });
    return this.http.post(`${environment.etl_rebus}/etl/search`,
      { ...body }, { headers }).toPromise();
  }

  public saveSearch(query: string) {
    if (JSON.parse(localStorage.getItem('recentSearchs'))) {
      this.recentSearchs = JSON.parse(localStorage.getItem('recentSearchs'));
    }

    this.recentSearchs.push(query);
    this.recentSearchs = takeRight(Array.from(new Set(this.recentSearchs)), 4);

    localStorage.setItem('recentSearchs', JSON.stringify(this.recentSearchs));
  }

  public saveSeen(data: IEvent | IAgenda | IEmbed | IGallery | INews |
    IProduct | IService | IStaff | ITicket | IStand, module: string) {

    if (JSON.parse(localStorage.getItem('recentSeen'))) {
      this.recentSeen = JSON.parse(localStorage.getItem('recentSeen'));
    }
    // Copia profunda de los items
    let items = Array.from(find(this.recentSeen, (r) => r.module === module).items);
    items.push(data);
    // toma los últimos 6
    items = takeRight(Array.from(new Set(items.map((item: any) => item.id)
      .map(id => {
        return find(items, (r: any) => r.id === id);
      })
    )), 6);
    // Guarda en localstorage
    this.recentSeen = this.recentSeen.map(item => {
      if (item.module === module) {
        item.items = items;
      }
      return item;
    });
    localStorage.setItem('recentSeen', JSON.stringify(this.recentSeen));
  }

  public getRecentSearchs(): string[] {
    return JSON.parse(localStorage.getItem('recentSearchs')) ?
      JSON.parse(localStorage.getItem('recentSearchs')) : [];
  }

  public updateRecentSearch(recentSearchs: string[]) {
    localStorage.setItem('recentSearchs', JSON.stringify(recentSearchs));
  }

  public getRecentSeen(module: string) {
    this.recentSeen = JSON.parse(localStorage.getItem('recentSeen')) ?
      JSON.parse(localStorage.getItem('recentSeen')) : this.recentSeen;
    return find(this.recentSeen, (r) => r.module === module).items;
  }
}
