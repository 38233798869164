import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IUser, ICard } from 'rebus-models';
import { head, isEmpty, get } from 'lodash';
import * as fromUser from './user.actions';
import { environment } from '@app/env';

export interface State {
  user: any;
  position: any;
  platform: any;
  redirectTo: string;
  error: any;
  payment: IPaymentMethods;
  loading: boolean
}

export interface IPaymentMethods {
  payment_methods: ICard[];
  default_method: string;
}

export const initialState: State = {
  user: null,
  position: null,
  platform: null,
  redirectTo: null,
  error: null,
  payment: {
    payment_methods: null,
    default_method: null
  },
  loading: false
};

export function reducer(state = initialState, action: fromUser.UserActions): State {

  switch (action.type) {
    case fromUser.GET_CURRENT_USER: {
      return {
        ...state,
        platform: action.payload ? action.payload.platform : state.platform,
        position: action.payload ? action.payload.position : state.position,
        error: null,
      };
    }

    case fromUser.GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        error: null,
      };
    }

    case fromUser.GET_CURRENT_USER_ERROR: {
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    }

    case fromUser.UPDATE_CURRENT_USER: {
      return {
        ...state,
        loading: true
      };
    }

    case fromUser.UPDATE_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
        loading: false
      };
    }

    case fromUser.UPDATE_CURRENT_USER_ERROR: {
      return {
        ...state,
        error: action.payload,
        loading: false
      };
    }

    case fromUser.SET_LOGOUT: {
      return {
        ...state,
        redirectTo: action.payload,
      };
    }

    case fromUser.SET_LOGOUT_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }

    case fromUser.GET_CURRENT_USER: {
      return {
        ...state
      };
    }

    case fromUser.SET_REDIRECT_USER_URL: {
      window.localStorage.setItem(`${environment.clientId}-HOMEPAGE`, action.payload);
      return {
        ...state,
        // redirectTo: action.payload,
        redirectTo: !state.redirectTo ? action.payload : state.redirectTo, // Solo interesa el primero para no perder el foco
      };
    }

    case fromUser.SET_PAYMENT_METHODS: {
      const def = action.payload.find(card => card.default === 1);
      const first = action.payload[0];
      const default_method = def ? get(def, 'token') : get(first, 'token');
      return {
        ...state,
        payment: {
          ...state.payment,
          payment_methods: action.payload,
          default_method
        },
        loading: false,
      };
    }

    case fromUser.SET_PAYMENT_METHODS_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }

    case fromUser.SET_DEFAULT_PAYMENT_METHOD: {
      return {
        ...state,
        loading: true
      };
    }

    case fromUser.SET_PAYMENT_METHODS_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getUserState = createFeatureSelector<State>('user');
export const getUser = createSelector(getUserState, (state: State) => state.user);
export const getUserId = createSelector(getUser, (state: IUser) => state ? state._id : null);
export const getUserBasicInfo = createSelector(getUser, (state: IUser) => {
  if (state) {
    return state.basicInformation;
  }
});
export const getUserInfo = createSelector(getUser, (state: IUser) => {
  if (state) {
    return state;
  }
});
export const getRedirectTo = createSelector(getUserState, (state: State) => state.redirectTo);
export const getUserPaymentMethods = createSelector(getUserState, (state: State) => state.payment);
export const getDefaultPaymentMethod = createSelector(getUserPaymentMethods, (state: IPaymentMethods) => {
  const { payment_methods, default_method } = { payment_methods: [], default_method: null, ...state };
  if (isEmpty(payment_methods)) {
    return null;
  } else {
    const def = payment_methods.find(m => m.token === default_method);
    return def ? def : head(payment_methods);
  }
});
export const getError = createSelector(getUserState, (state: State) => state.error);
