import { Component } from '@angular/core';

@Component({
  selector: 'app-publicity-banner',
  templateUrl: './publicity-banner.component.html',
  styleUrls: ['./publicity-banner.component.scss']
})
export class PublicityBannerComponent {

}
