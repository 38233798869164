import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UtilitiesService } from '@service/utilities';
import { get, head, toArray } from 'lodash';
import * as moment from 'moment';
import { IDesign, IQuestion, IQuestionLikes } from 'rebus-models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CompanyService } from 'src/app/modules/shared/service/company.service';
import { QuestionService } from '../../../services/question.service';

@Component({
  selector: 'app-question-item',
  templateUrl: './question-item.component.html',
  styleUrls: ['./question-item.component.scss'],
})
export class QuestionItemComponent implements OnInit, OnChanges {
  @Input() public design: IDesign;
  @Input() public question: IQuestion;
  @Input() public userId: string;
  @Input() public eventId: string;
  @Output() public evOnOpenDetail = new EventEmitter<string>();

  public like: IQuestionLikes = null;
  public diffDate: String = 'Justo ahora';
  public user$: Observable<any>;
  public countLikes: number;
  public name: string = null

  constructor(
    private questionService: QuestionService, 
    private ut: UtilitiesService, 
    private companyService: CompanyService
  ) { }

  public ngOnInit() {
    this.getDateDiff();
    this.user$ = this.questionService.getQuestionWriter(this.question).pipe(
      map((user) => {
        if (user) {
          return user;
        }
      }),
      tap((user) => {
        if (get(user, "_id", false)) {
          this.companyService.getCompaniesSnap(this.eventId, user._id)
          .then(res => toArray(res))
          .then(companies => {             
            this.name = user.basicInformation.name
            if (user.customInformation.surname) {
              this.name += " "
              this.name += user.customInformation.surname
            }
            if (companies.length) {
              if (get(companies[0], 'infoCompany.name_company', false)) {
                this.name += " (" + get(companies[0], 'infoCompany.name_company', false) + ")"
              }
            }
          })
        }
      })
    );
    this.countLikes = parseInt(this.question.stats.counterLike, 10) || 0;
    this.isLiked();
  }

  public async isLiked() {
    this.like = await this.questionService
      .isLiked(this.question.id, this.userId, this.eventId)
      .once('value')
      .then((snap) => snap.val() || {})
      .then((val) => head(Object.values(val)));
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.question) {
      this.question = changes.question.currentValue;
    }
  }

  public getDateDiff() {
    const qDate = moment(this.question.createdAt);
    const now = moment();
    const diff = {
      days: now.diff(qDate, 'days'),
      hours: now.diff(qDate, 'hours'),
      minutes: now.diff(qDate, 'minutes'),
    };
    if (diff.days > 0) {
      this.diffDate = 'Hace ' + diff.days + ' días';
    } else if (diff.hours > 0) {
      this.diffDate = 'Hace ' + diff.hours + ' horas';
    } else if (diff.minutes > 1) {
      this.diffDate = 'Hace ' + diff.minutes + ' minutos';
    }
  }

  public async toggleLike() {
    const likeToSend = this.like || {
      id: '',
      userId: this.userId,
      questionId: this.question.id,
      createdAt: new Date().getTime(),
      userId_questionId: `${this.userId}_${this.question.id}`,
    };
    this.like ? this.countLikes-- : this.countLikes++;
    if (this.like) {
      this.ut.sendGtagData(`Preguntas Unlike`);
    } else {
      this.ut.sendGtagData(`Preguntas Like`);
    }
    await this.questionService.toggleLikeQuestion(
      this.question.id,
      likeToSend,
      this.countLikes.toString(),
      this.question.eventId
    );
    await this.isLiked();
  }

  public onShowComments() {
    this.evOnOpenDetail.emit(this.question.id);
  }
}
