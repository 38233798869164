import { Component, OnInit, Input, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { IAgenda, IDesign } from 'rebus-models';
import { NavController } from '@ionic/angular';
import { AIService } from 'src/app/modules/shared/service/ai.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-agenda-item-search-result',
  templateUrl: './agenda-item-search-result.component.html',
  styleUrls: ['./agenda-item-search-result.component.scss']
})
export class AgendaItemSearchResultComponent implements OnInit, OnChanges {

  @Input() public design: IDesign;
  @Input() public agenda: IAgenda;
  @Input() public eventId: string;
  @Input() public userId: string;
  @Output() public evOnClickItem = new EventEmitter<void>();
  // public match$: Observable<any>;

  constructor(
    private navCtrl: NavController,
    private aiService: AIService
  ) { }

  public ngOnInit() {
    // this.match$ = this.aiService.getRecommendationsForUser(this.userId, 'Agenda', null, this.eventId, this.agenda.id)
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.agenda) {
      this.agenda = changes.agenda.currentValue;
    }
  }

  public onClickAgenda() {
    this.navCtrl.navigateForward([`agenda/detail/${this.agenda.id}/${this.agenda.eventId}`]);
    this.evOnClickItem.emit();
  }
}
