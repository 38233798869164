import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { NotificationsService } from '../../service/notifications.service';
import { IDaysAvailability, IDesign } from 'rebus-models';
import { toArray } from 'lodash';

@Component({
  selector: 'select-date-picker',
  templateUrl: './select-date-picker.component.html',
  styleUrls: ['./select-date-picker.component.scss'],
})
export class SelectDatePickerComponent implements OnInit {

  @Input() public validDates = [];
  @Input() public action: string;
  @Input() public label: string;
  @Input() public dateStart: any;
  @Input() public design: IDesign;

  constructor(
    public modalCtrl: ModalController,
    public notificationService: NotificationsService,
  ) { }

  public ngOnInit() {
  }

  public dateSelected(date) {
    const data = { action: this.action, dateSelected: date };
    this.modalCtrl.dismiss(data);
  }
}
