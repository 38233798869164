import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@app/env';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { getClientCategories } from '@state/config';
import { getDesign } from '@state/design';
import { getUser, getUserState } from '@state/user';
import { get, toArray } from 'lodash';
import { ICategory, IDesign, IUser } from 'rebus-models';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { UpdateCurrentUserAction } from '../../../shared/state/user/user.actions';

@Component({
  selector: 'app-interest',
  templateUrl: './interest.page.html',
  styleUrls: ['./interest.page.scss'],
})
export class InterestPage implements OnInit, OnDestroy {

  @Input() public openModalInterest: boolean;
  @Input() public user: any;

  public design$: Observable<IDesign>;
  public interest$: Observable<any>;
  public user$: Observable<IUser>;
  public interestUser = []
  public currentUser: IUser;
  public redirectTo: string;
  public blBtnLoading$: Observable<boolean>;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public modalController: ModalController,
    private store: Store<{}>,
    private route: ActivatedRoute,
    private notiService: NotificationsService
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.interest$ = this.store.select(getClientCategories).pipe(
      map(interest => {
        if (this.user) {
          if (get(this.user.customInformation, 'category', false)) {
            return toArray(interest).filter((i: any) => i.state && i.category === this.user.customInformation.category)
          }
        }
        return toArray(interest).filter((i: any) => i.state)
      })
    );
    this.blBtnLoading$ = this.store.select(getUserState)
      .pipe(map(data => data.loading));
    this.user$ = this.store.select(getUser).pipe(
      tap(user => {
        this.interestUser = user.interest.length ? user.interest : Object.keys(user.interest);
        this.currentUser = user;
      })
    );
    this.route.params.pipe(takeUntil(this.unsubscribe$)).subscribe(paramMap => {
      this.redirectTo = paramMap['redirectTo'] ? paramMap['redirectTo'] :
        (window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo);
    })
  }

  public validateColor(category: ICategory, design: IDesign) {
    let color = design.firstColor;
    this.interestUser.map(key => {
      if (key === category.id) {
        color = 'light';
      }
    });
    return color;
  }

  public validateBackground(category: ICategory, design: IDesign) {
    let color = 'light';
    this.interestUser.map(key => {
      if (key === category.id) {
        color = design.firstColor;
      }
    });
    return color;
  }

  public onSelectCategory(category: ICategory) {
    if (this.interestUser.find(el => el === category.id)) {
      this.interestUser.splice(this.interestUser.indexOf(category.id), 1);
    } else {
      this.interestUser.push(category.id);
    }
  }

  public async onSaveInterest() {
    if (this.interestUser.length >= 1) {
      // const load = await this.ut.presentLoad('Actualizando', false);
      // load.present();
      const user = { ...this.currentUser, interest: this.interestUser }
      this.store.dispatch(new UpdateCurrentUserAction({ user, redirectTo: this.redirectTo }));

      if (this.openModalInterest) {
        this.modalController.dismiss();
      }

      // this.userService.updateUser({ _id: this.currentUser._id, clientId: this.currentUser.clientId, interest: this.interestUser })
      //   .pipe(
      //     switchMap(response => [this.store.dispatch(new GetCurrentUserSuccessAction(response))]),
      //     // FIXME: Tracking de accion de actualizacion de intereses
      //     map(() => this.ut.presentToast('Intereses almacenados correctamente')),
      //     // FIXME: ajustar redireccion del usuario
      //     map(() => load.dismiss()),
      //     map(() => {
      //       this.redirectTo ? this.router.navigate([this.redirectTo]) :
      //         this.router.navigate([environment.redirectTo]);
      //     }),
      //   ).subscribe();
    } else {
      this.notiService.showRebusToast('Debe seleccionar al menos 1 categoría',
        'Error', false);
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
