import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { SharedModule } from '../shared/shared.module';
import { PAGES } from './pages';
import { COMPONENTS } from './components';
import { SERVICES } from './services';
import { FormsModule } from '@angular/forms';
import { IonicRatingModule } from 'ionic4-rating';
import { SurveyPage } from './pages/survey/survey.page';
import { QuestionsCtrlPage } from './pages/questions-pages/questions-ctrl/questions-ctrl.page';
import { QuestionsPage } from './pages/questions-pages/questions/questions.page';
import { QuestionDetailPage } from './pages/questions-pages/question-detail/question-detail.page';
import { ModalActionSurveyComponent } from './components/survey-components/modal-action-survey/modal-action-survey.component';
import { AgendaWidgetPage } from './pages/agenda-widget/agenda-widget.page';
import { OpenMicComponent } from './components/open-mic/open-mic.component';
import { ModalPendingMessagesNetworkingComponent } from '../networking/pages/modal-pending-messages-networking/modal-pending-messages-networking.component';
import { ChatNetworkingFastComponent } from '../networking/pages/chat-networking-fast/chat-networking-fast.component';

@NgModule({
  declarations: [
    ...PAGES,
    ...COMPONENTS,
  ],
  imports: [
    SharedModule,
    CommonModule,
    IonicModule,
    FormsModule,
    IonicRatingModule
  ],
  exports: [
    ...PAGES,
    ...COMPONENTS,
  ],
  providers: [
    ...SERVICES,
  ],
  entryComponents: [
    SurveyPage,
    QuestionsCtrlPage,
    AgendaWidgetPage,
    QuestionsPage,
    QuestionDetailPage,
    ModalActionSurveyComponent,
    OpenMicComponent,
  ]
})
export class InteractivtySharedModule { }
