import { Component, Input } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-event-banner',
  templateUrl: './event-banner.component.html',
  styleUrls: ['./event-banner.component.scss']
})
export class EventBannerComponent {

  @Input() public design: IDesign;
  @Input() public tituloBanner: string;
  @Input() public imageBanner: string;
  @Input() public isBackgroundColor: boolean;
  @Input() public subTitleBanner: string;
  @Input() public dateStarGallery: string;
  @Input() public dateEndGallery: string;

  constructor() { }
}
