import { Directive, OnInit, Input, ElementRef, Renderer2, SimpleChanges, OnChanges, RendererStyleFlags2 } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[disabled]',

})
export class DisabledDirective implements OnInit, OnChanges {

  @Input('disabled') public var: boolean;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
  ) { }

  public ngOnInit() {
    this.setStyles();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.var) {
      this.var = changes.var.currentValue;
      this.setStyles();
    }
  }

  public setStyles() {
    const flags = RendererStyleFlags2.Important;

    if (this.var) {
      this.renderer.setStyle(this.el.nativeElement, 'pointer-events', 'none');
      this.renderer.setStyle(this.el.nativeElement, 'cursor', 'not-allowed');
      this.renderer.setStyle(this.el.nativeElement, 'background-color', '#b3b3b3', flags);
      this.renderer.setStyle(this.el.nativeElement, 'color', 'white !important', flags);
    }
  }

}
