import { Component, OnInit, Input } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-no-request',
  templateUrl: './no-request.component.html',
  styleUrls: ['./no-request.component.scss'],
})
export class NoRequestComponent implements OnInit {

  @Input() public message: string;
  @Input() public design: IDesign;
  @Input() public messageDesc: string;
  @Input() public valueSearch: string;


  constructor() { }

  public ngOnInit() { }

}
