/* function to change the colors to hexadecimal that are in variables.css
add another case if a color is added in the variable.css file
*/
export function getColor(id: string) {
    switch (id) {
        case 'red-500':
            return '#f44336';
        case 'red-800':
            return '#c62828';
        case 'red-900':
            return '#b71c1c';
        case 'pink-500':
            return '#e91e63';
        case 'pink-800':
            return '#ad1457';
        case 'pink-900':
            return '#880e4f';
        case 'purple-500':
            return '#9c27b0';
        case 'purple-800':
            return '#6a1b9a';
        case 'purple-900':
            return '#4a148c';
        case 'deeppurple-500':
            return '#673ab7';
        case 'deeppurple-800':
            return '#4527a0';
        case 'deeppurple-900':
            return '#311b92';
        case 'indigo-500':
            return '#3f51b5';
        case 'indigo-800':
            return '#283593';
        case 'indigo-900':
            return '#1a237e';
        case 'blue-500':
            return '#2196f3';
        case 'blue-800':
            return '#1565c0';
        case 'blue-900':
            return '#0d47a1';
        case 'lightblue-500':
            return '#03a9f4';
        case 'lightblue-800':
            return '#0277bd';
        case 'lightblue-900':
            return '#01579b';
        case 'cyan-500':
            return '#00bcd4';
        case 'cyan-800':
            return '#00838f';
        case 'cyan-900':
            return '#006064';
        case 'teal-500':
            return '#009688';
        case 'teal-800':
            return '#00695c';
        case 'teal-900':
            return '#004d40';
        case 'green-500':
            return '#4caf50';
        case 'green-800':
            return '#2e7d32';
        case 'green-900':
            return '#1b5e20';
        case 'lightgreen-500':
            return '#8bc34a';
        case 'lightgreen-800':
            return '#558b2f';
        case 'lightgreen-900':
            return '#33691e';
        case 'lime-500':
            return '#cddc39';
        case 'lime-800':
            return '#9e9d24';
        case 'lime-900':
            return '#827717';
        case 'yellow-500':
            return '#ffeb3b';
        case 'yellow-800':
            return '#f9a825';
        case 'yellow-900':
            return '#f57f17';
        case 'amber-500':
            return '#ffc107';
        case 'amber-800':
            return '#ff8f00';
        case 'amber-900':
            return '#ff6f00';
        case 'orange-500':
            return '#ff9800';
        case 'orange':
            return '#FFA726';
        case 'orange-800':
            return '#ef6c00';
        case 'orange-900':
            return '#e65100';
        case 'deeporange-500':
            return '#ff5722';
        case 'deeporange-800':
            return '#d84315';
        case 'deeporange-900':
            return '#bf360c';
        case 'brown-500':
            return '#795548';
        case 'brown-800':
            return '#4e342e';
        case 'brown-900':
            return '#3e2723';
        case 'grey-200':
            return '#EEEEEE';
        case 'grey-500':
            return '#9e9e9e';
        case 'grey-800':
            return '#424242';
        case 'grey-900':
            return '#212121';
        case 'bluegrey-500':
            return '#607d8b';
        case 'bluegrey-800':
            return '#37474f';
        case 'bluegrey-900':
            return '#263238';
        case 'clear':
            return '#fff';
        case 'dark':
            return '#000';
        case 'navy':
            return '#2C5889';
        case 'pale_blue':
            return '#E9EFF8';
        case 'orange':
            return '#FF5B00';
        case 'solid_blue':
            return '#2E405A';
        case 'navy-500':
            return '#002558';
        case 'navy-501':
            return '#004276';
        case 'navy-502':
            return '#005f96';
        case "walk-1":
            return "#1f9cd3";
        default:
            return '#fff';
    }
}
