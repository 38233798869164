import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NavController } from '@ionic/angular';
import { IDesign } from 'rebus-models';
import { IProduct } from 'rebus-models';

@Component({
  selector: 'app-item-product-suggestion',
  templateUrl: './item-product-suggestion.component.html',
  styleUrls: ['./item-product-suggestion.component.scss']
})
export class ItemProductSuggestionComponent {

  @Input() public design: IDesign;
  @Input() public eventId: string;
  // TODO: @tote este modelo Iproduct no tiene campo match como lo solicitas en la plantilla
  @Input() public suggestionItem: IProduct | any;

  @Output() public evOnViewMoreProduct = new EventEmitter<string>();
  @Output() public evOnSelectOptionProduct = new EventEmitter<any>();

  public isAdmin = false;

  constructor(
    private router: NavController
  ) { }

  public onClickOption(option: string, suggestionItem) {
    this.evOnSelectOptionProduct.emit({ option, suggestionItem });
  }

  public onGoDetailProduct() {
    this.evOnViewMoreProduct.emit(this.suggestionItem.id);
  }
}
