import { Directive, HostListener, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { ModalController } from '@ionic/angular';
import { AuthRequiredComponent } from '../components/auth-required/auth-required.component';
import { IUser } from 'rebus-models';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[authRequired]'
})
export class AuthrequiredDirective implements OnDestroy {

  @Input() public redirectTo: string; // Ruta de redirección una vez loggeado
  @Input() public user: IUser; // Objeto para verificar si está loggeado
  @Input() public pageName: string; // Página a la cual va a ingresar
  @Output() public authClick = new EventEmitter(); // Referencia al método que debe ejecutarse si está loggeado

  public unsubscribe$ = new Subject<void>();

  constructor(private store: Store<{}>,
    private modalCtrl: ModalController
  ) {
  }

  @HostListener('click', ['$event'])
  public async clickEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.user) {
      this.authClick.emit();
    } else {
      const modal = await this.modalCtrl.create({
        component: AuthRequiredComponent,
        componentProps: { redirectTo: this.redirectTo, pageName: this.pageName },
        animated: true,
        backdropDismiss: true,
        cssClass: 'custom-modal'
      });
      await modal.present();
    }
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
