import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';

import { getDesign } from '@state/design';
import { NavController, AlertController, MenuController } from '@ionic/angular';
import { ActivatedRoute } from '@angular/router';
import { UtilitiesService } from '@service/utilities';
import { environment } from '@app/env';
import { AuthService } from '../../../shared/service/auth.service';
import { has } from 'lodash';
import { validateEmail } from '@helper/utils';
import { IDesign, IAuthConfig } from 'rebus-models';
import { getUserState } from '@state/user';
import { TranslateService } from '@ngx-translate/core';
import { SlaaskHidden } from '@decorator/slaask-hidden';
import { NotificationsService } from 'src/app/modules/shared/service/notifications.service';
import { getClientAuthConfig } from '@state/config';
import { GLOBAL } from 'src/environments/global';

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss']
})

@SlaaskHidden()

export class LoginPage implements OnInit, OnDestroy {

  @Input() public redirectTo: string = null;

  public design$: Observable<IDesign>;
  public activatedRouteSubscription$: Subscription;
  public activatedUserSubscription$: Subscription;
  public isAuthenticated = false;
  public menu_select: 'register' | 'login' = 'register';
  public authConfig$: Observable<IAuthConfig>;

  constructor(
    private store: Store<{}>,
    private navCtrl: NavController,
    private route: ActivatedRoute,
    private ut: UtilitiesService,
    private translate: TranslateService,
    private authService: AuthService,
    private alertCtrl: AlertController,
    private menuCtrl: MenuController,
    private notiService: NotificationsService
  ) { }

  public ngOnInit() {
    this.menuCtrl.enable(false);
    this.design$ = this.store.select(getDesign);
    this.redirectTo = this.route.snapshot.queryParams.redirect;
    this.authConfig$ = this.store.select(getClientAuthConfig);

    // Este observable se encarga de hacer una redireccion del usuario en caso finalizar autenticacion o ya ternerlo anteriormente
    this.activatedUserSubscription$ = this.store.select(getUserState)
      .subscribe(currentUser => {
        if (currentUser.user) {
          if (!this.isAuthenticated) {
            this.isAuthenticated = true;
            /* this.notiService.showRebusToast('Bienvenido al sistema', 'Success', false)
              .then(() => this.navCtrl.navigateBack(this.redirectTo || environment.redirectTo)); */
          }
        }
      });
  }

  public segmentChanged(ev) {
    this.menu_select = ev.detail.value;
  }

  public get authRequired() {
    return GLOBAL.authRequired;
  }

  public async onForgotPassword() {
    const load = await this.ut.presentLoad(null, false);
    const alert = await this.alertCtrl.create({
      header: this.translate.instant('Recordar contraseña'),
      message: this.translate.instant('Ingresa tu correo'),
      inputs: [{
        placeholder: this.translate.instant('Correo electrónico'),
        type: 'email',
        name: '____restore_correo',
      }],
      buttons: [{
        text: this.translate.instant('Cancelar'),
        role: 'cancel'
      }, {
        text: this.translate.instant('Enviar'),
        handler: (form) => {
          if (!has(form, '____restore_correo')) {
            this.notiService.showRebusToast('El correo electrónico es inválido, por favor verifícalo',
              'Error', false);
            return false;
          }

          if (!validateEmail(form['____restore_correo'])) {
            this.notiService.showRebusToast('El correo electrónico es inválido, por favor verifícalo',
              'Error', false);
            return false;
          }

          return Promise.resolve(load.present())
            .then(() => this.authService.resetEmail(form['____restore_correo']).toPromise())
            .then(result =>
              this.ut.getMessageSuccess(result)
                .then(message => this.notiService.showRebusToast(message,
                  'Success', false))
                .then(() => load.dismiss())
            )
            .catch(error =>
              this.ut.getMessageError(error)
                .then(message => this.notiService.showRebusToast(message,
                  'Success', false))
                .then(() => load.dismiss())
            );
        }
      }]
    });

    alert.present().then(() => {
      const input: any = document.querySelector('ion-alert input');
      input.focus();
      return;
    });
  }

  public cancelAuth() {
    this.cleanRedirectTo();
    this.navCtrl.navigateBack(window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo);
  }

  private cleanRedirectTo() {
    if (this.redirectTo.includes('?auto_enter')) {
      const newRoute = this.redirectTo.split('?auto_enter');
      this.redirectTo = newRoute[0];
    }
  }

  public ngOnDestroy() {
    this.menuCtrl.enable(true);
    if (this.activatedRouteSubscription$) { this.activatedRouteSubscription$.unsubscribe(); }
    if (this.activatedUserSubscription$) { this.activatedUserSubscription$.unsubscribe(); }
  }
}
