import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { environment } from '@app/env';
import { isValidEventId } from '@helper/utils';
import { ICoupon, ICouponConfig } from 'rebus-models';
import { UtilitiesService } from '@service/utilities';
import { consoleError } from '../../shared/helper/console.helper';
import { NotificationsService } from '../../shared/service/notifications.service';
import { TranslateObjectPipe } from '../../shared/pipes/translate-obj.pipe';
import { toArray } from 'lodash';
import { ClientService } from '../../shared/service/client.service';

@Injectable({ providedIn: 'root' })
export class CouponService {

  constructor(
    private afdb: AngularFireDatabase,
    private ut: UtilitiesService,
    private notiService: NotificationsService,
    private translate_obj: TranslateObjectPipe,
    private clientService: ClientService,

  ) { }

  public getCoupons(eventId: string): Observable<ICoupon[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<ICoupon>(`/Clients/${environment.clientId}/Event/${eventId}/Coupon`,
        ref => ref.orderByChild('state').equalTo(true))
        .valueChanges();
    } else {
      return this.afdb.list<ICoupon>(`/Clients/${environment.clientId}/Interactivity/Coupon`,
        ref => ref.orderByChild('state').equalTo(true))
        .valueChanges();
    }
  }

  public getConfigWidgetsCoupon(eventId: string): Observable<ICouponConfig> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<ICouponConfig>(`/Clients/${environment.clientId}/Event/${eventId}/CouponConfig`)
        .valueChanges();
    } else {
      return this.afdb.object<ICouponConfig>(`/Clients/${environment.clientId}/Interactivity/CouponConfig`)
        .valueChanges();
    }
  }


  public getCouponById(eventId: string, couponId: string): Observable<ICoupon> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<ICoupon>(`/Clients/${environment.clientId}/Event/${eventId}/Coupon/${couponId}`)
        .valueChanges();
    } else {
      return this.afdb.object<ICoupon>(`/Clients/${environment.clientId}/Interactivity/Coupon/${couponId}`)
        .valueChanges();
    }
  }

  public acquireCoupon(eventId: string, userId: string, coupon: ICoupon): Promise<void> {
    coupon = { ...coupon };
    coupon.eventId = eventId;
    return this.afdb.object(`Clients/${environment.clientId}/Interactivity/CouponUsers/${userId}/${coupon.id}`).set(coupon);
  }

  public deleteCoupon(userId: string, coupon: ICoupon): Promise<void> {
    return this.afdb.object(`Clients/${environment.clientId}/Interactivity/CouponUsers/${userId}/${coupon.id}`).remove();
  }

  public addCouponQuantity(eventId: string, coupon: ICoupon): Promise<void> {
    if (isValidEventId(eventId)) {
      return this.afdb.object(`/Clients/${environment.clientId}/Event/${eventId}/Coupon/${coupon.id}/discount/quota`)
        .query.ref.transaction(val => {
          if (val || val === 0) {
            return val + 1;
          }
          return val;
        });
    }
    return this.afdb.object(`/Clients/${environment.clientId}/Interactivity/Coupon/${coupon.id}/discount/quota`)
      .query.ref.transaction(val => {
        if (val || val === 0) {
          return val + 1;
        }
        return val;
      });
  }

  public reduceCouponQuantity(eventId: string, coupon: ICoupon): Promise<void> {
    if (isValidEventId(eventId)) {
      return this.afdb.object(`/Clients/${environment.clientId}/Event/${eventId}/Coupon/${coupon.id}/discount/quota`)
        .query.ref.transaction(val => {
          if (val) {
            return val - 1;
          }
          return val;
        });
    }
    return this.afdb.object(`/Clients/${environment.clientId}/Interactivity/Coupon/${coupon.id}/discount/quota`)
      .query.ref.transaction(val => {
        if (val) {
          return val - 1;
        }
        return val;
      });
  }

  public getAcquiredCoupons(userId: string): Observable<ICoupon[]> {
    return this.afdb.list<ICoupon>(`/Clients/${environment.clientId}/Interactivity/CouponUsers/${userId}`,
      ref => ref.orderByChild('state').equalTo(true))
      .valueChanges();
  }

  public async getAcquiredCouponsSnap(userId: string) {
    const snap = await this.afdb.list(`/Clients/${environment.clientId}/Interactivity/CouponUsers/${userId}`,
      ref => ref.orderByChild('state').equalTo(true)).query.once('value');
    return snap.val();
  }

  public getAcquiredById(userId: string, couponId: string) {
    return this.afdb.object<ICoupon>(`/Clients/${environment.clientId}/Interactivity/CouponUsers/${userId}/${couponId}`)
      .valueChanges();
  }

  public getAcquiredByCode(uid: string, code: string) {
    return this.afdb.list<ICoupon>(
      `/Clients/${environment.clientId}/Interactivity/CouponUsers/${uid}`,
      ref => ref.orderByChild('reference').equalTo(code)
    ).query.once('value').then(snap => snap.val());
  }

  public getFreeCoupons(eventId: string) {
    if (isValidEventId(eventId)) {
      return this.afdb.list<ICoupon>(
        `/Clients/${environment.clientId}/Event/${eventId}/Coupon`,
        ref => ref.orderByChild('autoAdd').equalTo(true)
      ).query.once('value').then(snap => snap.val());
    }
    else {
      return this.afdb.list<ICoupon>(
        `/Clients/${environment.clientId}/Interactivity/Coupon`,
        ref => ref.orderByChild('autoAdd').equalTo(true)
      ).query.once('value').then(snap => snap.val());
    }
  }


  public async acquireCouponUser(
    eventId: string,
    userId: string,
    coupon: ICoupon) {

    const listCouponsAcquirePerUser = await this.getAcquiredCouponsSnap(userId);
    const couponsAcquirePerUser = toArray(listCouponsAcquirePerUser).length;

    const maxCouponPerUser = await this.clientService.getMaxCouponsPerUser().once('value')
      .then(snap => snap.val());

    if (couponsAcquirePerUser < maxCouponPerUser && couponsAcquirePerUser !== maxCouponPerUser || !maxCouponPerUser) {
      this.acquireCoupon(eventId, userId, coupon).then(val => {
        this.reduceCouponQuantity(eventId, coupon)
          .catch(err => consoleError('error restando cantidad al cupon', err));
        this.notiService.showRebusToast(`Has adquirido ${this.translate_obj.transform(coupon.title)}. Puedes verlo en \'Mis cupones\'`,
          'Success', true, null, null, 'coupon', userId, eventId, null, coupon.photoDetail);
      });
    } else {
      this.notiService.showRebusToast('No puedes adquirir este cupón, ya tienes el número máximo de cupones adquiridos',
        'Warning', false, null, null, 'coupon');
    }
  }

  public async acquireDeepCouponUser(
    eventId: string,
    userId: string,
    coupon: ICoupon) {

    const listCouponsAcquirePerUser = await this.getAcquiredCouponsSnap(userId);
    const couponsAcquirePerUser = toArray(listCouponsAcquirePerUser).length;

    const maxCouponPerUser = await this.clientService.getMaxCouponsPerUser().once('value')
      .then(snap => snap.val());

    if (couponsAcquirePerUser < maxCouponPerUser && couponsAcquirePerUser !== maxCouponPerUser || !maxCouponPerUser) {
      this.acquireCoupon(eventId, userId, coupon).then(val => {
        this.reduceCouponQuantity(eventId, coupon)
          .catch(err => consoleError('error restando cantidad al cupon', err));
      });
    }
  }

  public deleteCouponUser(eventId, userId, coupon) {
    this.deleteCoupon(userId, coupon).then(val => {
      this.addCouponQuantity(eventId, coupon);
      this.notiService.showRebusToast('Cupón eliminado correctamente', 'Success', true, null, null, 'coupon');
    });
  }

}
