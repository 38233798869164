import { Component, Input } from '@angular/core';
import { IDesign, IUser } from 'rebus-models';
import { PopoverController } from '@ionic/angular';
import { Platform } from '@ionic/angular';
import { NotificationsService } from '../../service/notifications.service';

@Component({
  selector: 'app-permission-notifications-tooltip',
  templateUrl: './permission-notifications-tooltip.component.html',
  styleUrls: ['./permission-notifications-tooltip.component.scss']
})
export class PermissionNotificationsTooltipComponent {

  @Input() public design: IDesign;
  @Input() public userId: string;
  @Input() public user: IUser;

  constructor(
    private popoverCtrl: PopoverController,
    private notifications: NotificationsService,
    private platform: Platform
  ) { }

  public close() {
    this.popoverCtrl.dismiss().then(() => {
      this.platform.ready().then(async () => {
        await this.notifications.requestPermission(this.userId, this.user);
      });
    });
  }

  public closeNotNow() {
    this.popoverCtrl.dismiss().then(() => {
      window.localStorage.setItem(`userCloseSuscribeNotification` + this.userId, 'true');
    });
  }
}
