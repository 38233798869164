import { Action } from '@ngrx/store';

export const LOADING_START = '[ LOADING ] Prende un loading';
export const LOADING_START_SUCCESS = '[ LOADING ] loading start success';
export const LOADING_STOP = '[ LOADING ] apaga un loading';
export const LOADING_STOP_SUCCESS = '[ LOADING ] loading stop success';
export const LOADING_ERROR = '[ LOADING ] loading error';

export class LoadingStartAction implements Action {
  readonly type = LOADING_START;
  constructor(public payload: { type: 'load' | 'modal', message?: string, component?: string, params?: any, }) { }
}

export class LoadingStopAction implements Action {
  readonly type = LOADING_STOP;
}

export class LoadingStartSuccessAction implements Action {
  readonly type = LOADING_START_SUCCESS;
}

export class LoadingErrorAction implements Action {
  readonly type = LOADING_ERROR;
  constructor(public payload: any) { }
}


export type LoadingActions =
  LoadingStartAction |
  LoadingStartSuccessAction |
  LoadingStopAction |
  LoadingErrorAction;
