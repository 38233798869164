// Estos entry components se crearon para evitar loop de importaciones
/**
 * Todos los componentes modales que se necesiten en header y en sus módulos
 * deberán declararse aquí.
 */
import { InterestPage } from '../../user/pages/interest/interest.page';
import { AuthRequiredComponent } from '../components/auth-required/auth-required.component';
import { ModalInfoBrowserComponent } from '../components/modal-info-browser/modal-info-browser.component';
import { ModalShareItemComponent } from '../components/modal-share-item/modal-share-item.component';
import { TplPopupFilterItemComponent } from '../components/tpl-popup-filter-item/tpl-popup-filter-item.component';
import { ModalAlertNotificationComponent } from '../modal-alert-notification/modal-alert-notification.component';
import { ModalCompanyInfoComponent } from './pages/modal-company-info/modal-company-info.component';
import { ModalValidateCodeEventComponent } from './pages/modal-validate-code-event/modal-validate-code-event.component';
import { ModalValidateUserComponent } from './pages/modal-validate-user/modal-validate-user.component';
import { SubeventPage } from './pages/subevent/subevent.page';

export const ENTRYCOMPONENTS = [
  SubeventPage,
  TplPopupFilterItemComponent,
  AuthRequiredComponent,
  ModalShareItemComponent,
  ModalAlertNotificationComponent,
  ModalInfoBrowserComponent,
  InterestPage,
  ModalValidateUserComponent,
  ModalValidateCodeEventComponent,
  ModalCompanyInfoComponent
];
