import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import * as userSelectors from '../../../shared/state/user/user.reducer';

import { getDesign } from '@state/design';
import { IDesign, IModuleConfig } from 'rebus-models';
import { IUser } from 'rebus-models';
import { LogoutAction } from 'src/app/modules/shared/state/auth/auth.actions';
import { Router } from '@angular/router';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '@app/env';
import { getClient, getClientGateways } from '@state/config';
import { ClientService } from 'src/app/modules/shared/service/client.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.page.html',
  styleUrls: ['./profile.page.scss'],
})
export class ProfilePage implements OnInit, OnDestroy {
  public design$: Observable<IDesign>;
  public gateways$: Observable<string[]>;
  public moduleStateShoppingCart$: Observable<boolean>;
  public user: IUser;
  public title = { en: 'Profile', es: 'Perfil' };

  private unsubscribe$ = new Subject<void>();
  constructor(
    private store: Store<{}>,
    private router: Router,
    private clienteService: ClientService,
    private titleService: Title
  ) {}

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.gateways$ = this.store.select(getClientGateways);
    this.moduleStateShoppingCart$ = this.clienteService.getModuleState('shopping_cart', '--');
    this.store
      .select(userSelectors.getUser)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        if (user) {
          this.user = user;
          this.setDocumentTitle();
        } else {
          this.router.navigate([window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo]);
        }
      });
  }

  private async setDocumentTitle() {
    const client = await this.store.select(getClient).pipe(take(1)).toPromise();
    const documentTitle = `${client.name} - Perfil de usuario`
    this.titleService.setTitle(documentTitle)
  }

  public isMethodRequired(gateways: string[]): boolean {
    return gateways.includes('paymentez');
  }

  public segmentChanged(ev) {
    this.router.navigate(['/user/profile/', ev.detail.value]);
  }

  public logout() {
    this.store.dispatch(new LogoutAction());
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
