import { Component, OnInit, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { ICard } from 'rebus-models';
import { getUserPaymentMethods } from '@state/user';
import { map, filter } from 'rxjs/operators';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'select-payment-method',
  templateUrl: './select-payment-method.component.html',
  styleUrls: ['./select-payment-method.component.scss']
})
export class SelectPaymentMethodComponent implements OnInit {

  // @Input() public design: IDesign;
  @Input() public default: ICard;
  public methods$: Observable<ICard[]>;
  constructor(
    private store: Store<{}>,
    private popoverCrl: PopoverController,
  ) { }

  public ngOnInit(): void {
    this.methods$ = this.store.select(getUserPaymentMethods)
      .pipe(
        filter(payment => !!payment),
        map(({ payment_methods }) => payment_methods),
      );
  }

  public onMethodSelected(action: string, card: ICard = null) {
    this.popoverCrl.dismiss({ action, card });
  }

}
