import { Component, OnInit, Input } from '@angular/core';
import { I18n, IDesign } from 'rebus-models';
import { ModalController } from '@ionic/angular';
import { NotificationsService } from '../service/notifications.service';

@Component({
  selector: 'modal-alert-notification',
  templateUrl: './modal-alert-notification.component.html',
  styleUrls: ['./modal-alert-notification.component.scss'],
})
export class ModalAlertNotificationComponent implements OnInit {

  // Crear modelo de IALert
  @Input() public alert: any;;
  @Input() public design: IDesign;
  @Input() public userId: string;
  @Input() public eventId: string;

  constructor(
    public modalCtrl: ModalController,
    private notiService: NotificationsService
  ) { }

  public ngOnInit() { }

  public closeModal() {
    this.modalCtrl.dismiss();
    this.saveViewAlertNotification();
  }

  public goAction() {
    const redirect = this.alert.is_external ? '_blank' : '_self'
    window.open(this.alert.callback, redirect);
    this.saveViewAlertNotification();
    this.modalCtrl.dismiss();
  }

  public async saveViewAlertNotification() {
    const saveView = await this.notiService.saveViewAlertPerUser(this.userId, this.eventId, this.alert.id);
  }
}
