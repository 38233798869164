import { QuestionService } from './question.service';
import { SurveyService } from './survey.service';
import { AgendaService } from './agenda.service';
import { OpenMicService } from './openMic.service';

export const SERVICES = [
  SurveyService,
  QuestionService,
  AgendaService,
  OpenMicService
];
