import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { Store } from '@ngrx/store';
import { has } from 'lodash';

import { environment } from '@app/env';
import { isValidEventId } from '@helper/utils';
import { getEventId } from '@state/menu';
import { switchMap, map } from 'rxjs/operators';
import { CdnService } from './cdn.service';
import { IClient, IHomeConfig, IModuleConfig, IFavoriteModule, IEventConfig } from 'rebus-models';
import { consoleError } from '../helper/console.helper';

@Injectable()
export class ClientService {

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<{}>,
    private cdnService: CdnService,
  ) { }

  public getClient(): Observable<IClient> {
    return this.afdb.object<IClient>(`/ClientConfig/${environment.clientId}`)
      .valueChanges();
  }

  public async getClientSnap(): Promise<Observable<IClient>> {
    const snap = await this.afdb.object<IClient>(`/ClientConfig/${environment.clientId}`).query.once('value');
    return snap.val();
  }

  public getConfigClientBtnMyDay(): Observable<any> {
    return this.afdb.object<any>(`/ClientConfig/${environment.clientId}/plan_my_day/`)
      .valueChanges();
  }

  public getConfigEventBtnMyDay(eventId) {
    return this.afdb.object<any>(`/Clients/${environment.clientId}/EventConfig/${eventId}/plan_my_day/`)
      .valueChanges();
  }

  public getModuleState(module: string, eventId: string): Observable<boolean> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<boolean>(`Clients/${environment.clientId}/EventConfig/${eventId}/modules/${module}`).valueChanges();
    } else {
      return this.afdb.object<boolean>(`ClientConfig/${environment.clientId}/modules/${module}`).valueChanges();
    }
  }

  public getConfigFavoritesModules(): Observable<IFavoriteModule[]> {
    return this.store.select(getEventId).pipe(
      switchMap(eventId => {
        if (isValidEventId(eventId)) {
          return this.afdb.list<IFavoriteModule>(`Clients/${environment.clientId}/Event/${eventId}/FavoritesConfig`,
            ref => ref.orderByChild('state').equalTo(true))
            .valueChanges();
        } else {
          return this.afdb.list<IFavoriteModule>(`Clients/${environment.clientId}/Interactivity/FavoritesConfig`,
            ref => ref.orderByChild('state').equalTo(true))
            .valueChanges();
        }
      }),
    );
  }

  public getConfigWidgetsHome(eventId: string): Observable<IHomeConfig> {
    let reference = `/Clients/${environment.clientId}/Interactivity/HomeConfig`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/HomeConfig`;
    }

    return this.afdb.object<IHomeConfig>(reference).valueChanges();
  }

  public getConfigWidgetsEvent(): Observable<IEventConfig> {
    const reference = `/Clients/${environment.clientId}/Interactivity/EventConfig`;
    return this.afdb.object<IEventConfig>(reference).valueChanges();
  }

  public getConfigModule(module: string): Observable<IModuleConfig> {
    return this.store.select(getEventId).pipe(
      switchMap(eventId => {
        if (isValidEventId(eventId)) {
          if (module === 'embed') {
            return this.afdb.object<IModuleConfig>(`Clients/${environment.clientId}/EventConfig/${eventId}/EmbedConfig`)
              .valueChanges();
          }
          return this.afdb.object<IModuleConfig>(`Clients/${environment.clientId}/EventConfig/${eventId}/modules_config/${module}`)
            .valueChanges();
        } else {
          if (module === 'embed') {
            return this.afdb.object<IModuleConfig>(`Clients/${environment.clientId}/Interactivity/EmbedConfig`)
              .valueChanges();
          }
          return this.afdb.object<IModuleConfig>(`ClientConfig/${environment.clientId}/modules_config/${module}`)
            .valueChanges();
        }
      }),
      switchMap(item => {
        if (item) {
          return of(item);
        } else {
          return this.cdnService.getCdnModulesConfig().pipe(
            map(config => has(config, module) ? config[module] : null)
          );
        }
      })
    );
  }

  public getConfigModuleAgendaWidget(module: string): Observable<any> {
    return this.afdb.object<any>(`ClientConfig/${environment.clientId}/modules_config/${module}`)
      .valueChanges();
  }

  public getMaxCouponsPerUser() {
    return this.afdb.object(`/ClientConfig/${environment.clientId}/maxCouponPerUser`)
      .query.ref;
  }

  public async getConfigInterest() {
    const snap = await this.afdb.object(`/ClientConfig/${environment.clientId}/AuthConfig/interestDefault`)
      .query.once('value');
    return snap.val();
  }

  public async getConfigValidationUser() {
    const snap = await this.afdb.object(`/ClientConfig/${environment.clientId}/AuthConfig`)
      .query.once('value');
    return snap.val();
  }

  public async getInterestDefaultClient() {
    const snap = await this.afdb.object(`/ClientConfig/${environment.clientId}/AuthConfig/interest`)
      .query.once('value');
    return snap.val();
  }

  public async getClientConfig() {
    const snap = await this.afdb.object(`/ClientConfig/${environment.clientId}`)
      .query.once('value');
    return snap.val();
  }

  public getStore(storeId: string, eventId: string) {
    let reference = `/Clients/${environment.clientId}/Interactivity/Store/${storeId}`;
    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Store/${storeId}`;
    }
    return this.afdb.object<any>(reference).valueChanges();
  }

  public getSocialConfig(eventId: string) {
    return this.afdb.object(`/Clients/${environment.clientId}/Event/${eventId}/Social`)
      .valueChanges();
  }

  public async setLogoutUser(userId: string) {
    const data = { userId, setLogout: false }
    return this.afdb.database.ref(`Clients/${environment.clientId}/UsersActions/${userId}`)
      .update(data).then(() => data)
      .catch((error) => {
        consoleError('Error realizando logout a usuario', error);
      });
  }

  public getUserAction(userId: string) {
    return this.afdb.object<any>(`/Clients/${environment.clientId}/UsersActions/${userId}`).valueChanges();
  }

  public getDeviceSession(userId: string) {
    const identify = window.sessionStorage.getItem(`${environment.clientId}-REBUS-V5-SESSION`)
    return this.afdb.object<any>(`/Clients/${environment.clientId}/___connections_devices/${userId}/${identify}`).valueChanges();
  }

  public async setDeviceSession(userId: string) {
    const identify = window.sessionStorage.getItem(`${environment.clientId}-REBUS-V5-SESSION`)
    return await this.afdb.database.ref(`/Clients/${environment.clientId}/___connections_devices/${userId}/${identify}`).remove()
  }


  // public getInstagramTimeline(token: string) {
  //     return this.jsonp.request(`https://api.instagram.com/v1/users/self/media/recent/?access_token=${token}&callback=JSONP_CALLBACK`)
  //         .pipe(map((response: any) => {
  //             return response._body.data;
  //         }), catchError((error) => {
  //             return of(Observable);
  //         }))
  // }
}
