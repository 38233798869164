/*
  Este componente es un controlador de la que se encarga de la
  visualización de las páginas en formato modal  y
  'side-dialog' para facilitar la navegación entre estas
  2 pantallas.
*/

import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { IDesign } from 'rebus-models';
import * as designSelectors from '../../../../shared/state/design/design.reducer';

import { getAuthUID } from '@state/auth';

@Component({
  selector: 'app-questions-ctrl',
  templateUrl: './questions-ctrl.page.html',
  styleUrls: ['./questions-ctrl.page.scss'],
})
export class QuestionsCtrlPage implements OnInit, OnDestroy {

  @Input() public selectedQuestion: string;
  @Input() public eventId: string;
  @Output() public evOnClose = new EventEmitter<void>();
  public userId$: Observable<string>;
  public design$: Observable<IDesign>;
  public blShowDetail: Boolean = false;
  private unsubscribe$ = new Subject<void>();

  constructor(
    public modalCtrl: ModalController,
    private store: Store<{}>,
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(designSelectors.getDesign);
    this.userId$ = this.store.select(getAuthUID);
  }

  public onOpenDetail(value: string) {
    if (value) {
      this.blShowDetail = true;
      this.selectedQuestion = value;
    } else {
      this.blShowDetail = false;
    }
  }

  public onClose() {
    this.modalCtrl.dismiss();
    this.evOnClose.emit();
  }

  public ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
