import { NgModule } from '@angular/core';
import { InteractivtySharedModule } from './interactivity-shared.module';
import { InteractivityRoutingModule } from './interactivity.router';

@NgModule({
  declarations: [],
  imports: [
    InteractivtySharedModule,
    InteractivityRoutingModule,
  ]
})
export class InteractivityModule { }
