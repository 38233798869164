import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from '@app/env';
import { isValidEventId } from '@helper/utils';

@Injectable()
export class CustomService {

  constructor(
    private afdb: AngularFireDatabase,
  ) { }

  public getCustomListWithMenu(eventId: string = null): Observable<any[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Event/${eventId}/Custom`,
        ref => ref.orderByChild('withMenu').equalTo(true)).valueChanges();
    } else {
      return this.afdb.list<any>(`/Clients/${environment.clientId}/Interactivity/Custom`,
        ref => ref.orderByChild('withMenu').equalTo(true)).valueChanges();
    }
  }

  public getCustomById(eventId: string, customId: string): Observable<any> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<any>(`/Clients/${environment.clientId}/Event/${eventId}/Custom/${customId}`)
        .valueChanges();
    } else {
      return this.afdb.object<any>(`/Clients/${environment.clientId}/Interactivity/Custom/${customId}`)
        .valueChanges();
    }
  }
}
