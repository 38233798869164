import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { IDesign } from 'rebus-models';
import { getUserBasicInfo } from '@state/user';
import { IUser, IBasicInformationUser } from 'rebus-models';
import * as qr from 'qrcode';

@Component({
  selector: 'app-license',
  templateUrl: './license.page.html',
  styleUrls: ['./license.page.scss'],
})
export class LicensePage implements OnInit {

  public design$: Observable<IDesign>;
  public user$: Observable<IBasicInformationUser>;

  constructor(
    private store: Store<{}>,

  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
    this.user$ = this.store.select(getUserBasicInfo);
  }

  public createQRcode(user) {
    const plainData = JSON.stringify(user);
    let rawData = '';
    qr.toDataURL(plainData, function (err, url) {
      if (!err) { rawData = url; }
    });
    return rawData;
  }

}
