import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SurveyPage } from './pages/survey/survey.page';
import { MenuGuard } from '../shared/guards/menu.guard';

const routes: Routes = [
  { path: 'survey', component: SurveyPage, canActivate: [MenuGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InteractivityRoutingModule { }
