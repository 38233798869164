import { Injectable } from '@angular/core';
import { Observable, from } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from '@app/env';
import { Store } from '@ngrx/store';
import * as firebase from 'firebase/app';
import { isValidEventId } from '@helper/utils';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { AuthService } from '../../shared/service/auth.service';
import { ISurvey, ISurveyUserAnswer, ISurveyAnswer } from 'rebus-models';

@Injectable()
export class SurveyService {

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<{}>,
    private http: HttpClient,
    private authService: AuthService,
  ) { }


  public getActiveSurvey(eventId: string): Observable<string> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<string>(`/Clients/${environment.clientId}/EventConfig/${eventId}/interactivity/Survey/`)
        .valueChanges();
    }
    return this.afdb.object<string>(`/Clients/${environment.clientId}/Interactivity/Survey/`)
      .valueChanges();
  }

  public getSurvey(surveyId: string): Observable<ISurvey> {
    return this.afdb.object<ISurvey>(`/Clients/${environment.clientId}/Surveys/${surveyId}`)
      .valueChanges();
  }

  public async getSurveyAnswerSnap(surveyId: string, eventId: string) {
    if (isValidEventId(eventId)) {
      const snap = await this.afdb.list(`/Clients/${environment.clientId}/Event/${eventId}/SurveyAnswers/${surveyId}`).query.once('value');
      return snap.val();
    }
    const snapC = await this.afdb.list(`/Clients/${environment.clientId}/Interactivity/SurveyAnswers/${surveyId}`).query.once('value');
    return snapC.val();
  }

  public saveUserSurvey(
    eventId: string,
    surveyUserAnswer: ISurveyUserAnswer[],
    userId: string,
    surveyId: string,
    id: string = ''
  ): Promise<void> {

    id = this.afdb.createPushId();
    const created_at = +firebase.database.ServerValue.TIMESTAMP;

    const surveyAnswer: ISurveyAnswer = {
      'id': id,
      'userId': userId,
      'surveyId': surveyId,
      'answers': surveyUserAnswer,
      'createdAt': new Date().getTime(),
    };

    if (isValidEventId(eventId)) {
      return this.afdb.object(`Clients/${environment.clientId}/Event/${eventId}/SurveyAnswers/${surveyId}/${id}`).
        update(surveyAnswer);
    }
    return this.afdb.object(`Clients/${environment.clientId}/Interactivity/SurveyAnswers/${surveyId}/${id}`).
      update(surveyAnswer);
  }

  public getSurveyByUser(uid: string, eventId: string, surveyId: string): Observable<ISurveyAnswer[]> {

    if (isValidEventId(eventId)) {
      return this.afdb.list<ISurveyAnswer>(
        `/Clients/${environment.clientId}/Event/${eventId}/SurveyAnswers/${surveyId}`,
        ref => ref.orderByChild('userId').equalTo(uid)
      ).valueChanges();
    }

    return this.afdb.list<ISurveyAnswer>(
      `/Clients/${environment.clientId}/Interactivity/SurveyAnswers/${surveyId}`,
      ref => ref.orderByChild('userId').equalTo(uid)
    ).valueChanges();
  }


  public getSurveyByUserSnap(uid: string, eventId: string, surveyId: string) {
    if (isValidEventId(eventId)) {
      return this.afdb.list<ISurveyAnswer>(
        `/Clients/${environment.clientId}/Event/${eventId}/SurveyAnswers/${surveyId}`,
        ref => ref.orderByChild('userId').equalTo(uid)
      ).query.once('value').then(snap => snap.val());
    }

    return this.afdb.list<ISurveyAnswer>(
      `/Clients/${environment.clientId}/Interactivity/SurveyAnswers/${surveyId}`,
      ref => ref.orderByChild('userId').equalTo(uid)
    ).query.once('value').then(snap => snap.val());
  }


}
