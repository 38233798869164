import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { getDesign } from '@state/design';

import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.page.html',
  styleUrls: ['./guest.page.scss']
})
export class GuestPage implements OnInit {

  public design$: Observable<IDesign>;
  public menu_select: 'invitation' | 'my_guests' | 'record' = 'invitation';

  constructor(
    private store: Store<{}>,
  ) { }

  public ngOnInit() {
    this.design$ = this.store.select(getDesign);
  }

  public segmentChanged(ev: any) {
    this.menu_select = ev.detail.value;
  }

}
