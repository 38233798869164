import { Component, Input } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-highlight-stand',
  templateUrl: './highlight-stand.component.html',
  styleUrls: ['./highlight-stand.component.scss']
})
export class HighlightStandComponent {

  @Input() public design: IDesign;
  @Input() public data: any;
  @Input() public match: number;

}
