import { Pipe, PipeTransform } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CouponService } from '../../coupon/services/coupon.service';
import { ICoupon } from 'rebus-models';

/**
 * Se encarga de ver si un cupón está adquirido
 * @param [cuponId,userId,eventId]
 */
@Pipe({ name: 'coupon_acquired' })
export class CouponAcquiredPipe implements PipeTransform {
  constructor(
    private couponService: CouponService
  ) { }
  public transform(couponId: string, userId: string): Observable<ICoupon> {
    return this.couponService.getAcquiredById(userId, couponId);
  }
}
