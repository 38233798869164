import { Pipe, PipeTransform } from '@angular/core';
import { ProductService } from '../../product/services/product.service';
import { toArray } from 'lodash';
import { map } from 'rxjs/operators';

@Pipe({
  name: 'priceEntityProductDiscount'
})

export class PriceEntityProductPipe implements PipeTransform {

  public priceProduct: number;

  constructor(
    private productService: ProductService,
  ) { }

  public transform(value: number, eventId: string, entitysId) {
    const entityId = toArray(entitysId);
    return this.productService.getProductById(eventId, entityId[0].id).pipe(
      map(product => product.pay.payPrice - value)
    );
  }
}
