import { Component } from '@angular/core';

@Component({
  selector: 'app-market-cart',
  templateUrl: './market-cart.component.html',
  styleUrls: ['./market-cart.component.scss']
})
export class MarketCartComponent {

}
