import * as fromLoading from './loading.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface State {
  isActive: boolean;
  type: 'load' | 'modal';
  component: string;
  message: string;
  params: any;
  error: any;
}

export const initialState: State = {
  isActive: false,
  type: null,
  component: null,
  message: null,
  params: null,
  error: null,
};

export function reducer(state = initialState, action: fromLoading.LoadingActions): State {

  switch (action.type) {
    case fromLoading.LOADING_START: {
      return {
        ...state,
        type: action.payload.type,
        message: action.payload ? action.payload.message : null,
        component: action.payload ? action.payload.component : null,
        params: action.payload ? action.payload.params : null,
      };
    }

    case fromLoading.LOADING_STOP: {
      return initialState;
    }

    case fromLoading.LOADING_START_SUCCESS: {
      return {
        ...state,
        isActive: true,
      };
    }

    case fromLoading.LOADING_ERROR: {
      if (action.payload) {
        return {
          ...state,
          type: null,
          message: null,
          params: null,
          isActive: false,
          error: action.payload,
        };
      } else {
        return {
          error: 'No se detectó un error en el loading',
          ...state,
        };
      }
    }

    default: {
      return state;
    }
  }
}

export const getLoadingState = createFeatureSelector<State>('loading');
export const getIsActive = createSelector(getLoadingState, (state: State) => state.isActive);

