import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { IonInput, ModalController } from '@ionic/angular';
import { UtilitiesService } from '@service/utilities';
import * as moment from 'moment';
import { IDesign } from 'rebus-models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LivestreamService } from 'src/app/modules/shared/service/livestream.service';
import { UserService } from 'src/app/modules/user/service/user.service';

@Component({
  selector: 'livestream-chat-component',
  templateUrl: 'livestream-chat-component.html',
  styleUrls: ['./livestream-chat-component.scss'],
})
export class LivestreamChatComponent implements OnInit {
  @ViewChild('inputChatText') inputChatText: IonInput;
  @ViewChild('container') container: ElementRef;

  @Input() info: any;
  @Input() userId: string;
  @Input() eventId: string;
  @Input() method = 'modal';
  @Input() design: IDesign;

  @Output() evEmitCloseChat = new EventEmitter();

  chatList$: Observable<any[]>;
  messageText: string;
  limit = 15;
  first_load = false;
  isButtom = false;
  actualUrl: string;

  constructor(
    private livestreamService: LivestreamService,
    private userService: UserService,
    private router: Router,
    private modalController: ModalController,
    private ut: UtilitiesService
  ) {}

  ngOnInit() {
    this.actualUrl = this.router.url;
    this.ut.sendGtagData(`Ingreso a chat`);
    this.loadChats();
  }

  loadChats(more = false) {
    if (more) {
      this.limit += 10;
    }
    this.chatList$ = this.livestreamService.getLivestreamChatList(this.info.id, this.limit, this.eventId).pipe(
      map((list) => {
        if (this.isButtom) {
          this.calculateScrollContainerChat();
        }
        return list;
      })
    );
  }

  firstLoad(index, count) {
    /**
     * Este metodo hace un scroll to botton solo la primera vez que se carga,
     * el scroll solo se maneja cada vez que envia un mensaje
     */
    if (!this.first_load && index + 1 === count) {
      this.calculateScrollContainerChat();
      this.first_load = true;
    }
  }

  public async send(keycode = null) {
    if (keycode !== 13) {
      return;
    }
    if (!this.userId) {
      return;
    }

    const message = this.messageText ? this.messageText.trim() : '';

    if (message !== '') {
      const user = await this.userService.getUserById(this.userId).then((result) => {
        return { _id: result._id, ...result.basicInformation };
      });

      this.livestreamService
        .createMessage(this.info.id, { message, user }, this.eventId)
        .then(() => {
          this.messageText = '';
        })
        .then(() => {
          this.inputChatText.setFocus();
        })
        .then(() => {
          this.calculateScrollContainerChat();
        });
    }
  }

  verifiedScroll(ev) {
    let pos = (ev.target.scrollTop || document.body.scrollTop) + ev.target.offsetHeight;
    let max = ev.target.scrollHeight;
    if (max - pos < 200) {
      this.isButtom = false;
    } else {
      this.isButtom = true;
    }
  }

  public calculateScrollContainerChat() {
    const containerChat = document.getElementById('chatContainer');
    if (containerChat) {
      containerChat.scrollTop = containerChat.scrollHeight;
    }
    return true;
  }

  public closeChat() {
    if (this.method === 'modal') {
      this.modalController.dismiss();
    }
    this.evEmitCloseChat.emit();
  }

  public timeSince(date) {
    if (date) {
      return moment(date).locale('es').fromNow();
    }
  }

  public getImageUser(imageUser) {
    if (imageUser) {
      return `url('${imageUser}')`;
    } else {
      return `url('https://forwardsummit.ca/wp-content/uploads/2019/01/avatar-default.png')`;
    }
  }
}
