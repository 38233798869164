import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, ICoupon } from 'rebus-models';

@Component({
  selector: 'app-item-coupon-suggestion',
  templateUrl: './item-coupon-suggestion.component.html',
  styleUrls: ['./item-coupon-suggestion.component.scss'],
})
export class ItemCouponSuggestionComponent {

  @Input() public design: IDesign;
  @Input() public eventId: string;
  @Input() public userId: string;
  // TODO: @tote este modelo Ievent no tiene campo match como lo solicitas en la plantilla
  @Input() public coupon: ICoupon | any;
  @Input() public moduleNameFromView: string;

  @Output() public evOnViewMoreCoupon = new EventEmitter<string>();
  @Output() public evOnSelectOptionCoupon = new EventEmitter<any>();

  public onSelectOptionCoupon(option: string, coupon) {
    this.evOnSelectOptionCoupon.emit({ option, coupon });
  }

  public onViewMoreCoupon() {
    this.evOnViewMoreCoupon.emit(this.coupon.id);
  }

}
