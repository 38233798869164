import { Action } from '@ngrx/store';

export const GET_CURRENT_AUTH = '[ AUTH ] Carga el session actualmente autenticado';
export const GET_CURRENT_AUTH_SUCCESS = '[ AUTH ] Carga el session actualmente autenticado correctamente';
export const SET_AUTH_ANONYMOUS = '[ AUTH ] Autentica anonimamente el usuario ya que no dispone de una sesión válida';
export const SET_AUTH_ERROR = '[ AUTH ] Carga el session actualmente autenticado con error';
export const SET_AUTH_REQUIRED = '[ AUTH ] Accion donde requiere autenticacion para continuar';
export const LOGIN = '[ AUTH ] Se inicia proceso de autenticación por email';
export const LOGIN_SUCCESS = '[ AUTH ] Completado proceso de autenticación';
export const LOGIN_SOCIAL = '[ AUTH ] Se inicia proceso de autenticación por alguna red social';
export const LOGIN_SOCIAL_SUCCESS = '[ AUTH ] Completado proceso de autenticación por red social';
export const LOGOUT = '[ AUTH ] Se cierra sesión de un usuario';
export const LOGOUT_FAILED = '[ AUTH ] Error al cerrar sesión de un usuario';
export const LOGOUT_SUCCESS = '[ AUTH ] Completado proceso de cierre de sesion';
export const REGISTER = '[ AUTH ] Se inicia proceso de registro de usuario';
export const REGISTER_SUCCESS = '[ AUTH ] Completado registro de usuario';
export const RESTORE_PASSWORD = '[ AUTH ] Se inicia proceso de restaurar contraseña';
export const AUTH_FAILED = '[ AUTH ] Falla proceso de auth';

export class GetCurrentAuthAction implements Action {
  public readonly type = GET_CURRENT_AUTH;
}

export class SetAuthAnonymousAction implements Action {
  public readonly type = SET_AUTH_ANONYMOUS;
}

export class SetAuthRequiredAction implements Action {
  public readonly type = SET_AUTH_REQUIRED;
}

export class GetCurrentAuthSuccessAction implements Action {
  public readonly type = GET_CURRENT_AUTH_SUCCESS;
  constructor(public payload: any) { }
}

export class SetAuthErrorAction implements Action {
  public readonly type = SET_AUTH_ERROR;
  constructor(public payload: any) { }
}

export class LoginAction implements Action {
  public readonly type = LOGIN;
  constructor(public payload: { email: string, password: string }) { }
}

export class LoginSuccessAction implements Action {
  public readonly type = LOGIN_SUCCESS;
  constructor(public payload: any) { }
}

export class LogoutAction implements Action {
  public readonly type = LOGOUT;
}

export class LogoutSuccessAction implements Action {
  public readonly type = LOGOUT_SUCCESS;
}

export class LogoutFailedAction implements Action {
  public readonly type = LOGOUT_FAILED;
  constructor(public payload: any) { }
}

export class LoginSocialAction implements Action {
  public readonly type = LOGIN_SOCIAL;
  constructor(public payload: { provider: 'facebook' | 'google' }) { }
}

export class LoginSocialSuccessAction implements Action {
  public readonly type = LOGIN_SOCIAL_SUCCESS;
  constructor(public payload: any) { }
}

export class RestoreAction implements Action {
  public readonly type = RESTORE_PASSWORD;
  constructor(public payload: { token: string, passwordHash: string }) { }
}

export class AuthFailedAction implements Action {
  public readonly type = AUTH_FAILED;
  constructor(public payload: any) { }
}

export class RegisterAction implements Action {
  public readonly type = REGISTER;
  constructor(public payload: {
    email: string,
    passwordHash: string,
    name?: string,
    phone?: string;
    terms?: boolean,
    dni?: string,
    policy?: boolean,
    interest?: string[]
  }) { }
}

export class RegisterSuccessAction implements Action {
  public readonly type = REGISTER_SUCCESS;
  constructor(public payload: any) { }
}

export type AuthActions = GetCurrentAuthAction
  | GetCurrentAuthSuccessAction
  | SetAuthAnonymousAction
  | SetAuthRequiredAction
  | SetAuthErrorAction
  | LoginAction
  | LoginSuccessAction
  | LoginSocialAction
  | LoginSocialSuccessAction
  | RegisterAction
  | RegisterSuccessAction
  | RestoreAction
  | AuthFailedAction
  | LogoutAction
  | LogoutFailedAction
  | LogoutSuccessAction;
