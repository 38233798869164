import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import * as moment from 'moment';
import { environment } from '@app/env';

import { getCodesActions } from '../helper/analytics.helper';
import { getSecureToken, getCookie, getFirestoreDate } from '../helper/utils.helper';
import { consoleService } from '../helper/console.helper';
import { GLOBAL } from 'src/environments/global';
import { get } from 'lodash';

export const collection = '_TRACKING_JOURNEY';

declare const ga: Function;

@Injectable()
export class AnalyticsService {

  public sendPageTrack(page: string, action: { id: number, name: string }) {
    // Esto ya no se necesita ya que se hace analytics en el rutero
    // switch (action.id) {
    //   case 1:
    //     this.setEventGoogleAnalytics(page, action.name);
    //     break;
    // }
    // this.savePageTrackOnFirestore(page, action);
    return true;
  }

  // FIXME: definir modelo de datos de payload
  public savePageTrackOnFirestore(page: string, action: { id: number, name: string }, payload: any = null) {
    const currentUser = firebase.auth().currentUser;
    // const id = `${getSecureToken()}_${action.id}`;
    // const document = {
    //   id,
    //   processId: window.localStorage.getItem(`${environment.clientId}-PROCESS-TRACKING`),
    //   cookieId: getCookie(`${environment.clientId}-COOKIE-TRACKING`),
    //   userId: currentUser ? currentUser.uid : null,
    //   clientId: environment.clientId,
    //   page,
    //   action,
    //   payload,
    //   date: getFirestoreDate(),
    // };

    // consoleService(`saveTrack ${action.name}`, document);

    // firebase.firestore()
    //   .collection(collection).doc(environment.logLevelTrack).collection(moment().format('YYYY-MM-DD')).doc(id).set(document);
    // track de usuario
    // if (get(currentUser, 'uid', false) && GLOBAL.trackEvents) {
    //   firebase.database().ref(`/Clients/${environment.clientId}/__tracking_events/`).push({
    //     userId: currentUser ? currentUser.uid : null,
    //     page,
    //     payload,
    //     date: firebase.database.ServerValue.TIMESTAMP,
    //   })
    // }
    return true;
  }

  // TODO: Esta funcion se usa para track de eventos de google analytics
  public sendClick(nameElement, value) {
    ga('event', nameElement, {
      'value': value,
    });
  }

  public saveFirstTrackOnFirestore(uidTrack, date, payload = null) {
    // const currentUser = firebase.auth().currentUser;

    // const document = {
    //   id: `${uidTrack}_0`,
    //   processId: uidTrack,
    //   cookieId: getCookie(`${environment.clientId}-COOKIE-TRACKING`),
    //   userId: currentUser ? currentUser.uid : null,
    //   clientId: environment.clientId,
    //   page: null,
    //   // tslint:disable-next-line: no-use-before-declare
    //   action: _ACTIONS.firstLoad,
    //   payload,
    //   date,
    // };
    // firebase.firestore()
    //   .collection(collection).doc(environment.logLevelTrack).collection(moment().format('YYYY-MM-DD')).doc(`${uidTrack}_0`).set(document);

    // consoleService(`saveTrackFirst`, document);

    return true;
  }

  // public setEventGoogleAnalytics(page: string, action: string, eventValue: number = null) {
  //   ga('set', 'page', page);
  //   ga('send', 'pageview');
  //   ga('send', {
  //     hitType: 'event',
  //     eventCategory: environment.clientId,
  //     eventAction: page,
  //     eventLabel: action,
  //     eventValue,
  //   });
  // }

  // public setViewGoogleAnalytics(page: string) {
  //   ga('set', 'appName', environment.clientId);
  //   ga('send', 'screenview');
  //   ga('send', {
  //     screenName: page,
  //   });
  // }
}

export const _ACTIONS = getCodesActions();
