import * as fromAuth from './auth.actions';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { FirebaseAuth } from '@angular/fire';

export interface State {
  authUID: string;
  firebaseInfo: FirebaseAuth;
  isLogged: boolean;
  // tslint:disable-next-line:no-any
  error: any;
}

export const initialState: State = {
  authUID: null,
  firebaseInfo: null,
  isLogged: false,
  error: null,
};

export function reducer(state = initialState, action: fromAuth.AuthActions): State {

  switch (action.type) {

    case fromAuth.REGISTER:
    case fromAuth.LOGIN:
    case fromAuth.LOGIN_SOCIAL: {

      return {
        ...state,
      };
    }

    case fromAuth.LOGIN_SOCIAL_SUCCESS:
    case fromAuth.REGISTER_SUCCESS: {
      return {
        ...state,
        isLogged: true,
        authUID: action.payload.authUID,
        firebaseInfo: action.payload.firebaseInfo
      };
    }

    case fromAuth.AUTH_FAILED: {
      return {
        ...state,
        error: action.payload,
      };
    }

    case fromAuth.GET_CURRENT_AUTH: {
      return {
        ...state,
        error: null,
      };
    }

    case fromAuth.GET_CURRENT_AUTH_SUCCESS: {
      return {
        ...state,
        authUID: action.payload.uid,
        firebaseInfo: action.payload,
        isLogged: true,
        error: null,
      };
    }

    case fromAuth.SET_AUTH_ANONYMOUS: {
      return {
        ...state,
        firebaseInfo: null,
        isLogged: true,
        error: null,
      };
    }

    case fromAuth.SET_AUTH_ERROR: {
      return {
        ...state,
        authUID: null,
        firebaseInfo: null,
        isLogged: false,
        error: action.payload,
      };
    }

    case fromAuth.SET_AUTH_REQUIRED: {
      return {
        ...state,
        authUID: null,
        firebaseInfo: null,
        isLogged: false,
        error: null,
      };
    }

    case fromAuth.LOGOUT_SUCCESS: {
      return {
        ...state,
        authUID: null,
        firebaseInfo: null,
        isLogged: false,
        error: null
      };
    }

    case fromAuth.LOGOUT_FAILED: {
      return {
        ...state,
        error: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const getAuthState = createFeatureSelector<State>('auth');
export const getAuthUID = createSelector(getAuthState, (state: State) => state.authUID);
export const getFirebaseInfo = createSelector(getAuthState, (state: State) => state.firebaseInfo);
export const getIsLogged = createSelector(getAuthState, (state: State) => state ? state.isLogged : null);
export const getError = createSelector(getAuthState, (state: State) => state.error);
