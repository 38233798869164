import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@app/env';

const routes: Routes = [
  // { path: '', redirectTo: `/${window.localStorage.getItem(`${environment.clientId}-HOMEPAGE`) || environment.redirectTo}`, pathMatch: 'full' },
  { path: '', redirectTo: `/${environment.redirectTo}`, pathMatch: 'full' },
  { path: 'home', loadChildren: './modules/dashboard/dashboard.module#DashboardModule' },
  { path: 'embed', loadChildren: './modules/embed/embed.module#EmbedModule' },
  { path: 'user', loadChildren: './modules/user/user.module#UserModule' },
  { path: 'shared', loadChildren: './modules/shared/shared.module#SharedModule' },
  { path: 'event', loadChildren: './modules/event/event.module#EventModule' },
  { path: 'staff', loadChildren: './modules/staff/staff.module#StaffModule' },
  { path: 'product', loadChildren: './modules/product/product.module#ProductModule' },
  { path: 'networking', loadChildren: './modules/networking/networking.module#NetworkingModule' },
  { path: 'ticket', loadChildren: './modules/ticket/ticket.module#TicketModule' },
  { path: 'registry', loadChildren: './modules/registry/registry.module#RegistryModule' },
  { path: 'interactivity', loadChildren: './modules/interactivity/interactivity.module#InteractivityModule' },
  { path: 'publicity', loadChildren: './modules/publicity/publicity.module#PublicityModule' },
  { path: 'agenda', loadChildren: './modules/agenda/agenda.module#AgendaModule' },
  { path: 'stand', loadChildren: './modules/stand/stand.module#StandModule' },
  { path: 'coupon', loadChildren: './modules/coupon/coupon.module#CouponModule' },
  { path: 'news', loadChildren: './modules/news/news.module#NewsModule' },
  { path: 'cart', loadChildren: './modules/cart/cart.module#CartModule' },
  { path: 'gallery', loadChildren: './modules/gallery/gallery.module#GalleryModule' },
  { path: 'service', loadChildren: './modules/service/service.module#ServiceModule' },
  { path: 'pqr', loadChildren: './modules/pqr/pqr.module#PqrModule' },
  { path: 'map', loadChildren: './modules/map/map.module#MapModule' },
  { path: 'streaming', loadChildren: './modules/streaming/streaming.module#StreamingModule' },
  { path: 'countdown', loadChildren: './modules/countdown/countdown.module#CountDownModule' },
  { path: 'gamification', loadChildren: './modules/gamification/gamification.module#GamificationModule' },
  { path: 'search', loadChildren: './modules/header/header.module#HeaderModule' },
  { path: 'raffle', loadChildren: './modules/raffle/raffle.module#RaffleModule' },
  { path: 'reserve', loadChildren: './modules/reserve/reserve.module#ReserveModule' },
  { path: 'social', loadChildren: './modules/social/social.module#SocialModule' },
  { path: 'custom-form', loadChildren: './modules/custom-form/custom-form.module#CustomFormModule' },
  { path: 'sponsor', loadChildren: './modules/sponsor/sponsor.module#SponsorModule' },
  { path: 'resource', loadChildren: './modules/resource/resource.module#ResourceModule' },
  { path: 'beneficiaries', loadChildren: './modules/beneficiaries/beneficiaries.module#BeneficiariesModule' },
  { path: 'carnet', loadChildren: './modules/carnet/carnet.module#CarnetModule' },
  { path: 'custom_payment', loadChildren: './modules/custom-item-payment/custom-item-payment.module#CustomItemModule' },
  { path: 'timeline', loadChildren: './modules/timeline/timeline.module#TimeLineModule' },
  { path: 'jobs', loadChildren: './modules/job-offer/job-offer.module#JobsOfferModule' },
  { path: 'itinerary', loadChildren: './modules/itinerary/itinerary.module#ItineraryModule' },
  { path: 'scan-ticket', loadChildren: './modules/scan-qr-ticket/scan-qr-ticket.module#ScanQrTicketModule' },
  { path: 'scan-coupon', loadChildren: './modules/scan-qr-coupon/scan-qr-coupon.module#ScanQrCouponModule' },
  { path: 'livestream', loadChildren: './modules/livestream/livestream.module#LivestreamModule' },
  { path: 'c', loadChildren: './modules/custom/custom.module#CustomModule' },
  { path: 'companies', loadChildren: './modules/companies/companies.module#CompaniesModule' },

];

// Rutas habilitadas en producción
if (!environment.production) {
  routes.push({ path: '**', redirectTo: '/shared/404' });
  // routes.push({ path: '**', redirectTo: '/shared/500' });
}
@NgModule({
  imports: [
    RouterModule.forRoot(routes),
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule { }
