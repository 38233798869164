import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, ISponsor } from 'rebus-models';

@Component({
  selector: 'app-sponsor-widget',
  templateUrl: './sponsor-widget.component.html',
  styleUrls: ['./sponsor-widget.component.scss'],
})
export class SponsorWidgetComponent {

  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public sponsor: ISponsor;
  @Input() public views: number;

  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.sponsor.id);
  }

}
