import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Store } from '@ngrx/store';
import { CdnService } from '../../shared/service/cdn.service';
import { Observable } from 'rxjs';
import { IReserve, IReserveConfig, IItem } from 'rebus-models';
import { map, take, switchMap } from 'rxjs/operators';
import { isValidEventId } from '@helper/utils';
import { getAuthUID } from '@state/auth';
import { CartService } from '../../cart/services/cart.service';
import { NotificationsService } from '../../shared/service/notifications.service';
import { TranslateObjectPipe } from '../../shared/pipes/translate-obj.pipe';
import { consoleError } from '../../shared/helper/console.helper';
import { isArray, sum } from 'lodash';
import { AuthService } from '../../shared/service/auth.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '@app/env';

@Injectable()
export class ScanCouponService {

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<{}>,
    private cdnService: CdnService,
    private cartService: CartService,
    private notiService: NotificationsService,
    private translate_obj: TranslateObjectPipe,
    private authService: AuthService,
    private http: HttpClient,
  ) { }


  public getPayment(idTransaction: string) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get(`${environment.pay_rebus}/paymentId/${idTransaction}`,
          { headers });
      })
    )
  }

  public validateRegister(value) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.post(`${environment.pay_rebus}/v2/process/coupon`,
          {
            id: value.id,
            observe: value.observe,
            exempt: value.exempt,
            item: {
              id: value.itemId,
              datetime: value.dataTime,
              quota: value.quota,
            },
          },
          { headers }
        )
      })
    )
  }

}
