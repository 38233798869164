
import { TranslateObjectPipe } from './translate-obj.pipe';
import { ColorPipe } from './color.pipe';
import { MenuConfigPipe } from './menu-config.pipe';
import { SafePipe } from './safe.pipe';
import { SanizateHtmlPipe } from './sanitize-html';
import { ToArrayPipe } from './to-array.pipe';
import { ColorGradientPipe } from './color-gradient.pipe';
import { FormatDatePipe } from './format-date.pipe';
import { TimeFormat } from './format-hour.pipe';
import { GetUserInfoPipe } from './get-user.pipe';
import { CouponAcquiredPipe } from './coupon-acquired.pipe';
import { PriceEntityProductDiscountPipe } from './value-entity-coupon.pipe';
import { PriceEntityProductPipe } from './value-entity-coupon-discount.pipe';
import { PriceEntityServiceDiscountPipe } from './value-entity-service.pipe';
import { PriceEntityServicePipe } from './value-entity-service-discount.pipe';
import { UserRecomendationsPipe } from './user-recomendations.pipe';
import { ClearMenuPipe } from './clear-menu.pipe';
import { OptimizePipe } from './optimize-img.pipe';
import { OrderByPipe } from './orderBy.pipe';
import { ValidateLikePipe } from './validate-like.pipe';
import { GetTotalPost } from './get-total-post.pipe';
import { GetCommentsTimeLine } from './get-comments-timeline.pipe';
import { GetTotalCommentsPost } from './get-total-comments-post.pipe';
import { getStoreShoppingInfoPipe } from './get-store-info.pipe';
import { ClientCurrencyPipe } from './client-currency.pipe';

export const PIPES = [
  TranslateObjectPipe,
  ColorPipe,
  ColorGradientPipe,
  MenuConfigPipe,
  SafePipe,
  SanizateHtmlPipe,
  ToArrayPipe,
  FormatDatePipe,
  TimeFormat,
  GetUserInfoPipe,
  CouponAcquiredPipe,
  PriceEntityProductDiscountPipe,
  PriceEntityProductPipe,
  PriceEntityServiceDiscountPipe,
  PriceEntityServicePipe,
  UserRecomendationsPipe,
  ClearMenuPipe,
  OptimizePipe,
  OrderByPipe,
  ValidateLikePipe,
  GetTotalPost,
  GetCommentsTimeLine,
  GetTotalCommentsPost,
  getStoreShoppingInfoPipe,
  ClientCurrencyPipe,
];
