import { LoginPage } from './login/login.page';
import { ResetPage } from './reset/reset.page';
import { LicensePage } from './license/license.page';
import { ProfilePage } from './profile/profile.page';
import { GuestPage } from './guest/guest.page';
import { TermsPublicityPage } from './terms/terms-publicity/terms-publicity.page';
import { TermsServicePage } from './terms/terms-service/terms-service.page';

export const PAGES = [
  LoginPage,
  ResetPage,
  LicensePage,
  ProfilePage,
  GuestPage,
  TermsPublicityPage,
  TermsServicePage
];
