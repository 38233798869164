import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-validate-form',
  templateUrl: './validate-form.component.html',
  styleUrls: ['./validate-form.component.scss'],
})
export class ValidateFormComponent {

  @Input() public form: FormControl;
  @Input() public message = 'Invalid field';
  @Input() public submitAttempt: boolean;
}
