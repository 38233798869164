import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, IService } from 'rebus-models';

@Component({
  selector: 'app-item-service-suggestion',
  templateUrl: './item-service-suggestion.component.html',
  styleUrls: ['./item-service-suggestion.component.scss'],
})
export class ItemServiceSuggestionComponent {

  @Input() public design: IDesign;
  @Input() public eventId: string;
  // TODO: @tote este modelo Iservice no tiene campo match como lo solicitas en la plantilla
  @Input() public suggestionItem: IService | any;

  @Output() public evOnViewMoreService = new EventEmitter<string>();

  public viewMore() {
    this.evOnViewMoreService.emit(this.suggestionItem.id);
  }

}
