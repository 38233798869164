import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { environment } from '@app/env';
import { isValidEventId } from '@helper/utils';
import { ITicket, ITicketConfig, ITicketShoppingCart } from 'rebus-models';
import { IItem } from 'rebus-models';
import { Store } from '@ngrx/store';
import { getAuthUID } from '@state/auth';
import { take } from 'rxjs/operators';
import { CartService } from '../../cart/services/cart.service';
import { consoleError } from '../../shared/helper/console.helper';
import { NotificationsService } from '../../shared/service/notifications.service';

@Injectable({ providedIn: 'root' })
export class TicketService {

  constructor(
    private afdb: AngularFireDatabase,
    private store: Store<any>,
    private cartService: CartService,
    private notiService: NotificationsService
  ) { }

  public getTickets(eventId: string): Observable<ITicket[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<ITicket>(`/Clients/${environment.clientId}/Event/${eventId}/Ticket`,
        ref => ref.orderByChild('privacy').equalTo('public')
      )
        .valueChanges();
    } else {
      return this.afdb.list<ITicket>(`/Clients/${environment.clientId}/Interactivity/Ticket`,
        ref => ref.orderByChild('privacy').equalTo('public')
      )
        .valueChanges();
    }
  }

  public getConfigWidgetsTicket(eventId: string): Observable<ITicketConfig> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<ITicketConfig>(`/Clients/${environment.clientId}/Event/${eventId}/TicketConfig`)
        .valueChanges();
    } else {
      return this.afdb.object<ITicketConfig>(`/Clients/${environment.clientId}/Interactivity/TicketConfig`)
        .valueChanges();
    }
  }

  public getTicketById(eventId: string, ticketId: string): Observable<ITicket> {
    if (isValidEventId(eventId)) {
      return this.afdb.object<ITicket>(`/Clients/${environment.clientId}/Event/${eventId}/Ticket/${ticketId}`)
        .valueChanges();
    } else {
      return this.afdb.object<ITicket>(`/Clients/${environment.clientId}/Interactivity/Ticket/${ticketId}`)
        .valueChanges();
    }
  }

  public addTicketToShoppingCart(ticket: ITicketShoppingCart, amount: number, customForm = null): Promise<void> {
    if (ticket.plans.quota <= 0) {
      this.notiService.showRebusToast(
        'Este producto se encuentra agotado ',
        'Warning'
      );
      return Promise.resolve();
    }
    return this.store.select(getAuthUID)
      .pipe(take(1)).toPromise().then(uid => {
        const item = this.createTicketShoppingCartItem(ticket, amount, customForm);
        this.cartService.addShoppingCartItem(uid, item).then(val => {
          if (val) {
            this.notiService.showRebusToast('Ticket agregado al carrito de compras ',
              'Success', true, null, null, 'ticket', uid);
          }
        }, err => {
          consoleError('err[01] carrito productos', err);
          this.notiService.showRebusToast('Error agregando al carrito ', 'Error', false, null, null, 'ticket');
        });
      }, err => {
        consoleError('err[10] carrito productos', err);
        this.notiService.showRebusToast('Error agregando al carrito ', 'Error', false, null, null, 'ticket');

      });
  }

  public createTicketShoppingCartItem(ticket: ITicketShoppingCart, amount = 1, customForm): IItem {
    const ts = Date.now();
    return {
      addAt: ts,
      customForm,
      basePrice: ticket.plans.payPrice,
      data: { ...ticket },
      eventId: ticket.eventId || null,
      id: ticket.id,
      price: ticket.plans.payPrice,
      priceDiscount: 0,
      priceReference: ticket.plans.payPriceReference,
      saleAmount: amount,
      shop: ticket.storeId,
      tax: ticket.plans.tax,
      type: 'ticket',
    };
  }

  public async getTicketByIdInCartByUser(userId: string, ticketId: string) {
    const snap = await this.afdb.list(`/Clients/${environment.clientId}/ShoppingCart/${userId}/items`,
      ref => ref.orderByChild('id').equalTo(ticketId)).query.once('value');
    return snap.val();
  }

}
