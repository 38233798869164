import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeHtml'
})
@Injectable()
export class SanizateHtmlPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) { }

  public transform(v: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(v);
  }
}
