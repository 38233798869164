export function calculateRoute(menu, eventId) {
  switch (menu.component) {
    case 'event':
    case 'home':
    case 'dashboard':
      return [`/${menu.component}`];

    case 'likes':
      return [`/user/interest`, eventId ? eventId : '--'];

    case 'diary':
      return [`/agenda`, eventId ? eventId : '--'];

    case 'catalog':
      return [`/product`, eventId ? eventId : '--'];

    case 'business':
      return [`/networking`, eventId ? eventId : '--'];

    case 'commercial_samples':
      return [`/stand`, eventId ? eventId : '--'];

    case 'coupons':
      return [`/coupon`, eventId ? eventId : '--'];

    case 'embed':
      return [`/embed`, menu.embedId, eventId ? eventId : '--'];

    case 'services':
      return ['/service', eventId ? eventId : '--'];

    case 'custom':
      return ['/c', menu.customId, eventId ? eventId : '--'];

    case 'livestream':
      return ['/livestream', menu.livestreamId, eventId ? eventId : '--'];

    case 'embed':
      return menu.withRedirectMenu ? menu.redirectMenuUrl : [`/${menu.component}`, eventId ? eventId : '--'];

    default:
      return [`/${menu.component}`, eventId ? eventId : '--'];
  }
}
