import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { environment } from '@app/env';

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  @Input() color: string = null;

  public design$: Observable<any>;
  public version = environment.version;

  constructor(
    private store: Store<{}>
  ) { }

  ngOnInit() {
    this.design$ = this.store.select(getDesign);
  }
}
