import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { UtilitiesService } from '@service/utilities';
import { Observable } from 'rxjs';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize, catchError, take } from 'rxjs/operators';
import { IDesign } from 'rebus-models';
import { NotificationsService } from '../../service/notifications.service';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
})
export class UploadFileComponent implements OnInit {

  @Input() public maxFileSize = 30000000;
  @Input() public form: FormControl;
  @Input() public accept = 'image/*';
  @Input() public buttonName: string;
  @Input() public disabled = false;
  @Input() public icon: string;
  @Input() public filePath: string;
  @Input() public isMultiple = false;
  @Input() public custom = false;
  @Input() public design: IDesign;
  @Output() public uploadCustom = new EventEmitter();
  @Output() public uploadEnd = new EventEmitter();

  public uploadPercent: Observable<number>;
  public downloadURL: Observable<string>;
  public id: string;
  public hash = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz'.split('');

  constructor(
    private storage: AngularFireStorage,
    private utilities: UtilitiesService,
    private notiService: NotificationsService
  ) { }

  public ngOnInit() {
    this.id = Math.floor(Math.random() * this.hash.length).toString();
  }

  public open() {
    document.getElementById(this.id).click();
  }

  public verifiedUpload(event) {
    if (this.custom) {
      this.uploadCustom.emit(event);
    } else {
      this.uploadFile(event);
    }
  }

  public clearFile(position = null) {
    if (position >= 0) {
      const current: string[] = this.form.value;
      if (this.isMultiple) {
        current.splice(position, 1);
        this.uploadEnd.emit(current);
        // this.form.setValue(current);
      } else {
        this.uploadEnd.emit();
        // this.form.setValue("");
      }
    } else {
      this.uploadEnd.emit();
      // this.form.setValue("");
    }
  }

  private async uploadFile(event) {
    const files: File[] = Array.from(event.target.files);
    const sizeFile = event.target.files[0].size
    if (sizeFile > this.maxFileSize) {
      let message = 'No se ha podido subir porque pesa más de'
      message += ' 30MB'
      this.notiService.showRebusToast(message, 'Error', false);
    } else {
      if (files.length > 0) {
        const load = await this.utilities.presentLoad();
        load.present();
        const promises = files.map(file => {
          const filePath = `${this.filePath}/${this.id}_${file.name}`;
          const ref = this.storage.ref(filePath);
          const task = this.storage.upload(filePath, file);
          return this.fileUploadFinalizeToPromise(task, ref);
        });
        await this.endUpload(promises, load);
      }
    }
  }

  private async endUpload(promises, load) {
    const result = await Promise.all(promises);
    if (this.isMultiple) {
      const rs: string[] = this.form.value || [];
      this.uploadEnd.emit([...rs, ...result]);
    } else {
      this.uploadEnd.emit(result[0]);
    }
    if (load) {
      load.dismiss();
    }
  }

  private fileUploadFinalizeToPromise(task, ref) {
    return new Promise((resolve, reject) => {
      task.snapshotChanges().pipe(
        catchError(error => {
          reject(error);
          return [];
        }),
        finalize(() => {
          ref.getDownloadURL()
            .pipe(take(1))
            .subscribe(url => resolve(url));
        }),
      ).subscribe();
    });
  }

}
