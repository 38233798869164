import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'formatDate'
})

export class FormatDatePipe implements PipeTransform {

  constructor(
    private translate: TranslateService
  ) { }

  public transform(value: any, format: string): string {
    format = this.translateFormat(format);
    const defLang = this.translate.getDefaultLang();
    moment.locale(defLang);
    return moment(value).format(format);
  }

  private translateFormat(format: string): string {
    const newformatParts = format.split(new RegExp("\[(.*?)\ ]")).map(part => {
      if (new RegExp("\[(?:\[??[^\[]*?\]").test(part)) {
        const testPart = part.replace('[', '').replace(']', '');
        return `[${this.translate.instant(testPart)}]`;
      }
      return part;
    });
    return newformatParts.join(' ');
  }
}