import { Component, Input } from '@angular/core';
import { IDesign } from 'rebus-models';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-rebus-match-tooltip',
  templateUrl: './rebus-match-tooltip.component.html',
  styleUrls: ['./rebus-match-tooltip.component.scss']
})
export class RebusMatchTooltipComponent {

  @Input() public design: IDesign;

  constructor(
    private popoverCtrl: PopoverController
  ) { }

  public close() {
    this.popoverCtrl.dismiss();
  }
}
