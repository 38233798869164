import { Pipe, PipeTransform } from '@angular/core';
import { IUser } from 'rebus-models';
import { Observable, from } from 'rxjs';
import { UserService } from '../../user/service/user.service';

@Pipe({ name: 'getUserInfo' })

export class GetUserInfoPipe implements PipeTransform {

  constructor(
    private userService: UserService
  ) { }

  public transform(value: string): Observable<IUser> {
    return from(this.userService.getUserById(value));
  }

}
