import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { environment } from "@app/env";
import { isValidEventId } from "@helper/utils";
import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import { take } from "rxjs/operators";
import { consoleError } from "../../shared/helper/console.helper";
import { AuthService } from "../../shared/service/auth.service";

@Injectable()
export class CompanyService {
  constructor(
    private afdb: AngularFireDatabase,
    private authService: AuthService,
    private http: HttpClient
  ) {}

  // public getCompanies(
  //   eventId: String,
  //   userId: string,
  //   isSuperUser: boolean = false
  // ): Observable<any[]> {
  //   if (isValidEventId(eventId)) {
  //     return this.afdb
  //       .list<any>(
  //         `/Clients/${environment.clientId}/Event/${eventId}/Companies`,
  //         (ref) => ref.orderByChild("userId").equalTo(userId)
  //       ).valueChanges();
  //   }
  //   if (isSuperUser) {
  //     return this.afdb
  //       .list<any>(`/Clients/${environment.clientId}/Interactivity/Companies`)
  //       .valueChanges();
  //   }
  //   return this.afdb
  //     .list<any>(
  //       `/Clients/${environment.clientId}/Interactivity/Companies`,
  //       (ref) => ref.orderByChild("userId").equalTo(userId)
  //     )
  //     .valueChanges();
  // }


  public getCompanies(userId: string): Observable<any[]> {
    return this.afdb
      .list(`/VotingCompanies/${environment.clientId}`, (ref) =>
        ref.orderByChild("userId").equalTo(userId)
      )
      .valueChanges();
  }

  public getCompanyByNit(nit: string): Promise<any> {
    return this.afdb
      .object(`/VotingCompanies/${environment.clientId}/${nit}`)
      .query.once("value")
      .then((snap) => snap.val());
  }

  public async saveCompany(companyId, userId, status, companyForm) {
    const addPromise = [];
    if (status === "pending") {
      const token = await this.authService.getTokenUser().pipe(take(1)).toPromise();
      const headers = new HttpHeaders({
        Authorization: token,
        "Content-Type": "application/json",
      });
      addPromise.push(this.http.post(`${environment.backend}/docu/signature`, {clientId: environment.clientId, userId, companyForm}, { headers }).pipe(take(1)).toPromise());
    }

    return Promise.all([
      ...addPromise,
      this.afdb
        .object(`/VotingCompanies/${environment.clientId}/${companyId}/form`)
        .update({ ...companyForm }),
      this.afdb
        .object(`/VotingCompanies/${environment.clientId}/${companyId}/userId`)
        .set(userId),
      this.afdb
        .object(`/VotingCompanies/${environment.clientId}/${companyId}/updated_at`)
        .set(new Date().getTime()),
    ]);
  }

  public deleteCompanyRegisterByNit(companyId) {
    return this.afdb.object(`/VotingCompanies/${environment.clientId}/${companyId}`)
      .query.once("value")
      .then((snap) => snap.val())
      .then((company) => {
        const promises = [];
        if (company) {
          promises.push(
            this.afdb
              .object(`/VotingCompaniesByUser/${environment.clientId}/${company.userId}/${companyId}`)
              .remove()
          );
        }
        return Promise.all([
          ...promises,
          this.afdb
            .object(`/VotingCompanies/${environment.clientId}/${companyId}/form`)
            .remove(),
          this.afdb
            .object(`/VotingCompanies/${environment.clientId}/${companyId}/userId`)
            .remove(),
          this.afdb
            .object(`/VotingCompanies/${environment.clientId}/${companyId}/status`)
            .remove(),
          this.afdb
            .object(`/VotingCompanies/${environment.clientId}/${companyId}/updated_at`)
            .set(new Date().getTime()),
        ])
      });
  }




  public async getCompaniesSnap(
    eventId: String,
    userId: string
  ): Promise<Observable<any[]>> {
    if (isValidEventId(eventId)) {
      const snap = await this.afdb
        .list<any>(
          `/Clients/${environment.clientId}/Event/${eventId}/Companies`,
          (ref) => ref.orderByChild("userId").equalTo(userId)
        )
        .query.once("value");
      return snap.val();
    }
    const snap = await this.afdb
      .list<any>(
        `/Clients/${environment.clientId}/Interactivity/Companies`,
        (ref) => ref.orderByChild("userId").equalTo(userId)
      )
      .query.once("value");
    return snap.val();
  }

  public getCompanyConfig(eventId: String): Observable<any> {
    if (isValidEventId(eventId)) {
      return this.afdb
        .object<any>(
          `/Clients/${environment.clientId}/Event/${eventId}/CompanyConfig`
        )
        .valueChanges();
    }
    return this.afdb
      .object<any>(
        `/Clients/${environment.clientId}/Interactivity/CompanyConfig`
      )
      .valueChanges();
  }

  public getCompanyById(CompanyId: String, eventId: String): Observable<any> {
    if (isValidEventId(eventId)) {
      return this.afdb
        .object<any>(
          `/Clients/${environment.clientId}/Event/${eventId}/Companies/${CompanyId}`
        )
        .valueChanges();
    }
    return this.afdb
      .object<any>(
        `/Clients/${environment.clientId}/Interactivity/Companies/${CompanyId}`
      )
      .valueChanges();
  }

  public async createOrUpdateCompany(
    currentInfo,
    companyForm,
    userId,
    eventId: string,
    state: string
  ) {
    if (currentInfo) {
      return this.updateCompany(currentInfo.id, companyForm, eventId);
    } else {
      return this.createCompany(companyForm, userId, eventId, state).then(
        (result) => {
          this.afdb
            .object<any>(
              `/Clients/${environment.clientId}/__companies_valid/${companyForm.infoCompany.nit}`
            )
            .update({ used: true });
          return result;
        }
      );
    }
  }

  public updateCompany(companyId, company, eventId: string) {
    let reference = `/Clients/${environment.clientId}/Interactivity/Companies`;

    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Companies`;
    }

    const result = this.afdb
      .object(`${reference}/${companyId}`)
      .update(company)
      .then(() => company)
      .catch((error) => {
        consoleError("Whooops, something happen", error);
      });
    return result;
  }

  public deleteCompany(company) {
    let reference = `/Clients/${environment.clientId}/Interactivity/Companies`;
    return this.afdb
      .object(`${reference}/${company.id}`)
      .update({ delete: true })
      .then(() =>
        this.afdb
          .object(
            `/Clients/${environment.clientId}/__companies_valid/${company.infoCompany.nit}`
          )
          .update({ used: false })
      )
      .catch((error) => {
        consoleError("Whooops, something happen", error);
      });
  }

  public createCompany(
    company,
    userId,
    eventId: string,
    state: string,
    id: string = ""
  ) {
    id = this.afdb.createPushId();
    const created_at = firebase.database.ServerValue.TIMESTAMP;
    company.id = id;
    company.createdAt = created_at;
    company.userId = userId;
    company.state = state;
    company.eventId = eventId;

    let reference = `/Clients/${environment.clientId}/Interactivity/Companies`;

    if (isValidEventId(eventId)) {
      reference = `/Clients/${environment.clientId}/Event/${eventId}/Companies`;
    }

    const result = this.afdb
      .object(`${reference}/${id}`)
      .update(company)
      .then(() => company)
      .catch((error) => {
        consoleError("Whooops, something happen", error);
      });
    return result;
  }

  public getNitCompany(nit) {
    return this.afdb
      .object<any>(`/Clients/${environment.clientId}/__companies_valid/${nit}`)
      .query.once("value")
      .then((snap) => snap.val());
  }

  public async createDocumentCompany(
    infoCompany,
    representative,
    apoderado,
    companyCreatedId,
    companyFromDb
  ) {
    const token = await this.authService
      .getTokenUser()
      .pipe(take(1))
      .toPromise();
    const headers = new HttpHeaders({
      Authorization: token,
      "Content-Type": "application/json",
    });

    const first_name = apoderado.names;
    const last_names = apoderado.last_names;
    const name = first_name + " " + last_names;
    const cedula = apoderado.type_document + " " + apoderado.document_number;
    const email = apoderado.email;

    return this.http
      .post(
        `${environment.backend}/docu/signature`,
        {
          clientId: environment.clientId,
          companyId: companyCreatedId, // Id que se genera en firebase
          fields: {
            consecutivo: companyFromDb.num_poder, // sale de la base de empresas
            nombre: name,
            cedula: cedula,
            afiliado: "X", // Es vacio si no es la opcion tipo
            representante: "X", // Es vacio si no es la opcion tipo
            empresa: infoCompany.name_company,
            nit: infoCompany.nit,
            apoderado:
              apoderado.with_apoderado === ""
                ? null
                : apoderado.names_apoderado +
                  " " +
                  apoderado.last_names_apoderado, // para el caso de type apoderado
            cedula_apoderado:
              apoderado.with_apoderado === ""
                ? null
                : apoderado.type_document_apoderado +
                  " " +
                  apoderado.document_number_apoderado, // para el caso de type apoderado
          },
          type: apoderado.with_apoderado ? "apoderado" : "representante", // este es tipo de registro
          subject: "Asamblea Colsubsidio 2022",
          recipients: [
            // Para el caso type representante solo se envia ese recipient. Para el caso apoderado se envia un recipient adicional que sera el apoderado (es decir se envian 2)
            {
              id: "representante",
              name: name,
              email: email,
            },
            {
              id: apoderado.with_apoderado === "" ? null : "apoderado",
              name:
                apoderado.with_apoderado === ""
                  ? null
                  : apoderado.names_apoderado +
                    " " +
                    apoderado.last_names_apoderado,
              email:
                apoderado.with_apoderado === ""
                  ? null
                  : apoderado.email_apoderado,
            },
          ],
        },
        { headers }
      )
      .toPromise();
  }

  public async getReport() {
    const token = await this.authService
      .getTokenUser()
      .pipe(take(1))
      .toPromise();
    const headers = new HttpHeaders({
      Authorization: token,
      "Content-Type": "application/json",
    });
    return this.http
      .post<any>(
        `${environment.backend}/custom_reports/report_colsubsidio_01/`,
        { clientId: environment.clientId },
        { headers }
      )
      .toPromise();
  }
}
