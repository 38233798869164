import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/database';
import { environment } from '@app/env';
import { map } from 'rxjs/operators';
import { IAgenda } from 'rebus-models';
import { isValidEventId } from '@helper/utils';

@Injectable()
export class AgendaService {

  constructor(
    private afdb: AngularFireDatabase,
  ) { }

  public getAgendaByClient(eventId: string): Observable<IAgenda[]> {
    if (isValidEventId(eventId)) {
      return this.afdb.list<IAgenda>(`/Clients/${environment.clientId}/Event/${eventId}/Agenda`)
        .valueChanges()
        .pipe(
          map(list => list.filter(el => el.state))
        );
    } else {
      return this.afdb.list<IAgenda>(`/Clients/${environment.clientId}/Interactivity/Agenda`)
        .valueChanges()
        .pipe(
          map(list => list.filter(el => el.state))
        );
    }
  }
}
