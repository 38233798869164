import { FormControl } from '@angular/forms';

export class JsonValidator {
  public static isJsonRequired(control: FormControl): any {
    const obj = Object.keys(control.value);
    if (obj.length === 0) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  public static isJsonFiveLength(control: FormControl): any {
    const obj = Object.keys(control.value);
    if (obj.length < 5) {
      return { invalid: true };
    } else {
      return null;
    }
  }
}
