import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@app/env';
import { ITransactionItem } from 'rebus-models';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({ providedIn: 'root' })
export class TransactionService {

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) { }

  public getUserTransactions(uid: string, initialDate?: string, finalDate?: string) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });

        return this.http.get<ITransactionItem[]>(`${environment.backend}/payments/transactions`, {
          headers,
          params: {
            clientId: environment.clientId,
            userId: uid,
            // max: '5',
            initialDate: initialDate,
            finalDate: finalDate,
            sortBy: 'date_mayor'
          },
        });
      })
    );
  }

  public getUserTransactionById(id: string) {
    return this.authService.getTokenUser().pipe(
      switchMap((token: string) => {
        const headers = new HttpHeaders({ 'Authorization': token });

        return this.http.get<ITransactionItem>(`${environment.backend}/payments/transactions/${id}`, {
          headers
        });
      })
    );
  }

}
