import { Component, Input, EventEmitter, Output } from '@angular/core';
import { IStaff, IDesign } from 'rebus-models';

@Component({
  selector: 'app-item-staff-suggestion',
  templateUrl: './item-staff-suggestion.component.html',
  styleUrls: ['./item-staff-suggestion.component.scss'],
})
export class ItemStaffSuggestionComponent {

  @Input() public design: IDesign;
  @Input() public eventId: string;
  // TODO: @tote este modelo Istaff no tiene campo match como lo solicitas en la plantilla
  @Input() public staff: IStaff | any;
  @Input() public moduleNameFromView: string;

  @Output() public evOnSelectOptionStaff = new EventEmitter<any>();

  public onselectOptionStaff(option: string, staff: IStaff) {
    this.evOnSelectOptionStaff.emit({ option, staff });
  }

}
