import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IDesign, IStaff } from 'rebus-models';

@Component({
  selector: 'app-staff-widget',
  templateUrl: './staff-widget.component.html',
  styleUrls: ['./staff-widget.component.scss'],
})
export class StaffWidgetComponent {

  @Input() public design: IDesign;
  @Input() public match: number;
  @Input() public staff: IStaff;
  @Input() public views: number;

  @Output() public evOnViewDetailItem = new EventEmitter<any>();

  public onViewDetailItem() {
    this.evOnViewDetailItem.emit(this.staff.id);
  }
}
