import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IDesign, IUser } from 'rebus-models';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { getDesign } from '@state/design';
import { UtilitiesService } from '@service/utilities';
import { OpenMicService } from '../../services/openMic.service';
import { getUser } from '@state/user';
import { filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-open-mic',
  templateUrl: './open-mic.component.html',
  styleUrls: ['./open-mic.component.scss'],
})
export class OpenMicComponent implements OnInit {

  @Input() public eventId: string;
  @Input() public design: any;
  @Output() public evOnClose = new EventEmitter<any>();

  public design$: Observable<IDesign>;
  public user$: Observable<IUser>;

  public request$: Observable<any[]>;

  public requestPending: any;
  public requestAccepted: any;
  public requestAcceptedAll: any;
  public requestLive: any;
  public requestUser: any;

  public userId: string
  public optionSelected = "pending_list"

  constructor(
    private store: Store<{}>,
    private utilities: UtilitiesService,
    private openMicService: OpenMicService,
    public modalCtrl: ModalController,

  ) { }

  ngOnInit() {
    this.getRequest()
    this.user$ = this.store.select(getUser).pipe(
      filter(user => !!user),
      tap(user => this.userId = user._id)
    );
    this.design$ = this.store.select(getDesign);
  }

  public getImageUser(imageUser) {
    if (imageUser) {
      return `url('${imageUser}')`;
    } else {
      return `url('https://forwardsummit.ca/wp-content/uploads/2019/01/avatar-default.png')`;
    }
  }

  public onClose() {
    this.modalCtrl.dismiss();
    this.evOnClose.emit();
  }

  reorderItems(ev) {
    const itemMove = this.requestAccepted.splice(ev.detail.from, 1)[0];
    this.requestAccepted.splice(ev.detail.to, 0, itemMove);
    ev.detail.complete();
  }

  public getRequest() {
    this.request$ = this.openMicService.getRequest(this.eventId).pipe(
      tap(list => {
        this.requestPending = list.filter(item => !item.state)
        this.requestAccepted = list.filter(item => item.state && !item.isLive)
        this.requestAcceptedAll = list.filter(item => item.state)
        this.requestUser = list.filter(item => item.userId === this.userId)[0]
        this.requestLive = list.filter(item => item.state && item.isLive)[0]
      })
    )
  }

  public async requestOpenMic(user) {
    const load = await this.utilities.presentLoad();
    load.present();
    await this.openMicService.createRequest(user, this.eventId)
      .then((result) => {
        load.dismiss();
      });
  }

  public async cancelOpenMic(request) {
    const load = await this.utilities.presentLoad();
    load.present();
    await this.openMicService.cancelRequest(request, this.eventId)
      .then((result) => {
        load.dismiss();
      });
  }

  public async acceptOpenMic(request) {
    const load = await this.utilities.presentLoad();
    load.present();
    await this.openMicService.acceptRequest(request, this.eventId)
      .then((result) => {
        load.dismiss();
      });
  }

  public async selectUserToLive(request) {
    const load = await this.utilities.presentLoad();
    load.present();
    return this.requestAcceptedAll.map(r => {
      return this.openMicService.selectUserToLiveFalse(r, this.eventId).then(() => {
        return this.openMicService.selectUserToLiveTrue(request, this.eventId).then(() => {
          load.dismiss()
        })
      });
    })
  }

}
