import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { IDesign } from 'rebus-models';

@Component({
  selector: 'app-guest-list-invitation-modal',
  templateUrl: './guest-list-invitation-modal.component.html',
  styleUrls: ['./guest-list-invitation-modal.component.scss']
})
export class GuestListInvitationModalComponent implements OnInit {

  @Input() public design: IDesign;
  @Output() public evOnCloseModal = new EventEmitter<any>();
  @Output() public evOnSubmit = new EventEmitter<any>();
  constructor() { }

  ngOnInit() {
  }

  public onCloseModal() {
    this.evOnCloseModal.emit();
  }

  public onSendInvitation() {
    this.evOnSubmit.emit(); // enviar los datos del form
  }

}
