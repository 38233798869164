import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { IDesign } from 'rebus-models';
import { SearchService } from '../../services/search.service';
import { interval, Subject } from 'rxjs';
import { takeWhile, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-search-history',
  templateUrl: './search-history.component.html',
  styleUrls: ['./search-history.component.scss'],
})
export class SearchHistoryComponent implements OnInit, OnChanges {

  @Input() public design: IDesign;
  @Input() public moduleFrom: string;
  @Input() public pageFrom: string;
  @Input() public eventId: string;
  @Output() public evOnOpenRecentSearch = new EventEmitter<string>();

  public recentSearchs: string[] = [];
  public recentSeen: any[] = [];

  constructor(private searchService: SearchService) {
  }

  public ngOnInit() {
    this.moduleFrom = this.moduleFrom === '--' ? 'event' : this.moduleFrom;

    this.recentSearchs = this.searchService.getRecentSearchs().reverse();
    this.recentSeen = this.searchService.getRecentSeen(this.moduleFrom).reverse();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.eventId) {
      this.eventId = changes.eventId.currentValue;
    }
  }

  public onDeleteRecentSearch(index: number) {
    this.recentSearchs.reverse().splice(index, 1);
    this.searchService.updateRecentSearch(this.recentSearchs);
  }

  public openRecentSearch(search: string) {
    this.evOnOpenRecentSearch.emit(search);
  }

  public onClickItem(item) {
    this.searchService.saveSeen(item, this.moduleFrom);
  }

  public moveLeft() {
    document.getElementById('scroll').scrollTo({ left: (document.getElementById('scroll').scrollLeft - 300), behavior: 'smooth' })
  }

  public moveRight() {
    document.getElementById('scroll').scrollTo({ left: (document.getElementById('scroll').scrollLeft + 300), behavior: 'smooth' })
  }



}
