import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss']
})
export class UserImageComponent implements OnChanges {

  @Input() public image_url: string;

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.image_url) {
      this.image_url = changes.image_url.currentValue;
    }
  }

}
