import { Component, Input, EventEmitter, Output, OnChanges, SimpleChanges, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';
import { IDesign, IProduct } from 'rebus-models';
import { UtilitiesService } from '@service/utilities';
import { ProductService } from '../../services/product.service';
import { Observable } from 'rxjs';
import { AIService } from 'src/app/modules/shared/service/ai.service';

@Component({
  selector: 'app-product-item-search-result',
  templateUrl: './product-item-search-result.component.html',
  styleUrls: ['./product-item-search-result.component.scss']
})
export class ProductItemSearchResultComponent implements OnInit, OnChanges {

  @Input() public product: IProduct;
  @Input() public design: IDesign;
  @Input() public eventId: string;
  @Input() public userId: string;
  @Input() public isAdmin: Boolean = false;
  @Input() public moduleNameFromView: string;
  @Output() public evOnClickItem = new EventEmitter<void>();
  public match$: Observable<any>;

  constructor(
    private router: NavController,
    private ut: UtilitiesService,
    private productService: ProductService,
    private aiService: AIService
  ) { }

  public ngOnInit() {
    this.match$ = this.aiService.getRecommendationsForUser(this.userId, 'Products', null, this.eventId, this.product.id)

  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.product) {
      this.product = changes.product.currentValue;
    }
  }

  public getUrl() {
    return `url('assets/images/img-coupon.png')`;
  }

  public async onBuyProduct() {
    const loading = await this.ut.presentLoad();
    this.productService.addProductToShoppingCart(this.product, this.product.pay.minQuantity).then(val => {
      loading.dismiss();
    });
  }

  public onGoDetailProduct(productId) {
    this.router.navigateForward(['/product/detail', productId, this.eventId]);
    this.evOnClickItem.emit();
  }
}
